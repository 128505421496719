import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IVendor, IVendorApi } from "common/interface/IVendor";
import API, { apis } from "common/apis";
import { logoutIfNeeded } from "common/utils";
import { IApiError } from "common/interface/IApiError";
import { IPickerMappingUpload } from "common/interface/IPickerUploadScheme";
import { getPersistProcessingVendorWarehouseMapping, persistProcessingVendorWarehouseMapping, removePersistProcessingVendorWarehouseMapping } from "services/PersistService/PersistVendorServices";
import { IRequisitionUserMappingUpload } from "common/interface";
import { RootState } from "store/store";

export interface State {
  vendors?: IVendorApi;
  status: "loading" | "success" | "error" | "idle" | "cancelling" | "saving";
  error?: string;
  processingVendorWarehouseMapping: IPickerMappingUpload;
}

const initialState: State = {
  status: "idle",
  processingVendorWarehouseMapping: getPersistProcessingVendorWarehouseMapping(),
};

export const getAllVendors = createAsyncThunk<
  IVendorApi,
  { pageNo: number; filter: string, pageSize: number }
>("vendors/all", async ({ pageNo = 1, filter, pageSize }, thunkApi) => {
  const filterParams = filter
    ? Object.fromEntries(new URLSearchParams(filter))
    : "";
  const response = await API.get(apis.vendors, {
    params: {
      pageNo,
      pageSize,
      ...filterParams,
    },
  })
    .then((success) => success.data)
    .catch((error) => {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

export const getVendorById = createAsyncThunk<
  IVendor,
  { vendorId: string }
>("vendors/id", async ({ vendorId }, thunkApi) => {
  const response = await API.get(apis.vendors + `/${vendorId}`)
    .then((success) => success.data)
    .catch((error) => {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

export const saveVendor = createAsyncThunk<{}, IVendor>(
  "vendor/save",
  async (vendorFormValues, thunkApi) => {
    let response;
    if (vendorFormValues["id"] == undefined) {
      response = API.post(apis.vendors, vendorFormValues)
        .then((success) => success.data)
        .catch((error) => {
          const errorMessage =
            error?.response?.data?.message || "Something went wrong!";
          logoutIfNeeded(error, thunkApi);
          return thunkApi.rejectWithValue({ message: errorMessage });
        });
    }
    else {
      response = API.put(apis.vendors + `/${vendorFormValues['id']}`, vendorFormValues)
        .then((success) => success.data)
        .catch((error) => {
          const errorMessage =
            error?.response?.data?.message || "Something went wrong!";
          logoutIfNeeded(error, thunkApi);
          return thunkApi.rejectWithValue({ message: errorMessage });
        });
    }

    return response;
  }
);

export const getSupplyLocations = createAsyncThunk<
  string[],
  void,
  { rejectValue: { message: string } }
>("vendor/get-supply-locations", async (_, thunkApi) => {
  const response = await API.get(apis.getVendorSupplyLocations)
    .then((success) => success.data)
    .catch((error) => {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });
  return response;
});

export const updateVendorStatus = createAsyncThunk<
  {},
  {
    vendorId: number;
    isActive: boolean;
  }
>("vendor/update-status", async (payload, thunkApi) => {
  const response = await API.patch(
    `${apis.vendors}/${payload.vendorId}/status?status=${payload.isActive ? "ACTIVE" : "INACTIVE"}`,
    {
      "vendorId": payload.vendorId,
    },
  )
    .then((success) => success.data)
    .catch((error) => {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});


export const saveProcessingVendorWarehouse = createAsyncThunk<IRequisitionUserMappingUpload>("vendor/upload/save", async (_, thunkApi) => {
  const state = thunkApi.getState() as RootState;
  const { vendors: { processingVendorWarehouseMapping: { key } } } = state;

  const response = await API.post(apis.saveVendorWarehouseMapping, {}, {
    params: { key }
  })
    .then(success => success.data)
    .catch(error => {
      const errorMessage = error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

export const cancelProcessingVendorWarehouse = createAsyncThunk("vendor/upload/cancel", async (_, thunkApi) => {
  const state = thunkApi.getState() as RootState;
  const { vendors: { processingVendorWarehouseMapping: { key } } } = state;

  const response = await API.post(apis.saveVendorWarehouseMapping, {}, { //FixMe: Get Cancel API
    params: { key, cancel: 1 }
  })
    .then(success => success.data)
    .catch(error => {
      const errorMessage = error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

const vendorSlice = createSlice({
  name: "vendor",
  initialState,
  reducers: {
    setProcessingVendorWarehouseMapping: (state, action) => {
      state.status = "idle";
      state.error = "";
      state.processingVendorWarehouseMapping = action.payload;
      state.processingVendorWarehouseMapping.timeUploaded = new Date().toString();
      persistProcessingVendorWarehouseMapping(state.processingVendorWarehouseMapping);
    },
    removeProcessingVendorWarehouseMapping: (state) => {
      state.processingVendorWarehouseMapping = {};
      state.status = "idle";
      state.error = "";
      removePersistProcessingVendorWarehouseMapping();
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllVendors.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllVendors.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = "";
        state.vendors = action.payload;
      })
      .addCase(getAllVendors.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })

      .addCase(getVendorById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getVendorById.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = "";

        if (!state.vendors) {
          state.vendors = { data: [], pageNo: 1, pageSize: 25, pages: 1, total: 25 };
        }

        // if (state.vendors) {
        const vendorData = state.vendors?.data;
        const vendorInStore = vendorData.findIndex(
          (po) => po.id === action.payload.id
        );

        if (vendorInStore > -1) {
          const newPOs = [...state.vendors?.data];
          newPOs[vendorInStore] = {
            ...newPOs[vendorInStore],
            ...action.payload,
          };
          state.vendors.data = newPOs;
        } else if (vendorData) {
          vendorData.push(action.payload);
        } else {
          state.vendors.data = [action.payload];
        }
      })
      .addCase(getVendorById.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })

      .addCase(saveVendor.pending, (state) => {
        state.status = "loading";
      })
      .addCase(saveVendor.fulfilled, (state) => {
        state.status = "success";
      })
      .addCase(saveVendor.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })

      .addCase(updateVendorStatus.pending, (state) => {

        state.status = "saving";
      })
      .addCase(updateVendorStatus.fulfilled, (state, action) => {
        const temp = state.vendors;
        const index = temp!.data.findIndex(e => e.id == action.meta.arg.vendorId);
        temp!.data[index] = { ...temp!.data[index], status: action.meta.arg.isActive ? "ACTIVE" : "INACTIVE" };
        state.status = "idle";
      })
      .addCase(updateVendorStatus.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })

      /**
      * SAVE PROJECTED SR
      **/
      .addCase(saveProcessingVendorWarehouse.pending, state => {
        state.status = "loading";
      })
      .addCase(saveProcessingVendorWarehouse.fulfilled, state => {
        state.status = "success";
        state.error = "";
        state.processingVendorWarehouseMapping = {};
        removeProcessingVendorWarehouseMapping();
      })
      .addCase(saveProcessingVendorWarehouse.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })
      /**
      * GET SUPPLY LOCATIONS
      **/
      // .addCase(getSupplyLocations.fulfilled, state => {
      //   state.status = "success";
      //   state.error = "";
      // })
      // .addCase(getSupplyLocations.rejected, (state, action) => {
      //   const error = action.payload as IApiError;
      //   state.status = "error";

      //   if (error.message) {
      //     state.error = error.message;
      //   }
      // })

      /**
       * CANCEL PROJECTED SR
      **/
      .addCase(cancelProcessingVendorWarehouse.pending, state => {
        state.status = "cancelling";
      })
      .addCase(cancelProcessingVendorWarehouse.fulfilled, state => {
        state.status = "idle";
        state.error = "";
        state.processingVendorWarehouseMapping = {};
        removeProcessingVendorWarehouseMapping();
      })
      .addCase(cancelProcessingVendorWarehouse.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";
        if (error.message) {
          state.error = error.message;
        }
      })
  },
});

export const {
  removeProcessingVendorWarehouseMapping,
  setProcessingVendorWarehouseMapping,
} = vendorSlice.actions;

export default vendorSlice.reducer;
