import { IPickerMappingUpload } from "common/interface/IPickerUploadScheme";

const STORAGE_KEY_CONSUMER_UPLOAD = "processingConsumerUpload";
const STORAGE_KEY_CONSUMER_CLUSTER = "processingConsumerClusterUpload";

export const persistProcessingConsumerUpload = (data: IPickerMappingUpload) => {
    localStorage.setItem(STORAGE_KEY_CONSUMER_UPLOAD, JSON.stringify(data));
}

export const getPersistProcessingConsumerUpload = (): IPickerMappingUpload => {
    const processingWhReturnMapping = localStorage.getItem(STORAGE_KEY_CONSUMER_UPLOAD);
    return processingWhReturnMapping ? JSON.parse(processingWhReturnMapping) : {};
}

export const removePersistProcessingConsumerUpload = () => {
    localStorage.removeItem(STORAGE_KEY_CONSUMER_UPLOAD);
}

export const persistProcessingConsumerSocietyUpload = (data: IPickerMappingUpload) => {
    localStorage.setItem(STORAGE_KEY_CONSUMER_CLUSTER, JSON.stringify(data));
}

export const getPersistProcessingConsumerSocietyUpload = (): IPickerMappingUpload => {
    const processingWhReturnMapping = localStorage.getItem(STORAGE_KEY_CONSUMER_CLUSTER);
    return processingWhReturnMapping ? JSON.parse(processingWhReturnMapping) : {};
}

export const removePersistProcessingConsumerSocietyUpload = () => {
    localStorage.removeItem(STORAGE_KEY_CONSUMER_CLUSTER);
}