import { Grid, Layout } from "antd";
import { AppHeader } from "components/AppHeader/AppHeader";
import { AppLayout } from "components/AppLayout";
import { AppContent } from "components/AppLayout/AppContent";
import { AppSider } from "components/AppSider";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { fetchDataForLoggedInUser } from "store/requisition/requisitionSlice";

const { useBreakpoint } = Grid;

export const LoggedInLayout = () => {
  const screen = useBreakpoint();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchDataForLoggedInUser());
  }, [dispatch]);

  return (
    <AppLayout>
      <AppHeader />
      <Layout>
        <AppSider></AppSider>
        <AppContent style={{ ...(screen.xs && { paddingBottom: "7rem" }) }}>
          <Outlet />
        </AppContent>
      </Layout>
    </AppLayout>
  );
};
