import { INaPickingUserMappingUpload } from "common/interface";

const STORAGE_KEY_REQ = "processingMiddleMileMapping";

export const persistMiddleMileMapping = (data: INaPickingUserMappingUpload) => {
  localStorage.setItem(STORAGE_KEY_REQ, JSON.stringify(data));
}

export const getPersistedMiddleMileMapping = (): INaPickingUserMappingUpload => {
  const persistedUserMapping = localStorage.getItem(STORAGE_KEY_REQ);
  return persistedUserMapping ? JSON.parse(persistedUserMapping) : {};
}

export const removePersistedMiddleMileMapping = () => {
  localStorage.removeItem(STORAGE_KEY_REQ);
}