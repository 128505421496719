import { configureStore } from "@reduxjs/toolkit";
import appSlice from "store/app/appSlice";
import naPickingSlice from "store/na-picking/naPickingSlice";
import poSlice from "store/po/poSlice";
import requisitionSlice from "store/requisition/requisitionSlice";
import vendorSlice from "store/vendor/vendorSlice";
import storeRejectionSlice from "store/store-rejection/storeRejectionSlice";
import adminServicesSlice from "./admin-slice/adminServicesSlice";
import storeReturnSlice from "./store-return/storeReturnSlice";
import storeReturnUploadSlice from "./store-return/storeReturnUploadSlice";
import franchiseServicesSlice from "./franchise/franchiseSlice";
import stockExchangeSlice from "./stock-exchange/stockExchangeSlice";
import ppdSlice from "./ppd/ppdSlice";
import internelStockExchangeSlice from "./internel-stock-exchange/internelStockExchangeSlice";
import qualityCheckSlice from "./quality-check/qualityCheckSlice";
import middleMileSlice from "./middle-mile/middleMileSlice";
import cashCollectionSlice from "./cash-collection/cashCollectionSlice";
import clusterServicesSlice from "./cluster-slice/clusterServicesSlice";
import consumerServicesSlice from "./consumer-services/cosumerServicesSlice";
import splitSkuSlice from "./split-sku/splitSkuSlice";

const store = configureStore({
  reducer: {
    app: appSlice,
    requisition: requisitionSlice,
    naPicking: naPickingSlice,
    middleMile: middleMileSlice,
    vendors: vendorSlice,
    po: poSlice,
    ppd: ppdSlice,
    storeRejection: storeRejectionSlice,
    adminServices: adminServicesSlice,
    storeReturn: storeReturnSlice,
    storeReturnUpload: storeReturnUploadSlice,
    franchiseServices: franchiseServicesSlice,
    stockExchangeServices: stockExchangeSlice,
    internelStockExchangeServices: internelStockExchangeSlice,
    qualityCheck: qualityCheckSlice,
    cashCollection: cashCollectionSlice,
    clusterServices: clusterServicesSlice,
    consumerServices: consumerServicesSlice,
    splitSkuSlice: splitSkuSlice,
  }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export default store;