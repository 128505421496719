import { IPoUpload } from "common/interface";

const STORAGE_KEY_PO = "processingPO";
const STORAGE_KEY_BILING_RATE = "processingBillingRate";

export const persistProcessingPO = (data: IPoUpload) => {
  localStorage.setItem(STORAGE_KEY_PO, JSON.stringify(data));
}

export const getPersistedProcessingPO = (): IPoUpload => {
  const processingPO = localStorage.getItem(STORAGE_KEY_PO);
  return processingPO ? JSON.parse(processingPO) : {};
}

export const removePersistedProcessingPO = () => {
  localStorage.removeItem(STORAGE_KEY_PO);
}

export const persistProcessingBillRate = (data: IPoUpload) => {
  localStorage.setItem(STORAGE_KEY_BILING_RATE, JSON.stringify(data));
}

export const getPersistedProcessingBillRate = (): IPoUpload => {
  const processingPO = localStorage.getItem(STORAGE_KEY_BILING_RATE);
  return processingPO ? JSON.parse(processingPO) : {};
}

export const removePersistedProcessingBillRate= () => {
  localStorage.removeItem(STORAGE_KEY_BILING_RATE);
}