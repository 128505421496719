/** KEEP THIS IN SYNC WITH VARIABLES IN THEME.LESS  **/

export const variables = {
  primaryColor: "#6ea04c",
  secondaryColor: "#e2ecdb",
  wmBackgroundColorLight: "#f0f5eb",
  wmBackgroundColorLight1: "#f6f5f0",
  gray: "#DEDEDE",
  darkGray: "#878787",
  headerHeight: "64px",
  borderRadius: "4px"
};
