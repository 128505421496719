import {
    Row,
    Col,
    Alert,
    message,
    Table,
    Select,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import { apis, AUTH_HEADER_NAME } from "common/apis";
import {
    INaPickingUserMappingUpload,
    INaPickingUserMappingUploadRow,
} from "common/interface";
import { routes } from "common/routes";
import { generateHeaders } from "common/utils";
import { AppPageHeader } from "components/AppPageHeader";
import { AppUpload } from "components/AppUpload";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useDocumentTitle } from "hooks/usePageTitle";
import { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { removeProcessingClusterAmMapping, setProcessingClusterAmMapping } from "store/cluster-slice/clusterServicesSlice";

export const UploadClusterAMMappingPage = () => {
    useDocumentTitle("Cluster FA Mapping: Upload Mapping");
    const { authToken } = useAppSelector((state) => state.app.user);
    const { processingClusterAmMapping } = useAppSelector(
        (state) => state.clusterServices
    );
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [hasErrorRows, setHasErrorRows] = useState(false);

    useEffect(() => {
        const { timeUploaded } = processingClusterAmMapping;
        if (timeUploaded) {
            const uploadedTime = new Date(timeUploaded).getTime();
            const currentTime = new Date().getTime();
            const minsPassed = (currentTime - uploadedTime) / 1000 / 60;

            if (minsPassed >= 15) {
                dispatch(removeProcessingClusterAmMapping());
            }
        }

        if (processingClusterAmMapping && processingClusterAmMapping.key) {
            navigate(`/${routes.CLUSTER_SERVICE.SAVE_CLUSTER_AM_MAPPING}`, {});
        }

        return () => {
            message.destroy();
        };
    }, [processingClusterAmMapping, navigate, dispatch]);

    // useEffect(() => {
    //   return (() => {
    //     dispatch(removeProcessingNaPickingUserMapping())
    //   });
    // }, []);

    useEffect(() => {
        const failed = processingClusterAmMapping?.failedRows?.length;

        if (failed) {
            setHasErrorRows(true);
        }
    }, [processingClusterAmMapping?.failedRows]);

    const headers: { [name: string]: string } = {
        ...(authToken && { [AUTH_HEADER_NAME]: authToken }),
    };

    const onUploaded = (data: INaPickingUserMappingUpload) => {
        dispatch(setProcessingClusterAmMapping(data));
    };

    const onUploading = () => {
        dispatch(removeProcessingClusterAmMapping());
    };

    const { headerMapping } = processingClusterAmMapping;
    const headerMappingWithError = headerMapping + ",errors:Errors";

    const generateMemoizedHeaders = useMemo(
        () => generateHeaders(headerMappingWithError),
        [headerMappingWithError]
    );

    const tableHeaders = generateMemoizedHeaders || {};
    const successRows = processingClusterAmMapping?.successRows || [];
    const failedRows = processingClusterAmMapping?.failedRows || [];
    const successData = successRows?.map((row, i) => ({
        ...row,
        key: `data-success-${i}`,
    }));
    const failedData = failedRows?.map((row, i) => ({
        ...row,
        key: `data-failed-${i}`,
    }));
    const data = [...failedData, ...successData];
    const columns: ColumnsType<INaPickingUserMappingUploadRow & { key: string }> =
        [
            ...Object.keys(tableHeaders).map((key) => ({
                key,
                title: tableHeaders[key],
                dataIndex: key,
                render: (
                    text: string,
                    record: INaPickingUserMappingUploadRow & { key: string }
                ) => {
                    if (key === "errors") {
                        const errorMessages = record.errors
                            ?.map((error) => error.message)
                            .join(", ");
                        return <>{errorMessages}</>;
                    }
                    return <>{text}</>;
                },
            })),
        ];

    const addBorderToErrorRows = (
        record: INaPickingUserMappingUploadRow & { key: string }
    ) => {
        if (record.errors && record.errors.length) {
            return "error-row";
        }

        return "";
    };

    return (
        <>
            <Row>
                <Col>
                    <AppPageHeader title="Upload Cluster FA Mapping" />
                </Col>
            </Row>
            <Row>
                <Col lg={16} xs={24}>
                    <AppUpload
                        uploadUrl={apis.uploadClusterAmMapping}
                        headers={headers}
                        onUploaded={onUploaded}
                        onUploading={onUploading}
                        hasErrorRows={hasErrorRows}
                        data={{}}
                    />
                </Col>
            </Row>
            {!!Object.keys(processingClusterAmMapping).length &&
                !processingClusterAmMapping.key && (
                    <>
                        <Row>
                            <Col lg={16} xs={24}>
                                <Alert
                                    type="error"
                                    message="Errors in sheet data."
                                    description="Please correct errors and upload again."
                                    showIcon
                                    banner
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Table
                                size="small"
                                columns={columns}
                                dataSource={data}
                                rowClassName={(record) => addBorderToErrorRows(record)}
                                scroll={{ y: 500 }}
                                className="table-with-errors"
                            ></Table>
                        </Row>
                    </>
                )}
        </>
    );
};
