import {
  Alert,
  Col,
  message,
  Row,
  Table,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import { apis, AUTH_HEADER_NAME } from "common/apis";
import { IPickerMappingUpload, IPickerMappingUploadRow } from "common/interface/IPickerUploadScheme";
import { routes } from "common/routes";
import { generateHeaders } from "common/utils";

import { AppPageHeader } from "components/AppPageHeader";
import { AppUpload } from "components/AppUpload";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useDocumentTitle } from "hooks/usePageTitle";
import { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { removeProcessingHold, setProcessingHoldAdjustment } from "store/stock-exchange/stockExchangeSlice";

export const UploadHoldAdjustmentPage = () => {
  useDocumentTitle("Upload Hold Adjustment");
  const { authToken } = useAppSelector((state) => state.app.user);

  const { processingHoldAdjustment, warehouses, inventoryTypes } = useAppSelector(
    (state) => state.stockExchangeServices
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [hasErrorRows, setHasErrorRows] = useState(false);

  useEffect(() => {
    if (
      processingHoldAdjustment &&
      processingHoldAdjustment.key
    ) {
      navigate(`/${routes.STOCK_EXCHANGE.SAVE_HOLD}`, {});
    }

    return () => {
      message.destroy();
    };
  }, [processingHoldAdjustment, navigate]);

  useEffect(() => {
    const failed = processingHoldAdjustment?.failedRows?.length;

    if (failed) {
      setHasErrorRows(true);
    }
  }, [processingHoldAdjustment?.failedRows]);

  const headers: { [name: string]: string } = {
    ...(authToken && { [AUTH_HEADER_NAME]: authToken }),
  };

  const onUploaded = (data: IPickerMappingUpload) => {
    dispatch(setProcessingHoldAdjustment(data));
  };

  const onUploading = () => {
    dispatch(removeProcessingHold());
  };

  const { headerMapping } = processingHoldAdjustment;
  const headerMappingWithError = headerMapping + ",errors:Errors";

  const generateMemoizedHeaders = useMemo(
    () => generateHeaders(headerMappingWithError),
    [headerMappingWithError]
  );

  const tableHeaders = generateMemoizedHeaders || {};
  const successRows = processingHoldAdjustment?.successRows || [];
  const failedRows = processingHoldAdjustment?.failedRows || [];
  const successData = successRows?.map((row, i) => ({
    ...row,
    key: `data-success-${i}`,
  }));
  const failedData = failedRows?.map((row, i) => ({
    ...row,
    key: `data-failed-${i}`,
  }));
  const data = [...failedData, ...successData];
  const columns: ColumnsType<
    IPickerMappingUploadRow & { key: string }
  > = [
      ...Object.keys(tableHeaders).map((key) => ({
        key,
        title: tableHeaders[key],
        dataIndex: key,
        render: (
          text: string,
          record: IPickerMappingUploadRow & { key: string }
        ) => {
          if (key === "errors") {
            const errorMessages = record.errors
              ?.map((error) => error.message)
              .join(", ");
            return <>{errorMessages}</>;
          }
          return <>{text}</>;
        },
      })),
    ];

  const addBorderToErrorRows = (
    record: IPickerMappingUploadRow & { key: string }
  ) => {
    if (record.errors && record.errors.length) {
      return "error-row";
    }

    return "";
  };

  return (
    <>
      <Row>
        <Col>
          <AppPageHeader title="Upload Hold Adjustment" />
        </Col>
      </Row>
      {(
        <Row>
          <Col lg={16} xs={24}>
            <AppUpload
              uploadUrl={apis.uploadHoldAdjustment}
              headers={headers}
              onUploaded={onUploaded}
              onUploading={onUploading}
              hasErrorRows={hasErrorRows}
            />
          </Col>
        </Row>
      )}
      {Object.keys(processingHoldAdjustment).length != 0 &&
        !processingHoldAdjustment.key && (
          <>
            <Row>
              <Col lg={16} xs={24}>
                <Alert
                  type="error"
                  message="Errors in sheet data."
                  description="Please correct errors and upload again."
                  showIcon
                  banner
                />
              </Col>
            </Row>
            <Row>
              <Table
                size="small"
                columns={columns}
                dataSource={data}
                rowClassName={(record) => addBorderToErrorRows(record)}
                scroll={{ y: 500 }}
                className="table-with-errors"
              ></Table>
            </Row>
          </>
        )}
    </>
  );
};
