import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosRequestHeaders } from "axios";
import API, { apis } from "common/apis";
import { IRequisitionUserMappingUpload } from "common/interface";
import { IApiError } from "common/interface/IApiError";
import { IPpdDeliveryOrdersApi } from "common/interface/IPpdDeliveryOrders";
import { IPpdOrders, IPpdOrdersApi } from "common/interface/IPpdOrders";
import { IPickerMappingUpload as IPickerMappingUpload } from "common/interface/IPickerUploadScheme";
import { logoutIfNeeded } from "common/utils";
import { getPersistedProcessingPickerMapping, persistProcessingPickerMapping, removePersistedProcessingPickerMapping } from "services/PersistService/PersistPickerService";
import { RootState } from "store/store";
import { PpdInfo, TableInfo } from "common/interface/TableInfo";

export interface State {
    status: "loading" | "success" | "error" | "idle" | "cancelling" | "saving";
    error?: string;
    processingPickerMapping: IPickerMappingUpload;
    ppdOrders?: IPpdOrdersApi;
    ppdDeliveryOrders?: IPpdDeliveryOrdersApi;
    ppdTableInfo?: PpdInfo;
}

const initialState: State = {
    status: "idle",
    processingPickerMapping: getPersistedProcessingPickerMapping(),
};

export const savePickerMapping = createAsyncThunk<IRequisitionUserMappingUpload>("ppd/picker-packer/upload/save", async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const { ppd: { processingPickerMapping: { key } } } = state;

    const response = await API.post(apis.savePickerMapping, {}, {
        params: { key }
    })
        .then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
});

export const cancelProcessingPickerMapping = createAsyncThunk("ppd/picker-packer/upload/cancel", async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const { ppd: { processingPickerMapping: { key } } } = state;

    const response = await API.post(apis.savePickerMapping, {}, { //FixMe: Get Cancel API
        params: { key, cancel: 1 }
    })
        .then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
});

export const cronRemapUsers = createAsyncThunk<{}, AxiosRequestHeaders>("ppd/cron/remap-users", async (headers, thunkApi) => {
    const response = await API.post(apis.cronRemapUsers, {}, { headers: headers }).then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

});

export const getPpdOrderDetails = createAsyncThunk<IPpdOrders, string>("ppd/orders/id", async (id, thunkApi) => {
    const response = await API.get(`${apis.ppdOrder}/${id}`).then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });
    return response;
});

export const getPpdStoreTables = createAsyncThunk<PpdInfo, string>("ppd/table", async (storeId, thunkApi) => {
    const response = await API.get(`${apis.getPpdTableInfo}${storeId}`).then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });
    return response;
});

export const saveTableInfo = createAsyncThunk<number, TableInfo & { storeId: string }>("ppd/table/add", async (tableInfo, thunkApi) => {
    if (tableInfo.qa == undefined) {
        tableInfo.qa = 1;
    }
    const response = await API.put(`${apis.savePpdTableInfo}`, tableInfo).then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });
    return response;
});

export const removeTableInfo = createAsyncThunk<number, number>("ppd/table/remove", async (tableId, thunkApi) => {

    const response = await API.put(`${apis.savePpdTableInfo}/${tableId}/inactive`).then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });
    return response;
});

export const getPpdDeliveryOrderDetails = createAsyncThunk<IPpdOrders, string>("ppd/delivery/orders/id", async (id, thunkApi) => {
    const response = await API.get(`${apis.ppdDeliveryOrders}/${id}`).then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });
    return response;
});

export const getPpdOrders = createAsyncThunk<
    IPpdOrdersApi,
    { pageNo: number; pageSize: number; filter: string }
>("/ppd/orders", async ({ pageNo = 1, pageSize = 25, filter }, thunkApi) => {
    const filterParams = filter
        ? Object.fromEntries(new URLSearchParams(filter))
        : "";
    const response = await API.get(apis.ppdOrders, {
        params: {
            pageNo,
            pageSize,
            ...filterParams,
        },
    })
        .then((success) => success.data)
        .catch((error) => {
            const errorMessage =
                error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
});

export const cancelPpdOrder = createAsyncThunk("/ppd/order/cancel", async (data: any, thunkApi) => {
    const id = data["orderId"];
    const response = await API.post(apis.cancelPpdOrder + id + "/cancel", data)
        .then(success => success.data as IPpdOrders)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });
    return response;
});

export const getPpdDeliveryOrders = createAsyncThunk<
    IPpdDeliveryOrdersApi,
    { pageNo: number; pageSize: number, filter: string }
>("/ppd/delivery/orders", async ({ pageNo = 1, pageSize = 25, filter }, thunkApi) => {
    const filterParams = filter
        ? Object.fromEntries(new URLSearchParams(filter))
        : "";
    const response = await API.get(apis.ppdDeliveryOrders, {
        params: {
            pageNo,
            pageSize,
            ...filterParams,
        },
    })
        .then((success) => success.data)
        .catch((error) => {
            const errorMessage =
                error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
});

const ppdSlice = createSlice({
    name: "ppd",
    initialState,
    reducers: {
        setProcessingPickerMapping: (state, action) => {
            state.status = "idle";
            state.error = "";
            state.processingPickerMapping = action.payload;
            state.processingPickerMapping.timeUploaded = new Date().toString();
            persistProcessingPickerMapping(state.processingPickerMapping);
        },
        removeProcessingPickerMapping: (state) => {
            state.processingPickerMapping = {};
            state.status = "idle";
            state.error = "";
            removePersistedProcessingPickerMapping();
        },
        removePpdTableInfo: (state) => {
            state.ppdTableInfo = undefined;
            state.status = "idle";
            state.error = "";
        },
    },
    extraReducers(builder) {
        builder
            /**
            * SAVE REQUISITION USER MAPPING
            **/
            .addCase(savePickerMapping.pending, state => {
                state.status = "loading";
            })
            .addCase(savePickerMapping.fulfilled, state => {
                state.status = "success";
                state.error = "";
                state.processingPickerMapping = {};
                removePersistedProcessingPickerMapping();
            })
            .addCase(savePickerMapping.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";

                if (error.message) {
                    state.error = error.message;
                }
            })

            /**
             * CANCEL PROCESSING REQUISITION
            **/
            .addCase(cancelProcessingPickerMapping.pending, state => {
                state.status = "cancelling";
            })
            .addCase(cancelProcessingPickerMapping.fulfilled, state => {
                state.status = "idle";
                state.error = "";
                state.processingPickerMapping = {};
                removePersistedProcessingPickerMapping();
            })
            .addCase(cancelProcessingPickerMapping.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";
                if (error.message) {
                    state.error = error.message;
                }
            })
            /**
             * CRON PPD REMAP USERS
             */
            .addCase(cronRemapUsers.pending, state => {
                state.status = "loading";
            })
            .addCase(cronRemapUsers.fulfilled, state => {
                state.status = "success";
                state.error = "";
            })
            .addCase(cronRemapUsers.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";
                if (error.message) {
                    state.error = error.message;
                }
            })

            /**
             * GET PPD Orders
             **/
            .addCase(getPpdOrders.pending, state => {
                state.status = "loading";
            })
            .addCase(getPpdOrders.fulfilled, (state, action) => {
                state.status = "success";
                state.error = "";
                state.ppdOrders = action.payload;
            })
            .addCase(getPpdOrders.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";

                if (error.message) {
                    state.error = error.message;
                }
            })

            /**
             * GET PPD Delivery Orders
             **/
            .addCase(getPpdDeliveryOrders.pending, state => {
                state.status = "loading";
            })
            .addCase(getPpdDeliveryOrders.fulfilled, (state, action) => {
                state.status = "success";
                state.error = "";
                state.ppdDeliveryOrders = action.payload;
            })
            .addCase(getPpdDeliveryOrders.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";

                if (error.message) {
                    state.error = error.message;
                }
            })

            /**
             * GET PPD Table Info
             **/
            .addCase(getPpdStoreTables.pending, state => {
                state.status = "loading";
            })
            .addCase(getPpdStoreTables.fulfilled, (state, action) => {
                state.status = "success";
                state.error = "";
                state.ppdTableInfo = action.payload;
            })
            .addCase(getPpdStoreTables.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error"; 
                state.ppdTableInfo = { storeId: parseInt(action.meta.arg), tableInfo: [], };
                if (error.message) {
                    state.error = error.message;
                }
            })

            /**
             * Save Table Info
             **/
            .addCase(saveTableInfo.pending, state => {
                state.status = "loading";
            })
            .addCase(saveTableInfo.fulfilled, (state, action) => {
                state.status = "success";
                state.error = "";
                let temp = state.ppdTableInfo?.tableInfo ?? [];
                var index = temp.findIndex((e) => e.id == action.meta.arg.id);
                if (index != -1) {
                    temp[index] = action.meta.arg;
                } else {
                    temp.push({ ...action.meta.arg, id: action.payload });
                }
                // }
                state.ppdTableInfo = { storeId: state.ppdTableInfo!.storeId!, tableInfo: temp };
            }
            )
            .addCase(saveTableInfo.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";

                if (error.message) {
                    state.error = error.message;
                }
            })

            /**
             * Delete Table Info
             **/
            .addCase(removeTableInfo.pending, state => {
                state.status = "loading";
            })
            .addCase(removeTableInfo.fulfilled, (state, action) => {
                state.status = "success";
                state.error = "";
                let temp = state.ppdTableInfo?.tableInfo ?? [];
                var index = temp.findIndex((e) => e.id == action.meta.arg);
                temp.splice(index, 1);
                state.ppdTableInfo = { storeId: state.ppdTableInfo!.storeId!, tableInfo: temp };
            }
            )
            .addCase(removeTableInfo.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";

                if (error.message) {
                    state.error = error.message;
                }
            })

            /**
             * Cancel PPD Delivery Orders
             **/
            .addCase(cancelPpdOrder.pending, state => {
                state.status = "saving";
            })
            .addCase(cancelPpdOrder.fulfilled, (state, action) => {
                state.status = "success";
                state.error = "";
                const index = Array.from(state.ppdOrders?.data ?? []).findIndex((e) => e.id === action.payload.id);
                if (index > -1) {
                    const tempRows = Array.from(state.ppdOrders?.data ?? []);
                    tempRows[index] = action.payload;
                    state.ppdOrders!.data = tempRows;
                    state.ppdOrders = state.ppdOrders;
                }
            })
            .addCase(cancelPpdOrder.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";

                if (error.message) {
                    state.error = error.message;
                }
            })
    }
});

export const {
    setProcessingPickerMapping,
    removeProcessingPickerMapping,
    removePpdTableInfo,
} = ppdSlice.actions;
export default ppdSlice.reducer; 