import {
  Breadcrumb,
  Button,
  Col,
  Descriptions,
  Form,
  Input,
  InputNumber,
  message,
  Popconfirm,
  Row,
  Select,
  Table,
  Typography,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import { IStockTransfer, StockTransferItem } from "common/interface/IStockTransfer";
import { getDateAndTimeFromDateFormatted, getDateAndTimeFromISODate } from "common/utils";
import { variables } from "common/variables";
import { AppPageHeader } from "components/AppPageHeader";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useDocumentTitle } from "hooks/usePageTitle";
import React, { useCallback, useEffect, useState } from "react";
import { BsPlus } from "react-icons/bs";
import { NavLink, useNavigate, useParams, useLocation } from "react-router-dom";
import { deleteStockTranferItem, fetchWarehouses, getStockTranferInfo, updateStockTranferItemAtDstWH, updateStockTranferItemAtSrcWH, updateSTStatus } from "store/stock-exchange/stockExchangeSlice";

const MESSAGE_KEY = "stock_exchange_info";
type PartialStockTransferItemType = Partial<StockTransferItem> & { key: string };

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "select" | "text";
  record: PartialStockTransferItemType;
  index: number;
  children: React.ReactNode;
}

const { Option } = Select;

const STViewPage = () => {
  useDocumentTitle("Purchase Order");
  const dispatch = useAppDispatch();
  const { stockTransferData, error, status } = useAppSelector((state) => state.stockExchangeServices);
  const { masterSkus = [] } = useAppSelector((state) => state.requisition);
  const { whId: currentWhId, } = useAppSelector((state) => state.app);
  const { warehouses } = useAppSelector(
    (state) => state.stockExchangeServices
  );
  const { id } = useParams();
  const [viewedST, setViewedST] = useState<IStockTransfer>();
  const [tableColumns, setTableColumns] =
    useState<ColumnsType<PartialStockTransferItemType>>();
  const [tableRows, setTableRows] = useState<any[]>();
  const [tempTableRows, setTempTableRows] = useState<any[]>();
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const [isAddingItem, setIsAddingItem] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (editingKey) {
      const selectedRowData = viewedST?.stockTransferItems.find(e => e.id.toString() === editingKey.toString().split("-")[0]);
      form.setFieldsValue({
        skuId: masterSkus.find(k => k.skuCode === selectedRowData?.skuCode)?.id.toString(),
      });
    }
  }, [editingKey, form]);

  const isEditing = useCallback(
    (record: PartialStockTransferItemType) => record?.key === editingKey,
    [editingKey]
  );

  const edit = useCallback(
    (record: PartialStockTransferItemType) => {
      form.setFieldsValue({ ...record });
      setEditingKey(record.key);
    },
    [form]
  );

  useEffect(() => {
    dispatch(fetchWarehouses());
  }, []); 

  const saveItem = useCallback(
    (stId: number, stItem: StockTransferItem) => {
      const thisST = stockTransferData?.data.find((p) => p.id.toString() === id);
      if (currentWhId === thisST?.whIdSrc) {
        dispatch(updateStockTranferItemAtSrcWH({ stId, newItem: stItem }));
      }
      else if (currentWhId === thisST?.whIdDest){      
        dispatch(updateStockTranferItemAtDstWH({ stId, newItem: stItem }));
      }
    },
    [dispatch]
  );

  const deleteItem = useCallback(
    (stId: number, stItem: StockTransferItem) => {
      dispatch(deleteStockTranferItem({ stId, newItem: stItem }));
    },
    [dispatch]
  );

  const save = useCallback(
    async (stId: number, stItemId: string) => {
      try {
        const row = await form.validateFields();
        const index = masterSkus.findIndex(
          (item: any) => row.skuId === item.id.toString()
        );

        if (viewedST) {
          if (index > -1) {
            saveItem(stId, {
              ...row,
              skuCode: masterSkus[index].skuCode,
              skuName: masterSkus[index].name,
              id: stItemId,
            });
            setEditingKey("");
          } else {
            saveItem(stId, row);
            setEditingKey("");
          }
        }

        setTempTableRows([]);
        setIsAddingItem(false);
        form.resetFields();
      } catch (errInfo) {
        console.log("Validate Failed:", errInfo);
      }
    },
    [form, masterSkus, saveItem, viewedST]
  );

  useEffect(() => {
    if (id) {
      dispatch(getStockTranferInfo({ id: parseInt(id) }));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (status === "loading") {
      message.loading({ content: "loading purchase order", key: MESSAGE_KEY });
    } else if (status === "saving") {
      message.loading({ content: "saving purchase order", key: MESSAGE_KEY });
    } else if (status === "error") {
      message.error({ content: error, key: MESSAGE_KEY });
    }

    return () => {
      message.destroy();
    };
  }, [status, error]);
  const previousPageRoute = location.pathname.includes("received") ? "/stock-exchange-received/" : "/stock-exchange/";

  useEffect(() => {
    const thisST = stockTransferData?.data.find((p) => p.id.toString() === id);
    if (thisST) {
      setViewedST(thisST); ///TODO
    } else {
      navigate(previousPageRoute);
    }
  }, [navigate, id, stockTransferData?.data]);

  const cancel = useCallback(() => {
    setEditingKey("");
    setIsAddingItem(false);
    setTempTableRows([]);
    form.resetFields();
  }, [form]);

  const updateST = useCallback(() => {
    dispatch(updateSTStatus({ currentST: viewedST!,}))
      .unwrap()
      .then(() => navigate(previousPageRoute)); //TODO
  }, [dispatch, navigate, viewedST]);

  useEffect(() => {
    const thisST = stockTransferData?.data?.find((po) => po.id.toString() === id);

    const headerKeys: { [key: string]: string } = {
      skuCode: "Sku Code",
      destQty: "Destination Qty",
      srcQty: "Source Qty",
      // stockId: "Stock Id",
      actions: "Actions",
    };

    if (thisST) {
      const columns = [
        ...Object.keys(headerKeys).filter((key) => {
          if (viewedST?.whIdSrc === currentWhId && key === "destQty") {
            return false;
          }
          return true;
        }).map((key) => ({
          key,
          title: headerKeys[key],
          dataIndex: key,
          editable: key !== "actions" && true,
          render: (text: string, record: any) => {
            if (key === "skuCode") {
              return (
                <>
                  <div>{record.skuCode}</div>
                  <div>{masterSkus.find(e => e.skuCode === record.skuCode)?.name ?? ""}</div>
                </>
              );
            }
            if (key === "actions") {
              const editable = isEditing(record);
              return editable ? (
                <Row
                  align="middle"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  <Col style={{ marginRight: ".5rem" }}>
                    <Typography.Link
                      onClick={() => save(thisST.id, record.id)}
                      style={{ marginRight: 8 }}
                      disabled={status === "loading"}
                    >
                      Save
                    </Typography.Link>
                  </Col>
                  <Col>
                    <Typography.Link
                      onClick={cancel}
                      style={{ marginRight: 8 }}
                      disabled={status === "loading"}
                      type="secondary"
                    >
                      Cancel
                    </Typography.Link>
                  </Col>
                </Row>
              ) : (
                <Row><Col xs={20} sm={4} md={5} lg={4} >
                  <Typography.Link
                    disabled={editingKey !== ""
                      || disableSaving()
                    }
                    onClick={() => edit(record)}
                  >
                    Edit
                  </Typography.Link></Col>
                  {editingKey === "" && <Col >
                    <Popconfirm title="Sure to delete?" onConfirm={() => deleteItem(viewedST!.id, record)}>
                      <Typography.Link disabled={editingKey !== ""
                        || !["DEFAULT"].includes(viewedST?.status ?? "") || viewedST?.whIdDest === currentWhId
                      }>
                        Delete
                      </Typography.Link>
                    </Popconfirm>
                  </Col>}

                </Row>

              );
            }
            return <>{text}</>;
          },
        })),
      ];

      const mergedColumns = columns.map((col, i) => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          ...(i === 0 && { width: "220px" }),
          onCell: (record: PartialStockTransferItemType) => ({
            record,
            inputType: ["skuCode"].includes(col.dataIndex) ? "select" : "text",
            dataIndex: col.dataIndex,
            title: col.title,
            editing: isEditing(record),
          }),
        };
      });

      const rows: any[] =
        thisST?.stockTransferItems

          ?.map((item, idx) => ({
            destQty: item.destQty,
            srcQty: item.srcQty,
            stockId: item.stockId,
            key: item.id + `-${idx}`,
            skuCode: item.skuCode,
          }))
          ?.filter(
            (poI) => (isAddingItem && !(poI as any).temp) || !isAddingItem
          ) || [];

      setViewedST(thisST);
      setTableRows([...rows, ...(tempTableRows || [])]);
      setTableColumns(mergedColumns);
    }
  }, [
    cancel,
    edit,
    editingKey,
    form,
    isAddingItem,
    isEditing,
    id,
    stockTransferData?.data,
    save,
    tempTableRows,
    viewedST?.stockTransferItems,
    viewedST?.status,
  ]);

  const getSkuOptions = (includeSku?: string) => {
    if (masterSkus) {
      const selectedSkus = viewedST?.stockTransferItems
        .filter((poi) => poi.skuCode !== includeSku)
        .map((poI) => poI.skuCode);
      return masterSkus.reduce((acc, sku) => {
        if (!selectedSkus?.includes(sku.skuCode)) {
          return (acc = {
            ...acc,
            [sku.id]: {
              value: sku.id,
              label: sku.skuCode,
              skuCode: sku.skuCode,
              skuName: sku.name,
            },
          });
        }

        return acc;
      }, {} as Record<string, { skuCode: string; skuName: string }>);
    }

    return {};
  };

  const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    let inputNode =
      inputType === "select" ? (
        <>
          <div>{record.skuCode}</div>
          <div>{masterSkus.find(e => e.skuCode === record.skuCode)?.name}</div>
        </>
      ) : (
        <InputNumber required disabled={(currentWhId === viewedST?.whIdDest && dataIndex === "srcQty")} min={0}/>
      );
    if ((isAddingItem || isEditing) && inputType === "select") {
      inputNode = <Select
        showSearch
        optionFilterProp=""
        filterOption={(input, option) => {
          const keyWords =
            option?.children?.map((child) =>
              child.props.children.toLowerCase()
            ) || [];
          return keyWords.some((key) => key.includes(input.toLowerCase()));
        }}
        disabled={(!isAddingItem ?? false)}
        style={{ width: "200px" }}
      >
        {Object.entries(getSkuOptions(record.skuCode)).map(
          ([key, value]) => (
            <Option key={key} value={value.skuCode}>
              <div>{value.skuCode}</div>
              <div>{value.skuName}</div>
            </Option>
          )
        )
        }
      </Select>
    }

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required:
                  !(dataIndex === "billingRate" || dataIndex === "totalAmount"),
                message: `Please ${inputType === "select" ? "select" : "input"
                  } ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const onAddItem = () => {
    let idx = tableRows?.length || 0;
    const key = (++idx).toString();
    setTempTableRows([...(tempTableRows || []), { key, temp: true, }]);
    setEditingKey(key);
    setIsAddingItem(true);

    setTimeout(() => {
      document
        .querySelector(".editable-last-row")
        ?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  const getIncrementStatusLabel = (currentStatus: string) => {
    const labels: Record<string, string> = {
      "DEFAULT": "Dispatch Exchange",
      "IN_TRANSIT": "Reached Destination",
      "REACHED_DEST": "Receive Exchange",
    };
    return labels[currentStatus];
  }

  const stInfo = viewedST && (
    <>
      <Descriptions
        size="small"
        layout="vertical"
        labelStyle={{ fontWeight: "bold" }}
        bordered
        column={{ md: 4 }}
      >
        <Descriptions.Item label="ID">
          {viewedST.id}
        </Descriptions.Item>
        <Descriptions.Item label="Status">{viewedST.status}</Descriptions.Item>
        <Descriptions.Item label="Inventory Type">{viewedST.inventoryType}</Descriptions.Item>
        {(viewedST.status !== "RECIEVED") && (
          <Descriptions.Item label="Actions">
            <Button
              type="primary"
              onClick={() => updateST()}
              disabled={(["IN_TRANSIT"].includes(viewedST.status) && viewedST.whIdSrc === currentWhId) || status === "loading"}
              loading={status === "loading"}
            >
              {getIncrementStatusLabel(viewedST.status)}
            </Button>
          </Descriptions.Item>
        )}
      </Descriptions>

      {(viewedST.createdOn || viewedST.createdOn) && <Descriptions
        size="small"
        layout="vertical"
        labelStyle={{ fontWeight: "bold" }}
        bordered
        column={4}
        style={{ marginTop: "20px" }}
      >
        {viewedST.modifiedOn && <Descriptions.Item label="Modified on">
          {getDateAndTimeFromDateFormatted(viewedST.modifiedOn)}
        </Descriptions.Item>}
        {viewedST.createdOn && <Descriptions.Item label="Created on">
          {getDateAndTimeFromDateFormatted(viewedST.createdOn)}
        </Descriptions.Item>}
        {viewedST.whIdSrc && <Descriptions.Item label="Source Warehouse">
          {(warehouses.find(e => e.id === viewedST.whIdSrc))?.name} # {(warehouses.find(e => e.id === viewedST.whIdSrc))?.id }
        </Descriptions.Item>}
        {viewedST.whIdDest && <Descriptions.Item label="Destination Warehouse">
        {(warehouses.find(e => e.id === viewedST.whIdDest))?.name} # {(warehouses.find(e => e.id === viewedST.whIdDest))?.id }
        </Descriptions.Item>}
      </Descriptions>}

      {!!(tableRows && tableRows.length) && (
        <Row style={{ marginTop: "20px" }}>
          <Col xs={24}>
            <Form form={form} component={false}>
              <Table
                className="po-item-table"
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                bordered
                dataSource={tableRows}
                columns={tableColumns}
                rowClassName={(index) =>
                  index === tableRows.length - 1
                    ? "editable-last-row editable-row"
                    : "editable-row"
                }
                pagination={false}
                scroll={{ x: "100vh", y: "65vh" }}
                style={{ width: "100%" }}
                size="small"
                footer={() => viewedST.whIdDest === currentWhId ? <></> : (
                  <Button
                    type="primary"
                    icon={<BsPlus />}
                    onClick={onAddItem}
                    disabled={isAddingItem || disableSaving()}
                  >
                    Add Item
                  </Button>
                )}
              />
            </Form>
          </Col>
        </Row>
      )}
    </>
  );

  const disableSaving = () => {
    const thisST = stockTransferData?.data?.find((po) => po.id.toString() === id);
    
    if ([ "IN_TRANSIT", "RECEIVED"].includes(viewedST?.status!)) {
      return true;
    }

    if (viewedST?.status === "DEFAULT" && thisST?.whIdDest === currentWhId) {
      return true;
    }

    if (viewedST?.status === "REACHED_DEST" && thisST?.whIdSrc === currentWhId) {
      return true;
    }

    return false;
  }

  return (
    <>
      <Row
        style={{
          margin: "1rem 0",
          position: "sticky",
          top: 0,
          zIndex: 1,
          background: variables.wmBackgroundColorLight1,
          padding: "5px",
        }}
      >
        <Col xs={24}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to={previousPageRoute}>All {location.pathname.includes("received") ? "Received" : "Sent" } Stock Exchange</NavLink> 
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {viewedST?.id || "Stock Exchange"}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col>
          <AppPageHeader title="Stock Exchange" />
        </Col>
      </Row>
      <Row>
        <Col xs={24}>{stInfo}</Col>
      </Row>
    </>
  );
};

export default STViewPage;
