import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd";
import { useAppSelector } from "hooks/useAppSelector";
import moment from "moment";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

const StyledFilterContainer = styled.div`
  &,
  & .filter {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;

    &__item {
      margin: 0.5rem;

      & .ant-form-item {
        margin: 0;
      }

      &--submit {
        align-self: flex-end;
      }
    }
  }
`;

export const AppClusterRouteFilter = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [form] = Form.useForm();
    const { clustersList } = useAppSelector((state) => state.middleMile);

    useEffect(() => {
        const params = Object.fromEntries(new URLSearchParams(searchParams));
        form.setFieldsValue({ ...params });
    }, [form, searchParams]);

    const onSearch = (values: Record<string, string>) => {
        const params: Record<string, string> = {};
        Object.entries(values).forEach(([key, value]) => {
            if (value) {                
                params[key] = value;
            }
        });
        setSearchParams(params);
    };

    return (
        <StyledFilterContainer>
            <Form
                form={form}
                name="ppd-order-filter-form"
                onFinish={onSearch}
                layout="vertical"
                style={{ width: "100%" }}
            >
                <Row className="filter">
                    <Col className="filter__item">
                        <Form.Item name="clusterId" label="Cluster ID" required>
                            <Select
                                placeholder="Select Cluster ID"
                                size="small"
                                allowClear
                                options={(clustersList ?? []).map((clusterInfo) => ({
                                    value: clusterInfo.id?.toString(),
                                    label: `${clusterInfo.name} || ${ clusterInfo.id}`,
                                }),)}

                            />
                        </Form.Item>
                    </Col>

                    <Col
                        xs={20}
                        sm={4}
                        md={2}
                        lg={2}
                        className="filter__item filter__item--submit"
                    >
                        <Form.Item>
                            <Button type="primary" htmlType="submit" size="small">
                                Search
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </StyledFilterContainer>
    );
};