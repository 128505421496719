import styled from "styled-components";
import { Col, Layout, Row } from "antd";
import { AppSiderTrigger } from "components/AppSiderTrigger";
import { useAppDispatch } from "hooks/useAppDispatch";
import { setSider, toggleSider } from "store/app/appSlice";
import { AppLogo } from "components/AppLogo";
import { useEffect } from "react";
import { useAppSelector } from "hooks/useAppSelector";
import { AppUserInfoMenu } from "components/AppHeader/AppUserInfoMenu";

const { Header } = Layout;

const StyledHeader = styled(Header)`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  background-color: white;
  border-bottom: 1px solid #ebebeb;
  padding: 0 1.2rem;
`;

export const AppHeaderMobile = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.app);

  useEffect(() => {
    dispatch(setSider(false));
  }, [dispatch]);

  return (
    <StyledHeader>
      <Row justify="space-between">
        <Col>
          {user?.isAuthenticated && (
            <AppSiderTrigger onTrigger={() => dispatch(toggleSider())} />
          )}
        </Col>
        <Col>
          <AppLogo />
        </Col>
        <Col>{user?.isAuthenticated && <AppUserInfoMenu />}</Col>
      </Row>
    </StyledHeader>
  );
};
