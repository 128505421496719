import { Button, Col, DatePicker, Form, Input, Row } from "antd";
import moment from "moment";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

const StyledFilterContainer = styled.div`
  &,
  & .filter {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;

    &__item {
      margin: 0.5rem;

      & .ant-form-item {
        margin: 0;
      }

      &--submit {
        align-self: flex-end;
      }
    }
  }
`;

export const AppPpdDeliveryOrderFilter = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [form] = Form.useForm();

    useEffect(() => {
        const params = Object.fromEntries(new URLSearchParams(searchParams));
        form.setFieldsValue({...params, ...(params["deliveryDate"] ? {"deliveryDate": moment(params["deliveryDate"])} : {})});
    }, [form, searchParams]);

    const onSearch = (values: Record<string, string>) => {
        const params: Record<string, string> = {};
        Object.entries(values).forEach(([key, value]) => {
            if (value) {
                if (key === "deliveryDate") {
                    params[key] = moment(values["deliveryDate"]).format("yyyy-MM-DD");
                }
                else {
                    params[key] = value;
                }
            }
        });
        setSearchParams(params);
    };

    return (
        <StyledFilterContainer>
            <Form
                form={form} 
                name="ppd-order-filter-form"
                onFinish={onSearch}
                layout="vertical"
                style={{ width: "100%" }}
            >
                <Row className="filter">
                    <Col className="filter__item">
                        <Form.Item name={"deliveryDate"} label="Delivery Date" valuePropName='date'>
                            <DatePicker
                                format={"YYYY-MM-DD"}
                                onChange={(val) => {
                                    form.setFieldsValue({...form.getFieldsValue, "deliveryDate": val});
                                }}
                                disabledDate={(current) => current && current > moment(moment(), "YYYY-MM-DD")}
                                placeholder="Search by delivery date" size="small" allowClear
                            />
                        </Form.Item>

                    </Col>
                    <Col className="filter__item">
                        <Form.Item name="userId" label="User Id">
                            <Input
                                placeholder="Search by User Id"
                                size="small"
                                allowClear
                            />
                        </Form.Item>
                    </Col>

                    {/* <Col xs={20} sm={4} md={5} lg={4} className="filter__item">
                        <Form.Item name="pps" label="PPS">
                            <Input placeholder="Search by PPS" size="small" allowClear />
                        </Form.Item>
                    </Col> */}

                    <Col
                        xs={20}
                        sm={4}
                        md={2}
                        lg={2}
                        className="filter__item filter__item--submit"
                    >
                        <Form.Item>
                            <Button type="primary" htmlType="submit" size="small">
                                Search
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </StyledFilterContainer>
    );
};