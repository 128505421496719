import { Col, Form, message, Row } from "antd";
import { IVendor } from "common/interface/IVendor";
import { AppPageHeader } from "components/AppPageHeader";
import { VendorForm } from "forms/VendorForm";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { saveVendor } from "store/vendor/vendorSlice";

const MESSAGE_KEY = "new-vendor";

const NewVendorPage = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { status, error } = useAppSelector((state) => state.vendors);
  const navigate = useNavigate();

  useEffect(() => {
    if (status === "loading") {
      message.loading({ content: "Saving vendor", key: MESSAGE_KEY });
    } else if (status === "success") {
      message.success({ content: "Vendor saved!", key: MESSAGE_KEY });
    } else if (status === "error") {
      message.error({ content: error, key: MESSAGE_KEY });
    }

    return () => message.destroy();
  }, [status, error]);

  const onSubmit = () => {
    form.submit();
  };

  const onCancel = () => {
    navigate("/vendors");
  };

  const onFinish = (values: Record<string, any>) => {
    const {
      address1,
      address2,
      city,
      state,
      pincode,
      accountNumber,
      accountName,
      gstin,
      bankName,
      ifscCode,
      branchName,
      panFileUrl,
      addressFileUrl,
      cancelledChequeFileUrl,
      ...restOfValues } =
      values;

    const formValues = {
      address: { address1, address2, city, state, pincode },
      metadata: {
        gstin: gstin != undefined && gstin?.length > 0 ? gstin : undefined, 
        bankAccountDetails: {
          accountNumber,
          accountName,
          bankName,
          ifscCode,
          branchName,
        },
        panFileUrl: panFileUrl,
        addressFileUrl: addressFileUrl,
        cancelledChequeFileUrl: cancelledChequeFileUrl,
      },
      ...restOfValues,
    };
    dispatch(saveVendor(formValues as IVendor))
      .unwrap()
      .then(() => navigate("/vendors"));
  };

  return (
    <>
      <Row>
        <Col>
          <AppPageHeader title="New Vendor" />
        </Col>
      </Row>
      <Row>
        <VendorForm
          formInstance={form}
          onFinish={onFinish}
          onCancel={onCancel}
          onSubmit={onSubmit}
          loading={status === "loading"}
        />
      </Row>
    </>
  );
};

export default NewVendorPage;
