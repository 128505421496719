import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API, { apis } from "common/apis";
import { IApiError } from "common/interface/IApiError";
import { IRequisitionUserMappingUpload, ISku } from "common/interface";
import {
    removePersistedProcessingRequisitionUserMapping
} from "services/PersistService/PersistRequisitionService";
import { RootState } from "store/store";
import { logoutIfNeeded } from "common/utils";
import { IPickerMappingUpload } from "common/interface/IPickerUploadScheme";
import { getPersistProcessingWhReturnMapping, persistProcessingWhReturnMapping, removePersistProcessingWhReturnMapping } from "services/PersistService/PersistWareHouseReturn";

export interface State {
    processingStoreRejection: IPickerMappingUpload;
    status: "loading" | "success" | "error" | "idle" | "cancelling";
    error?: string;
}

const initialState: State = {
    processingStoreRejection: getPersistProcessingWhReturnMapping(),
    status: "idle",
};

export const saveStoreRejection = createAsyncThunk<IRequisitionUserMappingUpload>("store-rejection/rejected/upload/save", async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const { processingStoreRejection: { key } } = state.storeRejection;

    const response = await API.post(apis.saveStoreRejection, {}, {
        params: { key }
    })
        .then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
});

export const cancelStoreRejection = createAsyncThunk("store-rejection/rejected/upload/cancel", async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const { processingStoreRejection: { key } } = state.storeRejection;

    const response = await API.post(apis.saveStoreRejection, {}, {
        params: { key, cancel: 1 }
    })
        .then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
})
const storeRejectionSlice = createSlice({
    name: "store-rejection",
    initialState,
    reducers: {
        setProcessingWhReturnMapping: (state, action) => {
            state.status = "idle";
            state.error = "";
            state.processingStoreRejection = action.payload;
            state.processingStoreRejection.timeUploaded = new Date().toString();
            persistProcessingWhReturnMapping(state.processingStoreRejection);
        },
        removeProcessingWhReturnMapping: (state) => {
            state.processingStoreRejection = {};
            state.status = "idle";
            state.error = "";
            removePersistProcessingWhReturnMapping();
        },
    },
    extraReducers(builder) {
        builder

            /**
            * SAVE REQUISITION USER MAPPING
            **/
            .addCase(saveStoreRejection.pending, state => {
                state.status = "loading";
            })
            .addCase(saveStoreRejection.fulfilled, state => {
                state.status = "success";
                state.error = "";
                state.processingStoreRejection = {};
                removePersistedProcessingRequisitionUserMapping();
            })
            .addCase(saveStoreRejection.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";

                if (error.message) {
                    state.error = error.message;
                }
            })

            /**
             * CANCEL PROCESSING REQUISITION
            **/
            .addCase(cancelStoreRejection.pending, state => {
                state.status = "cancelling";
            })
            .addCase(cancelStoreRejection.fulfilled, state => {
                state.status = "idle";
                state.error = "";
                state.processingStoreRejection = {};
                removePersistedProcessingRequisitionUserMapping();
            })
            .addCase(cancelStoreRejection.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";
                if (error.message) {
                    state.error = error.message;
                }
            })
            /**
             * Get Master Skus
             */
            // .addCase(fetchMasterSkus.fulfilled, (state, action) => {
            //     state.masterSkus = action.payload;
            // })
    }
});

export const {
    setProcessingWhReturnMapping,
    removeProcessingWhReturnMapping
} = storeRejectionSlice.actions;
export default storeRejectionSlice.reducer; 