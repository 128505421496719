import { Avatar, Col, Form, message, Pagination, Row, Image, Modal, Button } from "antd";
import { useForm } from "antd/lib/form/Form";
import Table, { ColumnsType } from "antd/lib/table";
import { IQualityCheck } from "common/interface/IQualityCheck";
import { fallBackImage } from "common/styles";
import { getDateFromISODate } from "common/utils";
import { AppActionStrip } from "components/AppActionStrip/AppActionStrip";
import { AppFilter, AppFilterButton } from "components/AppFilter";
import { AppQualityCheck } from "components/AppFilter/AppQualityCheckFilter";
import { AppPageHeader } from "components/AppPageHeader";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useDocumentTitle } from "hooks/usePageTitle";
import { useState, useCallback, useEffect, useRef, LegacyRef } from "react";
import { useSearchParams } from "react-router-dom";
import { getQualityCheck } from "store/quality-check/qualityCheckSlice";
import styled from "styled-components";
import { Player } from "video-react";

type PartialQualityType = Partial<IQualityCheck> & { key: string };
const MESSAGE_KEY = "all_quality_check";

const headerKeys: { [key: string]: string } = {
    // id: "Id",
    skuCode: "Sku Code",
    date: "Date",
    qcParams: "QC Params",
    images: "Images",
    videos: "Videos",
};

const StyledPagination = styled(Pagination)`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

const QualityCheckPage = () => {
    useDocumentTitle("All Quality Checks");
    const dispatch = useAppDispatch();
    const [columns, setColumns] = useState<ColumnsType<PartialQualityType>>();
    const [rows, setRows] = useState<PartialQualityType[]>();
    const { qualityChecks, status, error } = useAppSelector((state) => state.qualityCheck);
    const [filterState, setFilterState] = useState(false);
    const [searchParams] = useSearchParams();
    const [filterCount, setFilterCount] = useState(0);
    const [videoUrl, setVideoUrl] = useState("");
    const [form] = useForm();
    const playerRef = useRef<any>(null);

    const getQualityChecks = useCallback(
        (pageNum = 1, pageSize = 25, filter = "") => {
            dispatch(getQualityCheck({ pageNo: pageNum, pageSize, filter }));
        },
        [dispatch]
    );

    const hideModal = () => {
        setVideoUrl("");
        playerRef.current.pause();
    }

    useEffect(() => {
        const filter = searchParams.toString();
        setFilterCount(
            Object.keys(Object.fromEntries(new URLSearchParams(filter))).length
        );
        getQualityChecks(1, 25, filter);
    }, [dispatch, getQualityChecks, searchParams]);

    useEffect(() => {
        if (status === "loading") {
            message.loading({ content: "Loading Quality Checks", key: MESSAGE_KEY });
        } else if (status === "error") {
            message.error({ content: error, key: MESSAGE_KEY });
        }
        return () => {
            message.destroy();
        };
    }, [status, error]);

    useEffect(() => {
        const qualityCheckData = qualityChecks?.data ? qualityChecks.data : [];

        const data: PartialQualityType[] = qualityCheckData.map((temp, i) => {
            return { ...temp, key: `qualityChecks-${i}`, };
        });

        const tableHeaders: ColumnsType<PartialQualityType> = [
            ...Object.keys(headerKeys).map((key, idx) => ({
                key,
                title: headerKeys[key],
                dataIndex: key,
                render: (data: any, record: PartialQualityType) => {
                    if (key === "date") {
                        return <>{getDateFromISODate(data)}</>
                    }
                    if (key === "images") {
                        return <Row align="middle" gutter={10}>
                            <Image.PreviewGroup>
                                {data.map((e: string) => <Col>
                                    <Avatar
                                        src={
                                            <Image
                                                src={e}
                                                fallback={fallBackImage}
                                                alt={e}
                                                width="100%"
                                            />
                                        }
                                    ></Avatar>
                                </Col>)}
                            </Image.PreviewGroup>
                        </Row>
                    }
                    if (key === "videos") {
                        return <Col span={5}>
                            {data.filter((e: any) => e).map((e: string, i: number) => <Row style={{ margin: ".5rem 0" }}>
                                <Button type="primary" onClick={() => setVideoUrl(e)}>Video {i + 1}
                                </Button>
                            </Row>)}
                        </Col>
                    }
                    if (key === "qcParams") {
                        return <Col>
                            {Object.entries(data).map(([k, v], idx) => (<Row><div style={{ fontWeight: "700" }}>{k}</div>{` : ${v}`}</Row>))}
                        </Col>
                    }
                    return <>{data}</>;
                },
            })),
        ];

        setColumns(tableHeaders);
        setRows(data);
    }, [qualityChecks]);

    const Paginator = () => {
        if (qualityChecks) {
            const { pageNo, pageSize, total } = qualityChecks;
            return (
                <StyledPagination
                    current={pageNo}
                    pageSize={pageSize}
                    total={total}
                    onChange={(page, size) => {
                        const filter = searchParams.toString();
                        setFilterCount(
                            Object.keys(Object.fromEntries(new URLSearchParams(filter))).length
                        );
                        getQualityChecks(page, size, filter);
                    }}
                />
            );
        }

        return <></>;
    };

    return (
        <>
            <Modal
                title={`Video Preview`}
                // visible={false}
                visible={videoUrl !== ""}
                footer={null}
                onCancel={hideModal}
                // afterClose={pause}
                bodyStyle={{ padding: 0 }}
            >
                <Player
                    autoPlay={true}
                    ref={(player) => playerRef.current = player}
                    src={videoUrl} 
                    onPlay={() => {}} 
                    onEnded={() => {}} 
                    onLoadStart={() => {}} 
                    onPause={() => {}} 
                    videoId={""}
                    />

            </Modal>
            <Row>
                <Col>
                    <AppPageHeader title="All Quality Checks" />
                </Col>
            </Row>
            <Row>
                <AppActionStrip>
                    <div className="action-strip__item">
                        <AppFilterButton
                            active={filterState}
                            onClick={() => setFilterState(!filterState)}
                            filterCount={filterCount}
                        />
                    </div>
                </AppActionStrip>
            </Row>

            <Row style={{ marginBottom: "1rem" }}>
                <AppFilter expanded={filterState}>
                    <AppQualityCheck />
                </AppFilter>
            </Row>
            <Form form={form}>
                {rows && rows.length ? (
                    <Row>
                        <Col xs={24}>
                            <Table
                                columns={columns}
                                dataSource={rows}
                                scroll={{ x: "100vh", y: "65vh" }}
                                size="small"
                                pagination={false}
                                loading={status === "loading"}
                                bordered
                            />
                        </Col>
                        <Col xs={24}>
                            <Paginator />
                        </Col>
                    </Row>
                ) : (
                    status !== "loading" && <>No PPD order to show.</>
                )}
            </Form>
        </>
    );
};

export default QualityCheckPage;