import { useDrag, useDrop } from 'react-dnd';


//@ts-ignore
const DndItem = ({ children, index, moveItem }) => {
  const [{ isDragging }, dragRef] = useDrag(() => ({
    type: 'ITEM',
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const [{ canDrop }, dropRef] = useDrop(() => ({
    accept: 'ITEM',
    canDrop: (monitor: any) => {
      const item = monitor;
      return index !== item.index; // Prevent dropping on itself
    },
    drop: (item) => {
      moveItem(item.index, index); // Call moveItem on drop
    },
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
    }),
  }));

  const opacity = isDragging ? 0.5 : (canDrop ? 1.2 : 1); // Adjust opacity based on dragging/dropping

  return (
    <div ref={(ref) => {
      dragRef(ref);
      dropRef(ref);
    }}
      style={{ opacity, marginBottom: '.5rem' }}
      onTouchStart={() => {

      }}
      
      >
      {children}
    </div>
  );
};

export default DndItem;