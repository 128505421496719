import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API, { apis } from "common/apis";
import { IApiError } from "common/interface/IApiError";
import { IQualityCheck } from "common/interface/IQualityCheck";
import { logoutIfNeeded } from "common/utils";

export interface State {
    status: "loading" | "success" | "error" | "idle" | "cancelling" | "saving";
    error?: string;
    qualityChecks?: IQualityCheck;
}

const initialState: State = {
    status: "idle",
};

export const getQualityCheck = createAsyncThunk<
    IQualityCheck,
    { pageNo: number; pageSize: number, filter: string }
>("/quality-check/all", async ({ pageNo = 1, pageSize = 25, filter }, thunkApi) => {
    const filterParams = filter
        ? Object.fromEntries(new URLSearchParams(filter))
        : "";
    const response = await API.get(apis.getQualityChecks, {
        params: {
            pageNo,
            pageSize,
            ...filterParams,
        },
    })
        .then((success) => success.data)
        .catch((error) => {
            const errorMessage =
                error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
});

const qualityCheckSlice = createSlice({
    name: "ppd",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            /**
             * Cancel PPD Delivery Orders
             **/
            .addCase(getQualityCheck.pending, state => {
                state.status = "loading";
            })
            .addCase(getQualityCheck.fulfilled, (state, action) => {
                state.status = "success";
                state.error = "";
                state.qualityChecks = action.payload;
            })
            .addCase(getQualityCheck.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";

                if (error.message) {
                    state.error = error.message;
                }
            })
    }
});

export const {
} = qualityCheckSlice.actions;
export default qualityCheckSlice.reducer; 