import {
  Alert,
  Button,
  Col,
  DatePicker,
  Form,
  message,
  Row,
  Select,
  Table,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import { apis, AUTH_HEADER_NAME } from "common/apis";
import {
  IRequisitionUserMappingUpload,
  IRequisitionUserMappingUploadRow,
} from "common/interface";
import { routes } from "common/routes";
import { generateHeaders } from "common/utils";
import { AppActionStrip } from "components/AppActionStrip/AppActionStrip";
import { AppPageHeader } from "components/AppPageHeader";
import { AppUpload } from "components/AppUpload";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useDocumentTitle } from "hooks/usePageTitle";
import { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  setStoreSecPicking,
  removeStoreSecPicking,
  generateDownloadUrl,
} from "store/requisition/requisitionSlice";

const MESSAGE_KEY = "all_requisitions";

export const UploadStoreSecondaryPicking = () => {
  useDocumentTitle("Upload Store Sec Picking");
  const { authToken } = useAppSelector((state) => state.app.user);
  const { processingStoreSecPicking, slots, status, error } = useAppSelector(
    (state) => state.requisition
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [hasErrorRows, setHasErrorRows] = useState(false);
  // const [date, setDate] = useState<string>();
  // const [slot, setSlot] = useState<string>();

  useEffect(() => {
    if (
      processingStoreSecPicking &&
      processingStoreSecPicking.key
    ) {
      navigate(`/${routes.STORE_REQUISITION.SEC_PICKING_SAVE}`, {});
    }

    return () => {
      message.destroy();
    };
  }, [processingStoreSecPicking, navigate]);

  useEffect(() => {
    if (status === "loading") {
      message.loading({ content: "Loading Requisitions", key: MESSAGE_KEY });
    } else if (status === "error") {
      message.error({ content: error, key: MESSAGE_KEY });
    }

    return () => {
      message.destroy();
    };
  }, [status, error,]);

  useEffect(() => {
    const failed = processingStoreSecPicking?.failedRows?.length;

    if (failed) {
      setHasErrorRows(true);
    }
  }, [processingStoreSecPicking?.failedRows]);

  const { Option } = Select;

  const headers: { [name: string]: string } = {
    ...(authToken && { [AUTH_HEADER_NAME]: authToken }),
  };

  const onUploaded = (data: IRequisitionUserMappingUpload) => {
    dispatch(setStoreSecPicking(data));
  };

  const onUploading = () => {
    dispatch(removeStoreSecPicking());
  };

  const { headerMapping } = processingStoreSecPicking;
  const headerMappingWithError = headerMapping + ",errors:Errors";

  const generateMemoizedHeaders = useMemo(
    () => generateHeaders(headerMappingWithError),
    [headerMappingWithError]
  );

  const tableHeaders = generateMemoizedHeaders || {};
  const successRows = processingStoreSecPicking?.successRows || [];
  const failedRows = processingStoreSecPicking?.failedRows || [];
  const successData = successRows?.map((row, i) => ({
    ...row,
    key: `data-success-${i}`,
  }));
  const failedData = failedRows?.map((row, i) => ({
    ...row,
    key: `data-failed-${i}`,
  }));
  const data = [...failedData, ...successData];
  const columns: ColumnsType<
    IRequisitionUserMappingUploadRow & { key: string }
  > = [
      ...Object.keys(tableHeaders).map((key) => ({
        key,
        title: tableHeaders[key],
        dataIndex: key,
        render: (
          text: string,
          record: IRequisitionUserMappingUploadRow & { key: string }
        ) => {
          if (key === "errors") {
            const errorMessages = record.errors
              ?.map((error) => error.message)
              .join(", ");
            return <>{errorMessages}</>;
          }
          return <>{text}</>;
        },
      })),
    ];

  const addBorderToErrorRows = (
    record: IRequisitionUserMappingUploadRow & { key: string }
  ) => {
    if (record.errors && record.errors.length) {
      return "error-row";
    }

    return "";
  };

  const downloadCsv = () => {
    dispatch(generateDownloadUrl());
  };

  return (
    <>
      <Row>
        <Col>
          <AppPageHeader title="Upload Store Secondary Picking" />
        </Col>
      </Row>
      <Row>
        <div style={{ display: "flex", justifyContent: "end", alignItems: "end", width: "100%" }}>
          <Col style={{ marginRight: "1rem" }}>
            <Button type="primary" onClick={downloadCsv}>Download JIT CSV</Button>
          </Col>
        </div>
      </Row>
      {
        // !!(date && slot) && 
        (
          <Row>
            <Col lg={16} xs={24}>
              <AppUpload
                uploadUrl={apis.uploadStoreSecPicking}
                headers={headers}
                onUploaded={onUploaded}
                onUploading={onUploading}
                hasErrorRows={hasErrorRows}
              />
            </Col>
          </Row>
        )}
      {!!Object.keys(processingStoreSecPicking).length &&
        !processingStoreSecPicking.key && (
          <>
            <Row>
              <Col lg={16} xs={24}>
                <Alert
                  type="error"
                  message="Errors in sheet data."
                  description="Please correct errors and upload again."
                  showIcon
                  banner
                />
              </Col>
            </Row>
            <Row>
              <Table
                size="small"
                columns={columns}
                dataSource={data}
                rowClassName={(record) => addBorderToErrorRows(record)}
                scroll={{ y: 500 }}
                className="table-with-errors"
              ></Table>
            </Row>
          </>
        )}
    </>
  );
};
