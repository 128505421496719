import { Form, Input, Button } from "antd";
import { ILoginForm } from "common/interface";
import { mobileFormValidator } from "common/utils";
interface LoginFormProps {
  onLogin: (user: ILoginForm) => void;
  loading: boolean;
}

export const LoginForm = ({ onLogin, loading = false }: LoginFormProps) => {
  const onFinish = (values: ILoginForm) => {
    onLogin(values);
  };

  return (
    <Form
      name="normal_login"
      className="login-form"
      initialValues={{ remember: true }}
      onFinish={onFinish}
    >
      <Form.Item
        name="mobile"
        rules={[
          {
            required: true,
            message: "Please input your Mobile!",
          },
          {
            validator: function (rule, value) {
              return mobileFormValidator(value);
            },
            validateTrigger: "onFinish",
          },
        ]}
      >
        <Input
          // prefix={<>+91</>}
          placeholder="Mobile Number"
          size="large"
          maxLength={10}
          formNoValidate={false}
          style={{ borderRadius: "10px", height: "60px" }}
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ required: true, message: "Please input your Password!" }]}
      >
        <Input
          type="password"
          placeholder="Enter Pin"
          size="large"
          style={{ borderRadius: "10px", height: "60px" }}
        />
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          block
          size="large"
          style={{ borderRadius: "10px", height: "60px", marginTop: "30px" }}
          loading={loading}
          disabled={loading}
        >
          Log in
        </Button>
      </Form.Item>
    </Form>
  );
};
