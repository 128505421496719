import {
  Alert,
  Col,
  DatePicker,
  Form,
  message,
  Row,
  Select,
  Table,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import { apis, AUTH_HEADER_NAME } from "common/apis";
import { IPickerMappingUpload, IPickerMappingUploadRow } from "common/interface/IPickerUploadScheme";
import { routes } from "common/routes";
import { generateHeaders } from "common/utils";

import { AppPageHeader } from "components/AppPageHeader";
import { AppUpload } from "components/AppUpload";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useDocumentTitle } from "hooks/usePageTitle";
import { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { fetchInventoryTypes, fetchWarehouses, setProcessingST } from "store/internel-stock-exchange/internelStockExchangeSlice";
import { removeProcessingPickerMapping } from "store/ppd/ppdSlice";

export const UploadInternalStockExchangePage = () => {
  useDocumentTitle("Upload Internal Stock Exchange");
  const { authToken } = useAppSelector((state) => state.app.user);

  const { processingStockExchange, inventoryTypes } = useAppSelector(
    (state) => state.internelStockExchangeServices
  );

  const { whId: srcWhId } = useAppSelector(
    (state) => state.app
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [hasErrorRows, setHasErrorRows] = useState(false);
  // const [storeId, setStoreId] = useState<string>();
  const [invType, setInvType] = useState<string>("HOLD");
  const [fromDay, setFromDay] = useState<string>();
  const [toDay, setToDay] = useState<string>();

  useEffect(() => {
    if (
      processingStockExchange &&
      processingStockExchange.key
    ) {
      navigate(`/${routes.INTERNAL_STOCK_EXCHANGE.SAVE}`, {});
    }

    return () => {
      message.destroy();
    };
  }, [processingStockExchange, navigate]);

  useEffect(() => {
    const failed = processingStockExchange?.failedRows?.length;

    if (failed) {
      setHasErrorRows(true);
    }
  }, [processingStockExchange?.failedRows]);

  const headers: { [name: string]: string } = {
    ...(authToken && { [AUTH_HEADER_NAME]: authToken }),
  };

  useEffect(() => {
    dispatch(fetchInventoryTypes());
    dispatch(fetchWarehouses());
  }, []);

  const onUploaded = (data: IPickerMappingUpload) => {
    dispatch(setProcessingST(data));
  };

  const onUploading = () => {
    dispatch(removeProcessingPickerMapping());
  };

  const { headerMapping } = processingStockExchange;
  const headerMappingWithError = headerMapping + ",errors:Errors";

  const generateMemoizedHeaders = useMemo(
    () => generateHeaders(headerMappingWithError),
    [headerMappingWithError]
  );

  const tableHeaders = generateMemoizedHeaders || {};
  const successRows = processingStockExchange?.successRows || [];
  const failedRows = processingStockExchange?.failedRows || [];
  const successData = successRows?.map((row, i) => ({
    ...row,
    key: `data-success-${i}`,
  }));
  const failedData = failedRows?.map((row, i) => ({
    ...row,
    key: `data-failed-${i}`,
  }));
  const data = [...failedData, ...successData];
  const columns: ColumnsType<
    IPickerMappingUploadRow & { key: string }
  > = [
      ...Object.keys(tableHeaders).map((key) => ({
        key,
        title: tableHeaders[key],
        dataIndex: key,
        render: (
          text: string,
          record: IPickerMappingUploadRow & { key: string }
        ) => {
          if (key === "errors") {
            const errorMessages = record.errors
              ?.map((error) => error.message)
              .join(", ");
            return <>{errorMessages}</>;
          }
          return <>{text}</>;
        },
      })),
    ];

  const addBorderToErrorRows = (
    record: IPickerMappingUploadRow & { key: string }
  ) => {
    if (record.errors && record.errors.length) {
      return "error-row";
    }

    return "";
  };

  return (
    <>
      <Row>
        <Col>
          <AppPageHeader title="Upload Internal Stock Exchange" />
        </Col>
      </Row>
      <Row>
        <Form>
          {inventoryTypes.length && (
            <Form.Item label="Inventory Type" name="inventoryType" required>
              <Select
                showSearch
                // filterOption={(input, option) => {                
                //   return option?.children?.toString().toLowerCase().includes(input?.toLowerCase() ?? "") ?? false;
                // }}
                options={(inventoryTypes).map(e => ({
                  value: e,
                  label: e,
                }),)}
                style={{ width: "200px" }}
                onChange={(e) => {
                  setInvType(e);
                }}
                defaultValue={"HOLD"}
              >
                {inventoryTypes.filter(e => e === "HOLD").map((e) => (
                  <Select.Option
                    value={e} key={`inventory-type-${e}`}>
                    {e}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {/* <Form.Item label="From Day" name="fromDay" required>
                <Select
                showSearch
                filterOption={(input, option) => {                
                  return option?.children?.toString().toLowerCase().includes(input?.toLowerCase() ?? "") ?? false;
                }}
                style={{ width: "200px" }}
                onChange={(e) => {
                  setFromDay(e);
                }}
              >
                {["D1", "D2", "D3", "D4"].map((e) => (
                  <Select.Option value={e} key={`day-${e}`}>
                    {e}
                  </Select.Option>
                ))}
              </Select>
              </Form.Item>
              <Form.Item label="To Day" name="toDay" required>
                <Select
                showSearch
                filterOption={(input, option) => {                
                  return option?.children?.toString().toLowerCase().includes(input?.toLowerCase() ?? "") ?? false;
                }}
                style={{ width: "200px" }}
                onChange={(e) => {
                  setToDay(e);
                }}
              >
                {["D1", "D2", "D3", "D4"].map((e) => (
                  <Select.Option value={e} key={`day-${e}`}>
                    {e}
                  </Select.Option>
                ))}
              </Select>
              </Form.Item> */}
        </Form>
      </Row>
      {(invType) && (
        <Row>
          <Col lg={16} xs={24}>
            <AppUpload
              uploadUrl={apis.uploadInternelStockTransfer}
              headers={headers}
              onUploaded={onUploaded}
              onUploading={onUploading}
              hasErrorRows={hasErrorRows}
              data={{ inventoryType: invType, whIdSrc: srcWhId, fromDay, toDay }}
            />
          </Col>
        </Row>
      )}
      {Object.keys(processingStockExchange).length != 0 &&
        !processingStockExchange.key && (
          <>
            <Row>
              <Col lg={16} xs={24}>
                <Alert
                  type="error"
                  message="Errors in sheet data."
                  description="Please correct errors and upload again."
                  showIcon
                  banner
                />
              </Col>
            </Row>

            <Row>
              <Table
                size="small"
                columns={columns}
                dataSource={data}
                rowClassName={(record) => addBorderToErrorRows(record)}
                scroll={{ y: 500 }}
                className="table-with-errors"
              ></Table>
            </Row>
          </>
        )}
    </>
  );
};
