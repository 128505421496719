import { INaPickingUserMappingUpload } from "common/interface";

const CLUSTER_AM_STORAGE_KEY = "processingClusterAmMapping";
const CLUSTER_STORAGE_KEY = "processingClusterStoreMapping";

export const persistClusterStoreMapping = (data: INaPickingUserMappingUpload) => {
  localStorage.setItem(CLUSTER_STORAGE_KEY, JSON.stringify(data));
}

export const getPersistedClusterStoreMapping = (): INaPickingUserMappingUpload => {
  const persistedUserMapping = localStorage.getItem(CLUSTER_STORAGE_KEY);
  return persistedUserMapping ? JSON.parse(persistedUserMapping) : {};
}

export const removePersistedClusterStoreMapping = () => {
  localStorage.removeItem(CLUSTER_STORAGE_KEY);
}

export const persistClusterAmMapping = (data: INaPickingUserMappingUpload) => {
  localStorage.setItem(CLUSTER_AM_STORAGE_KEY, JSON.stringify(data));
}

export const getPersistedClusterAmMapping = (): INaPickingUserMappingUpload => {
  const persistedUserMapping = localStorage.getItem(CLUSTER_AM_STORAGE_KEY);
  return persistedUserMapping ? JSON.parse(persistedUserMapping) : {};
}

export const removePersistedClusterAmMapping = () => {
  localStorage.removeItem(CLUSTER_AM_STORAGE_KEY);
}