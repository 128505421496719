import { Outlet } from "react-router-dom";
import { AppContent } from "components/AppLayout/AppContent";
import { AppLayout } from "components/AppLayout";
import { Alert } from "antd";
import { useAppSelector } from "hooks/useAppSelector";

export const LoggedOutLayout = () => {
  const { errors } = useAppSelector((state) => state.app);
  return (
    <AppLayout>
      <AppContent style={{ padding: 0, position: "relative" }}>
        <>
          {!!(errors && errors.length) && (
            <Alert
              message="Error"
              description={errors.map((error, i) => (
                <p key={"error" + i}>{error}</p>
              ))}
              type="error"
              showIcon
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: 1,
                width: "100%",
              }}
            />
          )}
          <Outlet />
        </>
      </AppContent>
    </AppLayout>
  );
};
