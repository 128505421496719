import { Row, Col, message, Table, Pagination } from "antd";
import { ColumnsType } from "antd/lib/table";
import { AppPageHeader } from "components/AppPageHeader";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useDocumentTitle } from "hooks/usePageTitle";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Link, NavLink, useSearchParams } from "react-router-dom";
import { getAllCreateRequests, resetRequestsList } from "store/store-return/storeReturnSlice";
import { IStoreReturnsNew } from "common/interface/IStoreReturn";
import { GrView } from "react-icons/gr";
import { CreateStoreReturnFilter } from "components/AppFilter/CreateStoreReturnFilter";

type PartialSRDetails = Partial<IStoreReturnsNew> & { key: string };
const MESSAGE_KEY = "all__return_grn";

const headerKeys: { [key: string]: string } = {
  // id: "",
  storeId: "Store Id",
  slot: "Slot",
  date: "Date",
  status: "Status",
  actions: "Actions", 
};

const StyledPagination = styled(Pagination)`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

const StyledActionLink = styled(Link)`
  border: 1px solid;
  padding: 0.2rem;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CreateSrPage = () => {
  useDocumentTitle("All Store Return Requests");
  const dispatch = useAppDispatch();
  const [columns, setColumns] = useState<ColumnsType<PartialSRDetails>>();
  const [rows, setRows] = useState<PartialSRDetails[]>();
  const { allSRs, status, error } = useAppSelector((state) => state.storeReturn);
  const [filterState, setFilterState] = useState(false);
  const [searchParams] = useSearchParams();
  const [filterCount, setFilterCount] = useState(0);

  const getCreateRequests = useCallback(
    (pageNum = 1, pageSize=25, filter = "") => {      
      if (filter == "") {
        dispatch((resetRequestsList()));
        return;
      }
      dispatch(getAllCreateRequests({ pageNo: pageNum, pageSize: pageSize, filter }));
    },
    [dispatch]
  );

  useEffect(() => {
    const filter = searchParams.toString();
    setFilterCount(
      Object.keys(Object.fromEntries(new URLSearchParams(filter))).length
    );      
    getCreateRequests(1, 500, filter);
  }, [dispatch, getCreateRequests, searchParams]);

  useEffect(() => {
    if (status === "loading") {
      message.loading({ content: "Loading Store Returns", key: MESSAGE_KEY });
    } else if (status === "success") {
      message.success({
        content: "Purchase Orders loaded!",
        key: MESSAGE_KEY,
      });
    } else if (status === "error") {
      message.error({ content: error, key: MESSAGE_KEY });
    }

    return () => {
      message.destroy();
    };
  }, [status, error]);

  useEffect(() => {
    const createRequestsList = allSRs ?? [];
    const data: PartialSRDetails[] = createRequestsList.map((storeReturn, i) => {
      const newRow = {
        ...storeReturn
      };
      // newRow.returnDate = getDateFromDateFormatted(newRow.returnDate);
      return { ...newRow, key: `po-${i}`, ellipses: true };
    });
    const tableHeaders: ColumnsType<PartialSRDetails> = [
      ...Object.keys(headerKeys).map((key) => ({
        key,
        title: headerKeys[key],
        dataIndex: key,
        render: (text: string, record: PartialSRDetails) => {
          if (key === "storeId") {
            if (["WH_DISPATCHED", "STORE_RECEIVED", "BULK_PENDING"].includes(record['status']!) && record.dispatchCount! > 0){
            return (
              <NavLink to={`/store-return/create/${record.id}`}>{record?.store?.name} || {record?.store?.id}</NavLink>
            );
          }
            else { 
              return <>{record?.store?.name} || {record?.store?.id}</>;
            }
          }

          if (key === "actions") {
            return (
              <Row
                align="middle"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                { ["WH_DISPATCHED", "STORE_RECEIVED", "BULK_PENDING"].includes(record['status']!) && record.dispatchCount! > 0 && <Col>
                  <StyledActionLink to={`/store-return/create/${record.id}`}>
                    <GrView />
                  </StyledActionLink>
                </Col> }
              </Row>
            );
          } 
          return <>{text}</>;
        },
      })),
    ];
    setColumns(tableHeaders);
    setRows(data);
  }, [allSRs]);

  return (
    <>
      <Row>
        <Col>
          <AppPageHeader title="All Store Return Requests" />
        </Col>
      </Row>
      <Row>
      <CreateStoreReturnFilter />
      </Row>
      {rows && rows.length ? (
        <Row>
          <Col xs={24}>
            <Table
              columns={columns}
              dataSource={rows}
              scroll={{ x: "100vh", y: "65vh" }}
              size="small"
              pagination={false}
              loading={status === "loading"}
              bordered
            />
          </Col>
        </Row>
      ) : (
        status !== "loading" && <>No Store Returns Requests available.</>
      )}
    </>
  );
};

export default CreateSrPage;
