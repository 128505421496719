import { InboxOutlined, UploadOutlined } from "@ant-design/icons";
import { Alert, Col, Row, message, Button } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import { IApiError } from "common/interface/IApiError";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { logout } from "store/app/appSlice";

type OnUploadType = (data: {}) => void;
type FnType = () => void;
export interface AppUploadProps {
  uploadUrl: string;
  headers?: { [name: string]: string };
  onUploaded: OnUploadType;
  onUploading?: FnType;
  hasErrorRows?: boolean;
  data?: Record<string, any>;
}

export const AppUpload = ({
  uploadUrl,
  headers,
  onUploaded,
  onUploading = () => {},
  hasErrorRows = false,
  data,
}: AppUploadProps) => {
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setError(!!hasErrorRows);
  }, [hasErrorRows]);

  const { whId } = useAppSelector((state) => state.app);

  headers = {...headers, "wh-id": whId!.toString()};

  return (
    <ErrorBoundary FallbackComponent={() => <>Something went wrong!</>}>
      <Dragger
        accept=".csv"
        action={uploadUrl}
        headers={headers}
        maxCount={1}
        disabled={!!uploading}
        type="select"
        data={data}
        itemRender={(node, file) => {
          if (file.status === "uploading") {
            return node;
          } else if (file.status === "error" && file.error) {
            const response: IApiError = file.response;
            return (
              <Alert
                message={
                  <>
                    Error Uploading <b>{file.name}</b>
                  </>
                }
                description={
                  response?.error
                    ? response?.message
                    : response?.errors && response?.errors.length
                    ? response?.errors.map((error, i) => (
                        <p key={`error-${i}`}>{error.message}</p>
                      ))
                    : "Something went wrong."
                }
                type="error"
                showIcon
                banner
              />
            );
          } else if (file.status === "success" || file.status === "done") {
            return (
              <Alert
                message={
                  <>
                    File <b>{file.name}</b> uploaded successfully
                  </>
                }
                type="success"
                showIcon
                banner
              />
            );
          }

          return node;
        }}
        onChange={(info) => {
          const response = info.file.response;
          setUploading(false);

          if (info.file.error) {
            if (info.file.error.status === 401) {
              message.error("You are logged out. Please login again!");
              dispatch(logout());
            }
          } else if (info.file.status === "uploading") {
            setUploading(true);
            onUploading();
          } else if (info.file.status === "done") {
            if (data) {
              response["date"] = data?.date;
              response["slot"] = data?.slot;
            }
            onUploaded(response);
          }
        }}
      >
        <Row
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {error ? (
            <Row>
              <Button
                type="primary"
                icon={<UploadOutlined />}
                disabled={uploading}
              >
                Click to Upload Again
              </Button>
            </Row>
          ) : (
            <>
              <Row>
                <Col>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                </Col>
              </Row>

              <Row>
                <Col>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                </Col>
              </Row>

              <Row>
                <Col>
                  <div className="ant-upload-hint">
                    <Alert
                      message="only .csv files are supported"
                      type="info"
                      showIcon
                      banner
                    />
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Row>
      </Dragger>
    </ErrorBoundary>
  );
};
