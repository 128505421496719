import React, { useEffect, useState } from "react";
import {
    Breadcrumb,
    Button,
    Col,
    Descriptions,
    Form,
    Input,
    message,
    Popconfirm,
    Row,
    Select,
    Table,
    Typography,
} from "antd";
import { BsPlus } from "react-icons/bs";
import { MdDeleteOutline } from "react-icons/md";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { AppPageHeader } from "components/AppPageHeader";
import { variables } from "common/variables";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useDocumentTitle } from "hooks/usePageTitle";
import { deleteSplitSku, fetchSplitSku, saveSplitSku, SplitSku, SplitSkuDetail } from "store/split-sku/splitSkuSlice";
import { WhInfo } from "common/interface";
import { getDarkStores } from "store/admin-slice/adminServicesSlice";
import { fetchWarehouses } from "store/stock-exchange/stockExchangeSlice";
import { getPersistedUser } from "services/PersistService/PersistUserService";
import { getWarehouseId } from "services/PersistService/PersistWareHouseReturn";

const { Option } = Select;

type PartialItemType = Partial<SplitSkuDetail> & {
    key: string;
    isNew?: boolean;
    isModified?: boolean;
    isDeleted?: boolean;
    piecesEnabled?: boolean;
    uom?: string;
};

const uomMappings: { [key: string]: string } = {
    'GRAM': 'Gm',
    'KILOGRAM': 'Kg',
    'PIECE': 'Pcs',
    'NUMBER': 'Pcs',
    'PACKET': 'Pkt',
    'BUNDLE': 'Bdl'
};
const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;

export function getUomMapping(uom: string | null | undefined): string {
    if (!uom) {
        return '';
    }
    return uomMappings[uom] || '';
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    title: any;
    inputType: "select" | "text";
    record: PartialItemType;
    index: number;
    children: React.ReactNode;
}
const MESSAGE_KEY = "split_sku_details";

const SplitSKUDetailsPage = () => {
    useDocumentTitle("Split SKU Details");
    const dispatch = useAppDispatch();
    const { splitSkuListing, error, status } = useAppSelector((state) => state.splitSkuSlice);
    const { user } = useAppSelector((state) => state.app);
    const { masterSkus = [] } = useAppSelector((state) => state.requisition);
    const { id: initSkuCode } = useParams();
    const navigate = useNavigate();
    const { darkStores } = useAppSelector(
        (state) => state.adminServices
    );
    const [viewedSku, setViewedSku] = useState<SplitSku>();
    const [form] = Form.useForm();
    const [parentForm] = Form.useForm();
    const [editingKey, setEditingKey] = useState("");
    const [tableRows, setTableRows] = useState<PartialItemType[]>([]);
    const [isAddingItem, setIsAddingItem] = useState(false);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

    const formatDate = (dateString: string) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        }).format(date);
    };

    const [selectedStores, setSelectedStores] = useState<number[]>([]);

    useEffect(() => {
        if (darkStores?.length) {
            setSelectedStores(darkStores!.map(storeInfo => storeInfo.id!));
        }
    }, [darkStores]);

    const handleSelectAll = () => {
        const allstoreIdss = darkStores.map(storeInfo => storeInfo.id!);
        setSelectedStores(allstoreIdss);
        parentForm.setFieldsValue({ storeIds: allstoreIdss });
    };

    const handleClearAll = () => {
        setSelectedStores([]);
        parentForm.setFieldsValue({ storeIds: [] });
    };

    const fetchProductDetails = async (skuCode: string) => {
        try {
            const token = getPersistedUser().authToken;
            let whId = getWarehouseId();
            const response = await fetch(API_DOMAIN + `/api/v1/combo-sku/store/product/${skuCode}`, {
                method: "GET",
                headers: {
                    "rz-auth-key": token?.toString() || "",
                    "wh-Id": whId?.toString() || "",
                },
            });
            const data = await response.json();
            return data;
        } catch (error) {
            console.error("Error fetching product details:", error);
            return null;
        }
    };

    const handleSkuChange = async (skuCode: string, record: PartialItemType) => {
        const productDetails = await fetchProductDetails(skuCode);

        if (productDetails[0]) {
            const piecesEnabled = getEnablePiecesRequest(productDetails[0]);
            console.log("Pieces Enables: ", piecesEnabled);

            const updatedRows = tableRows.map((row) =>
                row.key === record.key ? { ...row, skuCode, piecesEnabled, uom: productDetails[0]?.unit_of_measurement } : row
            );
            // form.resetFields();
            setTableRows(updatedRows);
            setHasUnsavedChanges(true);
        }
    };

    function getEnablePiecesRequest(productDetails: any) {
        if (productDetails?.unit_of_measurement == productDetails.metadata?.defaultUom) {
            return false;
        } else if (productDetails?.per_pcs_suffix == productDetails.metadata?.defaultUom) {
            return true;
        } else {
            return false;
        }
    }

    useEffect(() => {
        if (status === "loading") {
            message.loading({ content: "Loading SKU Data", key: MESSAGE_KEY });
        } else if (status === "saving") {
            message.loading({ content: "Saving SKU", key: MESSAGE_KEY });
        } else if (status === "error") {
            message.error({ content: error, key: MESSAGE_KEY });
        }

        return () => {
            message.destroy();
        };
    }, [status, error]);

    // Edit functionality
    const isEditing = (record: PartialItemType) => record.key === editingKey;

    const edit = (record: PartialItemType) => {
        form.setFieldsValue({
            skuCode: record.skuCode,
            grade: record.grade,
            quantity: record.quantity,
            pieces: record.pieces,
        });
        setEditingKey(record.key);
        handleSkuChange(record.skuCode ?? "", record);
    };

    const cancel = () => {
        // Check if the item is new and unsaved
        const isUnsavedItem = tableRows.find(row => row.key === editingKey && row.isNew);

        // If it's unsaved, remove it
        if (isUnsavedItem) {
            const newRows = tableRows.filter((row) => row.key !== editingKey);
            setTableRows(newRows);
        }

        // Reset the form and stop editing
        setEditingKey("");
        setIsAddingItem(false);
        form.resetFields();
        setHasUnsavedChanges(true);
    };

    // Save functionality
    const save = async (record: PartialItemType) => {
        try {
            const row = await form.validateFields();
            const newData = [...tableRows];
            const index = newData.findIndex(item => item.key === record.key);

            if (index > -1) {
                const item = newData[index];
                // Update the item, and mark it as not new (i.e., it's saved)
                const updatedItem = {
                    ...item,
                    ...row,
                    isNew: false,  // Mark it as no longer new after saving
                    isModified: true,
                };
                newData.splice(index, 1, updatedItem);
                setTableRows(newData);
                setEditingKey("");
                setHasUnsavedChanges(true);
            } else if (isAddingItem) {
                const newItem = {
                    ...row,
                    key: Date.now().toString(),
                    isNew: false, // Mark the new item as saved now
                };
                setTableRows([...newData, newItem]);
                setEditingKey("");
                setIsAddingItem(false);
                setHasUnsavedChanges(true);
            }
            form.resetFields();
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };


    // Add new item
    const onAddItem = () => {
        const newItem: PartialItemType = {
            key: `new-${Date.now()}`,
            skuCode: "",
            grade: "",
            quantity: 0,
            pieces: 0,
            piecesEnabled: false,
            isNew: true,
        };
        setTableRows([...tableRows, newItem]);
        setIsAddingItem(true);
        setEditingKey(newItem.key);
        form.setFieldsValue(newItem);
    };

    // Delete functionality
    const handleDelete = (record: PartialItemType) => {
        const newData = tableRows.filter(item => item.key !== record.key);
        setTableRows(newData);
        setHasUnsavedChanges(true);
        setIsAddingItem(false);
    };

    // Save all changes
    const saveAllChanges = async () => {
        try {
            const parentData = await parentForm.validateFields();
            await dispatch(saveSplitSku({
                id: viewedSku?.id,
                whId: parentData["whId"] ?? (viewedSku?.whId || 0),
                storeIds: parentData["storeIds"] ?? (viewedSku?.storeIds || 0),
                comboSkuCode: parentData["comboSkuCode"],
                splitSkuDetailBeans: tableRows.map(row => ({
                    skuCode: row.skuCode || '',
                    grade: row.grade || '',
                    quantity: row.quantity || 0,
                    pieces: row.pieces || 0,
                }))
            })).unwrap().then(() => navigate("/requisition/all-splitsku"));
            message.success("All changes saved successfully");
            setHasUnsavedChanges(false);
        } catch (error: any) {
            console.log("Save error:", error);

            message.error(error['message'] ?? "Failed to save changes");
        }
    };

    // Delete SKU
    const deleteSku = async () => {
        try {
            await dispatch(deleteSplitSku({
                id: viewedSku?.id,
            })).unwrap().then(() => navigate("/requisition/all-splitsku"));
            message.success("All changes saved successfully");
            setHasUnsavedChanges(false);
        } catch (error) {
            message.error("Failed to save changes");
            console.error("Save error:", error);
        }
    };

    // Get SKU options
    const getSkuOptions = (includeSku?: string) => {
        if (masterSkus) {
            const selectedSkus = viewedSku?.splitSkuDetailBeans
                .filter((poi) => poi.skuCode !== includeSku)
                .map((poI) => poI.skuCode);
            return masterSkus.reduce((acc, sku) => {
                if (!selectedSkus?.includes(sku.skuCode)) {
                    return (acc = {
                        ...acc,
                        [sku.id]: {
                            value: sku.id,
                            label: sku.skuCode,
                            skuCode: sku.skuCode,
                            skuName: sku.name,
                        },
                    });
                }

                return acc;
            }, {} as Record<string, { skuCode: string; skuName: string }>);
        }

        return {};
    };

    // Editable cell component
    const EditableCell: React.FC<EditableCellProps> = ({
        editing,
        dataIndex,
        title,
        inputType,
        record,
        index,
        children,
        ...restProps
    }) => {
        let inputNode;

        if (dataIndex === "skuCode") {
            inputNode = (
                <Select
                    showSearch
                    style={{ width: '100%' }}
                    filterOption={(input, option) =>
                        option?.children?.toString().toLowerCase().includes(input.toLowerCase()) || false
                    }
                    onChange={(value) => {
                        form.setFieldsValue({
                            skuCode: value,
                            grade: null,
                            quantity: 0,
                            pieces: 0,
                        });
                        handleSkuChange(value, record);
                    }}
                >
                    {Object.entries(getSkuOptions(record.skuCode)).map(([key, value]) => (
                        <Option key={key} value={value.skuCode}>
                            {value.skuCode} - {value.skuName}
                        </Option>
                    ))}
                </Select>
            );
        }
        else if (dataIndex === "grade") {
            inputNode = (
                <Select style={{ width: '100%' }}>
                    {[
                        "Default",
                        "Raw",
                        "Semi-ripe",
                        "Ripe",
                        "Medium",
                        "Large",
                        "Small",
                    ].map((grade) => (
                        <Option key={grade} value={grade}>{grade}</Option>
                    ))}
                </Select>
            );
        }

        else if (dataIndex === "pieces") {
            inputNode = <Input min={0}
                defaultValue={0}
                onKeyDown={(event) => {
                    if (
                        !/[0-9]/.test(event.key) && // Check if it's not a number
                        event.key !== 'Backspace' && // Allow Backspace
                        event.key !== 'Delete' &&    // Allow Delete
                        event.key !== 'ArrowLeft' && // Allow Left Arrow
                        event.key !== 'ArrowRight' && // Allow Right Arrow
                        event.key !== 'Tab'       // Allow Tab
                    ) {
                        event.preventDefault();
                    }
                }}
                type="number" disabled={!record.piecesEnabled} />;
        }

        else if (dataIndex === "quantity") {
            inputNode = <Input min={0}
                defaultValue={0}
                suffix={getUomMapping(record.uom?.toString())}
                onKeyDown={(event) => {
                    if (record.uom == null || record.uom?.toLowerCase() == "kilogram")
                        return;
                    if (
                        !/[0-9]/.test(event.key) && // Check if it's not a number
                        event.key !== 'Backspace' && // Allow Backspace
                        event.key !== 'Delete' &&    // Allow Delete
                        event.key !== 'ArrowLeft' && // Allow Left Arrow
                        event.key !== 'ArrowRight' && // Allow Right Arrow
                        event.key !== 'Tab'       // Allow Tab
                    ) {
                        event.preventDefault();
                    }
                }}
                type="number" />;
        }

        else {
            inputNode = <Input />;
        }

        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item
                        name={dataIndex}
                        style={{ margin: 0 }}
                        rules={[
                            {
                                required: true,
                                message: `Please enter ${title}`,
                            },
                        ]}
                        help={(record.piecesEnabled && dataIndex === "pieces") ? "Please Specify pieces or else fill zero" : ""}
                        hasFeedback={dataIndex === "pieces"}
                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };

    // Table columns configuration
    const columns = [
        {
            title: 'SKU ID/Name',
            dataIndex: 'skuCode',
            editable: true,
            render: (_: any, record: PartialItemType) => (
                <>
                    <div>{record.skuCode}</div>
                    <div>{masterSkus.find(sku => sku.skuCode === record.skuCode)?.name}</div>
                </>
            ),
        },
        {
            title: 'Grade',
            dataIndex: 'grade',
            editable: true,
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            editable: true,
        },
        {
            title: 'Pieces',
            dataIndex: 'pieces',
            editable: true,
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            render: (_: any, record: PartialItemType) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Typography.Link onClick={() => save(record)} style={{ marginRight: 8 }}>
                            Save
                        </Typography.Link>
                        <Typography.Link onClick={cancel}>Cancel</Typography.Link>
                    </span>
                ) : (
                    <span>
                        <Typography.Link
                            disabled={editingKey !== ''}
                            onClick={() => edit(record)}
                            style={{ marginRight: 8 }}
                        >
                            Edit
                        </Typography.Link>
                        <Popconfirm
                            title="Are you sure you want to delete this item?"
                            onConfirm={() => handleDelete(record)}
                        >
                            <Button
                                shape="circle"
                                icon={<MdDeleteOutline size={18} />}
                            />
                        </Popconfirm>
                    </span>
                );
            },
        },
    ];

    const mergedColumns = columns.map(col => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: PartialItemType) => ({
                record,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
                inputType: col.dataIndex === 'skuCode' || col.dataIndex === 'grade' ? 'select' : 'text',
            }),
        };
    });

    const fetchInitialSkuData = async () => {
        console.log("Fertching initial SKU data");

        if (initSkuCode && splitSkuListing.length > 0) {
            const currentSku = splitSkuListing.find(sku => sku.comboSkuCode.toString() === initSkuCode);
            if (currentSku) {
                const updatedRows = await Promise.all(
                    currentSku.splitSkuDetailBeans.map(async (detail, index) => {
                        const productDetails = await fetchProductDetails(detail.skuCode);
                        return {
                            ...detail,
                            key: index.toString(),
                            piecesEnabled: productDetails?.piecesEnabled || false, // Assuming piecesEnabled is part of the response
                        };
                    })
                );
                setTableRows(updatedRows);
                parentForm.setFieldsValue({
                    comboSkuCode: currentSku.comboSkuCode,
                    whId: currentSku.whId,
                    storeIds: currentSku.storeIds,
                });
            }
        }
    };

    // Load initial data
    useEffect(() => {
        if (!initSkuCode) return;

        if (splitSkuListing.length === 0) {
            dispatch(fetchSplitSku({ filter: "" })).then(() => {
                const currentSku = splitSkuListing.find(sku => sku.comboSkuCode.toString() === initSkuCode);
                if (currentSku) {
                    setViewedSku(currentSku);
                    setTableRows(
                        currentSku.splitSkuDetailBeans.map((detail, index) => ({
                            ...detail,
                            key: index.toString(),
                        }))
                    );
                    parentForm.setFieldsValue({
                        comboSkuCode: currentSku.comboSkuCode,
                        whId: currentSku.whId,
                        storeIds: currentSku.storeIds,
                    });
                    fetchInitialSkuData();
                } else {
                    navigate("/requisition/all-splitsku");
                }
            });
        } else {
            const currentSku = splitSkuListing.find(sku => sku.comboSkuCode.toString() === initSkuCode);
            if (currentSku) {
                setViewedSku(currentSku);
                setTableRows(
                    currentSku.splitSkuDetailBeans.map((detail, index) => ({
                        ...detail,
                        key: index.toString(),
                    }))
                );
                parentForm.setFieldsValue({
                    comboSkuCode: currentSku.comboSkuCode,
                    whId: currentSku.whId,
                    storeIds: currentSku.storeIds,
                });
                fetchInitialSkuData();
            } else {
                navigate("/requisition/all-splitsku");
            }
        }
    }, []);

    useEffect(() => {
        dispatch(fetchWarehouses());
        dispatch(getDarkStores());
    }, []);

    return (
        <>
            <Row style={{ margin: "1rem 0", position: "sticky", top: 0, zIndex: 1, background: variables.wmBackgroundColorLight1, padding: "5px" }}>
                <Col xs={24}>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <NavLink to="/requisition/all-splitsku">Split SKU</NavLink>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            {viewedSku?.comboSkuCode || "Split SKU Details"}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>

            <Row justify={"space-between"} align={"middle"}>
                <AppPageHeader title="Split SKU Details" />
                <Popconfirm
                    title="Are you sure you want to delete this SKU?"
                    disabled={status === "loading"}
                    onConfirm={deleteSku}>
                    <Button
                        type="primary"
                    >
                        Delete SKU
                    </Button>
                </Popconfirm>
            </Row>

            {(
                <>
                    <Descriptions
                        size="small"
                        layout="vertical"
                        labelStyle={{ fontWeight: "bold" }}
                        bordered
                        column={{ xs: 1, sm: 1, md: 3 }}
                        style={{ marginBottom: "20px" }}
                    >
                        {/* Split SKU Code */}
                        <Descriptions.Item label="Split SKU Code" style={{ width: '33%' }}>
                            <Form form={parentForm} component={false}>
                                <Form.Item
                                    name="comboSkuCode"
                                    initialValue={viewedSku?.comboSkuCode}
                                    rules={[{ required: true, message: 'Please enter Split SKU Code' }]}
                                    style={{ margin: 0 }}
                                >
                                    <Select
                                        showSearch
                                        style={{ width: '100%' }}
                                        filterOption={(input, option) =>
                                            option?.children?.toString().toLowerCase().includes(input.toLowerCase()) || false
                                        }
                                        onChange={(_) => {
                                            setHasUnsavedChanges(true);
                                        }}
                                    >
                                        {Object.entries(getSkuOptions("")).map(([key, value]) => (
                                            <Option key={key} value={value.skuCode}>
                                                {value.skuCode} - {value.skuName}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Form>
                        </Descriptions.Item>

                        {/* Warehouse ID */}
                        <Descriptions.Item label="Warehouse" style={{ width: '33%' }}>
                            <Form form={parentForm} component={false}>
                                <Form.Item
                                    name="whId"
                                    initialValue={viewedSku?.whId}
                                    rules={[{ required: true, message: 'Please select Warehouse ID' }]}
                                    style={{ margin: 0 }}
                                >
                                    <Select
                                        onChange={() => {
                                            setHasUnsavedChanges(true);
                                            handleClearAll();
                                        }}
                                        options={(user.user?.mappedWh ?? []).map((whInfo: WhInfo) => ({
                                            value: whInfo.id,
                                            label: `${whInfo.name} || ${whInfo.id}`,
                                        }))}
                                    />
                                </Form.Item>
                            </Form>
                        </Descriptions.Item>

                        {/* Store ID */}
                        <Descriptions.Item label="Stores" style={{ width: '33%' }}>
                            <Form form={parentForm} component={false} >
                                <Form.Item
                                    name="storeIds"
                                    initialValue={(darkStores ?? []).map(storeInfo => storeInfo.id)} // Default value: all selected
                                    rules={[{ required: true, message: 'Please enter Store ID' }]}
                                    style={{ margin: 0 }}

                                >
                                    <Select
                                        mode="multiple"
                                        onChange={(v) => {
                                            setHasUnsavedChanges(true);
                                        }}
                                        showSearch
                                        value={selectedStores} // Bind selected values to state
                                        options={(darkStores ?? []).map((storeInfo) => ({
                                            value: storeInfo.id,
                                            label: storeInfo.name
                                        }))}
                                    />
                                </Form.Item>
                                <Row style={{ marginTop: 8 }}>
                                    <Button
                                        type="link"
                                        onClick={() => handleSelectAll()}
                                    >
                                        Select All
                                    </Button>
                                    <Button
                                        type="link"
                                        onClick={() => handleClearAll()}
                                    >
                                        Clear All
                                    </Button>
                                </Row>
                            </Form>
                        </Descriptions.Item>
                    </Descriptions>


                    {viewedSku && <Descriptions layout="vertical"
                        labelStyle={{ fontWeight: "bold" }}
                        bordered
                        column={3}
                        style={{ marginBottom: "20px" }}>
                        <Descriptions.Item label="Created At">
                            <>{formatDate(viewedSku?.createdOn)}</>
                        </Descriptions.Item>
                        <Descriptions.Item label="Modified At">
                            <>{formatDate(viewedSku?.modifiedOn)}</>
                        </Descriptions.Item>
                    </Descriptions>}

                    <Form form={form} component={false}>
                        <Table
                            components={{
                                body: { cell: EditableCell },
                            }}
                            bordered
                            dataSource={tableRows}
                            columns={mergedColumns}
                            rowClassName="editable-row"
                            pagination={false}
                            scroll={{ x: "100vh", y: "65vh" }}
                            size="small"
                            footer={() => (
                                <Row justify="space-between">
                                    <Button
                                        type="primary"
                                        icon={<BsPlus />}
                                        onClick={onAddItem}
                                        disabled={editingKey !== ''}
                                    >
                                        Add Item
                                    </Button>
                                    <Button
                                        type="primary"
                                        onClick={saveAllChanges}
                                        disabled={tableRows.length === 0 || editingKey !== ''}
                                    >
                                        {viewedSku ? "Save All Changes" : "Create New SKU"}
                                    </Button>
                                </Row>
                            )}
                        />
                    </Form>
                </>
            )}
        </>
    );
};

export default SplitSKUDetailsPage;