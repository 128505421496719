import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import API, { apis } from "common/apis";
import { logoutIfNeeded } from 'common/utils';

export interface SplitSkuDetail {
    skuCode: string;
    grade: string;
    quantity: number;
    pieces: number;
}

export interface SplitSku {
    createdOn: string;
    modifiedOn: string;
    createdBy: number;
    modifiedBy: number;
    active: number;
    id: number;
    whId: number;
    storeIds: number[];
    comboSkuCode: string;
    grade: string;
    splitSkuDetailBeans: SplitSkuDetail[];
}

interface SplitSkuState {
    splitSkuListing: SplitSku[];
    status: "loading" | "success" | "error" | "idle" | "cancelling" | "saving" | "updating";
    loading: boolean;
    error: string | null;
}

const initialState: SplitSkuState = {
    splitSkuListing: [],
    status: "idle",
    loading: false,
    error: null,
};

export const fetchSplitSku = createAsyncThunk<SplitSku[], {filter: string}>('splitSku/fetchSplitSku', async ({ filter }, thunkApi) => {
    const filterParams = filter
    ? Object.fromEntries(new URLSearchParams(filter))
    : "";
  const response = await API.get(apis.getSplitSku, {
    params: {
      ...filterParams,
    },
  })
    .then((success) => success.data)
    .catch((error) => {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

export const saveSplitSku = createAsyncThunk<{whId: number, storeIds: number[], comboSkuCode: string, splitSkuDetailBeans: SplitSkuDetail[]}, {id?: number, whId: number, storeIds: number[], comboSkuCode: string, splitSkuDetailBeans: SplitSkuDetail[]}>('splitSku/saveSplitSku', async (splitSku, thunkApi) => {
  const response = await API.post(apis.createSplitSku, splitSku,)
    .then((success) => success.data)
    .catch((error) => {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

export const deleteSplitSku = createAsyncThunk<{}, {id?: number}>('splitSku/deleteSplitSku', async (splitSku, thunkApi) => {
  const response = await API.delete(apis.deleteSplitSku + '/' + splitSku.id,)
    .then((success) => success.data)
    .catch((error) => {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

const splitSkuSlice = createSlice({
    name: 'splitSku',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSplitSku.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(fetchSplitSku.fulfilled, (state, action) => {
                state.status = "success";
                state.splitSkuListing = action.payload;
            })
            .addCase(fetchSplitSku.rejected, (state, action) => {
                state.status = "error";
                state.splitSkuListing = [];
                state.error = action.error.message || 'Failed to fetch split SKU data';
            })
            .addCase(saveSplitSku.pending, (state) => {
                state.status = "saving";
                state.error = null;
            })
            .addCase(saveSplitSku.fulfilled, (state, action) => {
                state.status = "success";
                state.error = null;
            })
            .addCase(saveSplitSku.rejected, (state, action) => {
                state.status = "error";
                console.log(action.error);
                
                state.error = action.error.message || 'Failed to fetch split SKU data';
            });
    },
});

export default splitSkuSlice.reducer;