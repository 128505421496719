import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API, { apis } from "common/apis";
import { IApiError } from "common/interface/IApiError";
import { IRequisitionUserMappingUpload, ISku } from "common/interface";
import {
    removePersistedProcessingRequisitionUserMapping
} from "services/PersistService/PersistRequisitionService";
import { RootState } from "store/store";
import { logoutIfNeeded } from "common/utils";
import { IPickerMappingUpload } from "common/interface/IPickerUploadScheme";
import { getPersistProcessingStoreReturnMapping, persistProcessingStoreReturnMapping, removePersistProcessingStoreReturnMapping } from "services/PersistService/PersistStoreReturnMappingServices";

export interface State {
    processingStoreReturn: IPickerMappingUpload;
    status: "loading" | "success" | "error" | "idle" | "cancelling";
    error?: string;
    storesList?: StoreInfo[];
}

export interface StoreInfo {
    active: string,
    addres: string,
    extStoreId: string,
    id: number,
    name: string,
    storeType: string,
  }

const initialState: State = {
    processingStoreReturn: getPersistProcessingStoreReturnMapping(),
    status: "idle",
    storesList: [],
};

export const saveStoreReturn = createAsyncThunk<IRequisitionUserMappingUpload>("store-return/upload/save", async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const { processingStoreReturn: { key } } = state.storeReturnUpload;

    const response = await API.post(apis.saveStoreReturnMapping, {}, {
        params: { key }
    })
        .then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
});

export const cancelStoreReturn = createAsyncThunk("store-return/upload/cancel", async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const { processingStoreReturn: { key } } = state.storeReturnUpload;

    const response = await API.post(apis.saveStoreReturnMapping, {}, {
        params: { key, cancel: 1 }
    })
        .then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
})


export const fetchStoresList = createAsyncThunk<StoreInfo[], string>(
    "stores/get-all",
    async (id, thunkApi) => {
  
      const response = await API.get(`${apis.getStoresInfo}`)
        .then((success) => success.data as StoreInfo[])
        .catch((error) => {
          const errorMessage =
            error?.response?.data?.message || "Something went wrong!";
          logoutIfNeeded(error, thunkApi);
          return thunkApi.rejectWithValue({ message: errorMessage });
        });
  
      return response;
    }
  );
  
const storeReturnUploadSlice = createSlice({
    name: "store-return",
    initialState,
    reducers: {
        setProcessingStoreReturnMapping: (state, action) => {
            state.status = "idle";
            state.error = "";
            state.processingStoreReturn = action.payload;
            state.processingStoreReturn.timeUploaded = new Date().toString();
            persistProcessingStoreReturnMapping(state.processingStoreReturn);
        },
        removeProcessingStoreReturnMapping: (state) => {
            state.processingStoreReturn = {};
            state.status = "idle";
            state.error = "";
            removePersistProcessingStoreReturnMapping();
        },
    },
    extraReducers(builder) {
        builder

            /**
            * SAVE REQUISITION USER MAPPING
            **/
            .addCase(saveStoreReturn.pending, state => {
                state.status = "loading";
            })
            .addCase(saveStoreReturn.fulfilled, state => {
                state.status = "success";
                state.error = "";
                state.processingStoreReturn = {};
                removeProcessingStoreReturnMapping();
            })
            .addCase(saveStoreReturn.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";

                if (error.message) {
                    state.error = error.message;
                }
            })

            /**
             * CANCEL PROCESSING REQUISITION
            **/
            .addCase(cancelStoreReturn.pending, state => {
                state.status = "cancelling";
            })
            .addCase(cancelStoreReturn.fulfilled, state => {
                state.status = "idle";
                state.error = "";
                state.processingStoreReturn = {};
                removePersistedProcessingRequisitionUserMapping();
            })
            .addCase(cancelStoreReturn.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";
                if (error.message) {
                    state.error = error.message;
                }
            })
            /**
             * Get Stores List
             */
            .addCase(fetchStoresList.fulfilled, (state, action) => {
                state.storesList = action.payload;
            })
    }
});

export const {
    setProcessingStoreReturnMapping,
    removeProcessingStoreReturnMapping
} = storeReturnUploadSlice.actions;
export default storeReturnUploadSlice.reducer; 