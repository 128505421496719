import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Table, Alert, message } from "antd";
import { apis, AUTH_HEADER_NAME } from "common/apis";
import { IPoRow, IPoUpload } from "common/interface";
import { routes } from "common/routes";
import { AppPageHeader } from "components/AppPageHeader";
import { AppUpload } from "components/AppUpload";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { removeProcessingPO, setProcessingPO } from "store/po/poSlice";
import { generateHeaders } from "common/utils";
import { ColumnsType } from "antd/lib/table";
import { useDocumentTitle } from "hooks/usePageTitle";

const POUploadPage = () => {
  useDocumentTitle("Upload Purchase Order");
  const { authToken } = useAppSelector((state) => state.app.user);
  const { processingPO } = useAppSelector((state) => state.po);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [hasErrorRows, setHasErrorRows] = useState(false);

  useEffect(() => {
    const { timeUploaded } = processingPO;
    if (timeUploaded) {
      const uploadedTime = new Date(timeUploaded).getTime();
      const currentTime = new Date().getTime();
      const minsPassed = (currentTime - uploadedTime) / 1000 / 60;

      if (minsPassed >= 15) {
        dispatch(removeProcessingPO());
      }
    }

    if (processingPO && processingPO.key) {
      navigate(`/${routes.PO.SAVE}`, {});
    }

    return () => {
      message.destroy();
    };
  }, [processingPO, navigate, dispatch]);

  useEffect(() => {
    const failed = processingPO?.failedRows?.length;

    if (failed) {
      setHasErrorRows(true);
    }
  }, [processingPO?.failedRows]);

  const headers: { [name: string]: string } = {
    ...(authToken && { [AUTH_HEADER_NAME]: authToken }),
  };

  const onUploaded = (data: IPoUpload) => {
    dispatch(setProcessingPO(data));
  };

  const onUploading = () => {
    dispatch(removeProcessingPO());
  };

  // useEffect(() => {
  //   return (() => {
  //     dispatch(removeProcessingPO())
  //   });
  // }, []);

  const { headerMapping } = processingPO;
  const headerMappingWithError = headerMapping + ",errors:Errors";

  const generateMemoizedHeaders = useMemo(
    () => generateHeaders(headerMappingWithError),
    [headerMappingWithError]
  );

  const tableHeaders = generateMemoizedHeaders || {};
  const successRows = processingPO?.successRows || [];
  const failedRows = processingPO?.failedRows || [];
  const successData = successRows?.map((row, i) => ({
    ...row,
    key: `data-success-${i}`,
  }));
  const failedData = failedRows?.map((row, i) => ({
    ...row,
    key: `data-failed-${i}`,
  }));
  const data = [...failedData, ...successData];
  const columns: ColumnsType<IPoRow & { key: string }> = [
    ...Object.keys(tableHeaders).map((key) => ({
      key,
      title: tableHeaders[key],
      dataIndex: key,
      render: (text: string, record: IPoRow & { key: string }) => {
        if (key === "errors") {
          const errorMessages = record.errors
            ?.map((error) => error.message)
            .join(", ");
          return <>{errorMessages}</>;
        }
        return <>{text}</>;
      },
    })),
  ];

  const addBorderToErrorRows = (record: IPoRow & { key: string }) => {
    if (record.errors && record.errors.length) {
      return "error-row";
    }

    return "";
  };

  return (
    <>
      <Row>
        <Col>
          <AppPageHeader title="Upload Purchase Order" />
        </Col>
      </Row>

      <Row>
        <Col lg={16} xs={24}>
          <AppUpload
            uploadUrl={apis.uploadPO}
            headers={headers}
            onUploaded={onUploaded}
            onUploading={onUploading}
            hasErrorRows={hasErrorRows}
          />
        </Col>
      </Row>

      {!!Object.keys(processingPO).length && !processingPO.key && (
        <>
          <Row>
            <Col lg={16} xs={24}>
              <Alert
                type="error"
                message="Errors in sheet data."
                description="Please correct errors and upload again."
                showIcon
                banner
              />
            </Col>
          </Row>

          <Row>
            <Table
              size="small"
              columns={columns}
              dataSource={data}
              rowClassName={(record) => addBorderToErrorRows(record)}
              scroll={{ y: 500, x: "100vh" }}
              className="table-with-errors"
            ></Table>
          </Row>
        </>
      )}
    </>
  );
};

export default POUploadPage;
