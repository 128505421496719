import { Avatar, Button, Col, Form, FormInstance, Row, Upload } from 'antd';
import { useEffect, useState } from 'react';

import { Image } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import { useAppSelector } from 'hooks/useAppSelector';
import { AUTH_HEADER_NAME, apis } from 'common/apis';
import { fallBackImage } from 'common/styles';
import { MdDeleteOutline } from 'react-icons/md';


const CustomUploadField = ({ form, fieldName }: { form: FormInstance, fieldName: string }) => {

    const { authToken } = useAppSelector((state) => state.app.user);

    const [value, setValue] = useState();

    const headers: {} = {
        ...(authToken && { [AUTH_HEADER_NAME]: authToken }),
    };

    useEffect(() => {
        setTimeout(() => {
            setValue(form.getFieldValue(fieldName));
        }, 50,);
    }, [form,],); 

    function isImageUrl(url: string) {
        const pattern = /\.(jpeg|jpg|gif|png|bmp|svg|webp)$/i;
        return pattern.test(url);
    }

    return (
        <>
            {value && <Row>
                <Col xs={24} md={10}>
                    {isImageUrl(value) ? <Avatar
                    style={{border: "1px solid green"}}
                        src={<Image
                            src={`${value}`}
                            fallback={fallBackImage}
                            alt={"File"}
                            width="100%" />} /> : <Button
                            type='primary'
                                title='View File'
                                onClick={() => {
                                    window.open(value, "_blank")?.focus();
                                }}
                            >View File</Button>}
                </Col>
                <Col xs={24} md={2}></Col>
                <Col xs={24} md={2}>
                    <Button
                        icon={<MdDeleteOutline />}
                        onClick={() => {
                            form.setFieldsValue({[fieldName]: undefined});
                            setValue(undefined);
                        }} />
                </Col>
            </Row>}
            {!value && 
            <Col xs={24} md={2}>
                <Upload
                name='file'
                onRemove={() => setValue(undefined)}
                headers={headers}
                maxCount={1}
                action={apis.uploadVendorImage}
                onChange={(value) => {
                    if (value?.file?.status == "done") {
                        if (value.file.response?.url != undefined) {
                            form.setFieldsValue({[fieldName]: value.file.response?.url});
                            setValue(value.file.response?.url);
                        }
                    }
                }}>
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
            </Col>
            
            }
        </>
    );
};

export default CustomUploadField;
