import { IPickerMappingUpload } from "common/interface/IPickerUploadScheme";

const STORAGE_KEY_STORE_RETURN = "processingStoreReturnMapping";

export const persistProcessingStoreReturnMapping = (data: IPickerMappingUpload) => {
  localStorage.setItem(STORAGE_KEY_STORE_RETURN, JSON.stringify(data));
}

export const getPersistProcessingStoreReturnMapping = (): IPickerMappingUpload => {
  const processingWhReturnMapping = localStorage.getItem(STORAGE_KEY_STORE_RETURN);
  return processingWhReturnMapping ? JSON.parse(processingWhReturnMapping) : {};
}

export const removePersistProcessingStoreReturnMapping = () => {
    localStorage.removeItem(STORAGE_KEY_STORE_RETURN);
  }