import {
  Alert,
  Col,
  message,
  Row,
  Table,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import { apis, AUTH_HEADER_NAME } from "common/apis";
import { IPickerMappingUpload, IPickerMappingUploadRow } from "common/interface/IPickerUploadScheme";
import { routes } from "common/routes";
import { generateHeaders } from "common/utils";
import { AppPageHeader } from "components/AppPageHeader";
import { AppUpload } from "components/AppUpload";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useDocumentTitle } from "hooks/usePageTitle";
import { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { removeProcessingVendorWarehouseMapping, setProcessingVendorWarehouseMapping } from "store/vendor/vendorSlice";

export const UploadVendorWarehousePage = () => {
  useDocumentTitle("Upload Vendor Warehouse Mapping");
  const { authToken } = useAppSelector((state) => state.app.user);

  const { processingVendorWarehouseMapping: processingProjectedSrMapping } = useAppSelector(
    (state) => state.vendors,
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [hasErrorRows, setHasErrorRows] = useState(false);

  useEffect(() => {
    if (
      processingProjectedSrMapping &&
      processingProjectedSrMapping.key
    ) {
      navigate(`/${routes.PROCUREMENT.SAVE_VENDOR_WAREHOUSE_MAPPING}`, {});
    }

    return () => {
      message.destroy();
    };
  }, [processingProjectedSrMapping, navigate]);

  useEffect(() => {
    const failed = processingProjectedSrMapping?.failedRows?.length;

    if (failed) {
      setHasErrorRows(true);
    }
  }, [processingProjectedSrMapping?.failedRows]);

  const headers: { [name: string]: string } = {
    ...(authToken && { [AUTH_HEADER_NAME]: authToken }),
  };

  const onUploaded = (data: IPickerMappingUpload) => {
    dispatch(setProcessingVendorWarehouseMapping(data));
  };

  const onUploading = () => {
    dispatch(removeProcessingVendorWarehouseMapping());
  };

  const { headerMapping } = processingProjectedSrMapping;
  const headerMappingWithError = headerMapping + ",errors:Errors";

  const generateMemoizedHeaders = useMemo(
    () => generateHeaders(headerMappingWithError),
    [headerMappingWithError]
  );

  const tableHeaders = generateMemoizedHeaders || {};
  const successRows = processingProjectedSrMapping?.successRows || [];
  const failedRows = processingProjectedSrMapping?.failedRows || [];
  const successData = successRows?.map((row, i) => ({
    ...row,
    key: `data-success-${i}`,
  }));
  const failedData = failedRows?.map((row, i) => ({
    ...row,
    key: `data-failed-${i}`,
  }));
  const data = [...failedData, ...successData];
  const columns: ColumnsType<
    IPickerMappingUploadRow & { key: string }
  > = [
      ...Object.keys(tableHeaders).map((key) => ({
        key,
        title: tableHeaders[key],
        dataIndex: key,
        render: (
          text: string,
          record: IPickerMappingUploadRow & { key: string }
        ) => {
          if (key === "errors") {
            const errorMessages = record.errors
              ?.map((error) => error.message)
              .join(", ");
            return <>{errorMessages}</>;
          }
          return <>{text}</>;
        },
      })),
    ];

  const addBorderToErrorRows = (
    record: IPickerMappingUploadRow & { key: string }
  ) => {
    if (record.errors && record.errors.length) {
      return "error-row";
    }

    return "";
  };

  return (
    <>
      <Row>
        <Col>
          <AppPageHeader title="Upload Vendor Warehouse Mapping" />
        </Col>
      </Row>
      <Row>
        <Col lg={16} xs={24}>
          <AppUpload
            uploadUrl={apis.uploadVendorWarehouseMapping}
            headers={headers}
            onUploaded={onUploaded}
            onUploading={onUploading}
            hasErrorRows={hasErrorRows}
          />
        </Col>
      </Row>
      {Object.keys(processingProjectedSrMapping).length != 0 &&
        !processingProjectedSrMapping.key && (
          <>
            <Row>
              <Col lg={16} xs={24}>
                <Alert
                  type="error"
                  message="Errors in sheet data."
                  description="Please correct errors and upload again."
                  showIcon
                  banner
                />
              </Col>
            </Row>

            <Row>
              <Table
                size="small"
                columns={columns}
                dataSource={data}
                rowClassName={(record) => addBorderToErrorRows(record)}
                scroll={{ y: 500 }}
                className="table-with-errors"
              ></Table>
            </Row>
          </>
        )}
    </>
  );
};
