import { UploadOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  Descriptions,
  Form,
  Input,
  message,
  Popconfirm,
  Row,
  Select,
  Table,
  Typography,
  Upload,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import { apis, AUTH_HEADER_NAME } from "common/apis";
import { IPo, IPoItem } from "common/interface";
import { getDateAndTimeFromISODate, getDateFromISODate } from "common/utils";
import { variables } from "common/variables";
import { AppPageHeader } from "components/AppPageHeader";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useDocumentTitle } from "hooks/usePageTitle";
import React, { useCallback, useEffect, useState } from "react";
import { BsPlus } from "react-icons/bs";
import { MdDeleteOutline } from "react-icons/md";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { billPO, changeVendor, closePO, closePORequest, deletePoItem, discardPO, downloadGRN, downloadPO, getPoById, issuePO, savePoItem, sendToMail, updateInvoiceUrl, updatePoItemBillingRate } from "store/po/poSlice";
import { getAllVendors } from "store/vendor/vendorSlice";

const MESSAGE_KEY = "po_info";
type PartialIPoItemType = Partial<IPoItem> & { key: string };

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "select" | "text";
  record: PartialIPoItemType;
  index: number;
  children: React.ReactNode;
}

const { Option } = Select;

const PORequestViewPage = () => {
  useDocumentTitle("Purchase Order");
  const dispatch = useAppDispatch();
  const { pos, error, status } = useAppSelector((state) => state.po);
  const { masterSkus = [] } = useAppSelector((state) => state.requisition);
  const { poId } = useParams();
  const { vendors } = useAppSelector((state) => state.vendors);
  const [viewedPO, setViewedPO] = useState<IPo>();
  const [tableColumns, setTableColumns] =
    useState<ColumnsType<PartialIPoItemType>>();
  const [tableRows, setTableRows] = useState<any[]>();
  const [tempTableRows, setTempTableRows] = useState<any[]>();
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const [activeVendor, setActiveVendor] = useState("");
  const [isAddingItem, setIsAddingItem] = useState(false);
  const navigate = useNavigate();
  const { authToken } = useAppSelector((state) => state.app.user);
  const headers: {} = {
    ...(authToken && { [AUTH_HEADER_NAME]: authToken }),
  };
  const getVendorData = useCallback(
    (pageNum = 1, pageSize = 100, filter = "") => {
      dispatch(getAllVendors({ pageNo: pageNum, pageSize, filter }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (editingKey) {
      const selectedRowData = viewedPO?.poItems.find(e => e.id.toString() === editingKey.toString().split("-")[0]);
      form.setFieldsValue({
        skuId: masterSkus.find(k => k.skuCode === selectedRowData?.skuCode)?.id.toString(),
      });
    }
  }, [editingKey, form]);

  useEffect(() => {
    if (vendors == undefined || vendors?.data.length == 0) {
      getVendorData();
    }
  }, [])

  const isEditing = useCallback(
    (record: PartialIPoItemType) => record.key === editingKey,
    [editingKey]
  );

  const edit = useCallback(
    (record: PartialIPoItemType) => {
      form.setFieldsValue({ ...record });
      setEditingKey(record.key);
    },
    [form]
  );

  const saveItem = useCallback(
    (poId: string, poItem: IPoItem) => {
      // poItem.totalAmount = poItem.requestedQty * (poItem.billingRate ?? poItem.poRate);
      if (["GRN_CLOSED", "ISSUED"].includes(viewedPO!.status)) {
        dispatch(updatePoItemBillingRate({ poId, poItem }));
      }
      else {
        dispatch(savePoItem({ poId, poItem }));
      }
    },
    [dispatch, viewedPO]
  );

  const save = useCallback(
    async (poId: string, poItemId: string) => {
      try {
        const row = await form.validateFields();
        const index = masterSkus.findIndex(
          (item: any) => row.skuId === item.id.toString()
        );

        if (viewedPO) {
          if (index > -1) {
            saveItem(poId, {
              ...row,
              skuCode: masterSkus[index].skuCode,
              skuName: masterSkus[index].name,
              id: poItemId,
            });
            setEditingKey("");
          } else {
            saveItem(poId, row);
            setEditingKey("");
          }
        }

        setTempTableRows([]);
        setIsAddingItem(false);
        form.resetFields();
      } catch (errInfo) {
        console.log("Validate Failed:", errInfo);
      }
    },
    [form, masterSkus, saveItem, viewedPO]
  );

  useEffect(() => {
    if (poId) {
      dispatch(getPoById(poId)).then(() => {
      });      
    }
  }, [poId, dispatch]);

  useEffect(() => {
    if (status === "loading") {
      message.loading({ content: "loading purchase order", key: MESSAGE_KEY });
    } else if (status === "saving") {
      message.loading({ content: "saving purchase order", key: MESSAGE_KEY });
    } else if (status === "error") {
      message.error({ content: error, key: MESSAGE_KEY });
    }

    return () => {
      message.destroy();
    };
  }, [status, error]);

  useEffect(() => {
    const thisPO = pos?.data.find((p) => p.id.toString() == poId);
    console.log("Updating State");
    
    if (thisPO) {
      setViewedPO(thisPO);
    }
    // else {
    //   navigate("/po/requests/");
    // }
  }, [navigate, poId, pos?.data]);

  const cancel = useCallback(() => {
    setEditingKey("");
    setIsAddingItem(false);
    setTempTableRows([]);
    form.resetFields();
  }, [form]);

  const onDiscardPO = useCallback(() => {
    const id = Number(viewedPO!.id);

    if (!isNaN(id)) {
      dispatch(discardPO({ id }))
        .unwrap()
        .then(() => navigate("/po/requests"));
    }
  }, [dispatch, navigate, viewedPO]);

  const onBillingComplete = useCallback(() => {
    const id = Number(viewedPO!.id);

    if (!isNaN(id)) {
      dispatch(billPO({ id }))
        .unwrap()
        .then(() => navigate("/po/requests"));
    }
  }, [dispatch, navigate, viewedPO]);

  const onPOClosure = useCallback(() => {
    const id = Number(viewedPO!.id);
    if (!isNaN(id)) {
      dispatch(closePORequest({ id }))
        .unwrap()
        .then(() => navigate("/po/requests"));
    }
  }, [dispatch, navigate, viewedPO]);

  const onUpdatedInvoice = useCallback((url: string) => {
    const id = Number(viewedPO!.id);
    if (!isNaN(id)) {
      dispatch(updateInvoiceUrl({ id, url }));
    }
  }, [dispatch, navigate, viewedPO]);

  const onChangeVendor = useCallback((id: number) => {
    dispatch(changeVendor({ id: Number(viewedPO!.id), vendorId: id, vendor: vendors!.data!.find(e => e.id == id)! }))
      .unwrap()
      .then(() => {
        // const temp: IPo = ({ ...viewedPO!, vendorId: id.toString(), vendorDetails: vendors?.data.find((e) => e.id === id), });
        // temp.vendorId = id.toString();
        // temp.vendorDetails = vendors?.data.find((e) => e.id === id);
        // setViewedPO(temp);
      });
  }, [dispatch, navigate, viewedPO]);

  const onIssuePo = useCallback(() => {
    const id = Number(viewedPO!.id);

    if (!isNaN(id)) {
      dispatch(issuePO({ id }))
        .unwrap()
        .then(() => navigate(`/po/requests`));
    }
  }, [dispatch, navigate, viewedPO]);

  const onDownloadPo = useCallback(() => {
    const id = Number(viewedPO!.id);

    if (!isNaN(id)) {
      dispatch(downloadPO({ id }));
    }
  }, [dispatch, navigate, viewedPO]);

  const onSendToWhatsapp = useCallback(() => {
    const number = Number(viewedPO?.vendorDetails?.whatsappPhone ?? viewedPO?.vendorDetails?.primaryPhone);
    if (!isNaN(number)) {
      window.open(`https://api.whatsapp.com/send?phone=918728826011&text=hello%2C%0APlease%20find%20the%20PO%20attached.`)?.focus();
    }
  }, [dispatch, navigate, viewedPO]);

  const onSendToMail = useCallback(() => {
    const id = Number(viewedPO!.id);

    if (!isNaN(id)) {
      dispatch(sendToMail({ id }));
    }
  }, [dispatch, navigate, viewedPO]);

  const onDeleteItem = useCallback((poItem: PartialIPoItemType) => {
    dispatch(deletePoItem({ poId: viewedPO!.id, poItemId: poItem.id! }));
  }, [dispatch, navigate, viewedPO]);

  useEffect(() => {
    const thisPO = pos?.data?.find((po) => po.id.toString() === poId);

    const headerKeys: { [key: string]: string } = {
      skuId: "SKU ID/Name",
      uom: "UOM",
      rtvAllowed: "RTV Allowed",
      requestedQty: "Ordered Quantity",
      ...(["GRN_CLOSED", "RECEIVED_DRAFT", "BILLING_COMPLETED"].includes(viewedPO?.status ?? "") && {
        grossReceivedQty: "Gross Rcv. Qty.",
      }),
      ...(["GRN_CLOSED", "RECEIVED_DRAFT", "BILLING_COMPLETED"].includes(viewedPO?.status ?? "") && { dockRtv: "Dock RTV" }),
      // ...(["GRN_CLOSED", "RECEIVED_DRAFT", "BILLING_COMPLETED"].includes(viewedPO?.status ?? "") && { netReceivedQty: "Net Rcv. Qty." }),
      ...(["GRN_CLOSED", "RECEIVED_DRAFT", "BILLING_COMPLETED"].includes(viewedPO?.status ?? "") && { sortingRtv: "Sorting RTV" }),
      ...(["GRN_CLOSED", "RECEIVED_DRAFT", "BILLING_COMPLETED"].includes(viewedPO?.status ?? "") && { finalReceivedQty: "Final Receive Qty." }),
      poRate: "PO Rate",
      billingRate: "Billing Rate",
      totalAmount: "Total Amount",
      actions: "Actions",
    };

    if (thisPO) {
      const columns = [
        ...Object.keys(headerKeys).map((key) => ({
          key,
          title: headerKeys[key],
          dataIndex: key,
          editable: key !== "actions" && true,
          render: (text: string, record: any) => {
            if (key === "skuId") {
              return (
                <>
                  <div>{record.skuCode}</div>
                  <div>{record.skuName}</div>
                </>
              );
            }

            if (key === "rtvAllowed") {
              if (record.rtvAllowed === undefined) {
                return <div></div>;
              }
              else {
                return (
                  <div>
                    {record.rtvAllowed ? "Yes" : "No"}
                  </div>
                );
              }
            }

            if (key === "actions") {
              const editable = isEditing(record);
              return editable ? (
                <Row
                  align="middle"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  <Col style={{ marginRight: ".5rem" }}>
                    <Typography.Link
                      onClick={() => save(thisPO.id, record.id)}
                      disabled={status === "loading"}
                      style={{ marginRight: 8 }}
                    >
                      Save
                    </Typography.Link>
                  </Col>
                  <Col>
                    <Typography.Link
                      onClick={cancel}
                      style={{ marginRight: 8 }}
                      type="secondary"
                      disabled={status === "loading"}
                    >
                      Cancel
                    </Typography.Link>
                  </Col>
                </Row>
              ) : (
                <Row
                  align="middle"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  <Typography.Link
                    disabled={editingKey !== "" || !["ISSUE_DRAFT", "GRN_CLOSED", "ISSUED"].includes(viewedPO?.status ?? "")}
                    onClick={() => edit(record)}
                  >
                    Edit
                  </Typography.Link>
                  {viewedPO?.status === "ISSUE_DRAFT" &&
                    <Popconfirm
                      title="Are you sure you want to delete this item?"
                      disabled={status === "saving"}
                      onConfirm={() => onDeleteItem(record)}>
                      <Button
                        style={{ marginLeft: "1rem" }}
                        shape="circle"
                        icon={<MdDeleteOutline size={18} style={{marginTop: ".2rem"}}/>}
                      >
                      </Button>
                    </Popconfirm>
                  }
                </Row>
              );
            }
            return <>{text}</>;
          },
        })),
      ];

      const mergedColumns = columns.map((col, i) => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          ...(i === 0 && { width: "220px" }),
          onCell: (record: PartialIPoItemType) => ({
            record,
            inputType: ["skuId", "rtvAllowed", "uom"].includes(col.dataIndex) ? "select" : "text",
            dataIndex: col.dataIndex,
            title: col.title,
            editing: isEditing(record),
          }),
        };
      });

      const rows: any[] =
        thisPO?.poItems
          ?.map((poItem, idx) => ({
            id: poItem.id,
            skuId: poItem.id,
            key: poItem.id + `-${idx}`,
            skuCode: poItem.skuCode,
            skuName: poItem.skuName,
            extPoIds: poItem.extPoIds,
            requestedQty: poItem.requestedQty,
            rtvAllowed: poItem.rtvAllowed,
            grossReceivedQty: poItem.grossReceivedQty,
            dockRtv: poItem.dockRtv,
            // netReceivedQty: poItem.netReceivedQty,
            sortingRtv: poItem.sortingRtv,
            uom: poItem.uom,
            poRate: poItem.poRate,
            billingRate: poItem.billingRate,
            totalAmount: poItem.totalAmount,
            finalReceivedQty: poItem.finalReceivedQty,
          }))
          .filter(
            (poI) => (isAddingItem && !(poI as any).temp) || !isAddingItem
          ) || [];

      setViewedPO(thisPO);
      setTableRows([...rows, ...(tempTableRows || [])]);
      setTableColumns(mergedColumns);
    }
  }, [
    cancel,
    edit,
    editingKey,
    form,
    isAddingItem,
    isEditing,
    poId,
    pos?.data,
    save,
    tempTableRows,
    viewedPO?.poItems,
    viewedPO?.status,
  ]);

  useEffect(() => {
  }, [viewedPO?.vendorDetails]);

  const getSkuOptions = (includeSku?: string) => {
    if (masterSkus) {
      const selectedSkus = viewedPO?.poItems
        .filter((poi) => poi.skuCode !== includeSku)
        .map((poI) => poI.skuCode);
      return masterSkus.reduce((acc, sku) => {
        if (!selectedSkus?.includes(sku.skuCode)) {
          return (acc = {
            ...acc,
            [sku.id]: {
              value: sku.id,
              label: sku.skuCode,
              skuCode: sku.skuCode,
              skuName: sku.name,
            },
          });
        }

        return acc;
      }, {} as Record<string, { skuCode: string; skuName: string }>);
    }

    return {};
  };

  const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    let inputNode =
      inputType === "select" ? (
        <>
          <div>{record.skuCode}</div>
          <div>{record.skuName}</div>
        </>
      ) : (
        <Input disabled={dataIndex === 'totalAmount' || (["GRN_CLOSED", "ISSUED"].includes(viewedPO!.status) && dataIndex !== 'billingRate')} />
      );
    const isFieldBoolean = dataIndex === "rtvAllowed";
    if ((isAddingItem || isEditing) && inputType === "select") {
      inputNode = <Select
        showSearch
        optionFilterProp=""
        disabled={(["GRN_CLOSED", "ISSUED"].includes(viewedPO!.status))}
        filterOption={(input, option) => {
          const keyWords =
            option?.children?.map((child) =>
              child.props.children.toLowerCase()
            ) || [];
          return keyWords.some((key) => key.includes(input.toLowerCase()));
        }}
        style={!isFieldBoolean ? { width: "200px" } : {}}
      >
        {!isFieldBoolean ? Object.entries(getSkuOptions(record.skuCode)).map(
          ([key, value]) => (
            <Option key={key} value={key}>
              <div>{value.skuCode}</div>
              <div>{value.skuName}</div>
            </Option>
          )
        ) : <>
          <Option key={`${dataIndex}_option_1`} value={true}>
            <div>Yes</div>
          </Option>
          <Option key={`${dataIndex}_option_1`} value={false}>
            <div>No</div>
          </Option>
        </>
        }
      </Select>
    }

    if (dataIndex === "uom") {
      inputNode = <Select
        showSearch
        disabled={(["GRN_CLOSED", "ISSUED"].includes(viewedPO!.status))}
        optionFilterProp=""
        filterOption={(input, option) => {
          const keyWords =
            option?.children?.map((child) =>
              child.props.children.toLowerCase()
            ) || [];
          return keyWords.some((key) => key.includes(input.toLowerCase()));
        }}
        style={{ width: "100px" }}
      >
        {["Kg", "Packet", "Piece", "Box"].map(
          (key) => (
            <Option key={key} value={key}>
              {key}
            </Option>
          )
        )
        }
      </Select>
    }

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required:
                  !(dataIndex === "totalAmount" || dataIndex === "billingRate") && !["GRN_CLOSED", "ISSUED"].includes(viewedPO!.status),
                message: `Please ${inputType === "select" ? "select" : "input"
                  } ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const onAddItem = () => {
    let idx = tableRows?.length || 0;
    const key = (++idx).toString();
    setTempTableRows([...(tempTableRows || []), { key, temp: true, }]);
    setEditingKey(key);
    setIsAddingItem(true);

    setTimeout(() => {
      document
        .querySelector(".editable-last-row")
        ?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  const poInfo = viewedPO && (
    <>
      <Descriptions
        size="small"
        layout="vertical"
        labelStyle={{ fontWeight: "bold" }}
        bordered
        column={{ xs: 2, md: 5 }}
      >
        <Descriptions.Item label="PO ID">{viewedPO.poId}</Descriptions.Item>
        <Descriptions.Item label="Order Date">{getDateFromISODate(viewedPO.orderDate)}</Descriptions.Item>
        <Descriptions.Item label="Expected Date">{getDateFromISODate(viewedPO.expectedDeliveryDate)}</Descriptions.Item>
        <Descriptions.Item label="Status">{viewedPO.status}</Descriptions.Item>
        {(["ISSUED", "ISSUE_DRAFT"].includes(viewedPO.status)) && (
          <Descriptions.Item label="Actions">
            <Row>
              {viewedPO.status === "ISSUE_DRAFT" && <Col style={{ marginRight: "20px" }}>
                <Popconfirm
                  title="Are you sure you want to issue this PO??"
                  disabled={status === "loading"}
                  onConfirm={onIssuePo}>
                  <Button
                    type="primary"
                  >
                    Issue PO
                  </Button>
                </Popconfirm>
              </Col>}
              <Col>
                <Popconfirm title="Are you sure to discard this PO?"
                  disabled={status === "loading"}
                  onConfirm={onDiscardPO}>
                  <Button disabled={status === "saving"} type={'primary'} loading={status === "saving"}>
                    Discard PO
                  </Button>
                </Popconfirm>
              </Col>
            </Row>
          </Descriptions.Item>
        )}
        {
          viewedPO.status === "GRN_CLOSED" && (
            <Descriptions.Item label="Actions">
              <Row>
                <Col>
                  <Popconfirm title="Are you sure to you want to complete billing for this PO?"
                    disabled={status === "loading"}
                    onConfirm={onBillingComplete}>
                    <Button type="primary" disabled={status === "saving"} loading={status === "saving"}>
                      Complete Billing
                    </Button>
                  </Popconfirm>
                </Col>
              </Row>
            </Descriptions.Item>
          )
        }
        {
          viewedPO.status === "BILLING_COMPLETED" && (
            <Descriptions.Item label="Actions">
              <Row>
                <Col>
                  <Popconfirm title="Are you sure to you want to close this PO?"
                    disabled={status === "loading"}
                    onConfirm={onPOClosure}>
                    <Button disabled={status === "saving"} type="primary" loading={status === "saving"}>
                      Close PO
                    </Button>
                  </Popconfirm>
                </Col>
              </Row>
            </Descriptions.Item>
          )
        }
      </Descriptions>

      {viewedPO.vendorDetails?.name && <Descriptions
        size="small"
        layout="vertical"
        labelStyle={{ fontWeight: "bold" }}
        bordered
        column={{ xs: 2, md: 3 }}
        style={{ marginTop: '20px' }}
      >
        <Descriptions.Item label="Vendor">
          {viewedPO.vendorDetails?.vendorCode ?? viewedPO.vendorDetails?.id} || {viewedPO.vendorDetails?.name}
        </Descriptions.Item>
        {viewedPO.status == "ISSUE_DRAFT" && <Descriptions.Item label="Action">
          <Popconfirm title={<>
            <>Please Select a vendor</>
            <Select
              showSearch
              filterOption={(input, option) => {
                return option?.children?.toString().toLowerCase().includes(input?.toLowerCase() ?? "") ?? false;
              }}
              value={activeVendor === "" ? viewedPO.vendorId : activeVendor}
              style={{ width: "200px" }}
              onChange={(e) => {
                setActiveVendor(e);
              }}
              defaultValue={"HOLD"}

            >
              {(vendors?.data ?? []).map((e) => (
                <Select.Option
                  value={e.id} key={e.id}>
                  {e.vendorCode} || {e.name}
                </Select.Option>
              ))}
            </Select>
          </>}
            disabled={status === "loading"}
            onConfirm={() => onChangeVendor(parseInt(activeVendor))}>
            <Button
              loading={status === "saving"}
              type="primary"
            >
              Change Vendor
            </Button>
          </Popconfirm>
        </Descriptions.Item>}

      </Descriptions>}

      {(viewedPO.expectedDeliveryDate || viewedPO.receivedDate) && <Descriptions
        size="small"
        layout="vertical"
        labelStyle={{ fontWeight: "bold" }}
        bordered
        column={2}
        style={{ marginTop: "20px" }}
      >
        {viewedPO.expectedDeliveryDate && <Descriptions.Item label="Expected Delivery Date">
          {getDateAndTimeFromISODate(viewedPO.expectedDeliveryDate)}
        </Descriptions.Item>}
        {viewedPO.receivedDate && <Descriptions.Item label="Receiving Date">
          {getDateAndTimeFromISODate(viewedPO.receivedDate)}
        </Descriptions.Item>}
      </Descriptions>}

      {!!(tableRows && tableRows.length) && (
        <Row style={{ marginTop: "20px" }}>
          <Col xs={24}>
            <Form form={form} component={false}>
              <Table
                className="po-item-table"
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                bordered
                dataSource={tableRows}
                columns={tableColumns}
                rowClassName={(record, index) =>
                  index === tableRows.length - 1
                    ? "editable-last-row editable-row"
                    : "editable-row"
                }
                pagination={false}
                scroll={{ x: "100vh", y: "65vh" }}
                style={{ width: "100%" }}
                size="small"
                footer={() => (
                  <Button
                    type="primary"
                    icon={<BsPlus />}
                    onClick={onAddItem}
                    disabled={isAddingItem || !["ISSUE_DRAFT"].includes(viewedPO.status)}
                  >
                    Add Item
                  </Button>
                )}
              />
            </Form>
          </Col>
        </Row>
      )}
      {viewedPO.status === "ISSUE_DRAFT"
        &&
        <Row style={{ marginTop: "20px", display: 'flex', justifyContent: 'space-between' }}>
          <Button
            type="primary"
            onClick={onDownloadPo}
          >
            Download
          </Button>
          <Row>
            <Col>
              <Button
                type="primary"
                onClick={onSendToMail}
                disabled={viewedPO.vendorDetails?.email == null}
              >
                Email to Vendor
              </Button>
              {["ISSUE_DRAFT"].includes(viewedPO.status) && viewedPO.vendorDetails?.email && <Row style={{ marginTop: ".5rem" }}>{viewedPO.vendorDetails?.email}</Row>}
            </Col>
            <Button
              style={{ marginLeft: "20px" }}
              type="primary"
              onClick={onSendToWhatsapp}
            >
              Send to WhatsApp
            </Button>
          </Row>
        </Row>
      }
      {viewedPO.status === "BILLING_COMPLETED" &&
        <Row style={{ marginTop: "20px", display: 'flex', }}>
          {viewedPO.invoiceUrl && <Button
            type="primary"
            onClick={() => {
              window.open(viewedPO.invoiceUrl!, "_blank")?.focus();
            }}
            style={{ marginRight: "1rem" }}
          >
            Download
          </Button>}
          <Upload
            name='file'
            headers={headers}
            maxCount={1}
            showUploadList={false}
            action={apis.uploadInvoiceFile}
            onChange={(value) => {
              if (value?.file?.status == "done") {
                if (value.file.response?.url != undefined) {
                  onUpdatedInvoice(value.file.response?.url);
                }
              }
            }}>
            <Button type="primary" icon={<UploadOutlined />}>Click to Update Invoice</Button>
          </Upload>
        </Row>}
    </>
  );

  return (
    <>
      <Row
        style={{
          margin: "1rem 0",
          position: "sticky",
          top: 0,
          zIndex: 1,
          background: variables.wmBackgroundColorLight1,
          padding: "5px",
        }}
      >
        <Col xs={24}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to="/po/requests">Purchase Orders</NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {viewedPO?.poId || "Purchase Order"}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
        <Col>
          <AppPageHeader title="Purchase Order" />
        </Col>
        {viewedPO?.status === 'GRN_CLOSED' && <Col>
          <Button type="primary" onClick={() => {
            dispatch(downloadGRN({id: viewedPO!.id}));
          }}>Download GRN</Button>
        </Col>}
      </Row>
      <Row>
        <Col xs={24}>{poInfo}</Col>
      </Row>
    </>
  );
};

export default PORequestViewPage;
