import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API, { apis } from "common/apis";
import { IApiError } from "common/interface/IApiError";
import { IPo, IPoApi, IPoItem, IPoUpload } from "common/interface/IPo";
import { IVendor } from "common/interface/IVendor";
import { logoutIfNeeded } from "common/utils";
import {
  getPersistedProcessingBillRate,
  getPersistedProcessingPO,
  persistProcessingBillRate,
  persistProcessingPO,
  removePersistedProcessingBillRate,
  removePersistedProcessingPO,
} from "services/PersistService/PersistPoService";
import { RootState } from "store/store";

export interface State {
  pos?: IPoApi;
  processingPO: IPoUpload;
  processingBillingRate: IPoUpload;
  status: "loading" | "success" | "error" | "idle" | "cancelling" | "saving";
  error?: string;
}

const initialState: State = {
  processingPO: getPersistedProcessingPO(),
  processingBillingRate:getPersistedProcessingBillRate(),
  status: "idle",
};

export const getAllPOs = createAsyncThunk<
  IPoApi,
  { pageNo: number; filter: string, pageSize: number, isGrnRequest: boolean }
>("pos/all", async ({ pageNo = 1, pageSize = 25, filter, isGrnRequest = true, }, thunkApi) => {
  const filterParams = filter
    ? Object.fromEntries(new URLSearchParams(filter))
    : "";
  const response = await API.get(apis.pos, {
    params: {
      pageNo,
      pageSize,
      ...filterParams,
      isGrnRequest: !isGrnRequest ? false : null,
    },
  })
    .then((success) => success.data)
    .catch((error) => {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

export const cancelProcessingPO = createAsyncThunk(
  "po/cancel",
  async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const {
      po: {
        processingPO: { key },
      },
    } = state;

    const response = await API.post(
      apis.uploadSavePO,
      {},
      {
        params: { key, cancel: 1 },
      }
    )
      .then((success) => success.data)
      .catch((error) => {
        const errorMessage =
          error?.response?.data?.message || "Something went wrong!";
        logoutIfNeeded(error, thunkApi);
        return thunkApi.rejectWithValue({ message: errorMessage });
      });

    return response;
  }
);

export const saveProcessingPO = createAsyncThunk<IPoUpload>(
  "po/upload/save",
  async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const {
      po: {
        processingPO: { key },
      },
    } = state;

    const response = await API.post(
      apis.uploadSavePO,
      {},
      {
        params: { key },
      }
    )
      .then((success) => success.data)
      .catch((error) => {
        const errorMessage =
          error?.response?.data?.message || "Something went wrong!";
        logoutIfNeeded(error, thunkApi);
        return thunkApi.rejectWithValue({ message: errorMessage });
      });

    return response;
  }
);


export const cancelProcessingBillingRate = createAsyncThunk(
  "po/billingRate/cancel",
  async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const {
      po: {
        processingBillingRate: { key },
      },
    } = state;

    const response = await API.post(
      apis.uploadSaveBillingRate,
      {},
      {
        params: { key, cancel: 1 },
      }
    )
      .then((success) => success.data)
      .catch((error) => {
        const errorMessage =
          error?.response?.data?.message || "Something went wrong!";
        logoutIfNeeded(error, thunkApi);
        return thunkApi.rejectWithValue({ message: errorMessage });
      });

    return response;
  }
);

export const saveProcessingBillingRate = createAsyncThunk<IPoUpload>(
  "po/billingRate/upload/save",
  async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const {
      po: {
        processingBillingRate: { key },
      },
    } = state;

    const response = await API.post(
      apis.uploadSaveBillingRate,
      {},
      {
        params: { key },
      }
    )
      .then((success) => success.data)
      .catch((error) => {
        const errorMessage =
          error?.response?.data?.message || "Something went wrong!";
        logoutIfNeeded(error, thunkApi);
        return thunkApi.rejectWithValue({ message: errorMessage });
      });

    return response;
  }
);

export const getPoById = createAsyncThunk<IPo, string>(
  "po/get",
  async (poId, thunkApi) => {
    const response = await API.get(`${apis.pos}/${poId}`)
      .then((success) => success.data as IPo)
      .catch((error) => {
        const errorMessage =
          error?.response?.data?.message || "Something went wrong!";
        logoutIfNeeded(error, thunkApi);
        return thunkApi.rejectWithValue({ message: errorMessage });
      });

    return response;
  }
);

export const savePoItem = createAsyncThunk<
  IPo,
  {
    poId: string;
    poItem: IPoItem;
  }
>("po/save", async (payload, thunkApi) => {
  const extPoIds = Array.isArray(payload.poItem.extPoIds)
    ? payload.poItem.extPoIds.join(",")
    : payload.poItem.extPoIds;
  (payload.poItem.extPoIds as unknown as string) = extPoIds;
  const response = await API.put(
    `${apis.pos}/${payload.poId}/items`,
    payload.poItem
  )
    .then((success) => success.data)
    .catch((error) => {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

export const updatePoItemBillingRate = createAsyncThunk<
  any,
  {
    poId: string;
    poItem: IPoItem;
  }
>("po/item/update-billing-rate", async (payload, thunkApi) => {
  const response = await API.patch(
    `${apis.pos}/${payload.poId}/items/${payload.poItem.id}/billingRate?billingRate=${payload.poItem.billingRate}`,
  )
    .then((success) => success.data)
    .catch((error) => {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

export const deletePoItem = createAsyncThunk<
  any,
  {
    poId: string;
    poItemId: Number;
  }
>("po/item/delete", async (payload, thunkApi) => {
  const response = await API.delete(
    `${apis.pos}/${payload.poId}/items/${payload.poItemId}`,
  )
    .then((success) => success.data)
    .catch((error) => {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

export const savePoDraft = createAsyncThunk<IPo, Partial<IPo>>(
  "po/draft",
  async (payload, thunkApi) => {
    const newPoItems =
      payload?.poItems?.map((poItem) => {
        const extIds = Array.isArray(poItem.extPoIds)
          ? poItem.extPoIds.join(",")
          : poItem.extPoIds;
        const newPoItem = { ...poItem, extPoIds: extIds };
        return newPoItem;
      }) ?? [];
    const newPayload = {
      poItems: newPoItems.map((poI) => ({
        dockRtv: poI.dockRtv,
        rtvAllowed: poI.rtvAllowed,
        finalReceivedQty: poI.finalReceivedQty,
        grossReceivedQty: poI.grossReceivedQty,
        // netReceivedQty: poI.netReceivedQty,
        poItemAssets: poI.poItemAssets,
        poItemId: poI.id,
        sortingRtv: poI.sortingRtv,
        rejectedQty: poI.rejectedQty,
      })),
      receivedDate: payload.receivedDate,
      // vendorId: payload.vendorDetails?.id,
    };
    const response = await API.post(
      `${apis.pos}/${payload.id}/draft`,
      newPayload
    )
      .then((success) => success.data)
      .catch((error) => {
        const errorMessage =
          error?.response?.data?.message || "Something went wrong!";
        logoutIfNeeded(error, thunkApi);
        return thunkApi.rejectWithValue({ message: errorMessage });
      });

    return response;
  }
);

export const receivePO = createAsyncThunk<IPo, Partial<IPo>>(
  "po/receive",
  async (payload, thunkApi) => {
    const newPoItems =
      payload?.poItems?.map((poItem) => {
        const extIds = Array.isArray(poItem.extPoIds)
          ? poItem.extPoIds.join(",")
          : poItem.extPoIds;
        const newPoItem = { ...poItem, extPoIds: extIds };
        return newPoItem;
      }) ?? [];
    const newPayload = {
      poItems: newPoItems.map((poI) => ({
        dockRtv: poI.dockRtv,
        rtvAllowed: poI.rtvAllowed,
        finalReceivedQty: poI.finalReceivedQty,
        grossReceivedQty: poI.grossReceivedQty,
        // netReceivedQty: poI.netReceivedQty,
        poItemAssets: poI.poItemAssets,
        sortingRtv: poI.sortingRtv,
        poItemId: poI.id,
      })),
      receivedDate: payload.receivedDate,
    };
    const response = await API.post(
      `${apis.pos}/${payload.id}/receive`,
      newPayload
    )
      .then((success) => success.data)
      .catch((error) => {
        const errorMessage =
          error?.response?.data?.message || "Something went wrong!";
        logoutIfNeeded(error, thunkApi);
        return thunkApi.rejectWithValue({ message: errorMessage });
      });

    return response;
  }
);

export const updateSortingRTV = createAsyncThunk<
  {},
  {
    id: number;
    poItemId: number;
    sortingRtv: number;
  }
>("po/update/sorting-rtv", async (payload, thunkApi) => {
  const response = await API.patch(
    `${apis.pos}/${payload.id}/items/${payload.poItemId}/sorting-rtv`,
    {},
    {
      params: {
        sortingRtv: payload.sortingRtv,
      },
    }
  )
    .then((success) => success.data)
    .catch((error) => {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

export const closePO = createAsyncThunk<
  {},
  {
    id: number;
  }
>("po/close", async (payload, thunkApi) => {
  const response = await API.post(`${apis.pos}/${payload.id}/close`, payload)
    .then((success) => success.data)
    .catch((error) => {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

export const discardPO = createAsyncThunk<
  {},
  {
    id: number;
  }
>("po/discard", async (payload, thunkApi) => {
  const response = await API.patch(`${apis.pos}/${payload.id}/discard`)
    .then((success) => success.data)
    .catch((error) => {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

export const billPO = createAsyncThunk<
  {},
  {
    id: number;
  }
>("po/billing-complete", async (payload, thunkApi) => {
  const response = await API.patch(`${apis.pos}/${payload.id}/billing-completed`)
    .then((success) => success.data)
    .catch((error) => {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });
  return response;
});

export const closePORequest = createAsyncThunk<
  {},
  {
    id: number;
  }
>("po/close-request", async (payload, thunkApi) => {
  const response = await API.patch(`${apis.pos}/${payload.id}/po-closed`)
    .then((success) => success.data)
    .catch((error) => {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

export const updateInvoiceUrl = createAsyncThunk<
  any,
  {
    id: number;
    url: string;

  }
>("po/update-invoice-url", async (payload, thunkApi) => {
  const response = await API.patch(`${apis.pos}/${payload.id}/invoice?url=${payload.url}`)
    .then((success) => success.data)
    .catch((error) => {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

export const changeVendor = createAsyncThunk<
  {},
  {
    id: number;
    vendorId: number;
    vendor: IVendor,
  }
>("po/change-vendor", async (payload, thunkApi) => {
  const response = await API.put(
    `${apis.pos}/${payload.id}`,
    {
      "vendorId": payload.vendorId,
    },
  )
    .then((success) => success.data)
    .catch((error) => {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

export const issuePO = createAsyncThunk<
  {},
  {
    id: number;
  }
>("po/issue", async (payload, thunkApi) => {
  const response = await API.patch(`${apis.pos}/${payload.id}/status-update?status=ISSUED`)
    .then((success) => success.data)
    .catch((error) => {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

export const downloadPO = createAsyncThunk<
  any,
  {
    id: number;
  }
>("po/download", async (payload, thunkApi) => {
  const response = await API.get(`${apis.pos}/${payload.id}/download`)
    .then((success) => success.data)
    .catch((error) => {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

export const downloadGRN = createAsyncThunk<
  any,
  {
    id: string;
  }
>("po/download-grn", async (payload, thunkApi) => {
  const response = await API.get(`${apis.pos}/${payload.id}/download/grn`)
    .then((success) => success.data)
    .catch((error) => {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

export const sendToMail = createAsyncThunk<
  {},
  {
    id: number;
  }
>("po/send-mail", async (payload, thunkApi) => {
  const response = await API.get(`${apis.pos}/${payload.id}/email`)
    .then((success) => success.data)
    .catch((error) => {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

const poSlice = createSlice({
  name: "po",
  initialState,
  reducers: {
    setProcessingPO: (state, action) => {
      state.status = "idle";
      state.error = "";
      state.processingPO = action.payload;
      state.processingPO.timeUploaded = new Date().toString();
      persistProcessingPO(state.processingPO);
    },
    removeProcessingPO: (state) => {
      state.processingPO = {};
      state.status = "idle";
      state.error = "";
      removePersistedProcessingPO();
    },
    setProcessingBillingRate: (state, action) => {
      state.status = "idle";
      state.error = "";
      state.processingBillingRate = action.payload;
      state.processingBillingRate.timeUploaded = new Date().toString();
      persistProcessingBillRate(state.processingBillingRate);
    },
    removeProcessingBillingRate: (state) => {
      state.processingBillingRate = {};
      state.status = "idle";
      state.error = "";
      removePersistedProcessingBillRate();
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllPOs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllPOs.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = "";
        state.pos = action.payload;
      })
      .addCase(getAllPOs.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })

      .addCase(getPoById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getPoById.fulfilled, (state, action) => {
        if (!state.pos) {
          state.pos = { data: [], pageNo: 1, pageSize: 25, pages: 1, total: 25 };
        }

        // if (state.pos) {
        const poData = state.pos?.data;
        const poInStore = poData.findIndex(
          (po) => po.id === action.payload.id
        );

        if (poInStore > -1) {
          const newPOs = [...state.pos?.data];
          newPOs[poInStore] = {
            ...newPOs[poInStore],
            ...action.payload,
          };
          state.pos.data = newPOs;
        } else if (poData) {
          poData.push(action.payload);
        } else {
          state.pos.data = [action.payload];
        }

        // }

        state.status = "idle";
        state.error = "";
      })
      .addCase(getPoById.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })

      .addCase(saveProcessingPO.pending, (state) => {
        state.error = "";
        state.status = "loading";
      })
      .addCase(saveProcessingPO.fulfilled, (state) => {
        state.status = "success";
        state.error = "";
        state.processingPO = {};
        removePersistedProcessingPO();
      })
      .addCase(saveProcessingPO.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })
      /**
       * CANCEL PROCESSING PO
       */
      .addCase(cancelProcessingPO.pending, (state) => {
        state.status = "cancelling";
      })
      .addCase(cancelProcessingPO.fulfilled, (state) => {
        state.status = "idle";
        state.error = "";
        state.processingPO = {};
        removePersistedProcessingPO();
      })
      .addCase(cancelProcessingPO.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })
      
      /**
       * CANCEL PROCESSING BILLING
       */
      .addCase(cancelProcessingBillingRate.pending, (state) => {
        state.status = "cancelling";
      })
      .addCase(cancelProcessingBillingRate.fulfilled, (state) => {
        state.status = "idle";
        state.error = "";
        state.processingBillingRate = {};
        removePersistedProcessingBillRate();
      })
      .addCase(cancelProcessingBillingRate.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })
      
      /**
       * SAVE PROCESSING BILLING
       */
      .addCase(saveProcessingBillingRate.pending, (state) => {
        state.status = "cancelling";
      })
      .addCase(saveProcessingBillingRate.fulfilled, (state) => {
        state.status = "success";
        state.error = "";
        state.processingBillingRate = {};
        removePersistedProcessingBillRate();
      })
      .addCase(saveProcessingBillingRate.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })

      /**
       * SAVE PO ITEM
       */
      .addCase(savePoItem.pending, (state) => {
        state.status = "saving";
      })
      .addCase(savePoItem.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = "";
        const { id: poId, poItems } = action.payload;
        const pos = state.pos;
        const thisPoIdx = pos?.data.findIndex((p) => p.id === poId) ?? -1;
        if (thisPoIdx > -1 && pos?.data) {
          const thisPo = pos?.data[thisPoIdx];
          thisPo.poItems = poItems;
          pos.data[thisPoIdx] = thisPo;
        }
      })
      .addCase(savePoItem.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })

      /**
       * UPDATE PO ITEM BILLING RATE
       */
      .addCase(updatePoItemBillingRate.pending, (state) => {
        state.status = "saving";
      })
      .addCase(updatePoItemBillingRate.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = "";
        const pos = state.pos;
        const thisPoIdx = pos?.data.findIndex((p) => p.id === action.meta.arg.poId) ?? -1;
        if (thisPoIdx > -1 && pos?.data) {
          const thisPo = pos?.data[thisPoIdx];
          thisPo.poItems.splice(thisPo.poItems.findIndex((e) => e.id === action.meta.arg.poItem?.id), 1, action.payload);
          pos.data[thisPoIdx] = thisPo;
        }
      })
      .addCase(updatePoItemBillingRate.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })
      /**
       * DELETE PO ITEM
       */
      .addCase(deletePoItem.pending, (state) => {
        state.status = "saving";
      })
      .addCase(deletePoItem.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = "";
        const pos = state.pos;
        const thisPoIdx = pos?.data.findIndex((p) => p.id === action.meta.arg.poId) ?? -1;
        if (thisPoIdx > -1 && pos?.data) {
          const thisPo = pos?.data[thisPoIdx];
          thisPo.poItems.splice(thisPo.poItems.findIndex((e) => e.id === action.meta.arg.poItemId), 1,);
          pos.data[thisPoIdx] = thisPo;
        }
      })
      .addCase(deletePoItem.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })

      /**
       * SAVE PO DRAFT
       */
      .addCase(savePoDraft.pending, (state) => {
        state.status = "saving";
      })
      .addCase(savePoDraft.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = "";
        const { payload } = action;
        const pos = state.pos;
        const thisPoIdx = pos?.data.findIndex((p) => p.id === payload.id) ?? -1;
        if (thisPoIdx > -1 && pos?.data) {
          pos.data[thisPoIdx] = payload;
        }
      })
      .addCase(savePoDraft.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })

      /**
       * RECEIVE PO
       */
      .addCase(receivePO.pending, (state) => {
        state.status = "saving";
      })
      .addCase(receivePO.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = "";
        const { payload } = action;
        const pos = state.pos;
        const thisPoIdx = pos?.data.findIndex((p) => p.id === payload.id) ?? -1;
        if (thisPoIdx > -1 && pos?.data) {
          pos.data[thisPoIdx] = payload;
        }
      })
      .addCase(receivePO.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })

      /**
       * UPDATE SORTING RTV
       */
      .addCase(updateSortingRTV.pending, (state) => {
        state.status = "saving";
      })
      .addCase(updateSortingRTV.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = "";
      })
      .addCase(updateSortingRTV.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })

      /**
       * PO CLOSE
       */
      .addCase(closePO.pending, (state) => {
        state.status = "saving";
      })
      .addCase(closePO.fulfilled, (state) => {
        state.status = "idle";
        state.error = "";
      })
      .addCase(closePO.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })
      /**
       * PO DISCARD
       */
      .addCase(discardPO.pending, (state) => {
        state.status = "saving";
      })
      .addCase(discardPO.fulfilled, (state) => {
        state.status = "idle";
        state.error = "";
      })
      .addCase(discardPO.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })
      /**
       * PO DISCARD
       */
      .addCase(billPO.pending, (state) => {
        state.status = "saving";
      })
      .addCase(billPO.fulfilled, (state) => {
        state.status = "idle";
        state.error = "";
      })
      .addCase(billPO.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })
      /**
       * PO REQUEST CLOSURE
       */
      .addCase(closePORequest.pending, (state) => {
        state.status = "saving";
      })
      .addCase(closePORequest.fulfilled, (state) => {
        state.status = "idle";
        state.error = "";
      })
      .addCase(closePORequest.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })
      /**
       * UPDATE INVOICE URL
       */
      .addCase(updateInvoiceUrl.pending, (state) => {
        state.status = "saving";
      })
      .addCase(updateInvoiceUrl.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = "";
        const pos = state.pos;
        const thisPoIdx = pos?.data.findIndex((p) => p.id == action.meta.arg.id.toString()) ?? -1;
        if (thisPoIdx > -1 && pos?.data) {
          const thisPo = pos?.data[thisPoIdx];
          thisPo.invoiceUrl = action.meta.arg.url;
          pos.data[thisPoIdx] = thisPo;
        }
      })
      .addCase(updateInvoiceUrl.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })
      /**
       * CHANGE VENDOR
       */
      .addCase(changeVendor.pending, (state) => {
        state.status = "saving";
      })
      .addCase(changeVendor.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = "";
        const pos = state.pos;
        const thisPoIdx = pos?.data.findIndex((p) => p.id == action.meta.arg.id.toString()) ?? -1;
        if (thisPoIdx > -1 && pos?.data) {
          const thisPo = pos?.data[thisPoIdx];
          thisPo.vendorId = action.meta.arg.vendorId.toString();
          thisPo.vendorDetails = action.meta.arg.vendor;
          pos.data[thisPoIdx] = thisPo;
        }
      })
      .addCase(changeVendor.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })
      /**
       * PO ISSUE
       */
      .addCase(issuePO.pending, (state) => {
        state.status = "saving";
      })
      .addCase(issuePO.fulfilled, (state) => {
        state.status = "idle";
        state.error = "";
      })
      .addCase(issuePO.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })
      /**
       * PO DOWNLOAD
       */
      .addCase(downloadPO.pending, (state) => {
        state.status = "loading";
      })
      .addCase(downloadPO.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = "";
        window.open(action.payload)?.focus();
      })
      .addCase(downloadPO.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })
      
      /**
       * GRN DOWNLOAD
       */
      .addCase(downloadGRN.pending, (state) => {
        state.status = "loading";
      })
      .addCase(downloadGRN.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = "";
        window.open(action.payload)?.focus();
      })
      .addCase(downloadGRN.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })
      /**
       * PO MAIL
       */
      .addCase(sendToMail.pending, (state) => {
        state.status = "loading";
      })
      .addCase(sendToMail.fulfilled, (state) => {
        state.status = "idle";
        state.error = "";
      })
      .addCase(sendToMail.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      });
  },
});

export const { setProcessingPO, removeProcessingPO, setProcessingBillingRate, removeProcessingBillingRate } = poSlice.actions;
export default poSlice.reducer;
