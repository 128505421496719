import {
  Row,
  Col,
  Button,
  message,
  Form,
  Input,
  MenuProps,
  Select, Checkbox,
} from "antd";
import { IAdminUsers } from "common/interface/IAdminUsers";
import { AppPageHeader } from "components/AppPageHeader";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useDocumentTitle } from "hooks/usePageTitle";
import { useEffect, useState, } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getAdminGroups, getAdminUserDetail, getDarkStores, updateUser } from "store/admin-slice/adminServicesSlice";
import { fetchWarehouses } from "store/stock-exchange/stockExchangeSlice";

const MESSAGE_KEY = "UPDATE_ADMIN_USER";
export const UpdateAdminUserPage = () => {
  const [currentAdminUser, setCurrentAdminUser] = useState<IAdminUsers>();
  useDocumentTitle("Update User");
  const { status, error, adminUserGroups, darkStores } = useAppSelector(
    (state) => state.adminServices
  );
  const { warehouses } = useAppSelector(
    (state) => state.stockExchangeServices
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const adminGroupsCheckboxOptions = adminUserGroups.filter((items => items.name != 'administrator' && items.name != 'internal')).map(items => {
    return ({
      value: items.id,
      label: items.name,
    });
  });
  const { id } = useParams();
  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = async () => {
    const temp = await dispatch(getAdminUserDetail(id!));
    setCurrentAdminUser(temp.payload);
  }

  useEffect(() => {
    dispatch(fetchWarehouses());
  }, []);

  useEffect(() => {
    dispatch(getAdminGroups());
    dispatch(getDarkStores());
    return () => {
      message.destroy();
    };
  }, []);

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
          Male
        </a>
      ),
    },
    {
      key: '2',
      label: (
        <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
          Female
        </a>
      ),
    },];

  useEffect(() => {
    if (status === "loading") {
      message.loading({ content: "Loading User Group", key: MESSAGE_KEY });
    } else if (status === "success") {
      message.success({
        content: "Admin User Updated!",
        key: MESSAGE_KEY,
      });
      form.resetFields();
      navigate(-1);
    } else if (status === "cancelling") {
      message.loading({
        content: "Cancelling User Update",
        key: MESSAGE_KEY, duration: 3.5
      });
    } else if (status === "error") {
      message.error({ content: error, key: MESSAGE_KEY, duration: 3.5 });
    }
    else if (status === "saving") {
      message.loading({ content: "Saving User", key: MESSAGE_KEY });
    }
    return () => {
      message.destroy();
    };
  }, [status, error]);

  const [form] = Form.useForm();
  const onSave = async () => {
    try {
      await form.validateFields();
      if (Array.from(currentAdminUser!['groups'].map((e: any) => e.name)).includes("administrator")) {
        const values = form.getFieldsValue();
        if (!Array.from(values["groups"]).includes(1)) {
          values["groups"] = [...values["groups"], 1];
        }
        await dispatch(updateUser({ ...values, "id": id, }));
      }
      else {
        await dispatch(updateUser({ ...form.getFieldsValue(), "id": id, }));
      }

    }
    catch (e) {
    }
  };

  const onCancelSave = () => {
    navigate(-1);
  };

  return (
    <>
      <Row>
        <Col>
          <AppPageHeader title="Update Admin User" />
        </Col>
      </Row>

      {currentAdminUser === undefined ? <>Fetching User Details</> : <>
        <Form
          initialValues={{
            "firstname": currentAdminUser["firstname"],
            "lastname": currentAdminUser['lastname'],
            "email": currentAdminUser['email'],
            "phone": currentAdminUser['phone'].replace("+91", ""),
            "gender": currentAdminUser['gender'],
            "blocked": currentAdminUser['blocked'],
            "groups": Array.from(currentAdminUser['groups'].map((e: any) => e.id)),
            "mappedWh": ((currentAdminUser['mappedWh'] ?? []).map((e: any) => e.id)),
            "mappedStores": currentAdminUser['mappedStores'] ?? [],
          }}
          form={form}
          name="vendor-filter-form"
          layout="vertical"
          style={{ width: "50%" }}
        >
          <Form.Item name="firstname" label="First Name" rules={[
            {
              required: true,
            },
          ]}>
            <Input placeholder="First Name" size="small" allowClear required />
          </Form.Item>
          <Form.Item name="lastname" label="Last Name" rules={[
            {
              required: true,
            },
          ]}>
            <Input placeholder="Last Name" size="small" allowClear required />
          </Form.Item>
          <Form.Item name="email" label="Email" rules={[
            {

            },
          ]}>
            <Input placeholder="Email" size="small" allowClear required />
          </Form.Item>
          <Form.Item name="phone" label="Phone" rules={[
            {
              pattern: new RegExp("^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$"),
              message: "Invalid phone number",
              min: 10,
              max: 10,
              required: true,
            },
          ]} >
            <Input
              // addonBefore={"+91"}
              placeholder="Phone" size="middle" maxLength={10} allowClear />
          </Form.Item>
          <Form.Item
            label="Gender"
            name="gender"
            required
          >
            <Select
              defaultValue="MALE"
              options={[
                {
                  value: 'MALE',
                  label: 'Male',
                }, {
                  value: 'FEMALE',
                  label: 'Female',
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Is Blocked"
            name="blocked"
            required
          >
            <Select
              options={[
                {
                  value: 0,
                  label: 'FALSE',
                }, {
                  value: 1,
                  label: 'TRUE',
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Mapped Warehouses"
            name="mappedWh"
          >
            <Select
              mode="multiple"
              options={warehouses.map(wareHouse => ({
                value: wareHouse.id,
                label: `${wareHouse.name} # ${wareHouse.id}`,
              }))}
            />
          </Form.Item>
          <Form.Item
            label="Mapped Stores"
            name="mappedStores"
          >
            <Select
              mode="multiple"
              options={darkStores.map(store => ({
                value: store.id,
                label: `${store.name} # ${store.id}`,
              }))}
            />
          </Form.Item>
          <Form.Item name="groups" label="Groups" style={{ "marginBottom": "0" }}>
            <Checkbox.Group options={adminGroupsCheckboxOptions} />
          </Form.Item>
        </Form>
        <Row style={{ "marginBottom": "1.5rem" }}>
          {Array.from(currentAdminUser['groups'].map((e: any) => e.name)).includes("administrator") && <Checkbox disabled={true} checked={true}>administrator</Checkbox>}
        </Row>
        <Row>
          <Col>
            <Button
              type="primary"
              onClick={onSave}
              loading={status === "loading"}
              disabled={status === "loading"}
            >
              Update User
            </Button>
          </Col>
          <Col style={{ justifyContent: "left", marginLeft: "20px" }}>
            <Button
              onClick={onCancelSave}
              disabled={status === "loading"}
            >
              Cancel
            </Button>
          </Col>
        </Row></>}
    </>
  );
};