import { Row, Col, message, Table, Pagination, Button, Form, Input, Select, Typography, Popconfirm, InputNumber } from "antd";
import { ColumnsType } from "antd/lib/table";
import { AppPageHeader } from "components/AppPageHeader";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useDocumentTitle } from "hooks/usePageTitle";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useSearchParams } from "react-router-dom";
import { AppActionStrip } from "components/AppActionStrip/AppActionStrip";
import { AppFilterButton, AppFilter, } from "components/AppFilter";
import { useForm } from "antd/lib/form/Form";
import { ICashCollection } from "common/interface/ICashCollection";
import { cancelCashCollection, getCashCollections, receiveCashCollection } from "store/cash-collection/cashCollectionSlice";
import { AppCashCollectionFilter } from "components/AppFilter/AppCashCollectionFilter";
import { variables } from "common/variables";

type PartialCashCollection = Partial<ICashCollection> & { key: string };
const MESSAGE_KEY = "ALL_STORE_EXCHANGE";

const headerKeys: { [key: string]: string } = {
    // id: "Id",
    storeId: "Store ID",
    date: "Date",
    slot: "Slot",
    status: "Status",
    collectedBy: "Collected By",
    collectedAmount: "Collected Amount",
    receivedAmount: "Received Amount",
    actions: "Actions",
};

const StyledButton = styled(Button)`
  &.filterButton {
    &--active {
      border-color: ${variables.primaryColor} !important;
    }
  }
`;

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    title: any;
    inputType: "select" | "text";
    record: PartialCashCollection;
    index: number;
    children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}) => {
    if (editing != undefined && dataIndex === "receivedCash") {
        return <td><Form.Item name="receivedCash">
            <Input
                placeholder={record.receivedAmount?.toString() ?? "Received Amount"}
                size="small"
                allowClear
            // disabled={editing === record.key}
            />
        </Form.Item></td>;
    }

    return <td>{children}</td>;
};

const StyledPagination = styled(Pagination)`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

const AllCashCollectionsPage = () => {
    useDocumentTitle("All Cash Collection");
    const dispatch = useAppDispatch();
    const [columns, setColumns] = useState<ColumnsType<PartialCashCollection>>();
    const [rows, setRows] = useState<PartialCashCollection[]>();
    const { cashCollections, status, error } = useAppSelector((state) => state.cashCollection);
    const [filterState, setFilterState] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const [filterCount, setFilterCount] = useState(0);
    const [form] = useForm();
    const [editingKey, setEditingKey] = useState("");

    const cancel = (key: string) => {
        setEditingKey('');
        form.setFieldsValue({
            [key]: "",
        });
    };

    const getCashCollectionsData = useCallback(
        (pageNum = 1, pageSize = 25, filter = "") => {
            dispatch(getCashCollections({ pageNo: pageNum, pageSize, filter }));
        },
        [dispatch]
    );

    useEffect(() => {
        const filter = searchParams.toString();
        setFilterCount(
            Object.keys(Object.fromEntries(new URLSearchParams(filter))).length
        );
        getCashCollectionsData(1, 25, filter);
    }, [dispatch, getCashCollectionsData, searchParams]);

    useEffect(() => {
        if (status === "loading") {
            message.loading({ content: "Loading Cash Collection", key: MESSAGE_KEY });
        }
        else if (status === "error") {
            message.error({ content: error, key: MESSAGE_KEY });
        }
        else if (status === "saving") {
            message.loading({ content: "Updating Cash Collection", key: MESSAGE_KEY })
        }

        return () => {
            message.destroy();
        };
    }, [status, error]);


    const receiveCash = async (key: string, record: PartialCashCollection) => {
        try {
            const valid = await form.validateFields([key]);
            if (valid) {
                const value = form.getFieldValue(key);
                await dispatch(receiveCashCollection({ id: record.id?.toString(), receivedAmount: value }));
                cancel(key);
            }

        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const cancelCash = async (key: string, record: PartialCashCollection) => {
        try {
            const value = form.getFieldValue(key);
            await dispatch(cancelCashCollection({ id: record.id?.toString() }));
            cancel(key);

        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    useEffect(() => {
        const temp = cashCollections?.data ? cashCollections.data : [];
        const data: PartialCashCollection[] = temp.map((au, i) => ({ ...au, key: `cashCollection-${i}`, ellipses: true }));

        const tableHeaders: ColumnsType<PartialCashCollection> = [
            ...Object.keys(headerKeys).map((key, idx) => ({
                key,
                title: headerKeys[key],
                dataIndex: key,
                render: (text: string, record: PartialCashCollection) => {

                    if (key === "collectedBy") {
                        return <Col>
                        <Row>{record.metadata?.collectedBy?.name}</Row>
                        <Row>{record.metadata?.collectedBy?.phone}</Row>
                        </Col>
                    }

                    if (key === "receivedAmount") {
                        if (record.status === "COLLECTED") {
                            return <Form.Item
                                name={record.key}
                                label={record.receivedAmount}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input Received Amount Cash!",
                                    },
                                    {
                                        min: 1,
                                        message: "Please provide value above 0",
                                    },
                                ]}
                            >
                                <Input
                                    placeholder={"Received Amount"}
                                    size="small"
                                />
                            </Form.Item>;
                        }
                    }
                    if (key === "actions") {
                        if (record.status === "COLLECTED") {
                            return (
                                <span>
                                    <Popconfirm
                                        title="Are you Sure to receive this collection?"
                                        disabled={status === "loading"}
                                        onConfirm={() => receiveCash(record.key, record)}>
                                        <a>Receive</a>
                                    </Popconfirm>
                                    <Popconfirm title="Are you sure to cancel this collection?"
                                        disabled={status === "loading"}
                                        onConfirm={() => cancelCash(record.key, record)}>
                                        <a style={{ marginLeft: "1rem" }}>Cancel</a>
                                    </Popconfirm>
                                </span>
                            );
                        }
                    }
                    return <>{text}</>;
                },
            })),
        ];

        setColumns(tableHeaders);
        setRows(data);
    }, [cashCollections, editingKey]);

    const Paginator = () => {
        if (cashCollections) {
            const { pageNo, pageSize, total } = cashCollections;
            return (
                <StyledPagination
                    current={pageNo}
                    pageSize={pageSize}
                    total={total}
                    onChange={(page, size) => {
                        const filter = searchParams.toString();
                        setFilterCount(
                            Object.keys(Object.fromEntries(new URLSearchParams(filter))).length
                        );
                        getCashCollectionsData(page, size, filter);
                    }}
                />
            );
        }

        return <></>;
    };

    const togglePendingCollections = () => {
        const params = Object.fromEntries(new URLSearchParams(searchParams));
        if (params["status"] === "COLLECTED") {
            params["status"] = "";
        } else {
            params["status"] = "COLLECTED";
        }
        setSearchParams(params);
    }

    const isFilteringPending = () => {
        const params = Object.fromEntries(new URLSearchParams(searchParams));
        return params["status"] === "COLLECTED";
    }



    return (
        <>
            <Row>
                <Col>
                    <AppPageHeader title="All Cash Collections" />
                </Col>
            </Row>
            <Row>
                <AppActionStrip>
                    <div className="action-strip__item">
                        <AppFilterButton
                            active={filterState}
                            onClick={() => setFilterState(!filterState)}
                            filterCount={filterCount}
                        />
                    </div>
                </AppActionStrip>
            </Row>
            <Row style={{ marginBottom: "1rem" }}>
                <AppFilter expanded={filterState}>
                    <AppCashCollectionFilter />
                </AppFilter>
            </Row>
            <Form form={form}>
                {rows && rows.length ? (
                    <Row>
                        <Col xs={24}>
                            <Table
                                columns={columns}
                                components={{
                                    body: {
                                        cell: EditableCell,
                                    }
                                }}
                                dataSource={rows.filter(e => e.id !== -1)}
                                scroll={{ x: "100vh", y: "65vh" }}
                                size="small"
                                pagination={false}
                                loading={status === "loading"}
                                bordered
                            />
                        </Col>
                        <Col xs={24}>
                            <Paginator />
                        </Col>
                    </Row>
                ) : (
                    status !== "loading" && <>No Cash Collections to show.</>
                )}
            </Form>
        </>
    );
};

export default AllCashCollectionsPage; 