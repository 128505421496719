import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API, { apis } from "common/apis";
import { IApiError } from "common/interface/IApiError";
import { logoutIfNeeded } from "common/utils";
import { IPickerMappingUpload } from "common/interface/IPickerUploadScheme";
import { IRequisitionUserMappingUpload } from "common/interface";
import { RootState } from "store/store";
import { getPersistProcessingConsumerSocietyUpload, getPersistProcessingConsumerUpload, persistProcessingConsumerSocietyUpload, persistProcessingConsumerUpload, removePersistProcessingConsumerSocietyUpload, removePersistProcessingConsumerUpload } from "services/PersistService/PersisitConsumerServices";

export interface State {
    status: "loading" | "success" | "error" | "idle" | "cancelling" | "saving";
    error?: string;
    processingConsumerClusterUpload: IPickerMappingUpload;
    processingConsumerSocietyClusterUpload: IPickerMappingUpload;
}

const initialState: State = {
    processingConsumerClusterUpload: getPersistProcessingConsumerUpload(),
    processingConsumerSocietyClusterUpload: getPersistProcessingConsumerSocietyUpload(),
    status: "idle",
    error: "",
};

export const saveConsumerClusterUpload = createAsyncThunk<IRequisitionUserMappingUpload>("consumer/upload/save", async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const { consumerServices: { processingConsumerClusterUpload: { key } } } = state;

    const response = await API.post(apis.saveConsumerClusterSheet, {}, {
        params: { key }
    })
        .then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
});

export const cancelConsumerClusterUpload = createAsyncThunk("consumer/upload/cancel", async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const { consumerServices: { processingConsumerClusterUpload: { key } } } = state;

    const response = await API.post(apis.saveConsumerClusterSheet, {}, { //FixMe: Get Cancel API
        params: { key, cancel: 1 }
    })
        .then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
});

export const saveConsumerSocietyClusterUpload = createAsyncThunk<IRequisitionUserMappingUpload>("consumer/cluster/upload/save", async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const { consumerServices: { processingConsumerSocietyClusterUpload: { key } } } = state;

    const response = await API.post(apis.saveConsumerClusterSocietySheet, {}, {
        params: { key }
    })
        .then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
});

export const cancelConsumerSocietyClusterUpload = createAsyncThunk("consumer/cluster/upload/cancel", async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const { consumerServices: { processingConsumerSocietyClusterUpload: { key } } } = state;

    const response = await API.post(apis.saveConsumerClusterSocietySheet, {}, {
        params: { key, cancel: 1 }
    })
        .then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
});

const consumerServicesSlice = createSlice({
    name: "consumer-services",
    initialState,
    reducers: {
        setProcessingConsumerUpload: (state, action) => {
            state.status = "idle";
            state.error = "";
            state.processingConsumerClusterUpload = action.payload;
            state.processingConsumerClusterUpload.timeUploaded = new Date().toString();
            persistProcessingConsumerUpload(state.processingConsumerClusterUpload);
        },
        removeProcessingConsumerUpload: (state) => {
            state.processingConsumerClusterUpload = {};
            state.status = "idle";
            state.error = "";
            removePersistProcessingConsumerUpload();
        },
        
        setProcessingConsumerSocietyUpload: (state, action) => {
            state.status = "idle";
            state.error = "";
            state.processingConsumerSocietyClusterUpload = action.payload;
            state.processingConsumerClusterUpload.timeUploaded = new Date().toString();
            persistProcessingConsumerSocietyUpload(state.processingConsumerSocietyClusterUpload);
        },
        removeProcessingConsumerSocietyUpload: (state) => {
            state.processingConsumerSocietyClusterUpload = {};
            state.status = "idle";
            state.error = "";
            removePersistProcessingConsumerSocietyUpload();
        },
    },
    extraReducers(builder) {
        builder
            /**
            * SAVE UPLOAD
            **/
            .addCase(saveConsumerClusterUpload.pending, state => {
                state.status = "loading";
            })
            .addCase(saveConsumerClusterUpload.fulfilled, state => {
                state.status = "success";
                state.error = "";
                state.processingConsumerClusterUpload = {};
                removeProcessingConsumerUpload();
            })
            .addCase(saveConsumerClusterUpload.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";

                if (error.message) {
                    state.error = error.message;
                }
            })

            /**
             * CANCEL UPLOAD
            **/
            .addCase(cancelConsumerClusterUpload.pending, state => {
                state.status = "cancelling";
            })
            .addCase(cancelConsumerClusterUpload.fulfilled, state => {
                state.status = "idle";
                state.error = "";
                state.processingConsumerClusterUpload = {};
                removeProcessingConsumerUpload();
            })
            .addCase(cancelConsumerClusterUpload.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";
                if (error.message) {
                    state.error = error.message;
                }
            })
            
            /**
            * SAVE UPLOAD
            **/
            .addCase(saveConsumerSocietyClusterUpload.pending, state => {
                state.status = "loading";
            })
            .addCase(saveConsumerSocietyClusterUpload.fulfilled, state => {
                state.status = "success";
                state.error = "";
                state.processingConsumerSocietyClusterUpload = {};
                removeProcessingConsumerSocietyUpload();
            })
            .addCase(saveConsumerSocietyClusterUpload.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";

                if (error.message) {
                    state.error = error.message;
                }
            })

            /**
             * CANCEL UPLOAD
            **/
            .addCase(cancelConsumerSocietyClusterUpload.pending, state => {
                state.status = "cancelling";
            })
            .addCase(cancelConsumerSocietyClusterUpload.fulfilled, state => {
                state.status = "idle";
                state.error = "";
                state.processingConsumerSocietyClusterUpload = {};
                removeProcessingConsumerSocietyUpload();
            })
            .addCase(cancelConsumerSocietyClusterUpload.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";
                if (error.message) {
                    state.error = error.message;
                }
            })
    }
});

export const {
    setProcessingConsumerUpload,
    removeProcessingConsumerUpload,
    setProcessingConsumerSocietyUpload,
    removeProcessingConsumerSocietyUpload,
} = consumerServicesSlice.actions;
export default consumerServicesSlice.reducer; 

