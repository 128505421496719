import { IPickerMappingUpload as IPickerMappingUpload } from "common/interface/IPickerUploadScheme";

const STORAGE_KEY_PICKER_MAPPING = "processingRequisitionPickerMapping";

export const persistProcessingPickerMapping = (data: IPickerMappingUpload) => {
  localStorage.setItem(STORAGE_KEY_PICKER_MAPPING, JSON.stringify(data));
}

export const getPersistedProcessingPickerMapping = (): IPickerMappingUpload => {
  const processingUserMapping = localStorage.getItem(STORAGE_KEY_PICKER_MAPPING);
  return processingUserMapping ? JSON.parse(processingUserMapping) : {};
}

export const removePersistedProcessingPickerMapping = () => {
  localStorage.removeItem(STORAGE_KEY_PICKER_MAPPING);
}