import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API, { apis } from "common/apis";
import { IApiError } from "common/interface/IApiError";
import { IAsset, IRequisition, IRequisitionUpload, IRequisitionUserMappingUpload, ISku } from "common/interface";
import {
  getPersistedProcessingRequisition,
  getPersistedProcessingRequisitionUserMapping,
  getPersistedStoreSecPicking,
  persistProcessingRequisition,
  persistProcessingRequisitionUserMapping,
  persistStoreSecPicking,
  removePersistedProcessingRequisition,
  removePersistedProcessingRequisitionUserMapping,
  removePersistedStoreSecPicking
} from "services/PersistService/PersistRequisitionService";
import { RootState } from "store/store";
import { logoutIfNeeded, openInNewTab } from "common/utils";
import { StoreInfo } from "store/store-return/storeReturnUploadSlice";

export interface State {
  requisitions: IRequisition[];
  dispatchedRequisitions: IRequisition[];
  processingRequisition: IRequisitionUpload;
  processingRequistionUserMapping: IRequisitionUserMappingUpload;
  processingStoreSecPicking: IRequisitionUserMappingUpload;
  status: "loading" | "success" | "error" | "idle" | "cancelling" | "saving" | "updating";
  error?: string;
  slots?: { [label: string]: string };
  masterSkus?: ISku[];
  assets?: IAsset[];
  storesList?: StoreInfo[];
}

interface ChallanResponse {
  challanUrl?: string;
  id: string;
}

const initialState: State = {
  requisitions: [],
  dispatchedRequisitions: [],
  processingRequisition: getPersistedProcessingRequisition(),
  processingRequistionUserMapping: getPersistedProcessingRequisitionUserMapping(),
  processingStoreSecPicking: getPersistedStoreSecPicking(),
  status: "idle",
  masterSkus: [],
  assets: [],
  storesList: [],
};

export const saveRequisition = createAsyncThunk("requisition/save", async (_, thunkApi) => {
  const state = thunkApi.getState() as RootState;
  const { requisition: { processingRequisition: { key } } } = state;

  const response = await API.post(apis.saveRequisition, {}, {
    params: { key }
  })
    .then(success => success.data)
    .catch(error => {
      const errorMessage = error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
})

export const cancelProcessingRequisition = createAsyncThunk("requistion/cancel", async (_, thunkApi) => {
  const state = thunkApi.getState() as RootState;
  const { requisition: { processingRequisition: { key } } } = state;

  const response = await API.post(apis.saveRequisition, {}, {
    params: { key, cancel: 1 }
  })
    .then(success => success.data)
    .catch(error => {
      const errorMessage = error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
})

export const getRequisitions = createAsyncThunk("requisitions/get", async (_, thunkApi) => {
  const response = await API.get(apis.getRequisitions)
    .then(success => success.data as IRequisition[])
    .catch(error => {
      const errorMessage = error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

export const generateDownloadUrl = createAsyncThunk("requisitions/download", async (_, thunkApi) => {
  const response = await API.get(apis.downloadSecondaryPicking)
    .then(success => success.data as any)
    .catch(error => {
      const errorMessage = error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

export const getDispatchedRequisitions = createAsyncThunk<IRequisition[], { pageNo: number, pageSize: number, filter: string, }>("dispathed-requisitions/get", async ({ pageNo = 1, pageSize = 100, filter = "" }, thunkApi) => {
  const temp = Object.fromEntries(new URLSearchParams(filter));

  const filterParams = filter
    ? { ...temp }
    : "";
  const response = await API.get(apis.getDispatchedRequisitions, { params: { pageNo, pageSize, ...filterParams } })
    .then(success => success.data as IRequisition[])
    .catch(error => {
      const errorMessage = error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

export const getRequisitionById = createAsyncThunk<IRequisition, string>("requisition/get", async (reqId, thunkApi) => {
  const response: any = await API.get(`${apis.getRequisitionsById}/${reqId}`)
    .then(success => success.data as IRequisition)
    .catch(error => {
      const errorMessage = error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });
  return response;
});

export const updateChallanUrl = createAsyncThunk<ChallanResponse, string>("requisition/create-challan", async (reqId, thunkApi) => {
  const response: any = await API.post(`${apis.getRequisitionsById}/${reqId}/challan`)
    .then(success => ({ ...success.data as Partial<ChallanResponse>, id: reqId }) as ChallanResponse)
    .catch(error => {
      const errorMessage = error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

export const saveRequisitionUserMapping = createAsyncThunk<IRequisitionUserMappingUpload>("requisition/user-mapping/save", async (_, thunkApi) => {
  const state = thunkApi.getState() as RootState;
  const { requisition: { processingRequistionUserMapping: { key } } } = state;

  const response = await API.post(apis.saveRequisitionUserMapping, {}, {
    params: { key }
  })
    .then(success => success.data)
    .catch(error => {
      const errorMessage = error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

export const cancelProcessingRequisitionUserMapping = createAsyncThunk("requistion/user-mapping/cancel", async (_, thunkApi) => {
  const state = thunkApi.getState() as RootState;
  const { requisition: { processingRequistionUserMapping: { key } } } = state;

  const response = await API.post(apis.saveRequisitionUserMapping, {}, {
    params: { key, cancel: 1 }
  })
    .then(success => success.data)
    .catch(error => {
      const errorMessage = error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

export const saveStoreSecPicking = createAsyncThunk<IRequisitionUserMappingUpload>("requisition/store-sec-picking/save", async (_, thunkApi) => {
  const state = thunkApi.getState() as RootState;
  const { requisition: { processingStoreSecPicking: { key } } } = state;

  const response = await API.post(apis.saveStoreSecPicking, {}, {
    params: { key }
  })
    .then(success => success.data)
    .catch(error => {
      const errorMessage = error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

export const cancelStoreSecPicking = createAsyncThunk("requistion/store-sec-picking/cancel", async (_, thunkApi) => {
  const state = thunkApi.getState() as RootState;
  const { requisition: { processingStoreSecPicking: { key } } } = state;

  const response = await API.post(apis.saveStoreSecPicking, {}, {
    params: { key, cancel: 1 }
  })
    .then(success => success.data)
    .catch(error => {
      const errorMessage = error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

export const markRequisitionDispatched = createAsyncThunk<any, string>("requisition/dispatch", async (reqId, thunkApi) => {
  const state = thunkApi.getState() as RootState;
  const { requisition: { requisitions } } = state;
  const thisReqIndex = requisitions.findIndex(req => req?.id?.toString() === reqId);

  if (thisReqIndex > -1) {
    const response = await API.post(apis.dispatchRequisition, {
      storeRequisitionId: reqId
    }).then(success => success.data)
      .catch(error => {
        const errorMessage = error?.response?.data?.message || "Something went wrong!";
        logoutIfNeeded(error, thunkApi);
        return thunkApi.rejectWithValue({ message: errorMessage });
      });

    return response;
  }
});

export const fetchSlots = createAsyncThunk<Record<string, string> & IApiError>("app/slots", async (_, thunkApi) => {
  const response = await API.get(apis.slots)
    .then(success => success.data)
    .catch(error => {
      const errorMessage = error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

export const fetchMasterSkus = createAsyncThunk<ISku[]>("app/skus", async (_, thunkApi) => {
  const response = await API.get(apis.masterSkus)
    .then(success => success.data)
    .catch(error => {
      const errorMessage = error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

export const fetchAssets = createAsyncThunk<IAsset[]>("app/assets", async (_, thunkApi) => {
  const response = await API.get(apis.assets)
    .then(success => success.data)
    .catch(error => {
      const errorMessage = error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});


export const fetchStoresList = createAsyncThunk<StoreInfo[], string>(
  "stores/get-all",
  async (id, thunkApi) => {

    const response = await API.get(`${apis.getStoresInfo}`)
      .then((success) => success.data as StoreInfo[])
      .catch((error) => {
        const errorMessage =
          error?.response?.data?.message || "Something went wrong!";
        logoutIfNeeded(error, thunkApi);
        return thunkApi.rejectWithValue({ message: errorMessage });
      });

    return response;
  }
);

export const toggleBulkStatus = createAsyncThunk<IRequisition, { reqId: number, newStatus: string }>("requisition/toggle-bulk", async ({ reqId, newStatus }, thunkApi) => {
  const response: any = await API.patch(`${apis.toggleBulkStatus}/${reqId}/${newStatus}`,
  )
    .then(success => success.data as IRequisition)
    .catch(error => {
      const errorMessage = error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });
  return response;
});

export const fetchDataForLoggedInUser = createAsyncThunk("app/fetchData", async (_, thunkApi) => {
  thunkApi.dispatch(fetchSlots());
  thunkApi.dispatch(fetchMasterSkus());
  thunkApi.dispatch(fetchAssets());
});

//Get Suggested Inv Picking
export const getSuggestedPicking = createAsyncThunk("requisitions/suggested-picking", async (_, thunkApi) => {
  const response = await API.get(apis.getSuggestedPicking)
    .then(success => success.data)
    .catch(error => {
      const errorMessage = error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });
  return response;
});


const requisitionSlice = createSlice({
  name: "requisition",
  initialState,
  reducers: {
    setProcessingRequisition: (state, action) => {
      state.status = "idle";
      state.error = "";
      state.processingRequisition = action.payload;
      state.processingRequisition.timeUploaded = new Date().toString();
      persistProcessingRequisition(state.processingRequisition);
    },
    removeProcessingRequisition: (state) => {
      state.processingRequisition = {};
      state.status = "idle";
      state.error = "";
      removePersistedProcessingRequisition();
    },
    setProcessingRequisitionUserMapping: (state, action) => {
      state.status = "idle";
      state.error = "";
      state.processingRequistionUserMapping = action.payload;
      state.processingRequistionUserMapping.timeUploaded = new Date().toString();
      persistProcessingRequisitionUserMapping(state.processingRequistionUserMapping);
    },
    removeProcessingRequisitionUserMapping: (state) => {
      state.processingRequistionUserMapping = {};
      state.status = "idle";
      state.error = "";
      removePersistedProcessingRequisitionUserMapping();
    },
    setStoreSecPicking: (state, action) => {
      state.status = "idle";
      state.error = "";
      state.processingStoreSecPicking = action.payload;
      state.processingStoreSecPicking.timeUploaded = new Date().toString();
      persistStoreSecPicking(state.processingStoreSecPicking);
    },
    removeStoreSecPicking: (state) => {
      state.processingStoreSecPicking = {};
      state.status = "idle";
      state.error = "";
      removePersistedStoreSecPicking();
    },
  },
  extraReducers(builder) {
    builder
      .addCase(saveRequisition.pending, (state) => {
        state.error = "";
        state.status = "loading";
      })
      .addCase(saveRequisition.fulfilled, (state) => {
        state.status = "success";
        state.error = "";
        state.processingRequisition = {};
        removePersistedProcessingRequisition();
      })
      .addCase(saveRequisition.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })

      /** 
       * GET ALL REQUISITIONS
      **/
      .addCase(getRequisitions.pending, state => {
        state.status = "loading";
      })
      .addCase(getRequisitions.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = "";
        state.requisitions = action.payload;
      })
      .addCase(getRequisitions.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })
      /** 
       * Download Sec Pcicking
      **/
      .addCase(generateDownloadUrl.pending, state => {
        state.status = "loading";
      })
      .addCase(generateDownloadUrl.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = "";
        window.open(action.payload["csvUrl"], "_blank", "noreferrer");
      })
      .addCase(generateDownloadUrl.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";
        if (error.message) {
          state.error = error.message;
        }
      })

      /** 
       * GET ALL DISPATHED REQUISITIONS
      **/
      .addCase(getDispatchedRequisitions.pending, state => {
        state.status = "loading";
      })
      .addCase(getDispatchedRequisitions.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = "";
        state.dispatchedRequisitions = action.payload;
      })
      .addCase(getDispatchedRequisitions.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";
        if (error.message) {
          state.error = error.message;
        }
      })

      /**
       * GET REQUISITIONS BY ID
       */
      .addCase(getRequisitionById.pending, state => {
        state.status = "loading";
      })
      .addCase(getRequisitionById.fulfilled, (state, action) => {
        const reqInStore = state.requisitions.findIndex(req => req.id === action.payload.id);

        if (reqInStore > -1) {
          const newRequisitions = [...state.requisitions];
          newRequisitions[reqInStore] = action.payload;
          state.requisitions = newRequisitions;
        } else if (state.requisitions) {
          state.requisitions.push(action.payload);
        } else {
          state.requisitions = [action.payload];
        }

        state.status = "idle";
        state.error = "";
      })
      .addCase(getRequisitionById.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })

      /**
       * UPDATE CHALLAN URL
       */
      .addCase(updateChallanUrl.pending, state => {
        state.status = "loading";
      })
      .addCase(updateChallanUrl.fulfilled, (state, action) => {
        const reqInStore = state.dispatchedRequisitions.findIndex(req => req.id.toString() === action.payload.id);

        if (reqInStore > -1) {
          const newRequisitions = [...state.dispatchedRequisitions];
          newRequisitions[reqInStore] = { ...newRequisitions[reqInStore], challanUrl: action.payload.challanUrl };
          state.dispatchedRequisitions = newRequisitions;
          window.open(action.payload.challanUrl, "_self");
        }

        state.status = "idle";
        state.error = "";
      })
      .addCase(updateChallanUrl.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })

      /**
       * CANCEL PROCESSING REQUISITION
       */
      .addCase(cancelProcessingRequisition.pending, state => {
        state.status = "cancelling";
      })
      .addCase(cancelProcessingRequisition.fulfilled, state => {
        state.status = "idle";
        state.error = "";
        state.processingRequisition = {};
        removePersistedProcessingRequisition();
      })
      .addCase(cancelProcessingRequisition.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })

      /**
       * SAVE REQUISITION USER MAPPING
       */
      .addCase(saveRequisitionUserMapping.pending, state => {
        state.status = "loading";
      })
      .addCase(saveRequisitionUserMapping.fulfilled, state => {
        state.status = "success";
        state.error = "";
        state.processingRequistionUserMapping = {};
        removePersistedProcessingRequisitionUserMapping();
      })
      .addCase(saveRequisitionUserMapping.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })

      /**
       * CANCEL PROCESSING REQUISITION
       */
      .addCase(cancelProcessingRequisitionUserMapping.pending, state => {
        state.status = "cancelling";
      })
      .addCase(cancelProcessingRequisitionUserMapping.fulfilled, state => {
        state.status = "idle";
        state.error = "";
        state.processingRequistionUserMapping = {};
        removePersistedProcessingRequisitionUserMapping();
      })
      .addCase(cancelProcessingRequisitionUserMapping.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })
      /**
       * SAVE STORE SEC PICKING
       */
      .addCase(saveStoreSecPicking.pending, state => {
        state.status = "loading";
      })
      .addCase(saveStoreSecPicking.fulfilled, state => {
        state.status = "success";
        state.error = "";
        state.processingStoreSecPicking = {};
        removePersistedStoreSecPicking();
      })
      .addCase(saveStoreSecPicking.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })

      /**
       * CANCEL PROCESSING REQUISITION
       */
      .addCase(cancelStoreSecPicking.pending, state => {
        state.status = "cancelling";
      })
      .addCase(cancelStoreSecPicking.fulfilled, state => {
        state.status = "idle";
        state.error = "";
        state.processingStoreSecPicking = {};
        removePersistedStoreSecPicking();
      })
      .addCase(cancelStoreSecPicking.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })
      /**
       * DISPATCH REQUISITION
       */
      .addCase(markRequisitionDispatched.pending, (state, action) => {
        const dispatchingRequisition = action?.meta?.arg;
        if (dispatchingRequisition) {
          const reqInStoreIndex = state.requisitions.findIndex(req => req.id.toString() === dispatchingRequisition);
          if (reqInStoreIndex > -1) {
            state.requisitions[reqInStoreIndex] = { ...state.requisitions[reqInStoreIndex], dispatching: true, }
          }
        }
      })
      .addCase(markRequisitionDispatched.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = "";
        const dispatchingRequisition = action?.meta?.arg;
        if (dispatchingRequisition) {
          const reqInStoreIndex = state.requisitions.findIndex(req => req.id.toString() === dispatchingRequisition);
          if (reqInStoreIndex > -1) {
            if (action.payload.challanUrl) {
              openInNewTab(action.payload.challanUrl);
            }
            state.requisitions[reqInStoreIndex] = { ...state.requisitions[reqInStoreIndex], ...action.payload, dispatching: false, }
          }
        }
      })
      .addCase(markRequisitionDispatched.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        const dispatchingRequisition = action?.meta?.arg;
        if (dispatchingRequisition) {
          const reqInStoreIndex = state.requisitions.findIndex(req => req.id.toString() === dispatchingRequisition);
          if (reqInStoreIndex > -1) {
            state.requisitions[reqInStoreIndex] = { ...state.requisitions[reqInStoreIndex], dispatching: false, }
          }
        }

        if (error.message) {
          state.error = `${error.message.toString()}`;
        }
        state.requisitions = [...state.requisitions.map(e => ({ ...e, dispatching: false, }))];
      })

      /**
       * GET SUGGESTED PCIKING
       */
      .addCase(getSuggestedPicking.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getSuggestedPicking.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = "";
      })
      .addCase(getSuggestedPicking.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";
        if (error.message) {
          state.error = error.message;
        }
      })

      /**
       * Get Slots
       */
      .addCase(fetchSlots.fulfilled, (state, action) => {
        const { errors, error, message, ...slots } = action.payload;
        state.slots = slots;
      })

      /**
       * Get Master Skus
       */
      .addCase(fetchMasterSkus.fulfilled, (state, action) => {
        state.masterSkus = action.payload;
      })

      /**
       * Get Assets
       */
      .addCase(fetchAssets.fulfilled, (state, action) => {
        state.assets = action.payload;
      })
      /**
      * Get Stores List
      */
      .addCase(fetchStoresList.fulfilled, (state, action) => {
        state.storesList = action.payload;
      })
      /**
      * Get Stores List
      */
      .addCase(toggleBulkStatus.pending, (state, action) => {
        state.status = "updating";
        // state.storesList = action.payload;
      })
      .addCase(toggleBulkStatus.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })
      .addCase(toggleBulkStatus.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = "";
        const { id: reqId, } = action.payload;
        const reqs = state.dispatchedRequisitions;
        const thisPoIdx = reqs?.findIndex((p) => p.id === reqId) ?? -1;
        state.dispatchedRequisitions.splice(thisPoIdx, 1, action.payload);
      })
  }
});

export const {
  setProcessingRequisition,
  removeProcessingRequisition,
  setProcessingRequisitionUserMapping,
  removeProcessingRequisitionUserMapping,
  setStoreSecPicking,
  removeStoreSecPicking
} = requisitionSlice.actions;
export default requisitionSlice.reducer; 