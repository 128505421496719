import { Grid } from "antd";
import { AppHeaderDesktop } from "components/AppHeader/AppHeaderDesktop";
import { AppHeaderMobile } from "components/AppHeader/AppHeaderMobile";

const { useBreakpoint } = Grid;
export const AppHeader = () => {
  const screens = useBreakpoint();

  if (!Object.keys(screens).length) {
    return <></>;
  }
  return screens.md ? (
    <AppHeaderDesktop></AppHeaderDesktop>
  ) : (
    <AppHeaderMobile></AppHeaderMobile>
  );
};
