import { IPickerMappingUpload } from "common/interface/IPickerUploadScheme";

const STORAGE_KEY_STORE_RETURN = "processingVendorWarehouseMapping";

export const persistProcessingVendorWarehouseMapping = (data: IPickerMappingUpload) => {
    localStorage.setItem(STORAGE_KEY_STORE_RETURN, JSON.stringify(data));
}

export const getPersistProcessingVendorWarehouseMapping = (): IPickerMappingUpload => {
    const processingWhReturnMapping = localStorage.getItem(STORAGE_KEY_STORE_RETURN);
    return processingWhReturnMapping ? JSON.parse(processingWhReturnMapping) : {};
}

export const removePersistProcessingVendorWarehouseMapping = () => {
    localStorage.removeItem(STORAGE_KEY_STORE_RETURN);
}