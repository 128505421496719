import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd";
import moment from "moment";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

const StyledFilterContainer = styled.div`
  &,
  & .filter {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    &__item {
      margin: 0.5rem;

      & .ant-form-item {
        margin: 0;
      }

      &--submit {
        align-self: flex-end;
      }
    }
  }
`;

export const AppPoFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [form] = Form.useForm();

  useEffect(() => {
    const params = Object.fromEntries(new URLSearchParams(searchParams));

    form.setFieldsValue({...params, ...(params["orderDate"] ? {"orderDate": moment(params["orderDate"])} : {})});
  }, [form, searchParams]);

  const onSearch = (values: Record<string, string>) => {
    const params: Record<string, string> = {};
    Object.entries(values).forEach(([key, value]) => {

      if (value) {
        if (key === "orderDate") {
          params[key] = moment(values["orderDate"]).format("yyyy-MM-DD");
        }
        else {
          params[key] = value;
        }
      }
    });
    setSearchParams(params);
  };

  return (
    <StyledFilterContainer>
      <Form
        form={form}
        name="po-filter-form"
        onFinish={onSearch}
        layout="vertical"
        style={{ width: "100%" }}
      >
        <Row className="filter">
          <Col xs={20} sm={4} md={5} lg={4} className="filter__item">
            <Form.Item name="vendorId" label="Vendor ID">
              <Input placeholder="Search by Vendor ID" size="small" allowClear />
            </Form.Item>
          </Col>

          <Col xs={20} sm={4} md={5} lg={4} className="filter__item">
            <Form.Item name="status" label="Status">
              <Select
                allowClear
                size="small"
                placeholder="Search by Status"
                filterOption={(input, option) => {
                  return option?.children?.toString().toLowerCase().includes(input?.toLowerCase() ?? "") ?? false;
                }}
              >
                {["ISSUED", "RECEIVED_DRAFT", "GRN_CLOSED", "ISSUE_DRAFT", "DISCARDED", "BILLING_COMPLETED", "PO_CLOSED"].map((status) => (
                  <Select.Option value={status} key={status}>
                    {status}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={20} sm={4} md={5} lg={4} className="filter__item">
            <Form.Item name="poId" label="PO ID">
              <Input placeholder="Search by PO ID" size="small" allowClear />
            </Form.Item>
          </Col>

          <Col xs={20} sm={4} md={5} lg={4} className="filter__item">
            <Form.Item name={"orderDate"} label="Order Date" valuePropName='date'>
              <DatePicker
                format={"YYYY-MM-DD"}
                onChange={(val) => {
                  form.setFieldsValue({ ...form.getFieldsValue, "orderDate": val });
                }}
                placeholder="Search by Order Date" size="small" allowClear
              />
            </Form.Item>

          </Col>

          <Col
            xs={20}
            sm={4}
            md={2}
            lg={2}
            className="filter__item filter__item--submit"
          >
            <Form.Item>
              <Button type="primary" htmlType="submit" size="small">
                Search
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </StyledFilterContainer>
  );
};
