import { useEffect, useState } from "react";
import { Avatar, Grid, Layout, Menu, MenuProps } from "antd";
import { ShopOutlined, UserSwitchOutlined } from "@ant-design/icons";
import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";
import { routes } from "common/routes";
import { useAppSelector } from "hooks/useAppSelector";
import { useAppDispatch } from "hooks/useAppDispatch";
import { toggleSider } from "store/app/appSlice";
import { variables } from "common/variables";
import { BsDot } from "react-icons/bs";
import { BiTransfer } from "react-icons/bi";
import { HiUserGroup } from "react-icons/hi";

const { Sider } = Layout;

const StyledSider = styled(Sider)`
  background-color: white;
  border-right: 1px solid #ebebeb;
  
  & .ant-menu {
    overflow-x: hidden;
    overflow-y: auto;
  }
  
`;

const StyledMenu = styled(Menu)`
  height: 100%;
  margin-top: 1rem;
  padding-bottom: 6rem;

  & .ant-menu-submenu-title,
  & .ant-menu-item {
    padding: 0 20px !important;
    font-weight: 500;
    overflow: none;
  }

  & .ant-menu-sub .ant-menu-item {
    padding: 20px 0 20px 2rem !important;
  }

  & .anticon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const StyledSubMenuIcon = styled(BsDot)`
  font-size: 20px !important;
`;

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

// do not change the order
const rootSubmenuKeys = [
  "store-requisition",
  "store-na-picking",
  "store-rejection",
  "vendors",
  "po",
  "ppd",
  "cluster-society",
  "store-return",
  "admin-services",
  "franchise",
  "procurement",
  "stock-exchange",
  "internal-stock-exchange",
  "user-services",
  "quality-check",
  "middle-mile",
  "cash-collection",
  "cluster-service",
  "franchise-inventory",
  "consumer-service",
];

const subMenuKeys: { [key: string]: string } = {
  [`/${routes.STORE_REQUISITION.ALL}`]: "store-requisition",
  [`/${routes.STORE_REQUISITION.UPLOAD}`]: "store-requisition",
  [`/${routes.STORE_REQUISITION.ALL_DISPATCHED}`]: "store-requisition",
  [`/${routes.STORE_REQUISITION.ALL_SPLIT_SKU}`]: "store-requisition",
  [`/${routes.STORE_REQUISITION.SUGGESTED_PICKING}`]: "store-requisition",
  [`/${routes.STORE_REQUISITION.SEC_PICKING_UPLOAD}`]: "store-requisition",
  [`/${routes.STORE_REQUISITION.SEC_PICKING_SAVE}`]: "store-requisition",
  [`/${routes.SR_USER_MAPPING.NA_USER_MAPPING_UPLOAD}`]: "store-na-picking",
  [`/${routes.SR_USER_MAPPING.FA_USER_MAPPING_UPLOAD}`]: "store-na-picking",
  [`/${routes.STORE_REQUISITION.USER_MAPPING_UPLOAD}`]: "store-na-picking",
  [`/${routes.STORE_REJECTION.INVENTORY_REJECTION_UPLOAD}`]: "store-rejection",
  [`/${routes.VENDOR.ALL}`]: "po",
  [`/${routes.PO.UPLOAD}`]: "po",
  [`/${routes.PO.UPLOAD_BILLING_RATE}`]: "po",
  [`/${routes.PO.GRN}`]: "po",
  [`/${routes.PO.PO_REQUESTS}`]: "po",
  [`/${routes.PPD.CRON_REMAP_USERS}`]: "ppd",
  [`/${routes.PPD.UPLOAD}`]: "ppd",
  [`/${routes.PPD.ORDERS}`]: "ppd",
  [`/${routes.PPD.DELIVERY_ORDERS}`]: "ppd",
  [`/${routes.PPD.VIEW_TABLE}`]: "ppd",
  [`/${routes.STORE_RETURN.ALL}`]: "store-return",
  [`/${routes.STORE_RETURN.UPLOAD}`]: "store-return",
  [`/${routes.STORE_RETURN.CREATE_SR_REQUESTS}`]: "store-return",
  [`/${routes.ADMIN_SERVICES.ADMIN_USERS}`]: "admin-services",
  [`/${routes.ADMIN_SERVICES.CREATE_SOCIETY}`]: "cluster-society",
  [`/${routes.ADMIN_SERVICES.SOCIETY}`]: "cluster-society",
  [`/${routes.ADMIN_SERVICES.UPLOAD_SOCIETY_SEQUENCE}`]: "admin-services",
  [`/${routes.ADMIN_SERVICES.SAVE_SOCIETY_SEQUENCE}`]: "admin-services",
  [`/${routes.CLUSTER_SOCIETY.GET_DELAY_REQUEST}`]: "cluster-society",
  [`/${routes.PROCUREMENT.UPLOAD_PROJECTED_SR}`]: "procurement",
  [`/${routes.PROCUREMENT.UPLOAD_VENDOR_WAREHOUSE_MAPPING}`]: "procurement",
  [`/${routes.FRANCHISE.SAVE}`]: "franchise-inventory",
  [`/${routes.FRANCHISE.UPLOAD}`]: "franchise-inventory",
  [`/${routes.FRANCHISE.ALL}`]: "franchise",
  [`/${routes.FRANCHISE.SAVE_PRICING_OVERRIDE}`]: "franchise",
  [`/${routes.FRANCHISE.UPLOAD_PRICING_OVERRIDE}`]: "franchise",
  [`/${routes.FRANCHISE.UPLOAD_CATEGORY_PRICING}`]: "franchise",
  [`/${routes.FRANCHISE.UPLOAD_INVENTORY_ADJUSTMENT}`]: "franchise",
  [`/${routes.STOCK_EXCHANGE.UPLOAD}`]: "stock-exchange",
  [`/${routes.STOCK_EXCHANGE.SENT}`]: "stock-exchange",
   [`/${routes.STOCK_EXCHANGE.UPLOAD_HOLD}`]: "stock-exchange",
  [`/${routes.STOCK_EXCHANGE.SAVE_HOLD}`]: "stock-exchange",
  [`/${routes.STOCK_EXCHANGE.RECEIVED}`]: "stock-exchange",
  [`/${routes.INTERNAL_STOCK_EXCHANGE.SAVE}`]: "internal-stock-exchange",
  [`/${routes.INTERNAL_STOCK_EXCHANGE.UPLOAD}`]: "internal-stock-exchange",
  [`/${routes.USER_SERVICES.UPLOAD_USER_DAY_MAPPING}`]: "user-services",
  [`/${routes.QUALITY_CHECK.ALL}`]: "quality-check",
  [`/${routes.MIDDLE_MILE.MIDDLE_MILE_UPLOAD}`]: "middle-mile",
  [`/${routes.MIDDLE_MILE.MIDDLE_MILE_SAVE}`]: "middle-mile",
  [`/${routes.MIDDLE_MILE.MERGE_ROUTES}`]: "middle-mile",
  [`/${routes.MIDDLE_MILE.ALL_MIDDLE_MILE}`]: "middle-mile",
  [`/${routes.MIDDLE_MILE.TRACKING_INFO}`]: "middle-mile",
  [`/${routes.CASH_COLLECTION.ALL}`]: "cash-collection",
  [`/${routes.CLUSTER_SERVICE.UPLOAD_CLUSTER_STORE_MAPPING}`]: "cluster-service",
  [`/${routes.CLUSTER_SERVICE.SAVE_CLUSTER_STORE_MAPPING}`]: "cluster-service",
  [`/${routes.CLUSTER_SERVICE.UPLOAD_CLUSTER_AM_MAPPING}`]: "cluster-service",
  [`/${routes.CLUSTER_SERVICE.SAVE_CLUSTER_AM_MAPPING}`]: "cluster-service",
  [`/${routes.CONSUMER_SERVICE.UPLOAD_CLUSTER_MAPPING}`]: "consumer-service",
  [`/${routes.CONSUMER_SERVICE.SAVE_CLUSTER_MAPPING}`]: "consumer-service",
  [`/${routes.CONSUMER_SERVICE.UPLOAD_CLUSTER_SOCIETY_MAPPING}`]: "consumer-service",
  [`/${routes.CONSUMER_SERVICE.SAVE_CLUSTER_SOCIETY_MAPPING}`]: "consumer-service",
};

const getSubMenuKeyArray = (key: string): string[] => {
  const subkey = subMenuKeys[key];

  return subkey ? [subkey] : [];
};

const { useBreakpoint } = Grid;

export const AppSider = () => {
  const { siderCollapsed } = useAppSelector((state) => state.app);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const screens = useBreakpoint();
  const [defaultSelectedKeys, setDefaultSelectedKeys] = useState([
    location.pathname,
  ]);
  const [openKeys, setOpenKeys] = useState(
    getSubMenuKeyArray(location.pathname)
  );

  useEffect(() => {
    if (siderCollapsed !== undefined && !siderCollapsed) {
      setOpenKeys([subMenuKeys[location.pathname]]);
      setDefaultSelectedKeys([location.pathname]);
    } else {
      setOpenKeys([]);
      setDefaultSelectedKeys([]);
    }
  }, [siderCollapsed, location]);

  const onOpenChange = (keys: string[]) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);

    if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const onNavClicked = () => {
    if (screens.md !== undefined && !screens.md) {
      setOpenKeys([]);
      setDefaultSelectedKeys([]);
      dispatch(toggleSider());
    }
  };

  const items: MenuItem[] = [
    getItem(
      "Store Requisition",
      rootSubmenuKeys[0],
      <Avatar
        icon={
          <ShopOutlined
            style={{
              color: variables?.primaryColor,
              lineHeight: 0,
            }}
          />
        }
        style={{ backgroundColor: variables?.secondaryColor }}
      />,
      [
        getItem(
          <NavLink to={routes.STORE_REQUISITION.ALL} onClick={onNavClicked}>
            All Requisitions
          </NavLink>,
          `/${routes.STORE_REQUISITION.ALL}`,
          <StyledSubMenuIcon />
        ),
        getItem(
          <NavLink to={routes.STORE_REQUISITION.ALL_SPLIT_SKU} onClick={onNavClicked}>
            All Split SKU
          </NavLink>,
          `/${routes.STORE_REQUISITION.ALL_SPLIT_SKU}`,
          <StyledSubMenuIcon />
        ),
        getItem(
          <NavLink to={routes.STORE_REQUISITION.UPLOAD} onClick={onNavClicked}>
            Upload Store Requisition
          </NavLink>,
          `/${routes.STORE_REQUISITION.UPLOAD}`,
          <StyledSubMenuIcon />
        ),
        getItem(
          <NavLink
            to={routes.STORE_REQUISITION.ALL_DISPATCHED}
            onClick={onNavClicked}
          >
            All Non Dispatched
          </NavLink>,
          `/${routes.STORE_REQUISITION.ALL_DISPATCHED}`,
          <StyledSubMenuIcon />
        ),
        getItem(
          <NavLink
            to={routes.STORE_REQUISITION.SUGGESTED_PICKING}
            onClick={onNavClicked}
          >
            Suggested Inv Picking
          </NavLink>,
          `/${routes.STORE_REQUISITION.SUGGESTED_PICKING}`,
          <StyledSubMenuIcon />
        ),
        getItem(
          <NavLink
            to={routes.STORE_REQUISITION.SEC_PICKING_UPLOAD}
            onClick={onNavClicked}
          >
            Store Sec. Picking
          </NavLink>,
          `/${routes.STORE_REQUISITION.SEC_PICKING_UPLOAD}`,
          <StyledSubMenuIcon />
        ),
      ]
    ),

    getItem(
      "SR User Mapping",
      rootSubmenuKeys[1],
      <Avatar
        icon={
          <ShopOutlined
            style={{ color: variables?.primaryColor, lineHeight: 0 }}
          />
        }
        style={{ backgroundColor: variables?.secondaryColor }}
      />,
      [
        getItem(
          <NavLink
            to={routes.STORE_REQUISITION.USER_MAPPING_UPLOAD}
            onClick={onNavClicked}
          >
            Upload SR User Picking
          </NavLink>,
          `/${routes.STORE_REQUISITION.USER_MAPPING_UPLOAD}`,
          <StyledSubMenuIcon />
        ),
        getItem(
          <NavLink
            to={routes.SR_USER_MAPPING.NA_USER_MAPPING_UPLOAD}
            onClick={onNavClicked}
          >
            Upload SR N/A Picking
          </NavLink>,
          `/${routes.SR_USER_MAPPING.NA_USER_MAPPING_UPLOAD}`,
          <StyledSubMenuIcon />
        ),
        getItem(
          <NavLink
            to={routes.SR_USER_MAPPING.FA_USER_MAPPING_UPLOAD}
            onClick={onNavClicked}
          >
            Upload FA User Mapping
          </NavLink>,
          `/${routes.SR_USER_MAPPING.FA_USER_MAPPING_UPLOAD}`,
          <StyledSubMenuIcon />
        ),
      
      ]
    ),

    // getItem(
    //   'Store Rejection',
    //   rootSubmenuKeys[2],
    //   <Avatar
    //     icon={
    //       <ShopOutlined
    //         style={{ color: variables?.primaryColor, lineHeight: 0 }}
    //       />
    //     }
    //     style={{ backgroundColor: variables?.secondaryColor }}
    //   />,
    //   [
    //     getItem(
    //       <NavLink to={routes.STORE_REJECTION.INVENTORY_REJECTION_UPLOAD} onClick={onNavClicked}>
    //         Store Rejections Upload
    //       </NavLink>,
    //       `/${routes.STORE_REJECTION.INVENTORY_REJECTION_UPLOAD}`,
    //       <StyledSubMenuIcon />
    //     ),
    //   ]
    // ),

    // getItem(
    //   "Vendor Management",
    //   rootSubmenuKeys[3],
    //   <Avatar
    //     icon={
    //       <ShopOutlined
    //         style={{ color: variables?.primaryColor, lineHeight: 0 }}
    //       />
    //     }
    //     style={{ backgroundColor: variables?.secondaryColor }}
    //   />,
    //   [
    //     getItem(
    //       <NavLink to={routes.VENDOR.ALL} onClick={onNavClicked}>
    //         Vendors
    //       </NavLink>,
    //       `/${routes.VENDOR.ALL}`,
    //       <StyledSubMenuIcon />
    //     ),
    //   ]
    // ),

    getItem(
      "Purchase Order",
      rootSubmenuKeys[4],
      <Avatar
        icon={
          <ShopOutlined
            style={{ color: variables?.primaryColor, lineHeight: 0 }}
          />
        }
        style={{ backgroundColor: variables?.secondaryColor }}
      />,
      [
        getItem(
          <NavLink to={routes.VENDOR.ALL} onClick={onNavClicked}>
            Vendors
          </NavLink>,
          `/${routes.VENDOR.ALL}`,
          <StyledSubMenuIcon />
        ),
        getItem(
          <NavLink to={routes.PO.UPLOAD} onClick={onNavClicked}>
            Upload PO
          </NavLink>,
          `/${routes.PO.UPLOAD}`,
          <StyledSubMenuIcon />
        ),
        getItem(
          <NavLink
            to={routes.PO.UPLOAD_BILLING_RATE}
            onClick={onNavClicked}
          >
            Upload Billing Rate
          </NavLink>,
          `/${routes.PO.UPLOAD_BILLING_RATE}`,
          <StyledSubMenuIcon />
        ),
        getItem(
          <NavLink to={routes.PO.PO_REQUESTS} onClick={onNavClicked}>
            All PO
          </NavLink>,
          `/${routes.PO.PO_REQUESTS}`,
          <StyledSubMenuIcon />
        ),
        getItem(
          <NavLink to={routes.PO.GRN} onClick={onNavClicked}>
            GRN
          </NavLink>,
          `/${routes.PO.GRN}`,
          <StyledSubMenuIcon />
        ),
      ]
    ),

    getItem(
      "PPD",
      rootSubmenuKeys[5],
      <Avatar
        icon={
          <ShopOutlined
            style={{
              color: variables?.primaryColor,
              lineHeight: 0,
            }}
          />
        }
        style={{ backgroundColor: variables?.secondaryColor }}
      />,
      [
        getItem(
          <NavLink to={routes.PPD.UPLOAD} onClick={onNavClicked}>
            Picker Mapping Upload
          </NavLink>,
          `/${routes.PPD.UPLOAD}`,
          <StyledSubMenuIcon />
        ),
        getItem(
          <NavLink to={routes.PPD.CRON_REMAP_USERS} onClick={onNavClicked}>
            Cron User Remapping
          </NavLink>,
          `/${routes.PPD.CRON_REMAP_USERS}`,
          <StyledSubMenuIcon />
        ),
        getItem(
          <NavLink to={routes.PPD.ORDERS} onClick={onNavClicked}>
            Orders
          </NavLink>,
          `/${routes.PPD.ORDERS}`,
          <StyledSubMenuIcon />
        ),
        getItem(
          <NavLink to={routes.PPD.DELIVERY_ORDERS} onClick={onNavClicked}>
            Delivery Orders
          </NavLink>,
          `/${routes.PPD.DELIVERY_ORDERS}`,
          <StyledSubMenuIcon />
        ),
        getItem(
          <NavLink to={routes.PPD.VIEW_TABLE} onClick={onNavClicked}>
            View Table
          </NavLink>,
          `/${routes.PPD.VIEW_TABLE}`,
          <StyledSubMenuIcon />
        ),
      ]
    ),
    
    getItem(
      "Cluster-Society",
      rootSubmenuKeys[6],
      <Avatar
        icon={
          <ShopOutlined
            style={{
              color: variables?.primaryColor,
              lineHeight: 0,
            }}
          />
        }
        style={{ backgroundColor: variables?.secondaryColor }}
      />,
      [
        getItem(
          <NavLink to={routes.ADMIN_SERVICES.SOCIETY} onClick={onNavClicked}>
            Society
          </NavLink>,
          `/${routes.ADMIN_SERVICES.SOCIETY}`,
          <StyledSubMenuIcon />
        ),
        getItem(
          <NavLink to={routes.CLUSTER_SOCIETY.GET_DELAY_REQUEST} onClick={onNavClicked}>
            Delay Reasons
          </NavLink>,
          `/${routes.CLUSTER_SOCIETY.GET_DELAY_REQUEST}`,
          <StyledSubMenuIcon />
        ), 
      ]
    ),

    getItem(
      "Store Return",
      rootSubmenuKeys[7],
      <Avatar
        icon={
          <ShopOutlined
            style={{
              color: variables?.primaryColor,
              lineHeight: 0,
            }}
          />
        }
        style={{ backgroundColor: variables?.secondaryColor }}
      />,
      [
        getItem(
          <NavLink to={routes.STORE_RETURN.CREATE_SR_REQUESTS} onClick={onNavClicked}>
            Create Store Return
          </NavLink>,
          `/${routes.STORE_RETURN.CREATE_SR_REQUESTS}`,
          <StyledSubMenuIcon />
        ),
        getItem(
          <NavLink to={routes.STORE_RETURN.ALL} onClick={onNavClicked}>
            All Store Return
          </NavLink>,
          `/${routes.STORE_RETURN.ALL}`,
          <StyledSubMenuIcon />
        ),
        // getItem(
        //   <NavLink to={routes.STORE_RETURN.UPLOAD} onClick={onNavClicked}>
        //     Upload Store Return
        //   </NavLink>,
        //   `/${routes.STORE_RETURN.UPLOAD}`,
        //   <StyledSubMenuIcon />
        // ),
      ]
    ),

    getItem(
      "Admin Services",
      rootSubmenuKeys[8],
      <Avatar
        icon={
          <UserSwitchOutlined
            style={{
              color: variables?.primaryColor,
              lineHeight: 0,
            }}
          />
        }
        style={{ backgroundColor: variables?.secondaryColor }}
      />,
      [
        getItem(
          <NavLink to={routes.ADMIN_SERVICES.ADMIN_USERS} onClick={onNavClicked}>
            Admin Users
          </NavLink>,
          `/${routes.ADMIN_SERVICES.ADMIN_USERS}`,
          <StyledSubMenuIcon />
        ),
        getItem(
          <NavLink to={routes.ADMIN_SERVICES.UPLOAD_SOCIETY_SEQUENCE} onClick={onNavClicked}>
            Upload Society Sequence
          </NavLink>,
          `/${routes.ADMIN_SERVICES.UPLOAD_SOCIETY_SEQUENCE}`,
          <StyledSubMenuIcon />
        ),
      ]
    ),

    getItem(
      "Franchise Services",
      rootSubmenuKeys[9],
      <Avatar
        icon={
          <UserSwitchOutlined
            style={{
              color: variables?.primaryColor,
              lineHeight: 0,
            }}
          />
        }
        style={{ backgroundColor: variables?.secondaryColor }}
      />,
      [
        getItem(
          <NavLink to={routes.FRANCHISE.UPLOAD_PRICING_OVERRIDE} onClick={onNavClicked}>
            Upload Pricing Override
          </NavLink>,
          `/${routes.FRANCHISE.UPLOAD_PRICING_OVERRIDE}`,
          <StyledSubMenuIcon />
        ),
        getItem(
          <NavLink to={routes.FRANCHISE.UPLOAD_CATEGORY_PRICING} onClick={onNavClicked}>
            Upload Category Pricing
          </NavLink>,
          `/${routes.FRANCHISE.UPLOAD_CATEGORY_PRICING}`,
          <StyledSubMenuIcon />
        ),
        getItem(
          <NavLink to={routes.FRANCHISE.UPLOAD_INVENTORY_ADJUSTMENT} onClick={onNavClicked}>
            Upload Inventory Adj
          </NavLink>,
          `/${routes.FRANCHISE.UPLOAD_INVENTORY_ADJUSTMENT}`,
          <StyledSubMenuIcon />
        ),
        getItem(
          <NavLink to={routes.FRANCHISE.ALL} onClick={onNavClicked}>
            All Franchise Inventory
          </NavLink>,
          `/${routes.FRANCHISE.ALL}`,
          <StyledSubMenuIcon />
        ),
      ]
    ),
    getItem(
      "Procurement Services",
      rootSubmenuKeys[10],
      <Avatar
        icon={
          <UserSwitchOutlined
            style={{
              color: variables?.primaryColor,
              lineHeight: 0,
            }}
          />
        }
        style={{ backgroundColor: variables?.secondaryColor }}
      />,
      [

        getItem(
          <NavLink to={routes.PROCUREMENT.UPLOAD_PROJECTED_SR} onClick={onNavClicked}>
            Upload Projected SR
          </NavLink>,
          `/${routes.PROCUREMENT.UPLOAD_PROJECTED_SR}`,
          <StyledSubMenuIcon />
        ),
        getItem(
          <NavLink to={routes.PROCUREMENT.UPLOAD_VENDOR_WAREHOUSE_MAPPING} onClick={onNavClicked}>
            Upload Vendor Warehouse
          </NavLink>,
          `/${routes.PROCUREMENT.UPLOAD_VENDOR_WAREHOUSE_MAPPING}`,
          <StyledSubMenuIcon />
        ),
      ]
    ),
    getItem(
      "Stock Transfer",
      rootSubmenuKeys[11],
      <Avatar
        icon={
          <BiTransfer
            style={{
              color: variables?.primaryColor,
              lineHeight: 0,
              paddingTop: ".24rem",
            }} />}
        style={{ backgroundColor: variables?.secondaryColor }}
      />, [
      getItem(
        <NavLink to={routes.STOCK_EXCHANGE.UPLOAD} onClick={onNavClicked}>
          Upload Stock Transfer
        </NavLink>,
        `/${routes.STOCK_EXCHANGE.UPLOAD}`,
        <StyledSubMenuIcon />
      ),
      getItem(
        <NavLink to={routes.STOCK_EXCHANGE.SENT} onClick={onNavClicked}>
          All Sent Stock Transfer
        </NavLink>,
        `/${routes.STOCK_EXCHANGE.SENT}`,
        <StyledSubMenuIcon />
      ),
      getItem(
        <NavLink to={routes.STOCK_EXCHANGE.RECEIVED} onClick={onNavClicked}>
          All Rcv Stock Transfer
        </NavLink>,
        `/${routes.STOCK_EXCHANGE.RECEIVED}`,
        <StyledSubMenuIcon />
      ),
      getItem(
        <NavLink to={routes.STOCK_EXCHANGE.UPLOAD_HOLD} onClick={onNavClicked}>
          Upload Hold Transfer
        </NavLink>,
        `/${routes.STOCK_EXCHANGE.UPLOAD_HOLD}`,
        <StyledSubMenuIcon />
      ),
    ]),
    getItem(
      "Internal Stock Transfer",
      rootSubmenuKeys[12],
      <Avatar
        icon={
          <BiTransfer
            style={{
              color: variables?.primaryColor,
              lineHeight: 0,
              paddingTop: ".24rem",
            }} />}
        style={{ backgroundColor: variables?.secondaryColor }}
      />, [
      getItem(
        <NavLink to={routes.INTERNAL_STOCK_EXCHANGE.UPLOAD} onClick={onNavClicked}>
          Upload Internal Stock Transfer
        </NavLink>,
        `/${routes.INTERNAL_STOCK_EXCHANGE.UPLOAD}`,
        <StyledSubMenuIcon />
      ),
    ]),
    getItem(
      "User Services",
      rootSubmenuKeys[13],
      <Avatar
        icon={
          <HiUserGroup
            style={{
              color: variables?.primaryColor,
              lineHeight: 0,
              paddingTop: ".24rem",
            }} />}
        style={{ backgroundColor: variables?.secondaryColor }}
      />, [ 
      getItem(
        <NavLink to={routes.USER_SERVICES.UPLOAD_USER_DAY_MAPPING} onClick={onNavClicked}>
          Upload User Day Mapping
        </NavLink>,
        `/${routes.USER_SERVICES.UPLOAD_USER_DAY_MAPPING}`,
        <StyledSubMenuIcon />
      ),
    ]),
    getItem(
      "Quality Checks",
      rootSubmenuKeys[14],
      <Avatar
        icon={
          <HiUserGroup
            style={{
              color: variables?.primaryColor,
              lineHeight: 0,
              paddingTop: ".24rem",
            }} />}
        style={{ backgroundColor: variables?.secondaryColor }}
      />, [
      getItem(
        <NavLink to={routes.QUALITY_CHECK.ALL} onClick={onNavClicked}>
          All Quality Checks
        </NavLink>,
        `/${routes.QUALITY_CHECK.ALL}`,
        <StyledSubMenuIcon />
      ),
    ]),
    getItem(
      "Middle Mile",
      rootSubmenuKeys[15],
      <Avatar
        icon={
          <HiUserGroup
            style={{
              color: variables?.primaryColor,
              lineHeight: 0,
              paddingTop: ".24rem",
            }} />}
        style={{ backgroundColor: variables?.secondaryColor }}
      />, [
      getItem(
        <NavLink to={routes.MIDDLE_MILE.MIDDLE_MILE_UPLOAD} onClick={onNavClicked}>
          Upload Middle Mile
        </NavLink>,
        `/${routes.MIDDLE_MILE.MIDDLE_MILE_UPLOAD}`,
        <StyledSubMenuIcon />
      ),
      getItem(
        <NavLink to={routes.MIDDLE_MILE.TRACKING_INFO} onClick={onNavClicked}>
          Track Middle Mile 
        </NavLink>,
        `/${routes.MIDDLE_MILE.TRACKING_INFO}`,
        <StyledSubMenuIcon />
      ),
      getItem(
        <NavLink to={routes.MIDDLE_MILE.MERGE_ROUTES} onClick={onNavClicked}>
          Merge Routes 
        </NavLink>,
        `/${routes.MIDDLE_MILE.MERGE_ROUTES}`,
        <StyledSubMenuIcon />
      ),
    ]),
    getItem(
      "Cash Collection",
      rootSubmenuKeys[16],
      <Avatar
        icon={
          <HiUserGroup
            style={{
              color: variables?.primaryColor,
              lineHeight: 0,
              paddingTop: ".24rem",
            }} />}
        style={{ backgroundColor: variables?.secondaryColor }}
      />, [
      getItem(
        <NavLink to={routes.CASH_COLLECTION.ALL} onClick={onNavClicked}>
          View Cash Collections
        </NavLink>,
        `/${routes.CASH_COLLECTION.ALL}`,
        <StyledSubMenuIcon />
      ),
      
    ]),

    getItem(
      "Store Cluster Services",
      rootSubmenuKeys[17],
      <Avatar
        icon={
          <UserSwitchOutlined
            style={{
              color: variables?.primaryColor,
              lineHeight: 0,
            }}
          />
        }
        style={{ backgroundColor: variables?.secondaryColor }}
      />,
      [
        getItem(
          <NavLink to={routes.CLUSTER_SERVICE.UPLOAD_CLUSTER_STORE_MAPPING} onClick={onNavClicked}>
            Upload Cluster Store Mapping
          </NavLink>,
          `/${routes.CLUSTER_SERVICE.UPLOAD_CLUSTER_STORE_MAPPING}`,
          <StyledSubMenuIcon />
        ),
        getItem(
          <NavLink to={routes.CLUSTER_SERVICE.UPLOAD_CLUSTER_AM_MAPPING} onClick={onNavClicked}>
            Upload Cluster FA Mapping
          </NavLink>,
          `/${routes.CLUSTER_SERVICE.UPLOAD_CLUSTER_AM_MAPPING}`,
          <StyledSubMenuIcon />
        ),
      ]
    ),
    
    getItem(
      "Franchise Inventory",
      rootSubmenuKeys[18],
      <Avatar
        icon={
          <UserSwitchOutlined
            style={{
              color: variables?.primaryColor,
              lineHeight: 0,
            }}
          />
        }
        style={{ backgroundColor: variables?.secondaryColor }}
      />,
      [
        getItem(
          <NavLink to={routes.FRANCHISE.UPLOAD} onClick={onNavClicked}>
            Upload Franchise Inventory
          </NavLink>,
          `/${routes.FRANCHISE.UPLOAD}`,
          <StyledSubMenuIcon />
        ),
      ]
    ),
    
    getItem(
      "Consumer Services",
      rootSubmenuKeys[19],
      <Avatar
        icon={
          <UserSwitchOutlined
            style={{
              color: variables?.primaryColor,
              lineHeight: 0,
            }}
          />
        }
        style={{ backgroundColor: variables?.secondaryColor }}
      />,
      [
        getItem(
          <NavLink to={routes.CONSUMER_SERVICE.UPLOAD_CLUSTER_MAPPING} onClick={onNavClicked}>
            Upload Clusters
          </NavLink>,
          `/${routes.CONSUMER_SERVICE.UPLOAD_CLUSTER_MAPPING}`,
          <StyledSubMenuIcon />
        ),
        getItem(
          <NavLink to={routes.CONSUMER_SERVICE.UPLOAD_CLUSTER_SOCIETY_MAPPING} onClick={onNavClicked}>
            Upload Cluster Society Mapping
          </NavLink>,
          `/${routes.CONSUMER_SERVICE.UPLOAD_CLUSTER_SOCIETY_MAPPING}`,
          <StyledSubMenuIcon />
        ),
      ]
    ),

  ];

  return (
    <StyledSider
      width={screens.md === undefined || screens.md ? 280 : "100vw"}
      breakpoint="md"
      trigger={null}
      collapsible
      collapsedWidth="0"
      collapsed={siderCollapsed}
      style={{
        ...(!screens.md && {
          height: "100%",
          width: "100vw",
          zIndex: 9,
        }),
      }}
    >
      <StyledMenu
        items={items}
        mode="inline"
        defaultSelectedKeys={defaultSelectedKeys}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
      />
    </StyledSider>
  );
};
