import {
  Breadcrumb,
  Button,
  Col,
  Descriptions,
  Form,
  Input,
  message,
  Popconfirm,
  Row,
  Select,
  Table,
  Typography,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import { StoreInventoryUpdateDataItem, StoreReturnDetails } from "common/interface/IStoreReturn";
import { getDateAndTimeFromISODate } from "common/utils";
import { variables } from "common/variables";
import { AppPageHeader } from "components/AppPageHeader";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useDocumentTitle } from "hooks/usePageTitle";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { BsPlus } from "react-icons/bs";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { deleteSrItem, getStoreReturnDetails, receiveStoreReturn, saveSrDraft, saveSrItem } from "store/store-return/storeReturnSlice";

const MESSAGE_KEY = "store_return__info";
type PartialISRItemType = Partial<StoreInventoryUpdateDataItem> & { key: string };

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "select" | "text";
  record: PartialISRItemType;
  index: number;
  children: React.ReactNode;
}

const { Option } = Select;

const StoreReturnViewPage = () => {
  useDocumentTitle("Store Return Details");
  const dispatch = useAppDispatch();
  const { allStoreReturnDetails, error, status } = useAppSelector((state) => state.storeReturn);
  const { masterSkus = [] } = useAppSelector((state) => state.requisition);
  const { srId } = useParams();
  const [viewedSr, setViewedSr] = useState<StoreReturnDetails>();
  const [tableColumns, setTableColumns] = useState<ColumnsType<PartialISRItemType>>();
  const [tableRows, setTableRows] = useState<any[]>();
  const [receiveReason, setReceiveReason] = useState<String>();
  const [tempTableRows, setTempTableRows] = useState<any[]>();
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const [isAddingItem, setIsAddingItem] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (editingKey) {
      const selectedRowData = viewedSr?.storeReturnItemDataList.find(e => (e.id?.toString()) === editingKey.toString());
      if (selectedRowData !== undefined) {
        form.setFieldsValue({
          skuId: selectedRowData?.sku_code,
        });
      }
    }
  }, [editingKey, form]);

  const isEditing = useCallback(
    (record: PartialISRItemType) => record.key === editingKey,
    [editingKey]
  );

  const edit = useCallback(
    (record: PartialISRItemType) => {
      form.setFieldsValue({ ...record });
      setEditingKey(record.key);
    },
    [form]
  );

  const onReceive = useCallback(async (receiveResoning: String ) => {
    try {
      const row = await form.validateFields();
      let thisSr = allStoreReturnDetails?.find((sr) => sr.id === viewedSr?.id);
      if (thisSr) {
        thisSr = {
          ...thisSr,
          receivedDate: moment(row.receivedDate).toISOString(),
        };
        if (thisSr.storeReturnItemDataList.every((item) => item.quantity !== null && item.sku_code !== null)) {
          dispatch(receiveStoreReturn({ ...thisSr, returnIssue: receiveResoning, },),)
            .unwrap()
            .then(() => navigate(`/store-return/all`));
        }
        else {
          message.error("The Gross Receive Qty is not present for all fields. Please update the same.", 3);
        }

      }
    } catch (e) {
      console.error(e);
    }
  }, [dispatch, form, allStoreReturnDetails, navigate, viewedSr?.id]);

  const saveItem = useCallback(
    (srId: string, srItem: StoreInventoryUpdateDataItem) => {
      dispatch(saveSrItem({ srId, srItem }));
    },
    [dispatch]
  );

  const save = useCallback(
    async (srId: string, srItemId?: number) => {
      try {
        const row = await form.validateFields();

        if (viewedSr) {
          const index = viewedSr.storeReturnItemDataList.findIndex((e) => e.id === srItemId);
          if (index > -1) {
            saveItem(srId, {
              quantity: row.quantity,
              sku_code: row.skuId,
              day: row.day,
              id: viewedSr.storeReturnItemDataList[index].id,
              remarks: row.remarks,
            });
            setEditingKey("");
          } else {

            saveItem(srId, {
              quantity: row.quantity,
              sku_code: row.skuId,
              day: row.day,
              id: undefined,
              remarks: row.remarks,
            });
            setEditingKey("");
          }
        }

        setTempTableRows([]);
        setIsAddingItem(false);
        form.resetFields();
      } catch (errInfo) {
        console.log("Validate Failed:", errInfo);
      }
    },
    [form, masterSkus, saveItem, viewedSr]
  );

  const callDeleteItem = useCallback(
    (srId: string, srItem: StoreInventoryUpdateDataItem) => {      
      dispatch(deleteSrItem({ srId, srItem }));
    },
    [dispatch]
  );

  const deleteItem = useCallback(
    async (srId: string, srItemId?: number) => {
      try {
        const row = await form.validateFields();

        if (viewedSr) {
          const index = viewedSr.storeReturnItemDataList.findIndex((e) => e.id === srItemId);
            
          callDeleteItem(srId, {
            quantity: viewedSr.storeReturnItemDataList[index].quantity,
            sku_code: viewedSr.storeReturnItemDataList[index].sku_code,
            id: viewedSr.storeReturnItemDataList[index].id,
            remarks: viewedSr.storeReturnItemDataList[index].remarks,
            day: viewedSr.storeReturnItemDataList[index].remarks,
          });
        }
      } catch (errInfo) {
        console.log("Validate Failed:", errInfo);
      }
    },
    [form, masterSkus, saveItem, viewedSr]
  );

  useEffect(() => {
    if (srId) {
      dispatch(getStoreReturnDetails(srId));
    }
  }, [srId, dispatch]);

  useEffect(() => {
    if (status === "loading") {
      message.loading({ content: "loading purchase order", key: MESSAGE_KEY });
    } else if (status === "saving") {
      message.loading({ content: "saving purchase order", key: MESSAGE_KEY });
    } else if (status === "error") {
      message.error({ content: error, key: MESSAGE_KEY });
    }

    return () => {
      message.destroy();
    };
  }, [status, error]);

  useEffect(() => {

    const thisSR = allStoreReturnDetails?.find((sr) => sr.id.toString() === srId);
    if (thisSR) {
      setViewedSr(thisSR);
    }
    //   else {
    //     navigate("/store-return/all");
    //   }
  }, [navigate, srId, allStoreReturnDetails]);

  const cancel = useCallback(() => {
    setEditingKey("");
    setIsAddingItem(false);
    setTempTableRows([]);
    form.resetFields();
  }, [form]);

  useEffect(() => {
    let thisSr = allStoreReturnDetails?.find((sr) => sr.id.toString() === srId);

    const headerKeys: { [key: string]: string } = {
      skuId: "SKU ID/Name",
      pickedWeight: "Picked Weight",
      quantity: "Quantity",
      day: "Day",
      remarks: "Remarks",
      actions: "Actions",
    };

    if (thisSr) {
      const columns = [
        ...Object.keys(headerKeys).map((key) => ({
          key,
          title: headerKeys[key],
          dataIndex: key,
          editable: key !== "actions" && true,
          render: (text: string, record: any) => {
            if (key === "skuId") {
              const skuItem = masterSkus.find(e => e.skuCode === record.skuId);
              return (
                <>
                  <div>{skuItem?.skuCode}</div>
                  <div>{skuItem?.name}</div>
                </>
              );
            }
            if (key === "actions") {
              const editable = isEditing(record);
              return editable ? (
                <Row
                  align="middle"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  <Col style={{ marginRight: ".5rem" }}>
                    <Typography.Link
                      onClick={() => save(thisSr!.id.toString(), thisSr?.storeReturnItemDataList.find(e => e.sku_code === record.skuId)?.id)}
                      style={{ marginRight: 8 }}
                      disabled={status === "loading"}
                    >
                      Save
                    </Typography.Link>
                  </Col>
                  <Col>
                    <Typography.Link
                      onClick={cancel}
                      style={{ marginRight: 8 }}
                      type="secondary"
                      disabled={status === "loading"}
                    >
                      Cancel
                    </Typography.Link>
                  </Col>
                </Row>
              ) : (
                <Row align="middle"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                  }}>
                  <Col>
                    <Typography.Link
                      disabled={editingKey !== "" || viewedSr?.status == "RECEIVED"}
                      onClick={() => edit(record)}
                    >
                      Edit
                    </Typography.Link>
                  </Col>
                  <Col style={{ marginLeft: ".5rem" }}>
                    <Popconfirm
                      title={
                        <>
                          <p>Are you sure you want to cancel?</p>
                        </>
                      }
                      placement="topRight"
                      disabled={editingKey !== "" || viewedSr?.status == "RECEIVED" || ((thisSr?.storeReturnItemDataList?.length ?? 0) === 1)}
                      onConfirm={() => deleteItem(thisSr!.id.toString(), thisSr?.storeReturnItemDataList.find(e => e.sku_code === record.skuId)?.id)}
                    >
                      <Typography.Link
                        disabled={editingKey !== "" || viewedSr?.status == "RECEIVED" || ((thisSr?.storeReturnItemDataList?.length ?? 0) === 1)}
                      >
                        Delete
                      </Typography.Link>
                    </Popconfirm>
                  </Col>
                </Row>
              );
            }
            return <>{text}</>;
          },
        })),
      ];

      const mergedColumns = columns.map((col, i) => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          ...(i === 0 && { width: "220px" }),
          onCell: (record: PartialISRItemType) => ({
            record,
            inputType: ["skuId", "day"].includes(col.dataIndex) ? "select" : "text",
            dataIndex: col.dataIndex,
            title: col.title,
            editing: isEditing(record),
          }),
        };
      });

      const rows: any[] =
        thisSr?.storeReturnItemDataList
          ?.map((poItem, idx) => ({
            skuId: poItem.sku_code,
            skuCode: poItem.sku_code,
            remarks: poItem.remarks,
            day: poItem.day,
            key: `${poItem.id}`,
            id: poItem.id,
            quantity: poItem.quantity,
            pickedWeight: poItem.pickedWeight,
          }))
          .filter(
            (poI) => (isAddingItem && !(poI as any).temp) || !isAddingItem
          ) || [];

      setViewedSr(thisSr);
      setTableRows([...rows, ...(tempTableRows || [])]);
      setTableColumns(mergedColumns);

    }
  }, [
    cancel,
    edit,
    editingKey,
    form,
    isAddingItem,
    isEditing,
    srId,
    save,
    tempTableRows,
    viewedSr,
    viewedSr?.storeReturnItemDataList,
    viewedSr?.status,
  ]);

  const getSkuOptions = (includeSku?: string) => {
    if (masterSkus) {
      const selectedSkus = viewedSr?.storeReturnItemDataList
        .filter((srItem) => srItem.sku_code !== includeSku)
        .map((srItem) => srItem.sku_code);
      return masterSkus.reduce((acc, sku) => {
        if (!selectedSkus?.includes(sku.skuCode)) {
          return (acc = {
            ...acc,
            [sku.id]: {
              value: sku.id,
              label: sku.skuCode,
              skuCode: sku.skuCode,
              skuName: sku.name,
            },
          });
        }

        return acc;
      }, {} as Record<string, { skuCode: string; skuName: string }>);
    }

    return {};
  };

  const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    let inputNode = <Input disabled={["pickedWeight",].includes(dataIndex)}/>;
    if ((isAddingItem || isEditing) && inputType === "select") { 
      if (dataIndex === "skuId") {
        inputNode = <Select
          showSearch
          optionFilterProp=""
          filterOption={(input, option) => {
            const keyWords =
              option?.children?.map((child) =>
                child.props.children.toLowerCase()
              ) || [];
            return keyWords.some((key) => key.includes(input.toLowerCase()));
          }}
          style={{ width: "200px" }}
        >
          {Object.entries(getSkuOptions(record.sku_code)).map(
            ([key, value],) => {
              return (
                <Option key={key} value={value.skuCode}>
                  <div>{value.skuCode}</div>
                  <div>{value.skuName}</div>
                </Option>
              )
            }
          )
          }
        </Select>;
      }
      else if (dataIndex === "day") {
        inputNode = <Select
          showSearch
          optionFilterProp=""
          style={{ width: "200px" }}
        >
          {["D2", "D3", "D4",].map(
            (e,) => {
              return (
                <Option key={e} value={e}>
                  {e}
                </Option>
              );
            }
          )
          }
        </Select>
      }
    }
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required:
                  !(["billingRate","totalAmount","pickedWeight",].includes(dataIndex)),
                message: `Please ${inputType === "select" ? "select" : "input"
                  } ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const onAddItem = () => {
    let idx = tableRows?.length || 0;
    const key = (++idx).toString();
    setTempTableRows([...(tempTableRows || []), { key, temp: true, }]);
    setEditingKey(key);
    setIsAddingItem(true);

    setTimeout(() => {
      document
        .querySelector(".editable-last-row")
        ?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  const poInfo = viewedSr && (
    <>
      <Descriptions
        size="small"
        layout="vertical"
        labelStyle={{ fontWeight: "bold" }}
        bordered
        column={{ xs: 2, md: 3 }}
      >
        <Descriptions.Item label="ID">
          {viewedSr.id}
        </Descriptions.Item>
        <Descriptions.Item label="Status">{viewedSr.status}</Descriptions.Item>
        <Descriptions.Item label="Actions">
        <Row style={{display: "flex",}}>
          <Col>
            <Row>
            <Select
              showSearch
              optionFilterProp=""
              onChange={(e) => {
                setReceiveReason(e.toString());
              }}
              value={receiveReason}
              style={{ width: "200px" }}
            >
              {["QUANTITY_ISSUE",
                "WRONG_ITEM_ISSUE",
                "QUALITY_ISSUE",].map(
                  (e,) => {
                    return (
                      <Select.Option key={e} value={e}>
                        {e}
                      </Select.Option>
                    );
                  }
                )
              }
            </Select>
            </Row>
          </Col>
          <Col>
            {(viewedSr.status === "DRAFT") && (
              <Row>
                {receiveReason && <Button
                style={{marginLeft: "1rem"}}
                  type="primary"
                  onClick={() => onReceive(receiveReason)}
                  loading={status === "saving"}
                  disabled={editingKey !== "" || status === "saving"}
                >
                  Receive
                </Button>}
              </Row>
            )}
          </Col>
        </Row>
        </Descriptions.Item>
      </Descriptions>

      {(viewedSr.createdOn || viewedSr.modifiedOn) && <Descriptions
        size="small"
        layout="vertical"
        labelStyle={{ fontWeight: "bold" }}
        bordered
        column={2}
        style={{ marginTop: "20px" }}
      >
        {viewedSr.createdOn && <Descriptions.Item label="Created On">
          {getDateAndTimeFromISODate(viewedSr.createdOn)}
        </Descriptions.Item>}
        {viewedSr.modifiedOn && <Descriptions.Item label="Modified On">
          {getDateAndTimeFromISODate(viewedSr.modifiedOn)}
        </Descriptions.Item>}
      </Descriptions>}

      {!!(tableRows && tableRows.length) && (
        <Row style={{ marginTop: "20px" }}>
          <Col xs={24}>
            <Form form={form} component={false}>
              <Table
                className="po-item-table"
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                bordered
                dataSource={tableRows}
                columns={tableColumns}
                rowClassName={(record, index) =>
                  index === tableRows.length - 1
                    ? "editable-last-row editable-row"
                    : "editable-row"
                }
                pagination={false}
                scroll={{ x: "100vh", y: "65vh" }}
                style={{ width: "100%" }}
                size="small"
                footer={() => (
                  <Row >
                    <Col>
                      <Button
                        type="primary"
                        icon={<BsPlus />}
                        onClick={onAddItem}
                        disabled={isAddingItem || ["RECEIVED"].includes(viewedSr.status)}
                      >
                        Add Item
                      </Button>
                    </Col>
                  </Row>

                )}
              />
            </Form>
          </Col>
        </Row>
      )}
    </>
  );

  return (
    <>
      <Row
        style={{
          margin: "1rem 0",
          position: "sticky",
          top: 0,
          zIndex: 1,
          background: variables.wmBackgroundColorLight1,
          padding: "5px",
        }}
      >
        <Col xs={24}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to="/store-return/all">All Store Returns</NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {viewedSr?.id || "Store Return"}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col>
          <AppPageHeader title="Store Return" />
        </Col>
      </Row>
      <Row>
        <Col xs={24}>{poInfo}</Col>
      </Row>
    </>
  );
};

export default StoreReturnViewPage;
