import {
  Breadcrumb,
  Button,
  Col,
  Descriptions,
  Form,
  Input,
  message,
  Row,
  Select,
  Table,
  Typography,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import { IPpdOrders } from "common/interface/IPpdOrders";
import { getDateAndTimeFromISODate } from "common/utils";
import { variables } from "common/variables";
import { AppPageHeader } from "components/AppPageHeader";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useDocumentTitle } from "hooks/usePageTitle";
import React, { useCallback, useEffect, useState } from "react";
import { BsPlus } from "react-icons/bs";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { getPpdOrderDetails } from "store/ppd/ppdSlice";

const MESSAGE_KEY = "ppd_order_info";
type PartialPPDItemType = Partial<IPpdOrders> & { key: string };

const { Option } = Select;

const PPDViewPage = () => {
  useDocumentTitle("PPD Order ");
  const dispatch = useAppDispatch();
  const { ppdOrders, error, status } = useAppSelector((state) => state.ppd);
  const { id } = useParams();
  const [tableColumns, setTableColumns] =
    useState<ColumnsType<PartialPPDItemType>>();
  const [tableRows, setTableRows] = useState<any[]>();
  const [viewedPPD, setViewedPPD] = useState<IPpdOrders>();
  const [tempTableRows, setTempTableRows] = useState<any[]>();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      updatePpd();
    }
  }, [id, dispatch]);

  const updatePpd = async () => {
    if (id !== undefined) {
      let temp = await dispatch(getPpdOrderDetails(id));
      setViewedPPD(temp.payload as IPpdOrders);
    }
  }

  useEffect(() => {
    if (status === "loading") {
      message.loading({ content: "loading ppd order", key: MESSAGE_KEY });
    }

    return () => {
      message.destroy();
    };
  }, [status, error]);

  // useEffect(() => {
  //   const thisPO = pos?.data.find((p) => p.id.toString() === poId);
  //   if (thisPO) {
  //     setViewedPO(thisPO);
  //   } else {
  //     navigate("/po/all");
  //   }
  // }, [navigate, poId, pos?.data]);

  // const cancel = useCallback(() => {
  //   setEditingKey("");
  //   setIsAddingItem(false);
  //   setTempTableRows([]);
  //   form.resetFields();
  // }, [form]);

  // const onClosePo = useCallback(() => {
  //   const id = Number(viewedPO!.id);

  //   if (!isNaN(id)) {
  //     dispatch(closePO({ id }))
  //       .unwrap()
  //       .then(() => navigate("/po/all"));
  //   }
  // }, [dispatch, navigate, viewedPO]);

  useEffect(() => {
    // const thisPO = ppdOrders?.data?.find((ppd) => ppd.id.toString() === id);

    const headerKeys: { [key: string]: string } = {
      orderItemId: "Order Item ID",
      skuCode: "SKU Code",
      productName: "Product Name",
      uom: "Unit of Measurement",
      orderedQty: "Ordered Qty",
      finalQty: "Final Qty",
    };

    if (viewedPPD) {
      const columns = [
        ...Object.keys(headerKeys).map((key) => ({
          key,
          title: headerKeys[key],
          dataIndex: key,
          editable: key !== "actions" && true,
          render: (text: string, record: any) => {
            return <>{text}</>;
          },
        })),
      ];

      const mergedColumns = columns.map((col, i) => {
        return col;
      });

      const rows: any[] =
      viewedPPD?.orderItems
          ?.map((item, idx) => ({
            orderItemId: item.id,
            orderedQty: item.orderedQty,
            uom: item.uom,
            productName: item.productName,
            skuCode: item.skuCode,
            finalQty: item.finalQty,
          })) || [];

      // setViewedPPD(viewedPPD);
      setTableRows([...rows, ...(tempTableRows || [])]);
      setTableColumns(mergedColumns);
    }
  }, [
    tempTableRows,
    viewedPPD,
    viewedPPD?.id,
  ]);


  const poInfo = viewedPPD ? (
    <>
      <Descriptions
        size="small"
        layout="vertical"
        labelStyle={{ fontWeight: "bold" }}
        bordered
      >
        <Descriptions.Item label="PPD Order ID">
          {viewedPPD.id}
        </Descriptions.Item>
        <Descriptions.Item label="Zone">{viewedPPD.zone}</Descriptions.Item>
        <Descriptions.Item label="Status">{viewedPPD.status}</Descriptions.Item>
      </Descriptions>

      <Descriptions
        size="small"
        layout="vertical"
        labelStyle={{ fontWeight: "bold" }}
        bordered
        column={2}
        style={{ marginTop: "20px" }}
      >
      </Descriptions>

      {!!(tableRows && tableRows.length) && (
        <Row style={{ marginTop: "20px" }}>
          <Col xs={24}>
            <Table
              className="po-item-table"
              bordered
              dataSource={tableRows}
              columns={tableColumns}
              rowClassName={(record, index) =>
                index === tableRows.length - 1
                  ? "editable-last-row editable-row"
                  : "editable-row"
              }
              pagination={false}
              scroll={{ x: "100vh", y: "65vh" }}
              style={{ width: "100%" }}
              size="small"
            />
          </Col>
        </Row>
      )}
    </>) : (<>Loading</>
  );

  return (
    <>
      <Row
        style={{
          margin: "1rem 0",
          position: "sticky",
          top: 0,
          zIndex: 1,
          background: variables.wmBackgroundColorLight1,
          padding: "5px",
        }}
      >
        <Col xs={24}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to="/ppd/orders">Purchase Orders</NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {viewedPPD?.id || "PPD Order Id"}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col>
          <AppPageHeader title="PPD Order" />
        </Col>
      </Row>
      <Row>
        <Col xs={24}>{poInfo}</Col>
      </Row>
    </>
  );
};

export default PPDViewPage;
