import {
  Row,
  Col,
  Typography,
  Table,
  Button,
  message,
  Descriptions,
} from "antd";
import { routes } from "common/routes";
import { generateHeaders } from "common/utils";
import { AppPageHeader } from "components/AppPageHeader";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useDocumentTitle } from "hooks/usePageTitle";
import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { cancelClusterAmMapping, removeProcessingClusterAmMapping, saveClusterAmMapping } from "store/cluster-slice/clusterServicesSlice";

const MESSAGE_KEY = "SAVE_MIDDLE_MILE_MAPPING";

export const SaveClusterAmMappingPage = () => {
  useDocumentTitle("Cluster FA Mapping: Save Mapping");
  const { processingClusterAmMapping, status, error } = useAppSelector(
    (state) => state.clusterServices
  );
  const { slots } = useAppSelector((state) => state.requisition);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const { timeUploaded } = processingClusterAmMapping;
    if (timeUploaded) {
      const uploadedTime = new Date(timeUploaded).getTime();
      const currentTime = new Date().getTime();
      const minsPassed = (currentTime - uploadedTime) / 1000 / 60;

      if (minsPassed >= 15) {
        dispatch(removeProcessingClusterAmMapping());
      }
    }

    if (!Object.keys(processingClusterAmMapping).length) {
      navigate(`/${routes.CLUSTER_SERVICE.UPLOAD_CLUSTER_AM_MAPPING}`);
    }
  }, [dispatch, processingClusterAmMapping, navigate]);

  useEffect(() => {
    if (status === "loading") {
      message.loading({ content: "Saving Cluster FA mapping", key: MESSAGE_KEY });
    } else if (status === "success") {
      message.success({
        content: "Cluster FA mapping saved!",
        duration: 5,
        key: MESSAGE_KEY,
      });
    } else if (status === "cancelling") {
      message.loading({ content: "Cancelling Cluster FA mapping", key: MESSAGE_KEY });
    } else if (status === "error") {
      message.error({ content: error, key: MESSAGE_KEY });
    }

    return () => {
      message.destroy();
    };
  }, [status, error]);

  let content;
  const { headerMapping } = processingClusterAmMapping;
  const { Text } = Typography;

  const generateMemoizedHeaders = useMemo(
    () => generateHeaders(headerMapping),
    [headerMapping]
  );

  const onSaveUserMapping = () => {
    dispatch(saveClusterAmMapping());
  };

  const onCancelSave = () => {
    dispatch(cancelClusterAmMapping());
  };

  if (!Object.keys(processingClusterAmMapping).length) {
    content = <Text>Nothing to show here!</Text>;
  } else {
    const headers = generateMemoizedHeaders || {};
    const successRows = processingClusterAmMapping.successRows;
    const data = successRows?.map((row, i) => ({ ...row, key: `data-${i}` }));
    const columns = [
      ...Object.keys(headers).map(
        (key) =>
          ({
            key,
            title: headers[key],
            dataIndex: key,
          } as any)
      ),
    ];
    content = (
      <Table
        size="small"
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{ y: 500 }}
        summary={() => (
          <Table.Summary fixed="bottom">
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={2}>
                <Row>
                  <Col>
                    <Button
                      type="primary"
                      onClick={onSaveUserMapping}
                      loading={status === "loading"}
                      disabled={status === "loading"}
                    >
                      Save Mapping
                    </Button>
                  </Col>
                  <Col style={{ justifyContent: "left", marginLeft: "20px" }}>
                    <Button
                      onClick={onCancelSave}
                      disabled={status === "loading"}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      ></Table>
    );
  }

  return (
    <>
      <Row>
        <Col>
          <AppPageHeader title="Save Cluster FA Mapping" />
        </Col>
      </Row>
      <Row>
        <Col xs={24}>{content}</Col>
      </Row>
    </>
  );
};
