import {
    Avatar,
    Breadcrumb,
    Col,
    Descriptions,
    message,
    Row,
    Select,
    Table,
    Image,
  } from "antd";
  import { ColumnsType } from "antd/lib/table";
import Column from "antd/lib/table/Column";
  import { IPpdDeliveryOrders } from "common/interface/IPpdDeliveryOrders";
import { fallBackImage } from "common/styles";
import { getDateFromISODate } from "common/utils";
  import { variables } from "common/variables";
  import { AppPageHeader } from "components/AppPageHeader";
  import { useAppDispatch } from "hooks/useAppDispatch";
  import { useAppSelector } from "hooks/useAppSelector";
  import { useDocumentTitle } from "hooks/usePageTitle";
  import { useEffect, useState } from "react";
  import { NavLink, useNavigate, useParams } from "react-router-dom";
  import { getPpdDeliveryOrderDetails } from "store/ppd/ppdSlice";
  import * as process from "process";

  const MESSAGE_KEY = "ppd_order_info";
  type PartialPPDItemType = Partial<IPpdDeliveryOrders> & { key: string };
  
  const { Option } = Select;
  
  const DeliveryItemViewPage = () => {
    useDocumentTitle("PPD Delivery Order ");
    const dispatch = useAppDispatch();
    const { error, status } = useAppSelector((state) => state.ppd);
    const { id } = useParams();
    const [tableColumns, setTableColumns] =
      useState<ColumnsType<PartialPPDItemType>>();
    const [tableRows, setTableRows] = useState<any[]>();
    const [viewedOrder, setViewedOrder] = useState<IPpdDeliveryOrders>();
    const [tempTableRows, setTempTableRows] = useState<any[]>();
    const navigate = useNavigate();
  
    useEffect(() => {
      if (id) {
        updatePpd();
      }
    }, [id, dispatch]);

    const updatePpd = async () => {
      if (id !== undefined) {
        let temp = await dispatch(getPpdDeliveryOrderDetails(id));
        setViewedOrder(temp.payload as IPpdDeliveryOrders);
      }
    }
  
    useEffect(() => {
      if (status === "loading") {
        message.loading({ content: "loading ppd order", key: MESSAGE_KEY });
      }
  
      return () => {
        message.destroy();
      };
    }, [status, error]);

    useEffect(() => {
  
      const headerKeys: { [key: string]: string } = {
        // orderItemId: "Order Item ID",
        skuCode: "SKU Code",
        productName: "Product Name",
        uom: "UOM",
        orderedQty: "Ordered Qty",
        finalQty: "Final Qty",
      };
  
      if (viewedOrder) {
        const columns = [
          ...Object.keys(headerKeys).map((key) => ({
            key,
            title: headerKeys[key],
            dataIndex: key,
            editable: key !== "actions" && true,
            render: (text: string,) => {
              return <>{text}</>;
            },
          })),
        ];
  
        const mergedColumns = columns.map((col, i) => {
          return col;
        });
  
        const rows: any[] =
        viewedOrder?.ppdOrder.orderItems
            ?.map((item) => ({
              orderItemId: item.orderItemId,
              orderedQty: item.orderedQty,
              uom: item.uom,
              productName: item.productName,
              skuCode: item.skuCode,
              finalQty: item.finalQty,
            })) || [];
        setTableRows([...rows, ...(tempTableRows || [])]);
        setTableColumns(mergedColumns);
      }
    }, [
      tempTableRows,
      viewedOrder,
      viewedOrder?.id,
    ]);
  
  
    const poInfo = viewedOrder ? (
      <>
        <Descriptions
          size="small"
          layout="vertical"
          labelStyle={{ fontWeight: "bold" }}
          bordered
          column={4}
        >
          <Descriptions.Item label="Display Order ID">
            {viewedOrder?.ppdOrder?.metadata?.displayOrderId}
          </Descriptions.Item>
          <Descriptions.Item label="Delivery User">{`${viewedOrder.deliveryUserName} [${viewedOrder.deliveryUserPhone}]`}</Descriptions.Item>
          <Descriptions.Item label="Status">{viewedOrder.ppdOrder.status}</Descriptions.Item>
        </Descriptions>  

        <Descriptions
          size="small"
          layout="vertical"
          labelStyle={{ fontWeight: "bold" }}
          bordered
          column={2}
          style={{ marginTop: "20px" }}
        >
          <Descriptions.Item label="Delivery Date">{getDateFromISODate(viewedOrder.deliveryDate)}</Descriptions.Item>
          <Descriptions.Item label="Delivery Images"><Row align="middle" gutter={10}>
          {viewedOrder.metadata?.deliveryImages?.map(image=><Col>
                                        <Avatar
                                        src={
                                            <Image
                                                src={`${image}`}
                                                fallback={fallBackImage}
                                                alt={`${image}`}
                                                width="100%"
                                            />
                                        }
                                    ></Avatar></Col>)}
                                {/*<Col>{text}</Col>*/}
                            </Row></Descriptions.Item>

        </Descriptions>
  
        {!!(tableRows && tableRows.length) && (
          <Row style={{ marginTop: "20px" }}>
            <Col xs={24}>
              <Table
                className="po-item-table"
                bordered
                dataSource={tableRows}
                columns={tableColumns}
                rowClassName={(record, index) =>
                  index === tableRows.length - 1
                    ? "editable-last-row editable-row"
                    : "editable-row"
                }
                pagination={false}
                scroll={{ x: "100vh", y: "65vh" }}
                style={{ width: "100%" }}
                size="small"
              />
            </Col>
          </Row>
        )}
      </>) : (<>Loading</>
    );
  
    return (
      <>
        <Row
          style={{
            margin: "1rem 0",
            position: "sticky",
            top: 0,
            zIndex: 1,
            background: variables.wmBackgroundColorLight1,
            padding: "5px",
          }}
        >
          <Col xs={24}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <NavLink to="/ppd/delivery/orders">PPD Delivery Orders</NavLink>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                {viewedOrder?.ppdOrderId || "PPD Order Id"}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row>
          <Col>
            <AppPageHeader title="PPD Order" />
          </Col>
        </Row>
        <Row>
          <Col xs={24}>{poInfo}</Col>
        </Row>
      </>
    );
  };
  
  export default DeliveryItemViewPage;
  