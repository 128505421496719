import styled, { CSSProperties } from "styled-components";
import { Layout } from "antd";
import { Grid } from "antd";
import { ComponentWithPropsChildren } from "common/interface";

const StyledLayout = styled(Layout)`
  overflow: hidden;
  height: 100vh;
`;

const { useBreakpoint } = Grid;

interface AppLayoutProps extends ComponentWithPropsChildren {
  style?: CSSProperties;
}

export const AppLayout = (props: AppLayoutProps) => {
  const screens = useBreakpoint();

  if (!Object.keys(screens)) {
    return <></>;
  }

  return <StyledLayout>{props.children}</StyledLayout>;
};
