import { IPickerMappingUpload } from "common/interface/IPickerUploadScheme";

const STORAGE_KEY_STORE_RETURN = "processingStoreExchange";
const STORAGE_KEY_HOLD_EXCHANGE = "processingHoldExchange";
const STORAGE_KEY_INTERNAL_STORE_RETURN = "processingInternalStoreExchange";

export const persistProcessingHoldAdjustment = (data: IPickerMappingUpload) => {
  localStorage.setItem(STORAGE_KEY_HOLD_EXCHANGE, JSON.stringify(data));
}

export const getProcessingHoldAdjustment = (): IPickerMappingUpload => {
  const processingMapping = localStorage.getItem(STORAGE_KEY_HOLD_EXCHANGE);
  return processingMapping ? JSON.parse(processingMapping) : {};
}

export const removeProcessingHoldAdjustment = () => {
  localStorage.removeItem(STORAGE_KEY_HOLD_EXCHANGE);
}

export const persistProcessingStoreExchange = (data: IPickerMappingUpload) => {
  localStorage.setItem(STORAGE_KEY_STORE_RETURN, JSON.stringify(data));
}

export const getProcessingStoreExchange = (): IPickerMappingUpload => {
  const processingWhReturnMapping = localStorage.getItem(STORAGE_KEY_STORE_RETURN);
  return processingWhReturnMapping ? JSON.parse(processingWhReturnMapping) : {};
}

export const removeProcessingStoreExchange = () => {
  localStorage.removeItem(STORAGE_KEY_STORE_RETURN);
}

export const persistProcessingInternalStoreExchange = (data: IPickerMappingUpload) => {
  localStorage.setItem(STORAGE_KEY_INTERNAL_STORE_RETURN, JSON.stringify(data));
}

export const getProcessingInternalStoreExchange = (): IPickerMappingUpload => {
  const processingWhReturnMapping = localStorage.getItem(STORAGE_KEY_INTERNAL_STORE_RETURN);
  return processingWhReturnMapping ? JSON.parse(processingWhReturnMapping) : {};
}

export const removeProcessingInternalStoreExchange = () => {
  localStorage.removeItem(STORAGE_KEY_INTERNAL_STORE_RETURN);
}