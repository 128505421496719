import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API, { apis } from "common/apis";
import { IApiError } from "common/interface/IApiError";
import { IStoreReturnApi, IStoreReturnRequestInfo, IStoreReturnsNew, StoreInventoryUpdateDataItem, StoreReturnDetails, StoreReturnItemDataList } from "common/interface/IStoreReturn";
import { logoutIfNeeded } from "common/utils";
import { getWarehouseId } from "services/PersistService/PersistWareHouseReturn";

export interface State {
  allStoreReturns?: IStoreReturnApi;
  allSRs?: IStoreReturnsNew[];
  allStoreReturnDetails?: StoreReturnDetails[],
  qaOptions: String[],
  fullSrReturnOptions: Record<string, string>,
  status: "loading" | "success" | "error" | "idle" | "cancelling" | "saving";
  error?: string;
}

const initialState: State = {
  status: "idle",
  qaOptions: [],
  fullSrReturnOptions: {},
};

export const getAllStoreReturns = createAsyncThunk<
  IStoreReturnApi,
  { pageNo: number; pageSize: number; filter: string }
>("store-return/all", async ({ pageNo = 1, filter, pageSize = 25 }, thunkApi) => {

  const filterParams = filter
    ? Object.fromEntries(new URLSearchParams(filter))
    : "";
  const response = await API.get(apis.allStoreReturnList, {
    params: {
      pageNo,
      pageSize,
      ...filterParams,
    },
  })
    .then((success) => success.data)
    .catch((error) => {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});


export const getAllCreateRequests = createAsyncThunk<
  IStoreReturnsNew[],
  { pageNo: number; pageSize: number; filter: string }
>("store-return/requests/all", async ({ pageNo = 1, filter, pageSize = 25 }, thunkApi) => {
  const filterParams = filter
    ? Object.fromEntries(new URLSearchParams(filter))
    : "";

  const response = await API.get(apis.getStoreReturns, {
    params: {
      pageNo,
      pageSize,
      ...filterParams,
    },
  })
    .then((success) => success.data)
    .catch((error) => {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

export const getStoreReturnDetails = createAsyncThunk<StoreReturnDetails, string>(
  "store-return/get",
  async (srId, thunkApi) => {

    const response = await API.get(`${apis.storeReturn}/${srId}`)
      .then((success) => success.data as StoreReturnDetails)
      .catch((error) => {
        const errorMessage =
          error?.response?.data?.message || "Something went wrong!";
        logoutIfNeeded(error, thunkApi);
        return thunkApi.rejectWithValue({ message: errorMessage });
      });

    return response;
  }
);

export const getQaOptions = createAsyncThunk<String[]>(
  "store-return/qaOptions/get",
  async (_, thunkApi) => {

    const response = await API.get(`${apis.qaOptions}`)
      .then((success) => success.data["qaResult"])
      .catch((error) => {
        const errorMessage =
          error?.response?.data?.message || "Something went wrong!";
        logoutIfNeeded(error, thunkApi);
        return thunkApi.rejectWithValue({ message: errorMessage });
      });

    return response;
  }
);

export const getFullSrReturnOptions = createAsyncThunk<Record<string, string>>(
  "store-return/full-sr-return/options",
  async (_, thunkApi) => {
    const response = await API.get(`${apis.fullSrReturnOptions}`)
      .then((success) => success.data)
      .catch((error) => {
        const errorMessage =
          error?.response?.data?.message || "Something went wrong!";
        logoutIfNeeded(error, thunkApi);
        return thunkApi.rejectWithValue({ message: errorMessage });
      });

    return response;
  }
);

export const createFullSrReturn = createAsyncThunk<StoreReturnDetails, {ticketCategoryLabel?: string, returnDate: string, srId: string, remarks?: string, isFullSrReturn: boolean}>(
  "store-return/full-sr-return/create",
  async ({ticketCategoryLabel, returnDate, srId, remarks, isFullSrReturn}, thunkApi) => {
    const response = await API.post(
      `${apis.createFullSrReturn}`,
      {
        ticketCategoryLabel,
        returnDate,
        srId,
        remarks,
        "isFullSrReturn": isFullSrReturn ? 1 : 0,
      },
    )
      .then((success) => success.data)
      .catch((error) => {
        const errorMessage =
          error?.response?.data?.message || "Something went wrong!";
        logoutIfNeeded(error, thunkApi);
        return thunkApi.rejectWithValue({ message: errorMessage });
      });

    return response;
  }
);

export const getCreateStoreReturnDetails = createAsyncThunk<IStoreReturnRequestInfo, string>(
  "store-return/create/get",
  async (srId, thunkApi) => {
    const response = await API.get(`${apis.getStoreReturnRequestInfo}/${srId}`)
      .then((success) => success.data as IStoreReturnRequestInfo)
      .catch((error) => {
        const errorMessage =
          error?.response?.data?.message || "Something went wrong!";
        logoutIfNeeded(error, thunkApi);
        return thunkApi.rejectWithValue({ message: errorMessage });
      });

    return response;
  }
);

export const saveSrItem = createAsyncThunk<
  StoreReturnDetails,
  {
    srId: string;
    srItem: StoreInventoryUpdateDataItem;
  }
>("store-return/save-item", async (payload, thunkApi) => {
  const response = await API.post(
    `${apis.storeReturn}/${payload.srId}/saveItem`,
    { ...payload.srItem }
  )
    .then((success) => success.data)
    .catch((error) => {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

export const saveCreateSrItem = createAsyncThunk<
  any,
  {
    srId: string;
    srItem: any;
  }
>("store-return/create/save-item", async (payload, thunkApi) => {
  let returnDate = new Date();

  const response = await API.post(
    `${apis.storeReturn}/create`,
    {
      "qaResult": payload.srItem.qaResult,
      "quantity": payload.srItem.receivedQuantity,
      "refundQty": payload.srItem.refundQuantity,
      "remarks": payload.srItem.remarks,
      "returnDate": returnDate.toISOString().split("T")[0],
      "sku_code": payload.srItem.skuId,
      "srId": payload.srId,
      "attachments": payload.srItem.attachments,
      "pickedQty": payload.srItem.pickedQty,
      "pickerRemarks": payload.srItem.pickerRemarks,
    }
  )
    .then((success) => success.data)
    .catch((error) => {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

export const createSrItem = createAsyncThunk<
  any,
  {
    srItemId: string;
    formData: any;
  }
>("store-return/create/new", async (payload, thunkApi) => {
  let returnDate = new Date();
  const response = await API.post(
    `${apis.storeReturn}/create`,
    {
      srId: payload.srItemId,
      returnDate: returnDate.toISOString().split("T")[0],
      items: payload.formData.map((e: any) => ({
        ...e,
        quantity: e["receivedQuantity"],
        skuCode: e["skuId"],
      })),
    }
  )
    .then((success) => success.data)
    .catch((error) => {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

export const deleteSrItem = createAsyncThunk<
  StoreReturnDetails,
  {
    srId: string;
    srItem: StoreInventoryUpdateDataItem;
  }
>("store-return/delete-item", async (payload, thunkApi) => {
  const response = await API.post(
    `${apis.storeReturn}/${payload.srId}/deleteItem`,
    { ...payload.srItem }
  )
    .then((success) => success.data)
    .catch((error) => {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});


export const saveSrDraft = createAsyncThunk<StoreReturnDetails, Partial<StoreReturnDetails> & { whId: number }>(
  "store-return/draft",
  async (payload, thunkApi) => {
    const newPoItems =
      payload?.storeReturnItemDataList?.map((srItem) => {
        const newPoItem = { ...srItem };
        return newPoItem;
      }) ?? [];
    const newPayload = {
      storeInventoryUpdateDataList: newPoItems.map((poI) => ({
        quantity: poI.quantity,
        sku_code: poI.sku_code,
        id: poI.id,
      })),
      returnDate: "2023-01-09T09:53:53.559Z",
      storeId: payload.storeId,
      whId: payload.whId,
    };
    const response = await API.post(
      `${apis.saveStoreReturnDraft}`,
      newPayload
    )
      .then((success) => success.data)
      .catch((error) => {
        const errorMessage =
          error?.response?.data?.message || "Something went wrong!";
        logoutIfNeeded(error, thunkApi);
        return thunkApi.rejectWithValue({ message: errorMessage });
      });

    return response;
  }
);

export const receiveStoreReturn = createAsyncThunk<StoreReturnDetails, any, {}>(
  "store-return/receive",
  async (payload, thunkApi) => {
    const response = await API.post(
      `${apis.storeReturn}/${payload.id}/receive`,
    )
      .then((success) => success.data)
      .catch((error) => {
        if (error?.response?.data?.id != undefined ) {
          return thunkApi.rejectWithValue(error?.response?.data);
        }else {
        const errorMessage =
          error?.response?.data?.message || "Something went wrong!";
        logoutIfNeeded(error, thunkApi);
        return thunkApi.rejectWithValue({ message: errorMessage });}
      });

    return response;
  }
);

const storeReturnSlice = createSlice({
  name: "store-return",
  initialState,
  reducers: {
    resetRequestsList: (state) => {
      state.status = "idle";
      state.error = "";
      state.allSRs = [];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllStoreReturns.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllStoreReturns.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = "";
        state.allStoreReturns = action.payload;
      })
      .addCase(getAllStoreReturns.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })
      .addCase(getAllCreateRequests.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllCreateRequests.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = "";
        state.allSRs = action.payload;
      })
      .addCase(getAllCreateRequests.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })
      .addCase(createSrItem.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createSrItem.fulfilled, (state) => {
        state.status = "idle";
        state.error = "";
      })
      .addCase(createSrItem.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error['message']?.toString()?.length !== 0) {
          state.error = error.message;
        }
      })

      .addCase(getStoreReturnDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getStoreReturnDetails.fulfilled, (state, action) => {
        if (state.allStoreReturnDetails) {
          const presentData = state.allStoreReturnDetails!;
          const srInStore = presentData.findIndex(
            (sr) => sr.id === action.payload.id
          );

          if (srInStore > -1) {
            const newSRs = [...state.allStoreReturnDetails];
            newSRs[srInStore] = {
              ...newSRs[srInStore],
              ...action.payload,
            };
            state.allStoreReturnDetails = newSRs;
          } else if (presentData) {
            presentData.push(action.payload);
          } else {
            state.allStoreReturnDetails = [action.payload];
          }
        }
        else {
          state.allStoreReturnDetails = [action.payload];
        }


        state.status = "idle";
        state.error = "";
      })
      .addCase(getStoreReturnDetails.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })
      
      .addCase(getQaOptions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getQaOptions.fulfilled, (state, action) => {
        state.qaOptions = action.payload.map(e => e.trim());
        state.status = "idle";
        state.error = "";
      })
      .addCase(getQaOptions.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";
        if (error.message) {
          state.error = error.message;
        }
      })
      
      .addCase(getFullSrReturnOptions.pending, (state) => {
        // state.status = "loading";
      })
      .addCase(getFullSrReturnOptions.fulfilled, (state, action) => {
        state.fullSrReturnOptions = action.payload;
        state.status = "idle";
        state.error = "";
      })
      .addCase(getFullSrReturnOptions.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";
        if (error.message) {
          state.error = error.message;
        }
      })

      .addCase(createFullSrReturn.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createFullSrReturn.fulfilled, (state, action) => {
        if (state.allStoreReturnDetails) {
          const presentData = state.allStoreReturnDetails!;
          const srInStore = presentData.findIndex(
            (sr) => sr.id === action.payload.id
          );

          if (srInStore > -1) {
            const newSRs = [...state.allStoreReturnDetails];
            newSRs[srInStore] = {
              ...newSRs[srInStore],
              ...action.payload,
            };
            state.allStoreReturnDetails = newSRs;
          } else if (presentData) {
            presentData.push(action.payload);
          } else {
            state.allStoreReturnDetails = [action.payload];
          }
        }
        else {
          state.allStoreReturnDetails = [action.payload];
        }

        state.status = "idle";
        state.error = "";
      })
      .addCase(createFullSrReturn.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";
        if (error.message) {
          state.error = error.message;
        }
      })


      .addCase(getCreateStoreReturnDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCreateStoreReturnDetails.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = "";
      })
      .addCase(getCreateStoreReturnDetails.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })

      // .addCase(saveProcessingGRN.pending, (state) => {
      //   state.error = "";
      //   state.status = "loading";
      // })
      // .addCase(saveProcessingGRN.fulfilled, (state) => {
      //   state.status = "success";
      //   state.error = "";
      //   state.processingGrn = {};
      //   removePersistedProcessingPO();
      // })
      // .addCase(saveProcessingGRN.rejected, (state, action) => {
      //   const error = action.payload as IApiError;
      //   state.status = "error";

      //   if (error.message) {
      //     state.error = error.message;
      //   }
      // })
      // /**
      //  * CANCEL PROCESSING PO
      //  */
      // .addCase(cancelProcessingGRN.pending, (state) => {
      //   state.status = "cancelling";
      // })
      // .addCase(cancelProcessingGRN.fulfilled, (state) => {
      //   state.status = "idle";
      //   state.error = "";
      //   state.processingGrn = {};
      //   removePersistedProcessingPO();
      // })
      // .addCase(cancelProcessingGRN.rejected, (state, action) => {
      //   const error = action.payload as IApiError;
      //   state.status = "error";

      //   if (error.message) {
      //     state.error = error.message;
      //   }
      // })

      /**
       * SAVE PO ITEM
       */
      .addCase(saveSrItem.pending, (state) => {
        state.status = "saving";
      })
      .addCase(saveSrItem.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = "";
        const { id, storeReturnItemDataList: storeInventoryUpdateDataList } = action.payload;
        const pos = state.allStoreReturnDetails;
        const thisPoIdx = pos?.findIndex((p) => p.id === id) ?? -1;
        if (thisPoIdx > -1 && pos) {
          const thisPo = pos[thisPoIdx];
          thisPo.storeReturnItemDataList = storeInventoryUpdateDataList;
          pos[thisPoIdx] = thisPo;
        }
      })
      .addCase(saveSrItem.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })
      /**
       * DELETE PO ITEM
       */
      .addCase(deleteSrItem.pending, (state) => {
        state.status = "saving";
      })
      .addCase(deleteSrItem.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = "";
        const { id, storeReturnItemDataList: storeInventoryUpdateDataList } = action.payload;
        const pos = state.allStoreReturnDetails;
        const thisPoIdx = pos?.findIndex((p) => p.id === id) ?? -1;
        if (thisPoIdx > -1 && pos) {
          const thisPo = pos[thisPoIdx];
          thisPo.storeReturnItemDataList = storeInventoryUpdateDataList;
          pos[thisPoIdx] = thisPo;
        }
      })
      .addCase(deleteSrItem.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })

      // /**
      //  * SAVE SR DRAFT
      //  */
      .addCase(saveSrDraft.pending, (state) => {
        state.status = "saving";
      })
      .addCase(saveSrDraft.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = "";
        const { payload } = action;
        const pos = state.allStoreReturnDetails;
        const thisPoIdx = pos?.findIndex((p) => p.id === payload.id) ?? -1;
        if (thisPoIdx > -1 && pos) {
          pos[thisPoIdx] = payload;
        }
      })
      .addCase(saveSrDraft.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";
        if (error.message) {
          state.error = error.message;
        }
      })

      // /**
      //  * RECEIVE SR
      //  */
      .addCase(receiveStoreReturn.pending, (state) => {
        state.status = "saving";
      })
      .addCase(receiveStoreReturn.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = "";
        const { payload } = action;
        const pos = state.allStoreReturnDetails;
        const thisPoIdx = pos?.findIndex((p) => p.id === payload.id) ?? -1;
        if (thisPoIdx > -1 && pos) {
          pos[thisPoIdx] = payload;
        }
      })
      .addCase(receiveStoreReturn.rejected, (state, action) => {
        let temp = action.payload as StoreReturnDetails;
        
        if (temp.error?.message != undefined) {
          state.status = "error";
          state.error = temp.error?.message;
          const pos = state.allStoreReturnDetails;
          const thisPoIdx = pos?.findIndex((p) => p.id === temp.id) ?? -1;
          if (thisPoIdx > -1 && pos) {
            pos[thisPoIdx] = temp;
          }
        }
        else {
          const error = action.payload as IApiError;
          state.status = "error";

          if (error.message) {
            state.error = error.message;
          }
        }
      })
  },
});

export const { resetRequestsList } = storeReturnSlice.actions;
export default storeReturnSlice.reducer;
