import { LoadingOutlined } from "@ant-design/icons";
import { variables } from "common/variables";

export const AppLoader = () => (
  <div
    style={{
      width: "100vw",
      height: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <LoadingOutlined
      style={{ fontSize: "30px", color: variables?.primaryColor }}
    />
  </div>
);
