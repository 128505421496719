import { Row, Col, message, Table, Pagination, Button, Popconfirm, Select, Form } from "antd";
import { ColumnsType } from "antd/lib/table";
import { AppPageHeader } from "components/AppPageHeader";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useDocumentTitle } from "hooks/usePageTitle";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Link, NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { AppActionStrip } from "components/AppActionStrip/AppActionStrip";
import { AppFilterButton, AppFilter } from "components/AppFilter";
import { IPpdOrders } from "../../common/interface/IPpdOrders";
import { AppPpdOrderFilter } from "../../components/AppFilter/AppPpdOrderFilter/AppPpdOrderFilter";
import { routes } from "common/routes";
import { cancelPpdOrder, getPpdOrders } from "store/ppd/ppdSlice";
import { useForm } from "antd/lib/form/Form";

type PartialPpdOrderType = Partial<IPpdOrders> & { key: string };
const MESSAGE_KEY = "all_ppd_orders";

const headerKeys: { [key: string]: string } = {
    // id: "Id",
    displayOrderId: "Display OrderId",
    deliveryDate: "Delivery Date",
    // orderId: "OrderId",
    orderItems: "Order Items",
    zone: "Zone",
    route: "Route",
    pps: "PPS",
    sequenceNo: "Sequence",
    status: "Status",
    actions: "Actions"
};

const StyledPagination = styled(Pagination)`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

const StyledActionLink = styled(NavLink)`
  border: 1px solid;
  padding: 0.2rem;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const OrdersPage = () => {
    useDocumentTitle("PPD Orders");
    const dispatch = useAppDispatch();
    const [columns, setColumns] = useState<ColumnsType<PartialPpdOrderType>>();
    const [rows, setRows] = useState<PartialPpdOrderType[]>();
    const { ppdOrders, status, error } = useAppSelector((state) => state.ppd);
    const [filterState, setFilterState] = useState(false);
    const [searchParams] = useSearchParams();
    const [filterCount, setFilterCount] = useState(0);
    const [form] = useForm();

    const getPpdOrdersData = useCallback(
        (pageNum = 1, pageSize=25, filter = "") => {
            dispatch(getPpdOrders({ pageNo: pageNum, pageSize, filter }));
        },
        [dispatch]
    );

    useEffect(() => {
        const filter = searchParams.toString();
        setFilterCount(
            Object.keys(Object.fromEntries(new URLSearchParams(filter))).length
        );
        getPpdOrdersData(1, 25,filter);
    }, [dispatch, getPpdOrdersData, searchParams]);

    useEffect(() => {
        if (status === "loading") {
            message.loading({ content: "Loading PPD Orders", key: MESSAGE_KEY });
        } else if (status === "success") {
            // message.success({
            //   content: "Admin Users loaded!",
            //   key: MESSAGE_KEY,
            // });
        } else if (status === "error") {
            message.error({ content: error, key: MESSAGE_KEY });
        }
        else if (status === "saving") {
            message.loading({content: "Updating PPD Order", key: MESSAGE_KEY})
        }

        return () => {
            message.destroy();
        };
    }, [status, error]);

    useEffect(() => {
        const ppdOrdersData = ppdOrders?.data ? ppdOrders.data : [];
        const data: PartialPpdOrderType[] = ppdOrdersData.map((au, i) => {
            const {
                id,
                metadata,
                deliveryDate,
                orderId,
                orderItems,
                zone,
                batchId: route,
                pps,
                sequenceNo,
                status,
            } = au;

            const newRow = {
                id,
                displayOrderId: {metadata},
                deliveryDate,
                orderId,
                orderItems,
                zone,
                route,
                pps,
                sequenceNo,
                status,
            };
            return { ...newRow, key: `ppdOrders-${i}`, ellipses: true };
        });

        const tableHeaders: ColumnsType<PartialPpdOrderType> = [
            ...Object.keys(headerKeys).map((key, idx) => ({
                key,
                title: headerKeys[key],
                dataIndex: key,
                render: (text: string, record: PartialPpdOrderType) => {
                    if (key === "orderItems") {
                        return (
                            <>
                                {record.orderItems?.length}
                            </>
                        );
                    }

                    if (key === "actions") {
                        const fieldName = `cancellation-${idx}`;
                        return (
                            <Row
                                align="middle"
                                style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                }}
                            >
                                <Col style={{ marginRight: ".5rem" }}>
                                    <Button size="small">
                                        <Link to={`/${routes.PPD.ORDERS}/${record.orderId}`}>
                                            View
                                        </Link>
                                    </Button>
                                </Col>
                                <Col>
                                    <Popconfirm
                                        title={
                                            <>
                                                <p>Are you sure you want to cancel?</p>
                                                <Form.Item name={fieldName}>
                                                    <Select
                                                    placeholder={"Select Cancellation Reason"}
                                                        options={[
                                                            {
                                                                value: "ORDER_CANCELLED_BY_STORE",
                                                                label: 'Order Cancelled By Store',
                                                            }, {
                                                                value: "ORDER_CANCELLED_BY_CUSTOMER",
                                                                label: 'Order Cancelled By Customer',
                                                            },
                                                        ]}
                                                        onChange={(nv) => {
                                                            form.setFieldsValue({[`cancellation-${idx}`]: nv});
                                                        }
                                                        }
                                                        value={form.getFieldValue(fieldName)}
                                                    />
                                                </Form.Item>
                                            </>
                                        }
                                        placement="topRight"
                                        // defaultOpen={false}
                                        disabled={["ORDER_DELIVERED", "ORDER_CANCELLED_BY_STORE", "ORDER_CANCELLED_BY_CUSTOMER"].includes(record.status ?? "")}
                                        onConfirm={async () => {
                                            if (form.getFieldValue(fieldName) != undefined) {
                                                await dispatch(cancelPpdOrder({ orderId: record.orderId, status: form.getFieldValue(fieldName) }));
                                            }
                                            else {
                                                
                                            }
                                        }
                                        }
                                    onCancel={() => form.setFieldsValue({[`cancellation-${idx}`]: undefined})}
                                    // onOpenChange={(open) => {
                                    //     if (!open) {
                                    //         form.setFieldsValue({[`cancellation-${idx}`]: undefined});
                                    //     }
                                    // }}
                                    // okButtonProps={{ loading: record.dispatching }} 
                                    >
                                        <Button
                                            type="primary"
                                            size="small"
                                            ghost
                                            disabled={["ORDER_DELIVERED", "ORDER_CANCELLED_BY_STORE", "ORDER_CANCELLED_BY_CUSTOMER"].includes(record.status ?? "")}
                                        >
                                            Cancel
                                        </Button>
                                    </Popconfirm>
                                </Col>
                            </Row>
                        );
                    }
                    return <>{text}</>;
                },
            })),
        ];

        setColumns(tableHeaders);
        setRows(data);
    }, [ppdOrders]);

    const Paginator = () => {
        if (ppdOrders) {
            const { pageNo, pageSize, total } = ppdOrders;
            return (
                <StyledPagination
                    current={pageNo}
                    pageSize={pageSize}
                    total={total}
                    onChange={(page, size) => {
                        const filter = searchParams.toString();
                        setFilterCount(
                            Object.keys(Object.fromEntries(new URLSearchParams(filter))).length
                        );
                        getPpdOrdersData(page, size, filter);
                    }}
                />
            );
        }

        return <></>;
    };

    return (
        <>
            <Row>
                <Col>
                    <AppPageHeader title="All PPD Orders" />
                </Col>
            </Row>
            <Row>
                <AppActionStrip>
                    <div className="action-strip__item">
                        <AppFilterButton
                            active={filterState}
                            onClick={() => setFilterState(!filterState)}
                            filterCount={filterCount}
                        />
                    </div>
                </AppActionStrip>
            </Row>
            <Row style={{ marginBottom: "1rem" }}>
                <AppFilter expanded={filterState}>
                    <AppPpdOrderFilter />
                </AppFilter>
            </Row>
            <Form form={form}>
                {rows && rows.length ? (
                    <Row>
                        <Col xs={24}>
                            <Table
                                columns={columns}
                                dataSource={rows.filter(e => e.id !== -1)}
                                scroll={{ x: "100vh", y: "65vh" }}
                                size="small"
                                pagination={false}
                                loading={status === "loading"}
                                bordered
                            />
                        </Col>
                        <Col xs={24}>
                            <Paginator />
                        </Col>
                    </Row>
                ) : (
                    status !== "loading" && <>No PPD order to show.</>
                )}
            </Form>
        </>
    );
};

export default OrdersPage;