import { Row, Col, message, Table, Pagination, Button } from "antd";
import { ColumnsType } from "antd/lib/table";
import { AppPageHeader } from "components/AppPageHeader";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useDocumentTitle } from "hooks/usePageTitle";
import { useCallback, useEffect, useState } from "react";
import { GrView } from "react-icons/gr";
import styled from "styled-components";
import { NavLink, useSearchParams } from "react-router-dom";
import { AppActionStrip } from "components/AppActionStrip/AppActionStrip";
import { AppFilterButton, AppFilter } from "components/AppFilter";
import { fetchSplitSku, SplitSku } from "store/split-sku/splitSkuSlice";
import { fetchStoresList } from "store/store-return/storeReturnUploadSlice";
import { BsPlus } from "react-icons/bs";
import { AppSplitSkuExchange } from "components/AppFilter/AppSplitSkuFilter";

type PartialDataType = Partial<SplitSku> & { key: string };
const MESSAGE_KEY = "all_pos";

const headerKeys: { [key: string]: string } = {
  // id: "PO ID",
  comboSkuCode: "Combo SKU",
  whId: "Warehouse ID",
  storeIds: "Store ID",
  createdOn: "Created On",
  modifiedOn: "Modified On",
  actions: "Actions",
};

const StyledActionLink = styled(NavLink)`
  border: 1px solid;
  padding: 0.2rem;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AllSplitSkusPage = () => {
  useDocumentTitle("All Purchase Orders");
  const dispatch = useAppDispatch();
  const { masterSkus = [] } = useAppSelector((state) => state.requisition);
  const [columns, setColumns] = useState<ColumnsType<PartialDataType>>();
  const [rows, setRows] = useState<PartialDataType[]>();
  const { splitSkuListing, status, error } = useAppSelector((state) => state.splitSkuSlice);
  const [filterState, setFilterState] = useState(false);
  const [searchParams] = useSearchParams();
  const [filterCount, setFilterCount] = useState(0);

  const formatDate = (dateString: string) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    }).format(date);
  };


  const getSkuData = useCallback(
    (filter = "") => {
      dispatch(fetchSplitSku({ filter, }));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(fetchStoresList(""));
    const filter = searchParams.toString();
    setFilterCount(
      Object.keys(Object.fromEntries(new URLSearchParams(filter))).length
    );
    getSkuData(filter);
  }, [dispatch, getSkuData, searchParams, filterState]);

  useEffect(() => {
    if (status === "loading") {
      message.loading({ content: "Loading Split SKUs", key: MESSAGE_KEY });
    } else if (status === "success") {

    } else if (status === "error") {
      message.error({ content: error, key: MESSAGE_KEY });
    }

    return () => {
      message.destroy();
    };
  }, [status, error]);

  useEffect(() => {
    const listingData = splitSkuListing ? splitSkuListing : [];
    const data: PartialDataType[] = listingData.map((splitSku, i) => {
      
      const newRow = {
        ...splitSku,
      };
      return { ...newRow, key: `po-${i}`, ellipses: true };
    });

    const tableHeaders: ColumnsType<PartialDataType> = [
      ...Object.keys(headerKeys).map((key) => ({
        key,
        title: headerKeys[key],
        dataIndex: key,
        render: (text: string, record: PartialDataType) => {

          if (key === "createdOn" || key === "modifiedOn") {
            return (
              <Row
                align="middle"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <Col>
                  {formatDate(text)}
                </Col>

              </Row>
            );
          }
          
          if (key === "storeIds") {
            console.log(record);
            
            return (
              <Row
                align="middle"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <Col>
                  {record.storeIds?.join(", ")}
                </Col>

              </Row>
            );
          }



          if (key === "actions") {
            return (
              <Row
                align="middle"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <Col>
                  <StyledActionLink to={`${record.comboSkuCode}`}>
                    <GrView />
                  </StyledActionLink>
                </Col>

              </Row>
            );
          }

          if (key === "comboSkuCode") {
            return (
              <NavLink to={`${record.comboSkuCode}`}>{record.comboSkuCode}</NavLink>
            );
          }

          return <>{text}</>;
        },
      })),
    ];

    setColumns(tableHeaders);
    setRows(data);
  }, [splitSkuListing]);

  return (
    <>
      <Row style={{
        display: "flex",
        justifyContent: "space-between",
      }}>
        <Col>
          <AppPageHeader title="All Split SKUs" />
        </Col>
        <Col style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}>
        <NavLink to="/requisition/new-splitsku" >
              <Button
              type="primary"
              icon={<BsPlus />}
              >
              Create New
              </Button>
            </NavLink>
        </Col>
      </Row>
      <Row  justify="end">
        <AppActionStrip>
          <div className="action-strip__item">
            <AppFilterButton
              active={filterState}
              onClick={() => setFilterState(!filterState)}
              filterCount={filterCount}
            />
          </div>
        </AppActionStrip>
        
      </Row>
      <Row style={{ marginBottom: "1rem" }}>
        <AppFilter expanded={filterState}>
          <AppSplitSkuExchange />
        </AppFilter>
      </Row>
      {rows && rows.length ? (
        <Row>
          <Col xs={24}>
            <Table
              columns={columns}
              dataSource={rows}
              scroll={{ x: "100vh", y: "65vh" }}
              size="small"
              pagination={false}
              loading={status === "loading"}
              bordered
            />
          </Col>
        </Row>
      ) : (
        status !== "loading" && <>No Split SKUs to show.</>
      )}
    </>
  );
};

export default AllSplitSkusPage;
