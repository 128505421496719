import { INaPickingUserMappingUpload } from "common/interface";

const STORAGE_KEY_REQ = "processingNaPicking";
const STORAGE_KEY_FA = "processingFaPicking";

export const persistNaPickingUserMapping = (data: INaPickingUserMappingUpload) => {
  localStorage.setItem(STORAGE_KEY_REQ, JSON.stringify(data));
}

export const getPersistedNaPickingUserMapping = (): INaPickingUserMappingUpload => {
  const persistedUserMapping = localStorage.getItem(STORAGE_KEY_REQ);
  return persistedUserMapping ? JSON.parse(persistedUserMapping) : {};
}

export const removePersistedNaPickingUserMapping = () => {
  localStorage.removeItem(STORAGE_KEY_REQ);
}

export const persistFaPickingUserMapping = (data: INaPickingUserMappingUpload) => {
  localStorage.setItem(STORAGE_KEY_FA, JSON.stringify(data));
}

export const getPersistedFaPickingUserMapping = (): INaPickingUserMappingUpload => {
  const persistedUserMapping = localStorage.getItem(STORAGE_KEY_FA);
  return persistedUserMapping ? JSON.parse(persistedUserMapping) : {};
}

export const removePersistedFaPickingUserMapping = () => {
  localStorage.removeItem(STORAGE_KEY_FA);
}