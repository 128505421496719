import { Row, Col, Result, message, Form, DatePicker, Input, Alert } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import { AUTH_HEADER_NAME, apis, } from "common/apis";
import { IPickerMappingUpload, IPickerMappingUploadRow } from "common/interface/IPickerUploadScheme";
import { routes } from "common/routes";
import { generateHeaders } from "common/utils";
import { AppPageHeader } from "components/AppPageHeader";
import { AppUpload } from "components/AppUpload";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useDocumentTitle } from "hooks/usePageTitle";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { removeProcessingWhReturnMapping, setProcessingWhReturnMapping } from "store/store-rejection/storeRejectionSlice";

export const StoreRejectionUploadPage = () => {
  const { authToken } = useAppSelector((state) => state.app.user);
  const { processingStoreRejection } = useAppSelector(
    (state) => state.storeRejection
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [hasErrorRows, setHasErrorRows] = useState(false);
  const [storeId, setStoreId] = useState<string>();

  useEffect(() => {
    if (
      processingStoreRejection &&
      processingStoreRejection.key
    ) {
      navigate(`/${routes.STORE_REJECTION.INVENTORY_REJECTION_SAVE}`, {});
    }

    return () => {
      message.destroy();
    };
  }, [processingStoreRejection, navigate]);

  useEffect(() => {
    const failed = processingStoreRejection?.failedRows?.length;

    if (failed) {
      setHasErrorRows(true);
    }
  }, [processingStoreRejection?.failedRows]);

  const headers: { [name: string]: string } = {
    ...(authToken && { [AUTH_HEADER_NAME]: authToken }),
  };

  const onUploaded = (data: IPickerMappingUpload) => {
    dispatch(setProcessingWhReturnMapping(data));
  };

  const onUploading = () => {
    dispatch(removeProcessingWhReturnMapping());
  };

  const { headerMapping } = processingStoreRejection;
  const headerMappingWithError = headerMapping + ",errors:Errors";

  const generateMemoizedHeaders = useMemo(
    () => generateHeaders(headerMappingWithError),
    [headerMappingWithError]
  );

  const tableHeaders = generateMemoizedHeaders || {};
  const successRows = processingStoreRejection?.successRows || [];
  const failedRows = processingStoreRejection?.failedRows || [];
  const successData = successRows?.map((row, i) => ({
    ...row,
    key: `data-success-${i}`,
  }));
  const failedData = failedRows?.map((row, i) => ({
    ...row,
    key: `data-failed-${i}`,
  }));
  const data = [...failedData, ...successData];
  const columns: ColumnsType<
    IPickerMappingUploadRow & { key: string }
  > = [
      ...Object.keys(tableHeaders).map((key) => ({
        key,
        title: tableHeaders[key],
        dataIndex: key,
        render: (
          text: string,
          record: IPickerMappingUploadRow & { key: string }
        ) => {
          if (key === "errors") {
            const errorMessages = record.errors
              ?.map((error) => error.message)
              .join(", ");
            return <>{errorMessages}</>;
          }
          return <>{text}</>;
        },
      })),
    ];

  const addBorderToErrorRows = (
    record: IPickerMappingUploadRow & { key: string }
  ) => {
    if (record.errors && record.errors.length) {
      return "error-row";
    }

    return "";
  };

  useDocumentTitle("Store Return Upload");
  return (
    <>
      <Row>
        <Col>
          <AppPageHeader title="Upload Store Reject Mapping" />
        </Col>
      </Row>
      <Row>
        <Form>
          {/* <Form.Item label="Delivery date" name="date" required>
            <DatePicker
              onChange={(val) => setDate(val?.format("yyyy-MM-DD"))}
            />
          </Form.Item> */}
          <Form.Item label="Store ID" name="storeId" required>
              <Input
                placeholder="Provide Store Id"
                onChange={(val) => setStoreId(val.target.value)}
                required
              />
            </Form.Item>
        </Form>
      </Row>
      {(storeId) && (
        <Row>
          <Col lg={16} xs={24}>
            <AppUpload
              uploadUrl={apis.uploadStoreRejectionMapping}
              headers={headers}
              onUploaded={onUploaded}
              onUploading={onUploading}
              hasErrorRows={hasErrorRows}
              data={{ storeId }}
            />
          </Col>
        </Row>
      )}
      {Object.keys(processingStoreRejection).length != 0 &&
        !processingStoreRejection.key && (
          <>
            <Row>
              <Col lg={16} xs={24}>
                <Alert
                  type="error"
                  message="Errors in sheet data."
                  description="Please correct errors and upload again."
                  showIcon
                  banner
                />
              </Col>
            </Row>

            <Row>
              <Table
                  size="small"
                  columns={columns}
                  dataSource={data}
                  rowClassName={(record) => addBorderToErrorRows(record)}
                  scroll={{ y: 500 }}
                  className="table-with-errors"
                ></Table>
            </Row>
          </>
        )}
    </>
  );
};
