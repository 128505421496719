import {
  Alert,
  Col,
  DatePicker,
  Form,
  message,
  Row,
  Select,
  Table,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import { apis, AUTH_HEADER_NAME } from "common/apis";
import {
  IRequisitionUserMappingUpload,
  IRequisitionUserMappingUploadRow,
} from "common/interface";
import { routes } from "common/routes";
import { generateHeaders } from "common/utils";
import { AppPageHeader } from "components/AppPageHeader";
import { AppUpload } from "components/AppUpload";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useDocumentTitle } from "hooks/usePageTitle";
import { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  setProcessingRequisitionUserMapping,
  removeProcessingRequisitionUserMapping,
} from "store/requisition/requisitionSlice";

export const UploadUserMappingStoreRequisitionPage = () => {
  useDocumentTitle("Requisition: Upload User Picking");
  const { authToken } = useAppSelector((state) => state.app.user);
  const { processingRequistionUserMapping, slots } = useAppSelector(
    (state) => state.requisition
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [hasErrorRows, setHasErrorRows] = useState(false);
  const [date, setDate] = useState<string>();
  const [slot, setSlot] = useState<string>();

  useEffect(() => {
    if (
      processingRequistionUserMapping &&
      processingRequistionUserMapping.key
    ) {
      navigate(`/${routes.STORE_REQUISITION.USER_MAPPING_SAVE}`, {});
    }

    return () => {
      message.destroy();
    };
  }, [processingRequistionUserMapping, navigate]);


  // useEffect(() => {
  //   return (() => {
  //     dispatch(removeProcessingRequisitionUserMapping())
  //   });
  // }, []);

  useEffect(() => {
    const failed = processingRequistionUserMapping?.failedRows?.length;

    if (failed) {
      setHasErrorRows(true);
    }
  }, [processingRequistionUserMapping?.failedRows]);

  const { Option } = Select;

  const headers: { [name: string]: string } = {
    ...(authToken && { [AUTH_HEADER_NAME]: authToken }),
  };

  const onUploaded = (data: IRequisitionUserMappingUpload) => {
    dispatch(setProcessingRequisitionUserMapping(data));
  };

  const onUploading = () => {
    dispatch(removeProcessingRequisitionUserMapping());
  };

  const { headerMapping } = processingRequistionUserMapping;
  const headerMappingWithError = headerMapping + ",errors:Errors";

  const generateMemoizedHeaders = useMemo(
    () => generateHeaders(headerMappingWithError),
    [headerMappingWithError]
  );

  const tableHeaders = generateMemoizedHeaders || {};
  const successRows = processingRequistionUserMapping?.successRows || [];
  const failedRows = processingRequistionUserMapping?.failedRows || [];
  const successData = successRows?.map((row, i) => ({
    ...row,
    key: `data-success-${i}`,
  }));
  const failedData = failedRows?.map((row, i) => ({
    ...row,
    key: `data-failed-${i}`,
  }));
  const data = [...failedData, ...successData];
  const columns: ColumnsType<
    IRequisitionUserMappingUploadRow & { key: string }
  > = [
    ...Object.keys(tableHeaders).map((key) => ({
      key,
      title: tableHeaders[key],
      dataIndex: key,
      render: (
        text: string,
        record: IRequisitionUserMappingUploadRow & { key: string }
      ) => {
        if (key === "errors") {
          const errorMessages = record.errors
            ?.map((error) => error.message)
            .join(", ");
          return <>{errorMessages}</>;
        }
        return <>{text}</>;
      },
    })),
  ];

  const addBorderToErrorRows = (
    record: IRequisitionUserMappingUploadRow & { key: string }
  ) => {
    if (record.errors && record.errors.length) {
      return "error-row";
    }

    return "";
  };

  return (
    <>
      <Row>
        <Col>
          <AppPageHeader title="Upload Requisition User Picking" />
        </Col>
      </Row>
      <Row>
        <Form>
          <Form.Item label="Date" name="date" required>
            <DatePicker
              onChange={(val) => setDate(val?.format("yyyy-MM-DD"))}
            />
          </Form.Item>
          {slots && (
            <Form.Item label="Slot" name="slot" required>
              <Select
                placeholder="Select slot"
                onChange={(val) => setSlot(val)}
              >
                {Object.entries(slots).map(([label, value]) => (
                  <Option value={value} key={value}>
                    {label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </Form>
      </Row>
      {!!(date && slot) && (
        <Row>
          <Col lg={16} xs={24}>
            <AppUpload
              uploadUrl={apis.uploadRequisitionUserMapping}
              headers={headers}
              onUploaded={onUploaded}
              onUploading={onUploading}
              hasErrorRows={hasErrorRows}
              data={{ date, slot }}
            />
          </Col>
        </Row>
      )}
      {!!Object.keys(processingRequistionUserMapping).length &&
        !processingRequistionUserMapping.key && (
          <>
            <Row>
              <Col lg={16} xs={24}>
                <Alert
                  type="error"
                  message="Errors in sheet data."
                  description="Please correct errors and upload again."
                  showIcon
                  banner
                />
              </Col>
            </Row>

            <Row>
              <Table
                size="small"
                columns={columns}
                dataSource={data}
                rowClassName={(record) => addBorderToErrorRows(record)}
                scroll={{ y: 500 }}
                className="table-with-errors"
              ></Table>
            </Row>
          </>
        )}
    </>
  );
};
