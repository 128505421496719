import { useLocation, useNavigate } from "react-router-dom";
import { Row, Col } from "antd";
import { AppLayout } from "components/AppLayout";
import { useAppSelector } from "hooks/useAppSelector";
import styled from "styled-components";
import { ILoginForm, StateProps } from "common/interface";
import { LoginForm } from "forms/LoginForm";
import { AppLogo } from "components/AppLogo";
import { h1 } from "common/styles";
import { variables } from "common/variables";
import { useEffect } from "react";
import { useAppDispatch } from "hooks/useAppDispatch";
import { login } from "store/app/appSlice";
import { useDocumentTitle } from "hooks/usePageTitle";

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

const StyledRow = styled(Row)`
  height: 100%;
`;

const StyledH1 = styled.h1`
  ${h1}
`;

export const LoginPage = () => {
  useDocumentTitle("Login");
  const location = useLocation();
  const navigate = useNavigate();
  const { user, status, whId } = useAppSelector((state) => state.app);
  const dispatch = useAppDispatch(); 

  useEffect(() => {
    if (user?.isAuthenticated) {
      const from = (location?.state as StateProps)?.from?.pathname || "/";
      
      if (whId) {
        navigate(from, { replace: true });
      }
      else {
        navigate("/warehouse-selection", { replace: true });
      }
      
    }
  }, [location, navigate, user]);

  const onLogin = (user: ILoginForm) => {
    dispatch(login(user));
  };

  return (
    <AppLayout style={{ padding: 0 }}>
      <StyledRow justify="center">
        <Col
          md={12}
          xs={24}
          style={{ backgroundColor: variables.wmBackgroundColorLight1 }}
        >
          <Row
            style={{
              display: "flex",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Col style={{ width: "70%" }}>
              <FormContainer>
                <AppLogo />
                <StyledH1>Please Login</StyledH1>
                <LoginForm onLogin={onLogin} loading={status === "loading"} />
              </FormContainer>
            </Col>
          </Row>
        </Col>
        <Col md={12} xs={0}></Col>
      </StyledRow>
    </AppLayout>
  );
};
