import { Col, Form, message, Row } from "antd";
import { routes } from "common/routes";
import { AppPageHeader } from "components/AppPageHeader";
import { VendorForm } from "forms/VendorForm";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getVendorById, saveVendor } from "store/vendor/vendorSlice";

const MESSAGE_KEY = "edit-vendor";

const EditVendorPage = () => {
  const [form] = Form.useForm();
  const { vendors, status, error } = useAppSelector((state) => state.vendors);
  const [vendor, setVendor] = useState<any>();
  const { id } = useParams();
  const navigate = useNavigate();
  const onCancel = useCallback(() => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      navigate("/vendors")
    }
  }, [navigate]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (id) {
      dispatch(getVendorById({ vendorId: id })).then(() => {

      });
    }
    else {
      navigate(routes.VENDOR.ALL);
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (status === "loading") {
      message.loading({ content: "Saving vendor", key: MESSAGE_KEY });
    } else if (status === "success") {
      message.success({ content: "Vendor saved!", key: MESSAGE_KEY });
    } else if (status === "error") {
      message.error({ content: error, key: MESSAGE_KEY });
    }

    return () => message.destroy();
  }, [status, error]);

  useEffect(() => {
    const found = vendors?.data.find((v) => v.id.toString() === id);

    if (found) {
      const { address, ...thisVendorWithoutAddress } = found;
      const thisVendor = { ...thisVendorWithoutAddress, ...address };
      setVendor(thisVendor);
    }
    // else {
    //   onCancel();
    // }
  }, [vendors, id, onCancel, vendors?.data]);

  const onSubmit = () => {
    form.submit();
  };

  const onFinish = (values: Record<string, any>) => {
    const {
      address1,
      address2,
      city,
      state,
      pincode,
      accountNumber,
      accountName,
      gstin,
      bankName,
      ifscCode,
      branchName,
      panFileUrl,
      addressFileUrl,
      cancelledChequeFileUrl,
      extVendorId,
      ...restOfValues } =
      values;

    const formValues = {
      ...vendor,
      address: { address1, address2, city, state, pincode },
      id: Number(id),
      metadata: {
        gstin: gstin != undefined && gstin?.length > 0 ? gstin : undefined,
        bankAccountDetails: {
          accountNumber,
          accountName,
          bankName,
          ifscCode,
          branchName,
        },
        panFileUrl: panFileUrl,
        addressFileUrl: addressFileUrl,
        cancelledChequeFileUrl: cancelledChequeFileUrl,
      },
      extVendorId,
      ...restOfValues,
    };
    dispatch(saveVendor(formValues as any))
      .unwrap()
      .then(() => navigate("/vendors"));
  };

  return (
    <>
      <Row>
        <Col>
          <AppPageHeader title="Edit Vendor" />
        </Col>
      </Row>
      <Row>
        <VendorForm
          formInstance={form}
          values={{
            ...vendor,
            ...(vendor?.metadata?.bankAccountDetails ?? {}),
            ...(vendor?.metadata ?? {}),
          }}
          onCancel={onCancel}
          onFinish={onFinish}
          onSubmit={onSubmit}
          loading={status === "loading"}
        />
      </Row>
    </>
  );
};

export default EditVendorPage;
