import { Row, Col, message, Table, Pagination, Button, Popconfirm, Switch } from "antd";
import { ColumnsType } from "antd/lib/table";
import { AppPageHeader } from "components/AppPageHeader";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useDocumentTitle } from "hooks/usePageTitle";
import { useCallback, useEffect, useState } from "react";
import { MdEdit } from "react-icons/md";
import styled from "styled-components";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { AppActionStrip } from "components/AppActionStrip/AppActionStrip";
import { AppFilterButton, AppFilter } from "components/AppFilter";
import { getClusterListing, getSocieties, toggleSocietyStatus } from "../../store/admin-slice/adminServicesSlice";
import { BsBuilding } from "react-icons/bs";
import { variables } from "common/variables";
import { Society } from "common/interface/Society";
import { AppSocietyFilter } from "components/AppFilter/AppSocietyFilter";

type PartialAdminUserType = Partial<Society> & { key: string };
const MESSAGE_KEY = "all_admin_users";

const headerKeys: { [key: string]: string } = {
    // id: "User ID",
    name: "Name",
    // coordinates: "Coordinates",
    // tower: "Tower",
    storeId: "Store ID",
    clusterId: "Cluster ID",
    pincode: "Pincode",
    city: "City",
    // state: "State",
    actions: "Actions",
    status: "Status"
};

const StyledPagination = styled(Pagination)`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

const StyledActionLink = styled(NavLink)`
  border: 1px solid;
  padding: 0.2rem;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  &.filterButton {
    &--active {
      border-color: ${variables.primaryColor} !important;
    }
  }
`;

const SocietyListingPage = () => {
    useDocumentTitle("Societies");
    const dispatch = useAppDispatch();
    const [columns, setColumns] = useState<ColumnsType<PartialAdminUserType>>();
    const [rows, setRows] = useState<PartialAdminUserType[]>();
    const { societies, status, error } = useAppSelector((state) => state.adminServices);
    const [filterState, setFilterState] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [filterCount, setFilterCount] = useState(0);
    const navigator = useNavigate();
    const getData = useCallback(
        (pageNum = 1, pageSize = 25, filter = "") => {
            
            dispatch(getSocieties({filter: filter}));
        },
        [dispatch]
    );

    const toggleStatus = (id: any, status: any) => {
        dispatch(toggleSocietyStatus({ id: id, status: status}));
    };
      
    useEffect(() => {
        dispatch(getClusterListing());
    }, []);

    useEffect(() => {
        const filter = searchParams.toString();
        setFilterCount(
            Object.keys(Object.fromEntries(new URLSearchParams(filter))).length
        );
        getData(1, 25, filter);
    }, [dispatch, getData, searchParams]);

    useEffect(() => {
        if (status === "loading") {
            message.loading({ content: "Loading", key: MESSAGE_KEY });
        } else if (status === "success") {
            // message.success({
            //     content: "Admin Users Loaded.",
            //     key: MESSAGE_KEY,
            // });
        } else if (status === "error") {
            message.error({ content: error, key: MESSAGE_KEY });
        }

        return () => {
            message.destroy();
        };
    }, [status, error]);

    useEffect(() => {
        const adminUsersData = societies;
        const data: PartialAdminUserType[] = adminUsersData.map((au, i) => {
            const {
                id, name, latitude, longitude, tower, storeId, pincode, city, state, clusterId, active
            } = au;

            const newRow = {
                id, name, latitude, longitude, tower, storeId, pincode, city, state, clusterId, active
            };
            return { ...newRow, key: `adminusers-${i}`, ellipses: true };
        });

        const tableHeaders: ColumnsType<PartialAdminUserType> = [
            ...Object.keys(headerKeys).map((key) => ({
                key,
                title: headerKeys[key],
                dataIndex: key,
                render: (text: string, record: PartialAdminUserType) => {
                    
                    if (key === "coordinates") {
                        return <Col>
                        <Row>Latitude: {record.latitude}</Row>
                        <Row>Longitude: {record.longitude}</Row>
                        </Col>;
                    }

                    if (key === "tower") {
                        return <Col>
                        {record.tower?.map(e => (<Row>{e.towerName} || {e.floorCount}</Row>))}
                        </Col>;
                    }

                    if (key === "actions") {
                        if(record.active == 1) {
                            return (
                                <Row
                                    align="middle"
                                    style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                    }}
                                >
                                    <Col>
                                        <StyledActionLink to={`/admin-services/create-society/${record.id}`}>
                                            <MdEdit />
                                        </StyledActionLink>
                                    </Col>
                                </Row>
                            );
                        }
                    }

                    if (key === "status") {
                        const isSocietyActive = (record as Society).active === 1;
                    
                        return (
                            <Popconfirm
                                title="This will toggle the current society status, are you sure?"
                                disabled={status === "updating"}
                                onConfirm={() => {
                                    const newStatus = isSocietyActive ? 0 : 1;
                                    toggleStatus(record.id, newStatus);
                                }}
                            >
                                <Switch
                                    disabled={typeof isSocietyActive === 'undefined'}
                                    loading={status === "updating"}
                                    checkedChildren="Active"
                                    checked={isSocietyActive}
                                    unCheckedChildren={typeof isSocietyActive === 'undefined' ? "Loading" : "Inactive"}
                                />
                            </Popconfirm>
                        );
                    }

                    return <>{text}</>;
                },
            })),
        ];

        setColumns(tableHeaders);
        setRows(data);
    }, [societies]);

    return (
        <>
            <Row>
                <Col>
                    <AppPageHeader title="All Societies List" />
                </Col>
            </Row>
            <Row>
                <AppActionStrip>
                    <div className="action-strip__item">
                        <AppFilterButton
                            active={filterState}
                            onClick={() => setFilterState(!filterState)}
                            filterCount={filterCount}
                        />
                    </div>
                    <Button
                        icon={<BsBuilding style={{marginRight: '.5rem'}}/>}
                        type={"primary"}
                        onClick={() => navigator("/admin-services/create-society")}
                    >
                        Add Society
                    </Button>
                </AppActionStrip>
            </Row>
            <Row style={{ marginBottom: "1rem" }}>
                <AppFilter expanded={filterState}>
                    <AppSocietyFilter />
                </AppFilter>
            </Row>
            {rows && rows.length ? (
                <Row>
                    <Col xs={24}>
                        <Table
                            columns={columns}
                            dataSource={rows}
                            scroll={{ x: "100vh", y: "65vh" }}
                            size="small"
                            pagination={false}
                            loading={status === "loading"}
                            bordered
                        />
                    </Col>
                    {/* <Col xs={24}>
                        <Paginator />
                    </Col> */}
                </Row>
            ) : (
                status !== "loading" && <>No Admin User to show.</>
            )}
        </>
    );
};

export default SocietyListingPage;
