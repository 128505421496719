import {
    Row,
    Col,
    Alert,
    message,
    Table,
    Select,
    DatePicker,
    Form,
  } from "antd";
  import { ColumnsType } from "antd/lib/table";
  import { apis, AUTH_HEADER_NAME } from "common/apis";
  import {
    INaPickingUserMappingUpload,
    INaPickingUserMappingUploadRow,
  } from "common/interface";
  import { routes } from "common/routes";
  import { generateHeaders } from "common/utils";
  import { AppPageHeader } from "components/AppPageHeader";
  import { AppUpload } from "components/AppUpload";
  import { useAppDispatch } from "hooks/useAppDispatch";
  import { useAppSelector } from "hooks/useAppSelector";
  import { useDocumentTitle } from "hooks/usePageTitle";
import moment from "moment";
  import { useState, useEffect, useMemo } from "react";
  import { useNavigate } from "react-router-dom";
import { removeProcessingFaPickingUserMapping, setProcessingFaPickingUserMapping } from "store/na-picking/naPickingSlice";
  
  export const UploadUserMappingFaPickingPage = () => {
    useDocumentTitle("FA Picking: Upload User Mapping");
    const { authToken } = useAppSelector((state) => state.app.user);
    const { processingFaPickingUserMapping } = useAppSelector(
      (state) => state.naPicking
    );
    const { slots } = useAppSelector((state) => state.requisition);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [hasErrorRows, setHasErrorRows] = useState(false);
    const [date, setDate] = useState<string>();
    const [slot, setSlot] = useState<string>();
  
    useEffect(() => {
      const { timeUploaded } = processingFaPickingUserMapping;
      if (timeUploaded) {
        const uploadedTime = new Date(timeUploaded).getTime();
        const currentTime = new Date().getTime();
        const minsPassed = (currentTime - uploadedTime) / 1000 / 60;
  
        if (minsPassed >= 15) {
          dispatch(removeProcessingFaPickingUserMapping());
        }
      }
  
      if (processingFaPickingUserMapping && processingFaPickingUserMapping.key) {
        navigate(`/${routes.SR_USER_MAPPING.FA_USER_MAPPING_SAVE}`, {});
      }
  
      return () => {
        message.destroy();
      };
    }, [processingFaPickingUserMapping, navigate, dispatch]);
  
    // useEffect(() => {
    //   return (() => {
    //     dispatch(removeProcessingNaPickingUserMapping())
    //   });
    // }, []);
  
    useEffect(() => {
      const failed = processingFaPickingUserMapping?.failedRows?.length;
  
      if (failed) {
        setHasErrorRows(true);
      }
    }, [processingFaPickingUserMapping?.failedRows]);
  
    const headers: { [name: string]: string } = {
      ...(authToken && { [AUTH_HEADER_NAME]: authToken }),
    };
  
    const onUploaded = (data: INaPickingUserMappingUpload) => {
      dispatch(setProcessingFaPickingUserMapping(data));
    };
  
    const onUploading = () => {
      dispatch(removeProcessingFaPickingUserMapping());
    };
  
    const { Option } = Select;
  
    const { headerMapping } = processingFaPickingUserMapping;
    const headerMappingWithError = headerMapping + ",errors:Errors";
  
    const generateMemoizedHeaders = useMemo(
      () => generateHeaders(headerMappingWithError),
      [headerMappingWithError]
    );
  
    const tableHeaders = generateMemoizedHeaders || {};
    const successRows = processingFaPickingUserMapping?.successRows || [];
    const failedRows = processingFaPickingUserMapping?.failedRows || [];
    const successData = successRows?.map((row, i) => ({
      ...row,
      key: `data-success-${i}`,
    }));
    const failedData = failedRows?.map((row, i) => ({
      ...row,
      key: `data-failed-${i}`,
    }));
    const data = [...failedData, ...successData];
    const columns: ColumnsType<INaPickingUserMappingUploadRow & { key: string }> =
      [
        ...Object.keys(tableHeaders).map((key) => ({
          key,
          title: tableHeaders[key],
          dataIndex: key,
          render: (
            text: string,
            record: INaPickingUserMappingUploadRow & { key: string }
          ) => {
            if (key === "errors") {
              const errorMessages = record.errors
                ?.map((error) => error.message)
                .join(", ");
              return <>{errorMessages}</>;
            }
            return <>{text}</>;
          },
        })),
      ];
  
    const addBorderToErrorRows = (
      record: INaPickingUserMappingUploadRow & { key: string }
    ) => {
      if (record.errors && record.errors.length) {
        return "error-row";
      }
  
      return "";
    };
  
    return (
      <>
        <Row>
          <Col>
            <AppPageHeader title="Upload FA Picking User Mapping" />
          </Col>
        </Row>
        <Row>
          <Form>
            <Form.Item label="Date" name="date" required>
              <DatePicker
              disabledDate={(current) => {
                let customDate = moment().format("YYYY-MM-DD");
                return current && current < moment(customDate, "YYYY-MM-DD");
              }} 
                onChange={(val) => setDate(val?.format("yyyy-MM-DD"))}
              />
            </Form.Item>
            {slots && (
              <Form.Item label="Slot" name="slot" required>
                <Select
                  placeholder="Select slot"
                  onChange={(val) => setSlot(val)}
                >
                  {Object.entries(slots).map(([label, value]) => (
                    <Option value={value} key={value}>
                      {label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Form>
        </Row>
        {!!(date && slot) && (
          <Row>
            <Col lg={16} xs={24}>
              <AppUpload
                uploadUrl={apis.uploadFaPickingUserMapping}
                headers={headers}
                onUploaded={onUploaded}
                onUploading={onUploading}
                hasErrorRows={hasErrorRows}
                data={{ date, slot }}
              />
            </Col>
          </Row>
        )}
        {!!Object.keys(processingFaPickingUserMapping).length &&
          !processingFaPickingUserMapping.key && (
            <>
              <Row>
                <Col lg={16} xs={24}>
                  <Alert
                    type="error"
                    message="Errors in sheet data."
                    description="Please correct errors and upload again."
                    showIcon
                    banner
                  />
                </Col>
              </Row>
  
              <Row>
                <Table
                  size="small"
                  columns={columns}
                  dataSource={data}
                  rowClassName={(record) => addBorderToErrorRows(record)}
                  scroll={{ y: 500 }}
                  className="table-with-errors"
                ></Table>
              </Row>
            </>
          )}
      </>
    );
  };
  