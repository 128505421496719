import {
    Breadcrumb,
    Button,
    Checkbox,
    Col,
    Descriptions,
    Form,
    Input,
    Image,
    InputNumber,
    message,
    Row,
    Select,
    Table,
    Typography,
    Modal,
    Avatar,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import Link from "antd/lib/typography/Link";
import { apis, AUTH_HEADER_NAME } from "common/apis";
import { IStoreReturnRequestInfo, StoreReturnItemDataList } from "common/interface/IStoreReturn";
import { fallBackImage } from "common/styles";
import { getDateFromISODate } from "common/utils";
import { variables } from "common/variables";
import { AppPageHeader } from "components/AppPageHeader";
import CustomMultiUploadField from "components/FormUploadField/MultiFileUploadField";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useDocumentTitle } from "hooks/usePageTitle";
import React, { useCallback, useEffect, useState } from "react";
import { AiFillPlayCircle } from "react-icons/ai";
import { BsPlus } from "react-icons/bs";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { fetchStoresList } from "store/requisition/requisitionSlice";
import { createFullSrReturn, getCreateStoreReturnDetails, getFullSrReturnOptions, getQaOptions, receiveStoreReturn, saveCreateSrItem, } from "store/store-return/storeReturnSlice";
import styled from "styled-components";

const MESSAGE_KEY = "store_return__info";
type PartialISRItemType = Partial<StoreReturnItemDataList> & { key: string };

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    title: any;
    inputType: "select" | "text";
    record: PartialISRItemType;
    index: number;
    children: React.ReactNode;
}

const StyledActionLink = styled(NavLink)`
  border: 1px solid;
  padding: 0.2rem;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ViewCreateStoreReturnPage = () => {
    useDocumentTitle("Store Return Details");
    const { authToken } = useAppSelector((state) => state.app.user);
    const headers: { [name: string]: string } = {
        ...(authToken && { [AUTH_HEADER_NAME]: authToken }),
    };
    const dispatch = useAppDispatch();
    const { error, status, qaOptions, fullSrReturnOptions, allStoreReturnDetails } = useAppSelector((state) => state.storeReturn);
    const { masterSkus = [], storesList } = useAppSelector((state) => state.requisition);
    const { srId } = useParams();
    const [viewedStoreReturn, setViewedSr] = useState<IStoreReturnRequestInfo>();
    const [tableColumns, setTableColumns] = useState<ColumnsType<PartialISRItemType>>();
    const [tableRows, setTableRows] = useState<any[]>();
    const [isFullOrderReturn, setIsFullOrderReturn] = useState<boolean>(false);
    const [fullSrReason, setFullSrReason] = useState<string>();
    const [tempTableRows, setTempTableRows] = useState<any[]>();
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState("");
    const [isAddingItem, setIsAddingItem] = useState(false);
    const navigate = useNavigate();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeVideoUrl, setActiveVideoUrl] = useState("");

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setActiveVideoUrl("");
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setActiveVideoUrl("");
        setIsModalOpen(false);
    };

    useEffect(() => {
        dispatch(getQaOptions());
        dispatch(getFullSrReturnOptions());
    }, []);

    const save = useCallback(
        async (srItemId?: number) => {
            try {
                const row = await form.validateFields();
                dispatch(saveCreateSrItem({ srId: srId!, srItem: row })).unwrap().then(() => {
                    if (tableRows) {
                        const index = tableRows.findIndex((e) => e.id === srItemId);
                        
                        let newRow = {
                            skuId: row["skuId"],
                            skuCode: row["skuId"],
                            key: srItemId === undefined ? row["skuId"] : `${srItemId}`,
                            id: srItemId === undefined ? parseInt(row["skuId"].substring(2)) : srItemId,
                            weightPicked: row["weightPicked"],
                            remarks: row["remarks"],
                            refundQuantity: row["refundQuantity"],
                            receivedQuantity: row["receivedQuantity"],
                            qaResult: row["qaResult"],
                            attachments: row["attachments"],
                            pickedQty: row["pickedQty"],
                            pickerRemarks: row["pickerRemarks"],
                        };
                        if (index > -1) {
                            tableRows?.splice(index, 1, newRow);
                            setEditingKey("");
                        } else {
                            tableRows?.push(newRow);
                            setEditingKey("");
                        }
                        setTableRows(tableRows);
                    }
                    setEditingKey("");
                    setTempTableRows([]);
                    setIsAddingItem(false);
                    form.resetFields();
                });
            } catch (errInfo) {
                console.log("Validate Failed:", errInfo);
            }
        },
        [form, masterSkus, tableRows?.length, tempTableRows?.length, isAddingItem, editingKey]
    );

    const deleteItem = useCallback(
        async (srItemId?: number) => {
            try {
                if (tableRows) {
                    const index = tableRows.findIndex((e) => e.skuId === srItemId);
                    tableRows.splice(index, 1);
                    setTableRows([...tableRows ?? []]);
                }
            } catch (errInfo) {
                console.log("Validate Failed:", errInfo);
            }
        },
        [form, masterSkus, tableRows?.length,]
    );

    const isEditing = useCallback(
        (record: PartialISRItemType) => record.key === editingKey,
        [editingKey]
    );

    const edit = useCallback(
        (record: PartialISRItemType) => {
            form.setFieldsValue({ ...record });
            setEditingKey(record.key);
        },
        [form]
    );

    const onReceive = useCallback(async () => {
        try {
            let thisSr = {
                ...viewedStoreReturn,
            };
            if (tableRows?.every((item) => item.quantity !== null && item.sku_code !== null) ?? false) {
                dispatch(receiveStoreReturn({
                    createdBy: viewedStoreReturn?.createdBy,
                    createdOn: viewedStoreReturn?.createdOn,
                    modifiedBy: viewedStoreReturn?.modifiedBy,
                    modifiedOn: viewedStoreReturn?.modifiedOn,
                    returnDate: viewedStoreReturn?.returnDate,
                    status: viewedStoreReturn?.status,
                    storeId: viewedStoreReturn?.storeId,
                    storeReturnItemDataList: tableRows,
                    id: viewedStoreReturn?.id,
                    whId: viewedStoreReturn?.whId,
                    receivedDate: Date.now().toLocaleString(),
                },),)
                    .unwrap()
                    .then(() => navigate(-1));
            }
            else {
                message.error("The Gross Receive Qty is not present for all fields. Please update the same.", 3);
            }
        } catch (e) {
            console.error(e);
        }
    }, [dispatch, form, navigate, viewedStoreReturn?.id]);

    const onAddItem = () => {
        let idx = tableRows?.length || 0;
        const key = (++idx).toString();
        setTempTableRows([...(tempTableRows || []), { key, temp: true, }]);
        setEditingKey(key);
        setIsAddingItem(true);
        setTimeout(() => {
            document
                .querySelector(".editable-last-row")
                ?.scrollIntoView({ behavior: "smooth" });
        }, 100);
    };

    const onFullSrReturn = () => {
        dispatch(createFullSrReturn({
            ticketCategoryLabel: isFullOrderReturn ? fullSrReason : undefined,
            returnDate: getDateFromISODate(Date.now().toString()),
            srId: srId!.toString(),
            remarks: fullSrReason !== undefined ? fullSrReturnOptions[fullSrReason] : undefined,
            isFullSrReturn: isFullOrderReturn,
        }));
    };

    useEffect(() => {
        if (storesList === undefined || storesList?.length === 0)
            dispatch(fetchStoresList(""));
    }, []);

    useEffect(() => {
        fetchInfo();
    }, [srId, allStoreReturnDetails, dispatch]);

    useEffect(() => {
        setIsFullOrderReturn(viewedStoreReturn?.isFullSrReturn === 1);
        setFullSrReason(viewedStoreReturn?.ticketCategoryLabel);
    }, [viewedStoreReturn]);

    useEffect(() => {
        if (status === "loading") {
            message.loading({ content: "loading purchase order", key: MESSAGE_KEY });
        } else if (status === "saving") {
            message.loading({ content: "saving purchase order", key: MESSAGE_KEY });
        } else if (status === "error") {
            message.error({ content: error, key: MESSAGE_KEY });
        }
        return () => {
            message.destroy();
        };
    }, [status, error]);

    useEffect(() => {
        if (editingKey) {
            const selectedRowData = viewedStoreReturn?.storeReturnItemDataList.find(e => (e.id?.toString()) === editingKey.toString());
            if (selectedRowData !== undefined) {
                form.setFieldsValue({
                    skuId: selectedRowData?.sku_code,
                });
            }
        }
    }, [editingKey, form,],);

    const cancel = useCallback(() => {
        if (isAddingItem) {
        }
        setTempTableRows([]);
        setEditingKey("");
        setIsAddingItem(false);
        setTableRows([...tableRows ?? []].filter(
            (poI) => (isAddingItem && !(poI)["temp"]) || !isAddingItem
        ));
        form.resetFields();
    }, [form, tableRows?.length]);

    const fetchInfo = async () => {
        if (srId != undefined) {
            let tempSr = (await dispatch(getCreateStoreReturnDetails(srId),)).payload as IStoreReturnRequestInfo;
            setViewedSr({ ...tempSr });
            setTableRows(tempSr.storeReturnItemDataList.map((item) => ({
                key: item.sku_code,
                id: item.sku_code,
                skuId: item.sku_code,
                weightPicked: item.weightPicked,
                receivedQuantity: item.quantity,
                qaResult: item.qaResult,
                refundQuantity: item.refundQty,
                remarks: item.remarks,
                attachments: item.attachments,
                pickedQty: item.pickedQty,
                pickerRemarks: item.pickerRemarks,
            })));
        }
    }

    const getSkuOptions = (includeSku?: string) => {
        if (masterSkus) {
            const selectedSkus = tableRows!
                .filter((srItem) => srItem.skuId !== includeSku)
                .map((srItem) => srItem.skuId);
            return masterSkus.reduce((acc, sku) => {
                if (!selectedSkus?.includes(sku.skuCode)) {
                    return (acc = {
                        ...acc,
                        [sku.id]: {
                            value: sku.id,
                            label: sku.skuCode,
                            skuCode: sku.skuCode,
                            skuName: sku.name,
                        },
                    });
                }

                return acc;
            }, {} as Record<string, { skuCode: string; skuName: string }>);
        }
        return {};
    };

    useEffect(() => {
        let thisSr = viewedStoreReturn;
        const headerKeys: { [key: string]: string } = {
            skuId: "SKU ID/Name",
            weightPicked: "Dispatched Quantity",
            receivedQuantity: "Received Quantity",
            qaResult: "QA Result",
            refundQuantity: "Refund Quantity",
            pickedQty: "Picked Quantity",
            attachments: "Attachments",
            remarks: "Remarks",
            pickerRemarks: "Picker Remarks",
            actions: "Actions",
        };

        if (thisSr) {
            const columns = [
                ...Object.keys(headerKeys).map((key) => ({
                    key,
                    title: headerKeys[key],
                    dataIndex: key,
                    editable: key !== "actions" && true,
                    width: key === "qaResult" ? "16rem" : "220",
                    render: (text: string, record: any) => {
                        if (key === "skuId") {
                            const skuItem = masterSkus.find(e => e.skuCode === record.skuId);
                            return (
                                <>
                                    <div>{skuItem?.skuCode}</div>
                                    <div>{skuItem?.name}</div>
                                </>
                            );
                        }

                        if (key === "attachments") {
                            return (
                                <Row align="middle" gutter={10}>
                                    <Image.PreviewGroup>
                                        {(record.attachments ?? []).map((url: string) => {
                                            return url.substring(url.length - 4).includes("mp4")
                                                ? <Col>
                                                    <AiFillPlayCircle
                                                        onClick={() => { 
                                                            setActiveVideoUrl(url);
                                                            showModal();

                                                        }}
                                                        size={"2.35rem"}
                                                        color={variables?.primaryColor} />
                                                    <Modal title="Video Preview"
                                                        bodyStyle={{
                                                            height: "70vh",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center"
                                                        }}
                                                        destroyOnClose={true}
                                                        visible={isModalOpen}
                                                        onOk={() => handleOk()}
                                                        onCancel={() => handleCancel()}
                                                        okButtonProps={{ hidden: true }}
                                                        cancelButtonProps={{ hidden: true }}
                                                    >
                                                        <video
                                                            key={activeVideoUrl}
                                                            controls={true}
                                                            src={activeVideoUrl}
                                                            // muted={false}
                                                            autoPlay={false}
                                                            style={{ height: "100%", width: "100%", objectFit: "contain" }} //object-fit:cover
                                                        />
                                                        
                                                        {/* <video id={activeVideoUrl} controls height= "100%" width="100%" key={activeVideoUrl} autoPlay={true}>
                                                            <source src={activeVideoUrl}/>
                                                        </video> */}
                                                    </Modal>
                                                </Col>
                                                : <Col>
                                                    <Avatar
                                                    style={{border: "1px solid green"}}
                                                        src={<Image
                                                            src={`${url}`}
                                                            fallback={fallBackImage}
                                                            alt={text}
                                                            width="100%" />} />
                                                </Col>;
                                        })}
                                    </Image.PreviewGroup>
                                </Row>
                            );
                        }

                        if (key === "actions") {
                            const editable = isEditing(record);
                            return editable ? (
                                <Row
                                    align="middle"
                                    style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                    }}
                                >
                                    <Col style={{ marginRight: ".5rem" }}>
                                        <Typography.Link
                                            onClick={() => {
                                                save(tableRows?.find(e => e.skuId == record.skuId)?.id)
                                            }}
                                            style={{ marginRight: 8 }}
                                            disabled={status === "loading"}
                                        >
                                            Save
                                        </Typography.Link>
                                    </Col>
                                    <Col>
                                        <Typography.Link
                                            onClick={cancel}
                                            style={{ marginRight: 8 }}
                                            type="secondary"
                                            disabled={status === "loading"}
                                        >
                                            Cancel
                                        </Typography.Link>
                                    </Col>
                                </Row>
                            ) : (
                                <Row align="middle"
                                    style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                    }}>
                                    <Col>
                                        <Typography.Link
                                            disabled={editingKey !== "" || viewedStoreReturn?.status == "RECEIVED" || isFullOrderReturn}
                                            onClick={() => edit(record)}
                                        >
                                            Edit
                                        </Typography.Link>
                                    </Col>
                                </Row>
                            );
                        }
                        return <>{text}</>;
                    },
                })),
            ];

            const mergedColumns = columns.map((col, i) => {
                if (!col.editable) {
                    return col;
                }
                return {
                    ...col,

                    ...(i === 0 && { width: col.key === "qaResult" ? "16rem" : "220px" }),
                    onCell: (record: PartialISRItemType) => ({
                        record,
                        inputType: ["skuId", "qaResult"].includes(col.dataIndex) ? "select" : "text",
                        dataIndex: col.dataIndex,
                        title: col.title,
                        editing: isEditing(record),
                    }),
                };
            });
            setTableRows([...tableRows?.filter(
                (poI) => (isAddingItem && !(poI)["temp"]) || !isAddingItem
            ) ?? [], ...(tempTableRows || [])]);
            setTableColumns(mergedColumns);
        }
    }, [
        // cancel,
        edit,
        editingKey,
        form,
        isAddingItem,
        isEditing,
        srId,
        save,
        tempTableRows,
        viewedStoreReturn,
        deleteItem,
        viewedStoreReturn?.storeReturnItemDataList,
        viewedStoreReturn?.status,
        isFullOrderReturn,
        isModalOpen,
    ]);

    const disableRefund = (nv?: string) => ["No Quality Issue", "Old Stock", "No Dispatch from warehouse"].includes(nv ?? form.getFieldValue("qaResult"));

    const EditableCell: React.FC<EditableCellProps> = ({
        editing,
        dataIndex,
        title,
        inputType,
        record,
        index,
        children,
        ...restProps
    }) => {
        let inputNode = <Input disabled={(["weightPicked", "pickedQty", "pickerRemarks"].includes(dataIndex)) || (dataIndex === "refundQuantity" && disableRefund())} />;

        if ((isAddingItem || isEditing) && inputType === "select") {
            if (dataIndex === "skuId") {
                inputNode = <Select
                    showSearch
                    optionFilterProp=""
                    filterOption={(input, option) => {
                        const keyWords =
                            option?.children?.map((child) =>
                                child.props.children.toLowerCase()
                            ) || [];
                        return keyWords.some((key) => key.includes(input.toLowerCase()));
                    }}
                    onChange={() => {
                        let nv = form.getFieldValue("weightPicked") ?? viewedStoreReturn?.storeReturnItemDataList.find(e => e.sku_code == form.getFieldValue("skuId"))?.weightPicked;
                        form.setFieldsValue({ "weightPicked": nv });
                        if (nv === null || nv === undefined || nv === "") {
                            form.setFieldsValue({ "qaResult": "No Dispatch from warehouse" });
                            form.setFieldsValue({ "refundQuantity": 0 });
                        }
                    }}
                    style={{ width: "200px" }}
                >
                    {Object.entries(getSkuOptions(record.sku_code)).map(
                        ([key, value],) => {
                            return (
                                <Select.Option key={key} value={value.skuCode}>
                                    <div>{value.skuCode}</div>
                                    <div>{value.skuName}</div>
                                </Select.Option>
                            )
                        }
                    )
                    }
                </Select>;
            }
            if (dataIndex == "qaResult") {
                inputNode = <Row
                // className="qaRow"
                >
                    <Select
                        showSearch
                        optionFilterProp=""
                        disabled={[null, undefined, "", 0, "0"].includes(form.getFieldValue("weightPicked"))}
                        filterOption={(input, option) => {
                            const keyWords =
                                option?.children?.map((child) =>
                                    child.props.children.toLowerCaweightPickedse()
                                ) || [];
                            return keyWords.some((key) => key.includes(input.toLowerCase()));
                        }}
                        onChange={(value) => {
                            form.setFieldsValue({ "qaResult": value });
                            if (disableRefund(value)) {
                                form.setFieldsValue({ "refundQuantity": "0" });
                            }
                            else {
                                form.setFieldsValue({ "refundQuantity": form.getFieldValue("receivedQuantity") });
                            }
                        }}
                    >
                        {qaOptions.map(
                            (value, index) => {
                                return (
                                    <Select.Option key={index.toString()} value={value}>
                                        {value}
                                    </Select.Option>
                                )
                            }
                        )
                        }
                    </Select>
                </Row>;
            }
        }

        if (dataIndex === 'attachments') {
            return <td {...restProps} style={{}}>
                {editing ?
                <Form.Item 
                name={"attachments"}
                rules={[
                ]}
                >
                    <CustomMultiUploadField form={form} fieldName="attachments" uploadApi={apis.uploadStoreReturnImages}/>
                </Form.Item>
                
                : (
                    children
                )}
            </td>
        }

        if (dataIndex === "receivedQuantity" || dataIndex === "refundQuantity") {
            inputNode = <InputNumber
                disabled={(dataIndex === "refundQuantity" && disableRefund() )} min={0} max={dataIndex === "receivedQuantity" ? form.getFieldValue("weightPicked") : dataIndex === "refundQuantity" ? form.getFieldValue("receivedQuantity") : null}
                onChange={(nv) => {
                    if (dataIndex === "receivedQuantity" && !disableRefund() && nv < form.getFieldValue("refundQuantity")) {
                        form.setFieldsValue({ "refundQuantity": nv });
                    }
                }}
            />;
        }

        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item
                        name={dataIndex}
                        style={{ margin: 0 }}
                        rules={[
                            {
                                required: !((dataIndex === "weightPicked") || (dataIndex === "refundQuantity") || (["pickedQty", "pickerRemarks"].includes(dataIndex))),
                                message: `Please ${inputType === "select" ? "select" : "input"
                                    } ${title}!`,
                            },
                        ]}
                    >
                        {inputNode}
                    </Form.Item >
                ) : (
                    children
                )}
            </td>
        );
    };

    const poInfo = viewedStoreReturn && (
        <>
            {<Descriptions
                size="small"
                layout="vertical"
                labelStyle={{ fontWeight: "bold" }}
                bordered
                column={4}
                style={{ marginTop: "20px", marginBottom: "20px", }}
            >
                {viewedStoreReturn.storeId && <Descriptions.Item label="Store ID">
                    {(viewedStoreReturn.storeId)}
                </Descriptions.Item>}
                {storesList && viewedStoreReturn.storeId && <Descriptions.Item label="Store Name">
                    {(storesList.find((e) => e.id === viewedStoreReturn.storeId)?.name)}
                </Descriptions.Item>}
                {viewedStoreReturn.date && <Descriptions.Item label="Date">
                    {getDateFromISODate(viewedStoreReturn.date)}
                </Descriptions.Item>}
                {viewedStoreReturn.slot && <Descriptions.Item label="Slot">
                    {(viewedStoreReturn.slot)}
                </Descriptions.Item>}
            </Descriptions>}

            {viewedStoreReturn.status &&
                <Descriptions
                    size="small"
                    layout="vertical"
                    labelStyle={{ fontWeight: "bold" }}
                    bordered
                    column={{ xs: 2, md: 3 }}
                >
                    <Descriptions.Item label="Store Return ID">
                        {viewedStoreReturn.id}
                    </Descriptions.Item>
                    <Descriptions.Item label="Status">{viewedStoreReturn.status}</Descriptions.Item>
                    {viewedStoreReturn.status === "DRAFT" && <Descriptions.Item label="Actions">
                        <Row>
                            {<Button
                                style={{ marginLeft: "1rem" }}
                                type="primary"
                                onClick={() => onReceive()}
                                loading={status === "saving"}
                                disabled={editingKey !== "" || status === "saving"}
                            >
                                Receive
                            </Button>}
                        </Row>
                    </Descriptions.Item>}
                </Descriptions>
            }

            {viewedStoreReturn.status !== 'RECEIVED' && <Descriptions
                size="small"
                layout="vertical"
                labelStyle={{ fontWeight: "bold" }}
                bordered
                // column={{ xs: 3, md: 4 }}
                style={{ marginTop: "20px", marginBottom: "20px", }}>
                <Descriptions.Item span={1} label="Complete SR Return" style={{ width: "33%" }}>
                    <Col>
                        <>Is this a complete SR return?</>
                        <Checkbox
                            style={{ marginLeft: ".5rem" }}
                            disabled={["RECEIVED"].includes(viewedStoreReturn.status)}
                            checked={isFullOrderReturn}
                            onChange={(e) => {
                                if (e.target.checked === false) {
                                    // setFullSrReason(undefined);
                                }
                                setIsFullOrderReturn(e.target.checked);
                            }}
                        />
                    </Col>

                </Descriptions.Item>
                <Descriptions.Item span={1} label="Select Reason" style={{ width: "33%" }}>
                    <Col>
                        <Select
                            disabled={!isFullOrderReturn}
                            placeholder={"Select Reason"}
                            value={fullSrReason}
                            onChange={(e) => {
                                setFullSrReason(e);
                            }}
                        >
                            {Object.entries(fullSrReturnOptions).map(([key, value]) => <Select.Option key={key} value={key}>{value}</Select.Option>)}
                        </Select>
                    </Col>
                </Descriptions.Item>
                <Descriptions.Item span={1} label="Action" style={{ width: "33%" }}><Col>
                    <Button type="primary" onClick={onFullSrReturn} loading={status === 'loading' || status === 'saving'}
                        disabled={status === 'loading' || status === 'saving' || (isFullOrderReturn && fullSrReason == undefined)}
                    >
                        Confirm
                    </Button>
                </Col>
                </Descriptions.Item>
            </Descriptions>}

            {viewedStoreReturn.ticketCategoryLabel && <Descriptions
                size="small"
                layout="vertical"
                labelStyle={{ fontWeight: "bold" }}
                bordered
                column={{ xs: 3, md: 4 }}
                style={{ marginTop: "20px", marginBottom: "20px", }}>

                <Descriptions.Item label="Complete SR Return Reason"><Col>
                    {fullSrReturnOptions[viewedStoreReturn.ticketCategoryLabel ?? ""] ?? viewedStoreReturn.ticketCategoryLabel}
                </Col>
                </Descriptions.Item>
            </Descriptions>}
            {(
                <Row style={{ marginTop: "20px" }}>
                    <Col xs={24}>
                        <Form form={form} component={false}>
                            <Table
                                className="po-item-table"
                                components={{
                                    body: {
                                        cell: EditableCell,
                                    },
                                }}
                                bordered
                                dataSource={tableRows}
                                columns={tableColumns}
                                rowClassName={(record, index) =>
                                    index === (tableRows?.length ?? 0) - 1
                                        ? "editable-last-row editable-row"
                                        : "editable-row"
                                }
                                pagination={false}
                                scroll={{ x: "100vh", y: "65vh" }}
                                style={{ width: "100%" }}
                                size="small"
                                footer={() => (
                                    <Row >
                                        <Col>
                                            <Button
                                                type="primary"
                                                icon={<BsPlus />}
                                                onClick={onAddItem}
                                                disabled={isAddingItem || ["RECEIVED"].includes(viewedStoreReturn.status) || isFullOrderReturn}
                                            >
                                                Add Item
                                            </Button>
                                        </Col>
                                    </Row>
                                )}
                            />
                        </Form>
                    </Col>
                </Row>
            )}
        </>
    );

    return (
        <>
            <Row
                style={{
                    margin: "1rem 0",
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                    background: variables.wmBackgroundColorLight1,
                    padding: "5px",
                }}
            >
                <Col xs={24}>
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => navigate(-1)} >
                            <Link style={{ color: "grey" }}>Store Returns</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            {srId || "Store Return"}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col>
                    <AppPageHeader title="Store Return" />
                </Col>
            </Row>
            <Row>
                <Col xs={24}>{poInfo}</Col>
            </Row>
        </>
    );
};

export default ViewCreateStoreReturnPage;
