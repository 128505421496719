import { Row, Col, Table, Tag, message, Button, Popconfirm } from "antd";
import { ColumnsType } from "antd/lib/table";
import { IRequisition } from "common/interface";
import { routes } from "common/routes";
import { getSlotName, openInNewTab } from "common/utils";
import { AppActionStrip } from "components/AppActionStrip/AppActionStrip";
import { AppFilterButton, AppFilter } from "components/AppFilter";
import { AppRequisitionFilter } from "components/AppFilter/AppRequisitionFilter/AppRequisitionFilter";
import { AppPageHeader } from "components/AppPageHeader";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useDocumentTitle } from "hooks/usePageTitle";
import { useEffect, useState, useCallback } from "react";
import { Link, useSearchParams } from "react-router-dom";
import {
  getRequisitions,
  markRequisitionDispatched,
} from "store/requisition/requisitionSlice";

type PartialRequisitionType = Partial<IRequisition> & { key: string };

const headerKeys: { [key: string]: string } = {
  name: "Store Name",
  routeId: "Route ID",
  date: "Date",
  slot: "Slot",
  address: "Address",
  status: "Status",
  storeDeliveryType: "Store\nDelivery Type",
  // challanUrl: "Challan URL", 
  // dispatchCount: "Count",
  srType: "SR Type",
  actions: "Actions",
};

const MESSAGE_KEY = "all_requisitions";

export const AllRequisitionsPage = () => {
  useDocumentTitle("All Requisitions");
  const dispatch = useAppDispatch();
  const { requisitions, status, error, slots } = useAppSelector(
    (state) => state.requisition
  );
  const [columns, setColumns] = useState<ColumnsType<PartialRequisitionType>>();
  const [rows, setRows] = useState<PartialRequisitionType[]>();
  const [popConfirmReqId, setPopConfirmReqId] = useState<number>();
  const [filterState, setFilterState] = useState(false);
  const [searchParams] = useSearchParams();
  const [filterCount, setFilterCount] = useState(0);

  const getData = useCallback(
    () => {
      dispatch(getRequisitions());
    },
    [dispatch]
  );

  useEffect(() => {
    getData();
  }, [dispatch, getData]);

  useEffect(() => {
    if (status === "loading") {
      message.loading({ content: "Loading Requisitions", key: MESSAGE_KEY });
    } else if (status === "success") {
      message.success({
        content: "Requisitions loaded!",
        key: MESSAGE_KEY,
      });
    } else if (status === "error") {
      message.error({ content: error, key: MESSAGE_KEY });
    }

    return () => {
      message.destroy();
    };
  }, [status, error, requisitions]);

  useEffect(() => {
    const data: PartialRequisitionType[] = requisitions.map(
      (requisition, i) => {
        const {
          store: { address, name, id: storeId, storeDeliveryType },
          createdOn,
          status,
          id,
          dispatching,
          routeId,
          date,
          slot,
          challanUrl,
          dispatchCount,
          srType,
        } = requisition;

        const newRow = {
          address,
          name,
          status,
          createdOn,
          routeId,
          id,
          // store,
          storeId,
          dispatching,
          date,
          slot: slots && slot ? getSlotName(slots, slot) : "",
          storeDeliveryType,
          challanUrl,
          dispatchCount,
          srType,
        };
        return { ...newRow, key: `req-${i}` };
      }
    );

    const tableHeaders: ColumnsType<PartialRequisitionType> = [
      ...Object.keys(headerKeys).map((key) => ({
        key,
        title: headerKeys[key],
        dataIndex: key,
        render: (text: string, record: PartialRequisitionType) => {
          // record.dispatchCount = 9;
          if (key === "status") {

            return <Col>
              <Row>
                <Tag>{text}</Tag>
              </Row>
              <Row style={{ fontSize: ".8rem" }}>
                {(record?.dispatchCount ?? 0) > 0 && <>Dispatched Count: {record.dispatchCount}</>}
              </Row>
            </Col>;
          }

          if (key === 'srType') {
            return <Row align="middle"
              style={{
                display: "flex",
                flexWrap: "wrap",
              }}>
              <Row style={{ marginRight: ".5rem", width: "2rem", }}>{record.srType}</Row>
            </Row>
          }

          if (key === "dispatchCount") {
            return <>{record.dispatchCount ?? 0}</>;
          }

          // if (key === "challanUrl") {
          //   return <Button
          //     onClick={() => {
          //       openInNewTab(record.challanUrl!);
          //     }}
          //     disabled={record.challanUrl == undefined || record.challanUrl == undefined}
          //   >
          //     Download
          //   </Button>
          // }

          if (key === "dispatchCount") {
            return <>{record.dispatchCount ?? 0}</>;
          }

          if (key === "challanUrl") {
            return <Button
              onClick={() => {
                openInNewTab(record.challanUrl!);
              }}
              disabled={record.challanUrl == undefined || record.challanUrl == undefined}
            >
              Download
            </Button>
          }

          if (key === "actions") {
            return (
              <Row
                align="middle"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: ".5rem",
                }}
              >
                <Col style={{}}>
                  <Button size="small">
                    <Link to={`/${routes.STORE_REQUISITION.SELF}/${record.id}`}>
                      View
                    </Link>
                  </Button>
                </Col>
                <Col>
                  <Popconfirm
                  title={"Are you sure you want to dispatch this?"}
                  disabled={
                    !(
                      (["READY_FOR_DISPATCH"].includes(record.status ?? "") || (record?.dispatchCount === 0 && ["BULK_PENDING"].includes(record.status ?? "")))
                      ) || record.dispatching
                  }
                  onConfirm={() => dispatch(markRequisitionDispatched(record.id!.toString()))}
                  >
                  <Button
                    type="primary"
                    size="small"
                    ghost
                    disabled={
                      !(
                        (["READY_FOR_DISPATCH"].includes(record.status ?? "") || (record?.dispatchCount === 0 && ["BULK_PENDING"].includes(record.status ?? "")))
                      ) || record.dispatching
                    }
                    loading={record.dispatching}
                  >
                    Dispatch
                  </Button>
                  </Popconfirm>
                </Col>
              </Row>
            );
          }

          if (key == "name") {
            //@ts-ignore
            return <>{record["name"]} || {record["storeId"]}</>
          }

          if (key === "storeDeliveryType") {
            return <div style={{ color: text === "Unattended" ? "green" : "red" }}>{text}</div>
          }

          return <>{text}</>;
        },
      })),
    ];

    setColumns(tableHeaders);
    const filter = searchParams.toString();

    let { storeId, routeId } = Object.fromEntries(new URLSearchParams(filter));
    let temp = data.filter((e: any) => {
      return storeId ? e.storeId == storeId : true;
    });
    temp = temp.filter((e) => routeId ? e.routeId == routeId : true);
    console.log("Route ID", routeId);
    
    setRows(temp);
  }, [dispatch, popConfirmReqId, requisitions, slots, filterState, searchParams]);

  return (
    <>
      <Row>
        <Col>
          <AppPageHeader title="All Requisitions" />
        </Col>
      </Row>
      <Row>
        <AppActionStrip>
          <div className="action-strip__item">
            <AppFilterButton
              active={filterState}
              onClick={() => setFilterState(!filterState)}
              filterCount={filterCount}
            />
          </div>
        </AppActionStrip>
      </Row>
      <Row style={{ marginBottom: "1rem" }}>
        <AppFilter expanded={filterState}>
          <AppRequisitionFilter />
        </AppFilter>
      </Row>
      {rows && rows.length ? (
        <Row>
          <Col xs={24}>
            <Table
              columns={columns}
              dataSource={rows}
              pagination={false}
              scroll={{ x: "100vh" }}
            />
          </Col>
        </Row>
      ) : (
        status !== "loading" && <>No Requisitions to show.</>
      )}
    </>
  );
};
