import {
  Row,
  Col,
  Typography,
  Table,
  Button,
  message,
  Descriptions,
} from "antd";
import { routes } from "common/routes";
import { generateHeaders, getSlotName } from "common/utils";
import { AppPageHeader } from "components/AppPageHeader";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useDocumentTitle } from "hooks/usePageTitle";
import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  cancelProcessingRequisitionUserMapping,
  removeProcessingRequisitionUserMapping,
  saveRequisitionUserMapping,
} from "store/requisition/requisitionSlice";

const MESSAGE_KEY = "SAVE_USER_MAPPING";

export const SaveUserMappingStoreRequisitionPage = () => {
  useDocumentTitle("Requisition: Save User Picking");
  const { processingRequistionUserMapping, status, error, slots } =
    useAppSelector((state) => state.requisition);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const { timeUploaded } = processingRequistionUserMapping;
    if (timeUploaded) {
      const uploadedTime = new Date(timeUploaded).getTime();
      const currentTime = new Date().getTime();
      const minsPassed = (currentTime - uploadedTime) / 1000 / 60;

      if (minsPassed >= 15) {
        dispatch(removeProcessingRequisitionUserMapping());
      }
    }

    if (!Object.keys(processingRequistionUserMapping).length) {
      navigate(`/${routes.STORE_REQUISITION.USER_MAPPING_UPLOAD}`);
    }
  }, [dispatch, processingRequistionUserMapping, navigate]);

  useEffect(() => {
    if (status === "loading") {
      message.loading({ content: "saving user mapping", key: MESSAGE_KEY });
    } else if (status === "success") {
      message.success({
        content: "User mapping saved!",
        duration: 5,
        key: MESSAGE_KEY,
      });
    } else if (status === "cancelling") {
      message.loading({ content: "cancelling user mapping", key: MESSAGE_KEY });
    } else if (status === "error") {
      message.error({ content: error, key: MESSAGE_KEY });
    }

    return () => {
      message.destroy();
    };
  }, [status, error]);

  let content;
  const { headerMapping } = processingRequistionUserMapping;
  const { Text } = Typography;

  const generateMemoizedHeaders = useMemo(
    () => generateHeaders(headerMapping),
    [headerMapping]
  );

  const onSaveUserMapping = () => {
    dispatch(saveRequisitionUserMapping());
  };

  const onCancelSave = () => {
    dispatch(cancelProcessingRequisitionUserMapping());
  };

  if (!Object.keys(processingRequistionUserMapping).length) {
    content = <Text>Nothing to show here!</Text>;
  } else {
    const headers = generateMemoizedHeaders || {};
    const successRows = processingRequistionUserMapping.successRows;
    const data = successRows?.map((row, i) => ({ ...row, key: `data-${i}` }));
    const columns = [
      ...Object.keys(headers).map(
        (key) =>
          ({
            key,
            title: headers[key],
            dataIndex: key,
          } as any)
      ),
    ];
    content = (
      <Table
        size="small"
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{ y: 500 }}
        summary={() => (
          <Table.Summary fixed="bottom">
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={2}>
                <Row>
                  <Col>
                    <Button
                      type="primary"
                      onClick={onSaveUserMapping}
                      loading={status === "loading"}
                      disabled={status === "loading"}
                    >
                      Save Requisition
                    </Button>
                  </Col>
                  <Col style={{ justifyContent: "left", marginLeft: "20px" }}>
                    <Button
                      onClick={onCancelSave}
                      disabled={status === "loading"}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
    );
  }

  return (
    <>
      <Row>
        <Col>
          <AppPageHeader title="Save Requisition User Picking" />
        </Col>
      </Row>
      <Row style={{ marginBottom: "1rem" }}>
        {!!(slots && processingRequistionUserMapping.slot) && (
          <Descriptions
            size="small"
            layout="vertical"
            labelStyle={{ fontWeight: "bold" }}
            bordered
            column={{ xs: 2 }}
          >
            <Descriptions.Item label="Date">
              {processingRequistionUserMapping.date}
            </Descriptions.Item>
            <Descriptions.Item label="Slot">
              {getSlotName(slots, processingRequistionUserMapping.slot)}
            </Descriptions.Item>
          </Descriptions>
        )}
      </Row>
      <Row>
        <Col xs={24}>{content}</Col>
      </Row>
    </>
  );
};
