import { ComponentWithPropsChildren } from "common/interface";
import styled from "styled-components";

interface ActionStripProps {
  sticky?: boolean;
}

const ActionStrip = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 1rem;
  background-color: white;

  & > .action-strip {
    &__items {
      width: fit-content;
      display: flex;

      & > .action-strip__item {
        &:first-child:not(:last-child) {
          margin-right: 1rem;
        }
      }
    }
  }
`;

export const AppActionStrip = (
  props: ComponentWithPropsChildren & ActionStripProps
) => (
  <ActionStrip>
    <div className="action-strip__items">{props.children}</div>
  </ActionStrip>
);
