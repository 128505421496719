import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd";
import { log } from "console";
import { useAppSelector } from "hooks/useAppSelector";
import moment from "moment";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

const StyledFilterContainer = styled.div`
  &,
  & .filter {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    &__item {
      margin: 0.5rem;

      & .ant-form-item {
        margin: 0;
      }

      &--submit {
        align-self: flex-end;
      }
    }
  }
`;

export const CreateStoreReturnFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [form] = Form.useForm();
  const { slots } = useAppSelector((state) => state.requisition);

  useEffect(() => {
    const params = Object.fromEntries(new URLSearchParams(searchParams));
    form.setFieldsValue(params);
  }, [form, searchParams]);

  const onSearch = async (values: Record<string, string>) => {
    const params: Record<string, string> = {};
    let valid = await form.validateFields();
    
    if (valid["date"] != null && valid["slot"] != null) {
      Object.entries(values).forEach(([key, value]) => {
        if (value) {
          params[key] =
            typeof value === "string" ? value :  ( value as unknown as moment.Moment).format("YYYY-MM-DD");
        }
      });
      setSearchParams(params);
    }
  };

  return (
    <StyledFilterContainer>
      <Form
        form={form}
        name="vendor-filter-form"
        onFinish={onSearch}
        layout="vertical"
        style={{ width: "100%" }}
      >
        <Row className="filter">
          <Col xs={20} sm={4} md={5} lg={4} className="filter__item">
            <Form.Item name="storeId" label="Store ID">
              <Input
                placeholder="Search by Store ID"
                size="small"
                allowClear
              />
            </Form.Item>
          </Col>

          <Col xs={20} sm={4} md={5} lg={4} className="filter__item">
            {slots && <Form.Item name="slot" label="Slot"  required={true}>
              <Select placeholder="Search by Slot" size="small">
                {Object.entries(slots).map(([label, value]) => (
                  <Select.Option value={value} key={value}>
                    {label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>}
          </Col>

          <Col xs={20} sm={4} md={5} lg={4} className="filter__item">
            <Form.Item name="date" label="Date" required={true} valuePropName={'date'}>
              <DatePicker placeholder="Search by Date" size="small" />
            </Form.Item>
          </Col>

          <Col
            xs={20}
            sm={4}
            md={2}
            lg={2}
            className="filter__item filter__item--submit"
          >
            <Form.Item>
              <Button type="primary" htmlType="submit" size="small">
                Search
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </StyledFilterContainer>
  );
};
