import { Row, Col, message, Table, Button, Checkbox } from "antd";
import { ColumnsType } from "antd/lib/table";
import { AppPageHeader } from "components/AppPageHeader";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useDocumentTitle } from "hooks/usePageTitle";
import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { clearCurrentRoutes, getClusterRoutes, getClustersListing, mergeClusterRoutes } from "store/middle-mile/middleMileSlice";
import { AppFilter } from "components/AppFilter";
import { ClusterRoute } from "store/middle-mile/interface/cluster-route";
import { AppClusterRouteFilter } from "components/AppFilter/AppClusterRouteFilter/AppMiddleMileTrackingFilter";
import { Key } from "antd/lib/table/interface";
import { AppActionStrip } from "components/AppActionStrip/AppActionStrip";

type PartialTrackingInfo = Partial<ClusterRoute> & { key: string, remark?: string };
const MESSAGE_KEY = "middle_mile_tracking";

const headerKeys: { [key: string]: string } = {
    routeId: "Route",
    storeIds: "Store Ids",
    deliveryDate: "Delivery Date",
    slot: "Slot",
    dispatchTime: "Dispatch Time",
};

const MergeRoutesPage = () => {
    useDocumentTitle("Middle Mile: Merge Routes");
    const dispatch = useAppDispatch();
    const [columns, setColumns] = useState<ColumnsType<PartialTrackingInfo>>();
    const [rows, setRows] = useState<PartialTrackingInfo[]>();
    const [selectedIds, setSelectedIds] = useState<Key[]>([]);
    const { currentClusterRoutes, status, error } = useAppSelector((state) => state.middleMile);
    const [searchParams] = useSearchParams();

    const getClusterRoutesFromId = useCallback(
        () => {
            if (Object.fromEntries(new URLSearchParams(searchParams),)["clusterId"] != undefined) {
                // setSelectedIds([]);
                dispatch(getClusterRoutes(Object.fromEntries(new URLSearchParams(searchParams),),),);
            }
        },
        [dispatch, searchParams]
    );

    useEffect(() => {
        dispatch(getClustersListing());
        return () => {
            dispatch(clearCurrentRoutes());
        };
    }, []);

    useEffect(() => {
        if (Object.fromEntries(new URLSearchParams(searchParams),)["clusterId"] != "") {
            getClusterRoutesFromId();
        }
    }, [dispatch, getClusterRoutesFromId, searchParams]);

    useEffect(() => {
        if (status === "loading") {
            message.loading({ content: "Loading Tracking Details", key: MESSAGE_KEY });
        } else if (status === "success") {
            // message.success({
            //   content: "Admin Users loaded!",
            //   key: MESSAGE_KEY,
            // });
        } else if (status === "error") {
            message.error({ content: error, key: MESSAGE_KEY });
        }

        return () => {
            message.destroy();
        };
    }, [status, error]);

    useEffect(() => {

        const data: PartialTrackingInfo[] = (currentClusterRoutes ?? []).map((au, i) => {
            return { ...au, key: `trackingInfo-${i}`, ellipses: true };
        });

        const tableHeaders: ColumnsType<PartialTrackingInfo> = [
            ...Object.keys(headerKeys).map((key) => ({
                key,
                title: headerKeys[key],
                dataIndex: key,
                render: (text: string, record: PartialTrackingInfo) => {
                    if (key === 'id') {
                        return <Checkbox></Checkbox>
                    }
                    if (key === 'storeIds') {
                        return record.storeIds?.join(", ");
                    }
                    if (key === "deliveryDate") {
                        return (
                            <>
                                {record.deliveryDate?.split("T")[0]}
                            </>
                        );
                    }

                    return <>{text}</>;
                },
            })),
        ];

        setColumns(tableHeaders.map((e) => {
            if (e.key === "remarks") {
                e.width = "16rem";
            }
            if (e.key === "time") {
                e.width = "13rem";
            }
            if (e.key === "id") {
                e.width = "2rem";
            }

            return e;
        }));

        setRows(data);
    }, [currentClusterRoutes]);

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedIds(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys: selectedIds,
        onChange: onSelectChange,
    };

    const mergeRoutes = useCallback(async () => {
        let clusterIds: number[] = [];
        rows!.filter(e => selectedIds?.includes(e.key)).forEach((e: PartialTrackingInfo) => {
            clusterIds = [...clusterIds, ...(e?.ids ?? [])]
        });
        setSelectedIds([]);
        await dispatch(mergeClusterRoutes({ clusterIds: clusterIds.map(e => e.toString()) }));
    }, [selectedIds, rows]);

    return (
        <>
            <Row>
                <Col>
                    <AppPageHeader title="Middle Mile: Merge Routes" />
                </Col>
            </Row>
            <Row>
                <AppActionStrip>
                    <Button
                        type="primary"
                        onClick={mergeRoutes}
                        disabled={(selectedIds ?? []).length < 1}
                    >
                        Merge Routes
                    </Button>
                </AppActionStrip>
            </Row>
            <Row style={{ marginBottom: "1rem" }}>
                <AppFilter expanded={true}>
                    <AppClusterRouteFilter />
                </AppFilter>
            </Row>
            {rows && rows.length ? (
                <Row>
                    <Col xs={24}>
                        <Table
                            columns={columns}
                            dataSource={rows}
                            scroll={{ x: "100vh", y: "65vh" }}
                            size="small"
                            pagination={false}
                            loading={status === "loading"}
                            bordered
                            rowSelection={rowSelection}
                        />
                    </Col>
                </Row>
            ) : (
                status !== "loading" && <>No Tracking Details to show.</>
            )}
        </>
    );
};

export default MergeRoutesPage;