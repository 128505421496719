import { Button, Col, Form, Row, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { StateProps, WhInfo } from "common/interface";
import { h1 } from "common/styles";
import { variables } from "common/variables";
import { AppLayout } from "components/AppLayout";
import { AppLogo } from "components/AppLogo";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useDocumentTitle } from "hooks/usePageTitle";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getPersistedUser } from "services/PersistService/PersistUserService";
import { getWarehouseId } from "services/PersistService/PersistWareHouseReturn";
import { logout, updateWarehouseId } from "store/app/appSlice";
import styled from "styled-components";


const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

const StyledRow = styled(Row)`
  height: 100%;
`;

const StyledH1 = styled.h1`
  ${h1};
  text-align: center;
`;

export const WarehouseSelectionPage = () => {
    useDocumentTitle("Select Warehouse");
    const location = useLocation();
    const navigate = useNavigate();
    const { user, status, whId } = useAppSelector((state) => state.app);
    const dispatch = useAppDispatch();
    const [form] = useForm();

    // useEffect(() => {
    //     const userInfo = getPersistedUser();
    //     if (userInfo.user?.mappedWh?.length) {
    //         dispatch(updateWarehouseId(userInfo.user?.mappedWh?.[0]?.id ?? 1));
    //     }
    //     else {
    //         dispatch(updateWarehouseId(1));
    //     }
    //     const from = (location?.state as StateProps)?.from?.pathname || "/";
    //     navigate(from, { replace: true });
    // }, []);

    const updateWhId = async (values: Record<string, string>) => {
        if (await form.validateFields()) {
            try {
                const from = (location?.state as StateProps)?.from?.pathname || "/";
                dispatch(updateWarehouseId(values["whId"]));
                navigate(from, { replace: true });
            } catch (e) {
                console.log(e);
            }
        }
    };

    const goToLogin = async () => {
        dispatch(logout());
        navigate("/login", { replace: true });
    };


    return <AppLayout style={{ padding: 0 }}>
        <StyledRow justify="center">
            <Col
                md={12}
                xs={24}
                style={{
                    backgroundColor: variables.wmBackgroundColorLight1, width: "100%", display: "flex",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Row
                    style={{
                        display: "flex",
                        height: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Col>
                        <FormContainer>
                            {/* <AppLogo /> */}
                            <StyledH1>Select warehouse</StyledH1>
                            {user.user?.mappedWh?.length ? <>
                                <Form form={form} onFinish={updateWhId} initialValues={{ "whId": whId }}>
                                    <Form.Item name={"whId"} rules={[
                                        {
                                            required: true,
                                            message: "Pleastorese select Warehouse.",
                                        },
                                    ]}>
                                        <Select
                                            placeholder="Warehouse ID"
                                            size="large"
                                            style={{ borderRadius: "10px", width: "20rem" }}
                                            options={(user.user?.mappedWh ?? []).map((whInfo: WhInfo) => ({
                                                value: whInfo.id,
                                                label: `${whInfo.name} || ${whInfo.id}`,
                                            }),)}
                                            loading={status === "loading"}
                                        />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            block
                                            // size="large"
                                            style={{ borderRadius: "10px", height: "40px" }}
                                            loading={status === "loading"}
                                        >
                                            Proceed
                                        </Button>
                                    </Form.Item>
                                    {/* <Button
                                        block
                                        size="large"
                                        style={{ borderRadius: "10px", height: "60px", marginTop: "30px" }}
                                        loading={status === "loading"}
                                        onClick={goToLogin}
                                    >
                                        Go to Login Instead
                                    </Button> */}
                                </Form>
                            </> : <>
                                Sorry, you don not have any warehouse assigned. Please contact support.
                                <Button
                                    block
                                    size="large"
                                    style={{ borderRadius: "10px", height: "40px", marginTop: "30px" }}
                                    loading={status === "loading"}
                                    disabled={status === "loading"}
                                    onClick={goToLogin}
                                >
                                    Go to Login
                                </Button>
                            </>}
                        </FormContainer>
                    </Col>
                </Row>
            </Col>
        </StyledRow>
    </AppLayout>

}