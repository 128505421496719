import {
  Row,
  Col,
  Typography,
  Table,
  Button,
  message,
} from "antd";
import { AppLogo } from "components/AppLogo";
import { AppPageHeader } from "components/AppPageHeader";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useDocumentTitle } from "hooks/usePageTitle";
import { useEffect, useRef, useState } from "react";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import {
  getSuggestedPicking,
} from "store/requisition/requisitionSlice";

const MESSAGE_KEY = "SUGGESTED_PICKING";

export const SuggestedPickingPage = () => {
  useDocumentTitle("Suggested Inv Picking");
  const { status, error, masterSkus } = useAppSelector(
    (state) => state.requisition
  );
  const dispatch = useAppDispatch();
  const [suggestionData, setSuggestionData] = useState({});
  const [content, setContent] = useState<any>(<></>);
  const [isPrinting, setIsPrinting] = useState<boolean>(false);

  useEffect(() => {
    fetchSuggestions();
  }, []);

  const fetchSuggestions = async () => {
    const reduxResponse = await dispatch(getSuggestedPicking());
    if (reduxResponse.type === "requisitions/suggested-picking/fulfilled") {
      setSuggestionData((reduxResponse.payload));
    }
  }

  useEffect(() => {
    if (status === "loading") {
      message.loading({ content: "Loading Schedule", key: MESSAGE_KEY });
    } else if (status === "error") {
      message.error({ content: error, key: MESSAGE_KEY });
    }
    return () => {
      message.destroy();
    };
  }, [status, error]);

  const { Text } = Typography;
  const componentRef = useRef(null);

  useEffect(() => {
    if (!Object.keys(suggestionData).length) {
      setContent(<Text>Nothing to show here!</Text>);
    } else {
      const keys = Object.keys(suggestionData);
      const rows = (Object.values(suggestionData).map((e, index) => ({ ...Object(e), "key": `picking-suggestion=${index}`, "Sku Code": keys[index]})));
      const columns = [
        ...["Sku Code", "D1", "D2", "D3", "D4",].map(
          (key) =>
          ({
            key,
            title: key,
            dataIndex: key,
            width: key === "Sku Code" ? "40%" : "15%",
            render: (text: string, record: any) => {
              if (key === "Sku Code") {
                const skuItem = masterSkus?.find(e => e.skuCode === record["Sku Code"]);
                return (
                  <>
                    <div>{skuItem?.skuCode}</div>
                    <div>{skuItem?.name}</div>
                  </>
                );
              }
              return <>{text ?? 0}</>;
            },
          } as any)
        ),
      ];
      setContent(
        <Table
          className={isPrinting ? "printing-view" : ""}
          size="small"
          columns={columns}
          dataSource={rows}
          scroll={isPrinting ? { y: 500, x: "100vh" } : {}}
          pagination={false}
        />
      );
    }
  }, [suggestionData]);

  return (
    <div ref={componentRef} style={isPrinting ? {"marginLeft": "1rem", "marginRight": "1rem",} : {}}>
    {isPrinting && <Row style={{
        margin: "1rem 0",
        position: "sticky",
        top: 0,
        zIndex: 1,
        padding: "5px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}>
        <AppLogo />
      </Row>}
      <Row style={{"marginBottom": "1rem"}}>
        <Col>
          <AppPageHeader title="Suggested Inv Picking" />
        </Col>
      </Row>
      <Row style={{"marginBottom": "1rem"}}>
      {!isPrinting && <ReactToPrint
        trigger={() => <Button type="primary">Print</Button>}
        content={() => componentRef.current}
        onBeforeGetContent={async () => {
          setIsPrinting(true)
        }}
        onAfterPrint={() => setIsPrinting(false)}
      />}
      </Row>
      <Row>
        <Col xs={24}>
          {content}
        </Col>
      </Row>
    </div>
  );
};
