import { Button, Col, FormInstance, Row, Spin, Upload } from 'antd';
import { useEffect, useState } from 'react';

import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { useAppSelector } from 'hooks/useAppSelector';
import { AUTH_HEADER_NAME, apis } from 'common/apis';
import { MdDeleteOutline } from 'react-icons/md';
import { GrView } from 'react-icons/gr';

const CustomMultiUploadField = ({ form, fieldName, uploadApi }: { form: FormInstance, fieldName: string, uploadApi: string }) => {
    const antIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;
    const { authToken } = useAppSelector((state) => state.app.user);

    const [localV, setLocalV] = useState<string[]>();
    const [showProgress, setShowProgress] = useState<boolean>(false);

    const headers: {} = {
        ...(authToken && { [AUTH_HEADER_NAME]: authToken }),
    };

    const updateValue = (val: string[]) => {
        setLocalV(val);
        form.setFieldsValue({[fieldName]: val});
    }

    useEffect(() => {
        setTimeout(() => {
            setLocalV(form.getFieldValue(fieldName));
        }, 50,);
    }, [form,],);

    return (
        <>
            <Col xs={24} md={2}>
            <Upload
                    name='files'
                    showUploadList={false}
                    multiple={true}
                    headers={headers}
                    action={uploadApi}
                    accept="image/*,video/mp4"
                    onChange={(value) => {
                        if (value?.file?.status == "done") {
                            if (value.file.response?.[0] != undefined) {
                                const temp = Array.from(localV ?? []);
                                (temp ?? []).push(value.file.response?.[0]);
                                updateValue(temp);
                            }
                            setShowProgress(false);
                        }
                        else {
                            setShowProgress(true);
                        }
                    }}>
                    <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
                {showProgress && <Spin indicator={antIcon} /> }
            </Col>
            {localV && <Col>
                {localV.map((e, index) => <Row key={index} style={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'start', 
                    margin: '.5rem 0'
                    }}>
                    {index + 1}.
                    <Button
                    style={{marginLeft: '.2rem'}}
                        icon={<GrView />}
                        title='View File'
                        onClick={() => {
                            window.open(e, "_blank")?.focus();
                        }}
                    >
                    </Button>
                    {!e.includes('fos/files') && <Button
                        style={{ marginLeft: '1rem' }}
                        icon={<MdDeleteOutline />}
                        type='primary'
                        onClick={() => {
                            const temp = Array.from(localV);
                            temp.splice(index, 1);
                            form.setFieldsValue({ [fieldName]: temp });
                            updateValue(temp);
                        }} />}
                </Row>)}
            </Col>}
        </>
    );
};

export default CustomMultiUploadField;