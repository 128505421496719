import { Row, Col, message, Table, Button, Form, DatePicker, Select } from "antd";
import { ColumnsType } from "antd/lib/table";
import { AppPageHeader } from "components/AppPageHeader";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useDocumentTitle } from "hooks/usePageTitle";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { AppFilter } from "components/AppFilter";
import { variables } from "common/variables";
import { IMiddleMile } from "common/interface/IMiddleMile";
import { getMiddleMileListing } from "store/middle-mile/middleMileSlice";

type PartialMiddleMile = Partial<IMiddleMile> & { key: string };
const MESSAGE_KEY = "all_middle_mile";

const headerKeys: { [key: string]: string } = {
  id: "ID",
};


const StyledButton = styled(Button)`
  &.filterButton {
    &--active {
      border-color: ${variables.primaryColor} !important;
    }
  }
`;

const AllMiddleMilesPage = () => {
  useDocumentTitle("All Middle Miles");
  const dispatch = useAppDispatch();
  const [columns, setColumns] = useState<ColumnsType<PartialMiddleMile>>();
  const [rows, setRows] = useState<PartialMiddleMile[]>();
  const { middleMiles, status, error } = useAppSelector((state) => state.middleMile);
  const [slot, setSlot] = useState<string>();
  const [deliveryDate, setDeliveryDate] = useState<string>();
  const { slots } = useAppSelector((state) => state.requisition);
  const getAdminUsersData = useCallback(
    () => {
      dispatch(getMiddleMileListing({ deliveryDate, slot }));
    },
    [dispatch]
  );
  const { Option } = Select;

  useEffect(() => {
    if (status === "loading") {
      message.loading({ content: "Loading Admin Users", key: MESSAGE_KEY });
    } else if (status === "success") {
      message.success({
        content: "Admin Users Loaded.",
        key: MESSAGE_KEY,
      });
    } else if (status === "error") {
      message.error({ content: error, key: MESSAGE_KEY });
    }

    return () => {
      message.destroy();
    };
  }, [status, error]);

  useEffect(() => {
    const middleMilesData = middleMiles;
    
    const data: PartialMiddleMile[] = middleMilesData.map((au, i) => {
      const {
        id,
      } = au;

      const newRow = {
        id,
      };
      return { ...newRow, key: `middle-mile-${i}`, ellipses: true };
    });

    const tableHeaders: ColumnsType<PartialMiddleMile> = [
      ...Object.keys(headerKeys).map((key) => ({
        key,
        title: headerKeys[key],
        dataIndex: key,
        render: (text: string, record: PartialMiddleMile) => {
          return <>{text}</>;
        },
      })),
    ];

    setColumns(tableHeaders);
    setRows(data);
  }, [middleMiles]);
  
  return (
    <>
      
      <Row>
        <Col>
          <AppPageHeader title="All Middle Mile Listing" />
        </Col>
      </Row>
      <Row style={{ marginBottom: "1rem" }}>
        <AppFilter expanded={true}>
          <Row>
            <Form.Item label="Date" name="deliveryDate" required style={{ marginRight: "1rem" }}>
              <DatePicker
                onChange={(val) => setDeliveryDate(val?.format("yyyy-MM-DD"))}
              />
            </Form.Item>
            {slots && (
              <Form.Item label="Slot" name="slot" required style={{ marginRight: "1rem" }}>
                <Select
                  placeholder="Select slot"
                  onChange={(val) => setSlot(val)}
                >
                  {Object.entries(slots).map(([label, value]) => (
                    <Option value={value} key={value}>
                      {label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {deliveryDate && slot ? <StyledButton onClick={getAdminUsersData}>Search</StyledButton> : <></>}
          </Row>
        </AppFilter>
      </Row>
      {rows && rows.length ? (
        <Row>
          <Col xs={24}>
            <Table
              columns={columns}
              dataSource={rows.filter(e => e.id !== -1)}
              scroll={{ x: "100vh", y: "65vh" }}
              size="small"
              pagination={false}
              loading={status === "loading"}
              bordered
            />
          </Col>
          {/* <Col xs={24}>
            <Paginator />
          </Col> */}
        </Row>
      ) : (
        status !== "loading" && <>No Admin User to show.</>
      )}

    </>
  );
};

export default AllMiddleMilesPage;
