import { FC } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from "hooks/useAppSelector";
import { getWarehouseId } from "services/PersistService/PersistWareHouseReturn";

interface PropType {
  component: React.FC;
}

export const ProtectedRoute: FC<PropType> = ({ component: Component }) => {
  const { user } = useAppSelector((state) => state.app);
  const whId = getWarehouseId();
  const location = useLocation();
  // console.log("Protected route", whId);
  
  if (user.isAuthenticated && whId !== undefined) return <Component />;
  return <Navigate to="/login" state={{ from: location }} replace />;
};
