import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApiServices, { apis as apiEndPoints } from "common/apis";
import { WhInfo } from "common/interface";
import { IApiError } from "common/interface/IApiError";
import { IPickerMappingUpload } from "common/interface/IPickerUploadScheme";
import { IStockerTranferApi, IStockTransfer, StockTransferItem } from "common/interface/IStockTransfer";
import { logoutIfNeeded } from "common/utils";
import { getProcessingHoldAdjustment, getProcessingStoreExchange, persistProcessingHoldAdjustment, persistProcessingStoreExchange, removeProcessingHoldAdjustment, removeProcessingStoreExchange } from "services/PersistService/PersistStockExchangeService";
import { RootState } from "store/store";

export interface State {
    status: "loading" | "success" | "error" | "idle" | "cancelling" | "saving";
    error?: string;
    inventoryTypes: string[];
    warehouses: WhInfo[];
    processingStockExchange: IPickerMappingUpload;
    processingHoldAdjustment: IPickerMappingUpload;
    stockTransferData?: IStockerTranferApi;
}

const initialState: State = {
    status: "idle",
    inventoryTypes: [],
    warehouses: [],
    processingStockExchange: getProcessingStoreExchange(),
    processingHoldAdjustment: getProcessingHoldAdjustment(),
};

export const fetchInventoryTypes = createAsyncThunk<string[]>("stock-exchange/inventory-types", async (_, thunkApi) => {
    const response = await ApiServices.get(apiEndPoints.inventoryTypes)
        .then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
});

export const fetchWarehouses = createAsyncThunk<WhInfo[]>("stock-exchange/warehouses", async (_, thunkApi) => {
    const response = await ApiServices.get(apiEndPoints.warehouses)
        .then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
});

export const cancelStockTranfer = createAsyncThunk("stock-exchange/upload/cancel", async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const { processingStockExchange } = state.stockExchangeServices;

    const response = await ApiServices.post(apiEndPoints.saveStockTransfer, {}, { params: { key: processingStockExchange!.key, cancel: 1 } })
        .then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
});

export const saveStockTranfer = createAsyncThunk("stock-exchange/upload/save", async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const { processingStockExchange } = state.stockExchangeServices;

    const response = await ApiServices.post(apiEndPoints.saveStockTransfer, {}, { params: { key: processingStockExchange!.key, } })
        .then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
});

export const cancelHoldAdjustment = createAsyncThunk("stock-exchange/hold-adjustment/upload/cancel", async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const { processingHoldAdjustment } = state.stockExchangeServices;

    const response = await ApiServices.post(apiEndPoints.saveHoldAdjustment, {}, { params: { key: processingHoldAdjustment!.key, cancel: 1 } })
        .then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
});

export const saveHoldAdjustment = createAsyncThunk("stock-exchange/hold-adjustment/upload/save", async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const { processingHoldAdjustment } = state.stockExchangeServices;

    const response = await ApiServices.post(apiEndPoints.saveHoldAdjustment, {}, { params: { key: processingHoldAdjustment!.key, } })
        .then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
});

export const getStockTranfers = createAsyncThunk<IStockerTranferApi, { pageNo: number, pageSize: number, filter: string, isReceivePage?: boolean }>("stock-exchange/", async ({ pageNo = 1, pageSize = 25, filter = "", isReceivePage=false }, thunkApi) => {
    const temp = Object.fromEntries(new URLSearchParams(filter));
    
    const filterParams = filter
        ? {...temp}
        : "";
    const response = await ApiServices.get(apiEndPoints.getStockTransfers, { params: { pageNo, pageSize, isReceiving: isReceivePage, ...filterParams } })
        .then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
});

export const getStockTranferInfo = createAsyncThunk<IStockTransfer, { id: number }>("stock-exchange/id", async ({ id }, thunkApi) => {

    const response = await ApiServices.get(`${apiEndPoints.getStockTransfers}/${id}`)
        .then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
});

export const updateStockTranferItemAtSrcWH = createAsyncThunk<IStockTransfer, { newItem: StockTransferItem, stId: number, }>("stock-exchange/item/update", async ({ newItem, stId }, thunkApi) => {

    const response = await ApiServices.post(`${apiEndPoints.getStockTransfers}/${stId}/item`, { skuCode: newItem.skuCode, srcQty: newItem.srcQty })
        .then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
});

export const updateStockTranferItemAtDstWH = createAsyncThunk<IStockTransfer, { newItem: StockTransferItem, stId: number, }>("stock-exchange/item/update-dst", async ({ newItem, stId }, thunkApi) => {

    const response = await ApiServices.post(`${apiEndPoints.getStockTransfers}/${stId}/dest-qty`, { skuCode: newItem.skuCode, srcQty: newItem.destQty })
        .then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
});

export const deleteStockTranferItem = createAsyncThunk<IStockTransfer, { newItem: StockTransferItem, stId: number, }>("stock-exchange/item/delete", async ({ newItem, stId }, thunkApi) => {

    const response = await ApiServices.post(`${apiEndPoints.getStockTransfers}/${stId}/item-delete`, {}, { params: { skuCodes: newItem.skuCode } })
        .then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
});

export const updateSTStatus = createAsyncThunk<{}, { currentST: IStockTransfer, }>("stock-exchange/status/update", async ({ currentST }, thunkApi) => {
    const ep: Record<string, string> = { 
        "DEFAULT": "dispatch", 
        "IN_TRANSIT": "reached-destination",
        "REACHED_DEST": "received", 
    }
    const response = await ApiServices.post(`${apiEndPoints.getStockTransfers}/${currentST.id}/${ep[currentST.status]}`,)
        .then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
});

const stockExchangeSlice = createSlice({
    name: "stockExchange",
    initialState,
    reducers: {
        setProcessingST: (state, action) => {
            state.status = "idle";
            state.error = "";
            state.processingStockExchange = action.payload;
            state.processingStockExchange.timeUploaded = new Date().toString();
            persistProcessingStoreExchange(state.processingStockExchange);
        },
        removeProcessingST: (state) => {
            state.processingStockExchange = {};
            state.status = "idle";
            state.error = "";
            removeProcessingStoreExchange();
        },
        setProcessingHoldAdjustment: (state, action) => {
            state.status = "idle";
            state.error = "";
            state.processingHoldAdjustment = action.payload;
            state.processingHoldAdjustment.timeUploaded = new Date().toString();
            persistProcessingHoldAdjustment(state.processingStockExchange);
        },
        removeProcessingHold: (state) => {
            state.processingStockExchange = {};
            state.status = "idle";
            state.error = "";
            removeProcessingHoldAdjustment();
        },
    },
    extraReducers(builder) {
        builder
            /**
            * Save inventory Types
            **/
            .addCase(fetchInventoryTypes.pending, state => {
                state.status = "loading";
            })
            .addCase(fetchInventoryTypes.fulfilled, (state, action) => {
                state.status = "idle";
                state.error = "";
                state.inventoryTypes = action.payload;
            })
            .addCase(fetchInventoryTypes.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";
                if (error.message) {
                    state.error = error.message;
                }
            })
            /**
            * Save warehouses
            **/
            .addCase(fetchWarehouses.pending, state => {
                state.status = "loading";
            })
            .addCase(fetchWarehouses.fulfilled, (state, action) => {
                state.status = "idle";
                state.error = "";
                state.warehouses = action.payload;
            })
            .addCase(fetchWarehouses.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";
                if (error.message) {
                    state.error = error.message;
                }
            })
            /**
            * Cancel Upload
            **/
            .addCase(cancelStockTranfer.pending, state => {
                state.status = "loading";
            })
            .addCase(cancelStockTranfer.fulfilled, state => {
                state.status = "idle";
                state.error = "";
                state.processingStockExchange = {};
                removeProcessingStoreExchange();
            })
            .addCase(cancelStockTranfer.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";
                if (error.message) {
                    state.error = error.message;
                }
            })
            /**
            * Save Upload
            **/
            .addCase(saveStockTranfer.pending, state => {
                state.status = "loading";
            })
            .addCase(saveStockTranfer.fulfilled, state => {
                state.status = "success";
                state.error = "";
                state.processingStockExchange = {};
                removeProcessingStoreExchange();
            })
            .addCase(saveStockTranfer.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";
                if (error.message) {
                    state.error = error.message;
                }
            })
            /**
            * Cancel Upload
            **/
            .addCase(cancelHoldAdjustment.pending, state => {
                state.status = "loading";
            })
            .addCase(cancelHoldAdjustment.fulfilled, state => {
                state.status = "idle";
                state.error = "";
                state.processingHoldAdjustment = {};
                removeProcessingHoldAdjustment();
            })
            .addCase(cancelHoldAdjustment.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";
                if (error.message) {
                    state.error = error.message;
                }
            })
            /**
            * Save Upload
            **/
            .addCase(saveHoldAdjustment.pending, state => {
                state.status = "loading";
            })
            .addCase(saveHoldAdjustment.fulfilled, state => {
                state.status = "success";
                state.error = "";
                state.processingHoldAdjustment = {};
                removeProcessingHoldAdjustment();
            })
            .addCase(saveHoldAdjustment.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";
                if (error.message) {
                    state.error = error.message;
                }
            })
            /**
            * Get Stock Tranfers
            **/
            .addCase(getStockTranfers.pending, state => {
                state.status = "loading";
            })
            .addCase(getStockTranfers.fulfilled, (state, action) => {
                state.status = "idle";
                state.error = "";
                state.stockTransferData = action.payload;
            })
            .addCase(getStockTranfers.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";
                if (error.message) {
                    state.error = error.message;
                }
            })
            /**
            * Update Stock Tranfer item
            **/
            .addCase(updateStockTranferItemAtSrcWH.pending, state => {
                state.status = "loading";
            })
            .addCase(updateStockTranferItemAtSrcWH.fulfilled, (state, action) => {
                state.status = "idle";
                state.error = "";
                const tempList = [...(state.stockTransferData?.data ?? [])];

                const index = tempList.findIndex(e => {
                    return e.id === action.payload.id
                });
                if (index > -1) {
                    tempList.splice(index, 0, { ...action.payload });
                    // console.log(tempList, 'templist')
                    state.stockTransferData = { ...state.stockTransferData!, data: [...tempList] };
                    // console.log('check state', state.stockTransferData)
                }
                state.error = "";
            })
            .addCase(updateStockTranferItemAtSrcWH.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";
                if (error.message) {
                    state.error = error.message;
                }
            })
            /**
            * Update Stock Tranfer item
            **/
            .addCase(updateStockTranferItemAtDstWH.pending, state => {
                state.status = "loading";
            })
            .addCase(updateStockTranferItemAtDstWH.fulfilled, (state, action) => {
                state.status = "idle";
                state.error = "";
                const tempList = [...(state.stockTransferData?.data ?? [])];

                const index = tempList.findIndex(e => {
                    return e.id === action.payload.id
                });
                if (index > -1) {
                    tempList.splice(index, 0, { ...action.payload });
                    // console.log(tempList, 'templist')
                    state.stockTransferData = { ...state.stockTransferData!, data: [...tempList] };
                    // console.log('check state', state.stockTransferData)
                }
                state.error = "";
            })
            .addCase(updateStockTranferItemAtDstWH.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";
                if (error.message) {
                    state.error = error.message;
                }
            })
            /**
            * Delete Stock Tranfer Item
            **/
            .addCase(deleteStockTranferItem.pending, state => {
                state.status = "loading";
            })
            .addCase(deleteStockTranferItem.fulfilled, (state, action) => {
                state.status = "idle";
                state.error = "";
                const tempList = [...(state.stockTransferData?.data ?? [])];

                const index = tempList.findIndex(e => {
                    return e.id === action.payload.id
                });

                if (index > -1) {
                    // const itemIndex = tempList[index].stockTransferItems.findIndex(e => e.skuCode === action.payload.stockTransferItems.skuCode);
                    tempList.splice(index, 0, { ...action.payload });
                    state.stockTransferData = { ...state.stockTransferData!, data: [...tempList] };
                }
                state.error = "";
            })
            .addCase(deleteStockTranferItem.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";
                if (error.message) {
                    state.error = error.message;
                }
            })
            /**
            * Update Stock Transfer Status
            **/
            .addCase(updateSTStatus.pending, state => {
                state.status = "loading";
            })
            .addCase(updateSTStatus.fulfilled, (state, action) => {
                state.status = "idle";
                state.error = "";
            })
            .addCase(updateSTStatus.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";
                if (error.message) {
                    state.error = error.message;
                }
            })
    }
});

export const {
    setProcessingST,
    removeProcessingST,
    setProcessingHoldAdjustment,
    removeProcessingHold,
} = stockExchangeSlice.actions;
export default stockExchangeSlice.reducer; 