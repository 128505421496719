import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API, { apis } from "common/apis";
import { IApiError } from "common/interface/IApiError";
import { IRequisitionUserMappingUpload, ISku } from "common/interface";

import { RootState } from "store/store";
import { logoutIfNeeded } from "common/utils";
import { IPickerMappingUpload } from "common/interface/IPickerUploadScheme";
import { getPersistProcessingCategoryPricing, getPersistProcessingFranchiseMapping, getPersistProcessingInventoryAdj, getPersistProcessingPricingOverride, persistProcessingCategoryPricing, persistProcessingFranchiseMapping, persistProcessingInventoryAdj, persistProcessingPricingOverride, removePersistProcessingCategoryPricing, removePersistProcessingFranchiseMapping, removePersistProcessingInventoryAdj, removePersistProcessingPricingOverride, } from "services/PersistService/PersistFranchiseServices";
import { FranchiseCatalogueItem, IFranchiseCatalouge } from "common/interface/IFranchiseServices";

export interface State {
    processingFranchiseMapping: IPickerMappingUpload;
    processingPricingOverride: IPickerMappingUpload;
    processingCategoryPricing: IPickerMappingUpload;
    processingInventoryAdj: IPickerMappingUpload;
    warehouseCatalogue?: IFranchiseCatalouge,
    categories?: string[],
    isLatestPrices?: boolean,
    status: "loading" | "success" | "error" | "idle" | "cancelling" | "saving" | "updating";
    error?: string;
}

const initialState: State = {
    processingPricingOverride: getPersistProcessingPricingOverride(),
    processingFranchiseMapping: getPersistProcessingFranchiseMapping(),
    processingCategoryPricing: getPersistProcessingCategoryPricing(),
    processingInventoryAdj: getPersistProcessingInventoryAdj(),
    status: "idle",
};

export const saveFranchiseMapping = createAsyncThunk<IRequisitionUserMappingUpload>("franchise/upload/save", async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const { processingFranchiseMapping: { key } } = state.franchiseServices;

    const response = await API.post(apis.saveFranchiseMapping, {}, {
        params: { key }
    })
        .then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
});

export const cancelFranchiseMapping = createAsyncThunk("franchise/upload/cancel", async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const { processingFranchiseMapping: { key } } = state.franchiseServices;

    const response = await API.post(apis.saveFranchiseMapping, {}, {
        params: { key, cancel: 1 }
    })
        .then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
});

export const savePricingOverride = createAsyncThunk<IRequisitionUserMappingUpload>("franchise/pricing-override/upload/save", async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const { processingPricingOverride: { key } } = state.franchiseServices;

    const response = await API.post(apis.savePricingOverride, {}, {
        params: { key }
    })
        .then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
});

export const cancelPricingOverride = createAsyncThunk("franchise/pricing-override/upload/cancel", async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const { processingPricingOverride: { key } } = state.franchiseServices;

    const response = await API.post(apis.savePricingOverride, {}, {
        params: { key, cancel: 1 }
    })
        .then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
});

export const saveCategoryPricing = createAsyncThunk<IRequisitionUserMappingUpload>("franchise/category-pricing/upload/save", async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const { processingCategoryPricing: { key } } = state.franchiseServices;

    const response = await API.post(apis.saveCategoryPricing, {}, {
        params: { key }
    })
        .then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
});

export const cancelCategoryPricing = createAsyncThunk("franchise/category-pricing/upload/cancel", async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const { processingCategoryPricing: { key } } = state.franchiseServices;

    const response = await API.post(apis.saveCategoryPricing, {}, {
        params: { key, cancel: 1 }
    })
        .then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
});

export const saveInventoryAdjustment = createAsyncThunk<IRequisitionUserMappingUpload>("franchise/inventory-adjustment/upload/save", async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const { processingInventoryAdj: { key } } = state.franchiseServices;

    const response = await API.post(apis.saveFranchiseMapping, {}, {
        params: { key, isAdjustment: 1 }
    })
        .then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
});

export const cancelInventoryAdjustment = createAsyncThunk("franchise/inventory-adjustment/upload/cancel", async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const { processingInventoryAdj: { key } } = state.franchiseServices;

    const response = await API.post(apis.saveFranchiseMapping, {}, {
        params: { key, cancel: 1, isAdjustment: 1 }
    })
        .then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
});

export const getFranchiseCatalogue = createAsyncThunk<IFranchiseCatalouge, { pageNo: number, pageSize: number, whId: number, filter: string }>("franchise/catalogue/all", async ({ pageNo, pageSize, whId, filter }, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const temp = Object.fromEntries(new URLSearchParams(filter));
    const filterParams = filter
        ? { ...temp }
        : "";
    const response = await API.get(apis.franchiseCatalogue + whId, {
        params: { pageNo, pageSize, whId, ...filterParams }
    })
        .then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });
    return response;
});

export const updateFranchiseItems = createAsyncThunk<FranchiseCatalogueItem, Partial<FranchiseCatalogueItem>>("franchise/catalogue/update", async (updatedItem, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const response = await API.post(`${apis.franchiseCatalogue}${updatedItem.whId}/sku/${updatedItem.skuCode}`, updatedItem)
        .then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });
    return response;
});

export const addWhSku = createAsyncThunk<any, any>("franchise/whSku/add", async (data, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const response = await API.post(`${apis.franchiseCatalogue}${data.whId}/sku/${data.skuCode}`, data)
        .then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });
    return response;
});

export const fetchCategories = createAsyncThunk<any, any>("franchise/categories", async (data, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const response = await API.get(apis.fetchCategories)
        .then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });
    return response;
});

export const fetchLatestPricesStatus = createAsyncThunk("franchise/latest-prices/get", async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const response = await API.get(apis.fetchLatestPricesStatus)
        .then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });
    return response;
});

export const updateLatestInventoryFlag = createAsyncThunk("franchise/latest-prices/update", async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const response = await API.post(`${apis.updateLatestPricesStatus}?isLatestPrice=${state.franchiseServices.isLatestPrices ? 0 : 1}`,)
        .then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });
    return response;
});

const franchiseServicesSlice = createSlice({
    name: "franchise-services",
    initialState,
    reducers: {
        setProcessingFranchiseMapping: (state, action) => {
            state.status = "idle";
            state.error = "";
            state.processingFranchiseMapping = action.payload;
            state.processingFranchiseMapping.timeUploaded = new Date().toString();
            persistProcessingFranchiseMapping(state.processingFranchiseMapping);
        },
        removeProcessingFranchiseMapping: (state) => {
            state.processingFranchiseMapping = {};
            state.status = "idle";
            state.error = "";
            removePersistProcessingFranchiseMapping();
        },
        setProcessingPricingOverride: (state, action) => {
            state.status = "idle";
            state.error = "";
            state.processingPricingOverride = action.payload;
            state.processingPricingOverride.timeUploaded = new Date().toString();
            persistProcessingPricingOverride(state.processingPricingOverride);
        },
        removeProcessingPricingOverride: (state) => {
            state.processingPricingOverride = {};
            state.status = "idle";
            state.error = "";
            removePersistProcessingPricingOverride();
        },
        setProcessingCategoryPricing: (state, action) => {
            state.status = "idle";
            state.error = "";
            state.processingCategoryPricing = action.payload;
            state.processingCategoryPricing.timeUploaded = new Date().toString();
            persistProcessingCategoryPricing(state.processingPricingOverride);
        },
        removeProcessingCategoryPricing: (state) => {
            state.processingCategoryPricing = {};
            state.status = "idle";
            state.error = "";
            removePersistProcessingCategoryPricing();
        },
        setProcessingInventoryAdj: (state, action) => {
            state.status = "idle";
            state.error = "";
            state.processingInventoryAdj = action.payload;
            state.processingInventoryAdj.timeUploaded = new Date().toString();
            persistProcessingInventoryAdj(state.processingInventoryAdj);
        },
        removeProcessingInventoryAdj: (state) => {
            state.processingInventoryAdj = {};
            state.status = "idle";
            state.error = "";
            removePersistProcessingInventoryAdj();
        },
    },
    extraReducers(builder) {
        builder
            /**
            * SAVE REQUISITION USER MAPPING
            **/
            .addCase(saveFranchiseMapping.pending, state => {
                state.status = "loading";
            })
            .addCase(saveFranchiseMapping.fulfilled, state => {
                state.status = "success";
                state.error = "";
                state.processingFranchiseMapping = {};
                removePersistProcessingFranchiseMapping();
            })
            .addCase(saveFranchiseMapping.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";

                if (error.message) {
                    state.error = error.message;
                }
            })

            /**
             * CANCEL PROCESSING REQUISITION
            **/
            .addCase(cancelFranchiseMapping.pending, state => {
                state.status = "cancelling";
            })
            .addCase(cancelFranchiseMapping.fulfilled, state => {
                state.status = "idle";
                state.error = "";
                state.processingFranchiseMapping = {};
                removePersistProcessingFranchiseMapping();
            })
            .addCase(cancelFranchiseMapping.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";
                if (error.message) {
                    state.error = error.message;
                }
            })

            /**
            * SAVE PRICING OVERRIDE
            **/
            .addCase(savePricingOverride.pending, state => {
                state.status = "loading";
            })
            .addCase(savePricingOverride.fulfilled, state => {
                state.status = "success";
                state.error = "";
                state.processingPricingOverride = {};
                removePersistProcessingPricingOverride();
            })
            .addCase(savePricingOverride.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";

                if (error.message) {
                    state.error = error.message;
                }
            })

            /**
             * CANCEL PRICING OVERRIDE
            **/
            .addCase(cancelPricingOverride.pending, state => {
                state.status = "cancelling";
            })
            .addCase(cancelPricingOverride.fulfilled, state => {
                state.status = "idle";
                state.error = "";
                state.processingPricingOverride = {};
                removePersistProcessingPricingOverride();
            })
            .addCase(cancelPricingOverride.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";
                if (error.message) {
                    state.error = error.message;
                }
            })
            
            /**
            * SAVE CATEGORY PRICING
            **/
            .addCase(saveCategoryPricing.pending, state => {
                state.status = "loading";
            })
            .addCase(saveCategoryPricing.fulfilled, state => {
                state.status = "success";
                state.error = "";
                state.processingCategoryPricing = {};
                removePersistProcessingCategoryPricing();
            })
            .addCase(saveCategoryPricing.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";

                if (error.message) {
                    state.error = error.message;
                }
            })

            /**
             * CANCEL CATEGORY PRICING
            **/
            .addCase(cancelCategoryPricing.pending, state => {
                state.status = "cancelling";
            })
            .addCase(cancelCategoryPricing.fulfilled, state => {
                state.status = "idle";
                state.error = "";
                state.processingCategoryPricing = {};
                removePersistProcessingCategoryPricing();
            })
            .addCase(cancelCategoryPricing.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";
                if (error.message) {
                    state.error = error.message;
                }
            })
            
            /**
            * SAVE INVENTORY HOLD
            **/
            .addCase(saveInventoryAdjustment.pending, state => {
                state.status = "loading";
            })
            .addCase(saveInventoryAdjustment.fulfilled, state => {
                state.status = "success";
                state.error = "";
                state.processingInventoryAdj = {};
                removePersistProcessingInventoryAdj();
            })
            .addCase(saveInventoryAdjustment.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";

                if (error.message) {
                    state.error = error.message;
                }
            })

            /**
             * CANCEL INVENTORY HOLD
            **/
            .addCase(cancelInventoryAdjustment.pending, state => {
                state.status = "cancelling";
            })
            .addCase(cancelInventoryAdjustment.fulfilled, state => {
                state.status = "idle";
                state.error = "";
                state.processingInventoryAdj = {};
                removePersistProcessingInventoryAdj();
            })
            .addCase(cancelInventoryAdjustment.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";
                if (error.message) {
                    state.error = error.message;
                }
            })

            /**
             * GET FRANCHISE CATALOGUE
            **/
            .addCase(getFranchiseCatalogue.pending, state => {
                state.status = "loading";
            })
            .addCase(getFranchiseCatalogue.fulfilled, (state, action) => {
                state.status = "idle";
                state.error = "";
                state.warehouseCatalogue = action.payload;
            })
            .addCase(getFranchiseCatalogue.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";
                if (error.message) {
                    state.error = error.message;
                }
            })

            /**
             * UPDATE SKU ITEM
            **/
            .addCase(updateFranchiseItems.pending, state => {
                state.status = "loading";
            })
            .addCase(updateFranchiseItems.fulfilled, (state, action) => {
                state.status = "success";
                const temp = state.warehouseCatalogue!.data;
                const index = temp!.findIndex((item) => item.id === action.payload.id);
                if (index > -1) {
                    temp.splice(index, 1, action.payload);
                    state.warehouseCatalogue = { ...state.warehouseCatalogue!, data: temp };
                }
                state.error = "";
            })
            .addCase(updateFranchiseItems.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";
                if (error.message) {
                    state.error = error.message;
                }
            })

            /**
             * ADD WH SKU
            **/
            .addCase(addWhSku.pending, state => {
                state.status = "saving";
            })
            .addCase(addWhSku.fulfilled, (state) => {
                state.status = "success";
                state.error = "";
            })
            .addCase(addWhSku.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";
                if (error.message) {
                    state.error = error.message;
                }
            })
            
            /**
             * FETCH CATEGORIES
            **/
            .addCase(fetchCategories.pending, state => {
                state.status = "loading";
            })
            .addCase(fetchCategories.fulfilled, (state, action) => {                
                state.categories = action.payload["storeCategories"] ?? [];
                state.error = "";
            })
            .addCase(fetchCategories.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";
                if (error.message) {
                    state.error = error.message;
                }
            })
            
            /**
             * FETCH LATEST PRICES FLAG
            **/
            .addCase(fetchLatestPricesStatus.pending, state => {
                state.status = "updating";
            })
            .addCase(fetchLatestPricesStatus.fulfilled, (state, action) => {
                state.isLatestPrices = action.payload['dayToggle'] === 1;
                state.status = "idle";
                state.error = "";
            })
            .addCase(fetchLatestPricesStatus.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";
                if (error.message) {
                    state.error = error.message;
                }
            })
            /**
             * UPDATE LATEST PRICES FLAG
            **/
            .addCase(updateLatestInventoryFlag.pending, state => {
                // state.isLatestPrices = !state.isLatestPrices;
                state.status = "updating";
            })
            .addCase(updateLatestInventoryFlag.fulfilled, (state) => {
                state.status = "idle";
                state.isLatestPrices = !state.isLatestPrices;
                state.error = "";
            })
            .addCase(updateLatestInventoryFlag.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";
                if (error.message) {
                    state.error = error.message;
                }
            })

    }
});

export const {
    setProcessingFranchiseMapping,
    removeProcessingFranchiseMapping,
    setProcessingPricingOverride,
    removeProcessingPricingOverride,
    setProcessingCategoryPricing,
    removeProcessingCategoryPricing,
    setProcessingInventoryAdj,
    removeProcessingInventoryAdj,
} = franchiseServicesSlice.actions;
export default franchiseServicesSlice.reducer; 