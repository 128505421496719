import { Row, Col, message, Table, Pagination } from "antd";
import { ColumnsType } from "antd/lib/table";
import { AppPageHeader } from "components/AppPageHeader";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useDocumentTitle } from "hooks/usePageTitle";
import { useCallback, useEffect, useState } from "react";
import { GrView } from "react-icons/gr";
import styled from "styled-components";
import { NavLink, useSearchParams } from "react-router-dom";
import {getDateAndTimeFromISODate, getDateFromDateFormatted, getDateFromISODate} from "common/utils";
import { AppActionStrip } from "components/AppActionStrip/AppActionStrip";
import { AppFilterButton, AppFilter } from "components/AppFilter";
import { IStoreReturn } from "common/interface/IStoreReturn";
import { getAllStoreReturns } from "store/store-return/storeReturnSlice";
import { AppStoreReturnFilter } from "components/AppFilter/StoreReturnFilter";
import { fetchStoresList } from "store/requisition/requisitionSlice";

type PartialSRDetails = Partial<IStoreReturn> & { key: string };
const MESSAGE_KEY = "all__return_grn";

const headerKeys: { [key: string]: string } = {
  storeId: "Store Id",
  skuCounts: "Sku Count",
  returnDate: "Return Date",
  status: "Status",
  actions: "Actions", 
};

const StyledPagination = styled(Pagination)`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

const StyledActionLink = styled(NavLink)`
  border: 1px solid;
  padding: 0.2rem;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AllStoreReturnsPage = () => {
  useDocumentTitle("All Store Returns");
  const dispatch = useAppDispatch();
  const [columns, setColumns] = useState<ColumnsType<PartialSRDetails>>();
  const [rows, setRows] = useState<PartialSRDetails[]>();
  const { storesList } = useAppSelector((state) => state.requisition);
  const { allStoreReturns, status, error } = useAppSelector((state) => state.storeReturn);
  const [filterState, setFilterState] = useState(false);
  const [searchParams] = useSearchParams();
  const [filterCount, setFilterCount] = useState(0);

  const getGrnData = useCallback(
    (pageNum = 1, pageSize=25, filter = "") => {
      dispatch(getAllStoreReturns({ pageNo: pageNum, pageSize: pageSize, filter }));
    },
    [dispatch]
  );

  useEffect(() => {
    const filter = searchParams.toString();
    setFilterCount(
      Object.keys(Object.fromEntries(new URLSearchParams(filter))).length
    );
    getGrnData(1, 25, filter);
  }, [dispatch, getGrnData, searchParams]);

  useEffect(() => {
    dispatch(fetchStoresList(""));
}, []);

  useEffect(() => {
    if (status === "loading") {
      message.loading({ content: "Loading Store Returns", key: MESSAGE_KEY });
    } else if (status === "success") {
      message.success({
        content: "Purchase Orders loaded!",
        key: MESSAGE_KEY,
      });
    } else if (status === "error") {
      message.error({ content: error, key: MESSAGE_KEY });
    }

    return () => {
      message.destroy();
    };
  }, [status, error]);

  useEffect(() => { 
    const grnData = allStoreReturns?.data ?? [];
    const data: PartialSRDetails[] = grnData.map((storeReturn, i) => {
      const {
        id,
        skuIds,
        skuCounts,
        returnDate,
        srId,
        status,
        storeId,
      } = storeReturn;

      const newRow = {
        id,
        skuIds,
        skuCounts,
        returnDate,
        srId,
        status,
        storeId,
      };
      newRow.returnDate = getDateFromDateFormatted(newRow.returnDate);
      return { ...newRow, key: `po-${i}`, ellipses: true };
    });
    const tableHeaders: ColumnsType<PartialSRDetails> = [
      ...Object.keys(headerKeys).map((key) => ({
        key,
        title: headerKeys[key],
        dataIndex: key,
        render: (text: string, record: PartialSRDetails) => {
          if (key === "storeId") {
            return (
              <NavLink to={`/store-return/create/${record.srId}`}>{storesList?.find((e) => e.id === record.storeId)?.name} || {record.storeId}</NavLink>
            );
          }

          if (key === "actions") { 
            return (
              <Row
                align="middle"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <Col>
                  <StyledActionLink to={`/store-return/create/${record.srId}`}>
                    <GrView />
                  </StyledActionLink>
                </Col>
              </Row>
            );
          }

          if (key === "returnDate") {
            return <div>{getDateFromISODate(record[key] || "")}</div>;
          }

          return <>{text}</>;
        },
      })),
    ];

    setColumns(tableHeaders);
    setRows(data);
  }, [allStoreReturns]);

  const Paginator = () => {
    if (allStoreReturns) {
      const { pageNo, pageSize, total } = allStoreReturns;
      return (
        <StyledPagination
          current={pageNo}
          pageSize={pageSize}
          total={total}
          onChange={(page, size) => {
            const filter = searchParams.toString();
            getGrnData(page, size, filter);
          }}
        />
      );
    }

    return <></>;
  };

  return (
    <>
      <Row>
        <Col>
          <AppPageHeader title="All Store Returns" />
        </Col>
      </Row>
      <Row>
        <AppActionStrip>
          <div className="action-strip__item">
            <AppFilterButton
              active={filterState}
              onClick={() => setFilterState(!filterState)}
              filterCount={filterCount}
            />
          </div>
        </AppActionStrip>
      </Row>
      <Row style={{ marginBottom: "1rem" }}>
        <AppFilter expanded={filterState}>
          <AppStoreReturnFilter />
        </AppFilter>
      </Row>
      {rows && rows.length ? (
        <Row>
          <Col xs={24}>
            <Table
              columns={columns}
              dataSource={rows}
              scroll={{ x: "100vh", y: "65vh" }}
              size="small"
              pagination={false}
              loading={status === "loading"}
              bordered
            />
          </Col>
          <Col xs={24}>
            <Paginator />
          </Col>
        </Row>
      ) : (
        status !== "loading" && <>No Purchase Orders to show.</>
      )}
    </>
  );
};

export default AllStoreReturnsPage;
