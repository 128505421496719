import { MenuOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import { variables } from "common/variables";

interface AppSiderTriggerProps {
  onTrigger: () => void;
}

export const AppSiderTrigger = ({ onTrigger }: AppSiderTriggerProps) => {
  return (
    <Avatar style={{ backgroundColor: variables?.secondaryColor }}>
      <MenuOutlined
        onClick={onTrigger}
        style={{ color: variables?.primaryColor }}
      />
    </Avatar>
  );
};
