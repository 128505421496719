import styled from "styled-components";

const Logo = styled.h1`
  display: flex;
  align-items: center;
  font-size: 20px;
  font-style: italic;
  font-weight: bold;
  height: 4rem;
`;

export const AppLogo = () => <Logo>SORTED</Logo>;
