import { Row, Col, message, Alert, Form, Select } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import { AUTH_HEADER_NAME, apis, } from "common/apis";
import { IPickerMappingUpload, IPickerMappingUploadRow } from "common/interface/IPickerUploadScheme";
import { routes } from "common/routes";
import { generateHeaders } from "common/utils";
import { AppPageHeader } from "components/AppPageHeader";
import { AppUpload } from "components/AppUpload";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useDocumentTitle } from "hooks/usePageTitle";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchCategories, removeProcessingCategoryPricing, setProcessingCategoryPricing } from "store/franchise/franchiseSlice";

const CategoryPricingUploadPage = () => {
  const { authToken } = useAppSelector((state) => state.app.user);
  const { processingCategoryPricing, categories } = useAppSelector(
    (state) => state.franchiseServices
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [hasErrorRows, setHasErrorRows] = useState(false);
  const [storeCategory, setStoreCategory] = useState<string>();

  useEffect(() => {
    dispatch(fetchCategories(""));
  }, []);

  useEffect(() => {
    if (
      processingCategoryPricing &&
      processingCategoryPricing.key
    ) {
      navigate(`/${routes.FRANCHISE.SAVE_CATEGORY_PRICING}`, {});
    }

    return () => {
      message.destroy();
    };
  }, [processingCategoryPricing, navigate]);

  useEffect(() => {
    const failed = processingCategoryPricing?.failedRows?.length;

    if (failed) {
      setHasErrorRows(true);
    }
  }, [processingCategoryPricing?.failedRows]);

  const headers: { [name: string]: string } = {
    ...(authToken && { [AUTH_HEADER_NAME]: authToken }),
  };

  const onUploaded = (data: IPickerMappingUpload) => {
    dispatch(setProcessingCategoryPricing(data));
  };

  const onUploading = () => {
    dispatch(removeProcessingCategoryPricing());
  };

  const { headerMapping } = processingCategoryPricing;
  const headerMappingWithError = headerMapping + ",errors:Errors";

  const generateMemoizedHeaders = useMemo(
    () => generateHeaders(headerMappingWithError),
    [headerMappingWithError]
  );

  const tableHeaders = generateMemoizedHeaders || {};
  const successRows = processingCategoryPricing?.successRows || [];
  const failedRows = processingCategoryPricing?.failedRows || [];
  const successData = successRows?.map((row, i) => ({
    ...row,
    key: `data-success-${i}`,
  }));
  const failedData = failedRows?.map((row, i) => ({
    ...row,
    key: `data-failed-${i}`,
  }));
  const data = [...failedData, ...successData];
  const columns: ColumnsType<
    IPickerMappingUploadRow & { key: string }
  > = [
      ...Object.keys(tableHeaders).map((key) => ({
        key,
        title: tableHeaders[key],
        dataIndex: key,
        render: (
          text: string,
          record: IPickerMappingUploadRow & { key: string }
        ) => {
          if (key === "errors") {
            const errorMessages = record.errors
              ?.map((error) => error.message)
              .join(", ");
            return <>{errorMessages}</>;
          }
          return <>{text}</>;
        },
      })),
    ];

  const addBorderToErrorRows = (
    record: IPickerMappingUploadRow & { key: string }
  ) => {
    if (record.errors && record.errors.length) {
      return "error-row";
    }

    return "";
  };

  useDocumentTitle("Franchise Category Pricing Upload");
  return (
    <>
      <Row>
        <Col>
          <AppPageHeader title="Upload Category Pricing" />
        </Col>
      </Row>
      <>
        <Form>
          <Form.Item label="Store Category" name="category" required>

            <Select
              showSearch
              filterOption={(input, option) => {                
                return option?.children?.toString().toLowerCase().includes(input?.toLowerCase() ?? "") ?? false;
              }}
              style={{ width: "200px" }}
              onChange={(e) => {
                setStoreCategory(e);
              }}
            
            >
              {categories && (categories ?? []).map((storeInfo) => (
                <Select.Option value={storeInfo} key={storeInfo}>
                  {storeInfo}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </>
      {(storeCategory &&
        <Row>
          <Col lg={16} xs={24}>
            <AppUpload
              uploadUrl={apis.uploadCategoryPricing}
              headers={headers}
              onUploaded={onUploaded}
              onUploading={onUploading}
              hasErrorRows={hasErrorRows}
              data={{
                storeCategory,
              }}
            />
          </Col>
        </Row>
      )}
      {Object.keys(processingCategoryPricing).length != 0 &&
        !processingCategoryPricing.key && (
          <>
            <Row>
              <Col lg={16} xs={24}>
                <Alert
                  type="error"
                  message="Errors in sheet data."
                  description="Please correct errors and upload again."
                  showIcon
                  banner
                />
              </Col>
            </Row>

            <Row>
              <Table
                size="small"
                columns={columns}
                dataSource={data}
                rowClassName={(record) => addBorderToErrorRows(record)}
                scroll={{ y: 500 }}
                className="table-with-errors"
               />
            </Row>
          </>
        )}
    </>
  );
};

export default CategoryPricingUploadPage;