import {
  Avatar,
  Badge,
  Breadcrumb,
  Col,
  Descriptions,
  Image,
  message,
  Row,
  Table,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import {
  IRequisition,
  IRequisitionSku,
  REQUISITION_STATUS,
} from "common/interface";
import { fallBackImage } from "common/styles";
import { AppPageHeader } from "components/AppPageHeader";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useDocumentTitle } from "hooks/usePageTitle";
import { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { getRequisitionById } from "store/requisition/requisitionSlice";
import { variables } from "common/variables";
import { getSlotName } from "common/utils";
import { routes } from "common/routes";

const MESSAGE_KEY = "requisitions_info";

const headerKeys: { [key: string]: string } = {
  id: "Sku Id",
  skuCode: "Sku Code",
  name: "Sku Name",
  cratesRequested: "Crates Requested",
  totalCratesPicked: "Crates Picked",
  isBulk: "Is Bulk",
  status: "Status",
};

type PartialRequisitionSkuType = Partial<IRequisitionSku> & { key: string };

export const DispatchedRequisitionInfoPage = () => {
  useDocumentTitle("Dispatched Requisition Info");
  const { reqId } = useParams();
  const dispatch = useAppDispatch();
  const { requisitions, error, status, slots } = useAppSelector(
    (state) => state.requisition
  );
  const [requisition, setRequisition] = useState<IRequisition>();
  const [tableColumns, setTableColumns] =
    useState<ColumnsType<PartialRequisitionSkuType>>();
  const [tableRows, setTableRows] = useState<any[]>();
  const navigate = useNavigate();

  useEffect(() => {
    if (reqId) {
      dispatch(getRequisitionById(reqId));
    }
  }, [reqId, dispatch]);

  useEffect(() => {
    if (status === "loading") {
      message.loading({ content: "loading requisition", key: MESSAGE_KEY });
    } else if (status === "success") {
      message.success({
        content: "Requisition loaded!",
        key: MESSAGE_KEY,
      });
    } else if (status === "error") {
      message.error({ content: error, key: MESSAGE_KEY });
    }

    return () => {
      message.destroy();
    };
  }, [status, error]);

  useEffect(() => {
    const thisRequisition = requisitions?.find(
      (req) => req.id.toString() === reqId
    );

    if (thisRequisition) {
      const columns: ColumnsType<PartialRequisitionSkuType> = [
        ...Object.keys(headerKeys).map((key) => ({
          key,
          title: headerKeys[key],
          dataIndex: key,
          render: (text: string, record: any) => {
            if (key === "status") {
              return (
                <Badge
                  status={getRequisitionStatus(record.status)}
                  text={record.status}
                />
              );
            }

            if (key === "name") {
              return (
                <Row align="middle" gutter={10}>
                  <Col>
                    <Avatar
                      src={
                        <Image
                          src={record.image}
                          fallback={fallBackImage}
                          alt={text}
                          width="100%"
                        />
                      }
                    ></Avatar>
                  </Col>
                  <Col>{text}</Col>
                </Row>
              );
            }

            if (key === "isBulk") {
              return <>{record["isBulk"] ? "True" : "False"}</>;
            }

            return <>{text}</>;
          },
        })),
      ];

      const rows: any[] = thisRequisition?.skus?.map((reqSku) => ({
        key: reqSku.id,
        id: reqSku.sku.id,
        skuCode: reqSku.sku.skuCode,
        name: reqSku.sku.name,
        cratesRequested: reqSku.cratesRequested,
        totalCratesPicked: reqSku.totalCratesPicked,
        status: reqSku.status,
        isBulk: reqSku.isBulk,
        image: reqSku.sku.image,
      }));

      setRequisition(thisRequisition);
      setTableRows(rows);
      setTableColumns(columns);
    }
  }, [requisitions, reqId]);

  const getRequisitionStatus = (status?: REQUISITION_STATUS) => {
    if (status === "DEFAULT" || !status) {
      return "default";
    } else if (status === "STORE_RECEIVED") {
      return "success";
    } else {
      return "processing";
    }
  };

  const requisitionInfo = requisition && (
    <>
      <Descriptions
        size="small"
        layout="vertical"
        labelStyle={{ fontWeight: "bold" }}
        bordered
        column={{ xs: 2, md: 4 }}
      >
        <Descriptions.Item label="Store Name">
          {requisition.store.name}
        </Descriptions.Item>
        <Descriptions.Item label="Store Id">
          {requisition.store.id}
        </Descriptions.Item>

        <Descriptions.Item label="Store Address">
          {requisition.store.address}
        </Descriptions.Item>
        <Descriptions.Item label="Date">{requisition.date}</Descriptions.Item>

        <Descriptions.Item label="Slot">
          {slots && requisition.slot
            ? getSlotName(slots, requisition.slot)
            : ""}
        </Descriptions.Item>
        <Descriptions.Item label="Created On">
          {requisition.createdOn}
        </Descriptions.Item>
        <Descriptions.Item label="Status">
          <Badge
            status={getRequisitionStatus(requisition.status)}
            text={requisition.status}
          />
        </Descriptions.Item>
      </Descriptions>
      {tableRows && tableRows.length && (
        <Row style={{ marginTop: "20px" }}>
          <Col xs={24}>
            <Table
              size="small"
              columns={tableColumns}
              dataSource={tableRows}
              scroll={{ x: "100vh" }}
              style={{ width: "100%" }}
            ></Table>
          </Col>
        </Row>
      )}
    </>
  );

  return (
    <>
      <Row
        style={{
          margin: "1rem 0",
          position: "sticky",
          top: 0,
          zIndex: 1,
          background: variables.wmBackgroundColorLight1,
          padding: "5px",
        }}
      >
        <Col xs={24}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to={"/requisition/all-dispatched"}>All Non Dispatched Requisitions</NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {requisition?.store?.name || "Requisition Info"}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col>
          <AppPageHeader title="Requisition Info" />
        </Col>
      </Row>
      <Row>
        <Col xs={24}>{requisitionInfo}</Col>
      </Row>
    </>
  );
};
