import {
  Breadcrumb,
  Button,
  Col,
  DatePicker,
  Descriptions,
  Form,
  Input,
  InputNumber,
  message,
  Popconfirm,
  Row,
  Select,
  Table,
  Tag,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import { IPo, IPoApi, IPoItem } from "common/interface";
import { getDateFromISODate } from "common/utils";
import { variables } from "common/variables";
import { AppPageHeader } from "components/AppPageHeader";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useDocumentTitle } from "hooks/usePageTitle";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { RiAddBoxLine } from "react-icons/ri";
import {
  getPoById,
  savePoDraft,
  receivePO,
  updateSortingRTV,
} from "store/po/poSlice";

const MESSAGE_KEY = "po_receieve";
type PartialIPoItemType = Partial<IPoItem> & { key: string };

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "select" | "text";
  record: PartialIPoItemType;
  index: number;
  children: React.ReactNode;
}

const headerKeys: { [key: string]: string } = {
  skuId: "SKU ID/Name",
  uom: "UOM",
  // extPoIds: "Ext PO ID",
  requestedQty: "Requested Quantity",
  rtvAllowed: "RTV Allowed",
  grossReceivedQty: "Gross Rcv. Qty.",
  dockRtv: "Dock RTV",
  sortingRtv: "Sorting RTV",
  rejectedQty: "Rejected Qty.",
  // netReceivedQty: "Net Rcv. Qty.",
  finalReceivedQty: "Final Received Qty.",
  // poRate: "PO Rate",
  // billingRate: "Billing Rate",
  // totalAmount: "Total Amount",
  actions: "Actions",
};

const { Option } = Select;

type Page = "sorting-rtv" | "receiving";

const POReceivePage = () => {
  const dispatch = useAppDispatch();
  const { pos, error, status } = useAppSelector((state) => state.po);
  const { masterSkus = [], assets = [] } = useAppSelector(
    (state) => state.requisition
  );
  const { poId } = useParams();
  const [viewedPO, setViewedPO] = useState<IPo>();
  const [tableColumns, setTableColumns] =
    useState<ColumnsType<PartialIPoItemType>>();
  const [tableRows, setTableRows] = useState<any[]>();
  const [tempTableRows, setTempTableRows] = useState<any[]>();
  const [form] = Form.useForm();
  const [assetForm] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const [isAddingItem, setIsAddingItem] = useState(false);
  const [isRowExpanded, setIsRowExpanded] = useState(false);
  const [expandedRowId, setExpandedRowId] = useState();
  // const [updateValue, setUpdateValue] = useState("");
  const [localPos, setLocalPos] = useState<IPoApi>();
  const [hasUnsavedItems, setHasUnsavedItems] = useState<number | undefined>(
    undefined
  );
  const [page, setPage] = useState<Page>("sorting-rtv");
  const navigate = useNavigate();
  const location = useLocation();

  // useEffect(() => {
  // }, [updateValue]);

  // const updateAddValue = (nv: string) => {
  //   setUpdateValue(nv);
  // }

  useEffect(() => {
    const path = location.pathname.split("/").pop();
    if (path === "sorting-rtv") {
      setPage("sorting-rtv");
    } else {
      setPage("receiving");
    }
  }, [location]);

  useEffect(() => {
    if (editingKey) {
      const selectedRowData = viewedPO?.poItems.find(e => e.id.toString() === editingKey.toString().split("-")[0]);
      form.setFieldsValue({
        skuId: masterSkus.find(k => k.skuCode === selectedRowData?.skuCode)?.id.toString(),
      });
    }
  }, [editingKey, form]);

  useEffect(() => {
    if (hasUnsavedItems) {
      message.warning({
        content: (
          <div style={{ width: "100%" }}>
            <p>
              There are unsaved items, please click <b>Done</b>.
            </p>
          </div>
        ),
        key: MESSAGE_KEY,
        duration: 0,
      });
    } else {
      message.destroy();
    }
  }, [hasUnsavedItems]);

  const isEditing = useCallback(
    (record: PartialIPoItemType) => record.key === editingKey,
    [editingKey]
  );

  const edit = useCallback(
    (record: PartialIPoItemType) => {
      form.setFieldsValue({ ...record });
      setEditingKey(record.key);
    },
    [form]
  );

  const saveItem = useCallback(
    async (record: any, closeEdit = false) => {
      try {
        calculateAndUpdateNetRcvQty();
        const row = await form.validateFields();

        const thisItemIdx =
          viewedPO?.poItems.findIndex(
            (po) => po.id.toString() === record.id.toString()
          ) ?? -1;
        if (thisItemIdx > -1) {
          const viewedPoIndex =
            localPos?.data.findIndex(
              (d) => d.id.toString() === viewedPO?.id.toString()
            ) ?? -1;

          if (viewedPoIndex > -1) {
            let newLocalPos = Object.assign({}, localPos);
            newLocalPos.data = (localPos?.data || []).slice();
            newLocalPos.data[viewedPoIndex] = {
              ...newLocalPos.data[viewedPoIndex],
              poItems: [...(localPos?.data[viewedPoIndex].poItems || [])],
              receivedDate: moment(record.receivedDate).toISOString(),
            };

            newLocalPos.data[viewedPoIndex].poItems[thisItemIdx] = {
              ...newLocalPos.data[viewedPoIndex].poItems[thisItemIdx],
              ...row,
            };
            setLocalPos(newLocalPos as any);
            (closeEdit || !isRowExpanded) && setEditingKey("");
            if (closeEdit) {
              setIsRowExpanded(false);
              setExpandedRowId(undefined);
            }
            setHasUnsavedItems(undefined);
          }
          form.setFieldsValue({
            "tempAddField": "0",
          });
        }
      } catch (e) { }
    },
    [form, isRowExpanded, localPos, viewedPO?.id, viewedPO?.poItems,]
  );

  const calculateAndUpdateNetRcvQty = useCallback(() => {
    const { grossReceivedQty = 0, dockRtv = 0, sortingRtv = 0, rejectedQty = 0 } = form.getFieldsValue([
      "grossReceivedQty",
      "dockRtv",
      "sortingRtv",
      "rejectedQty",
    ]);
    const thisItem = viewedPO?.poItems.find(
      (po) => po.id.toString() === editingKey.toString()
    );
    if (thisItem) {
      const selectedAssets = thisItem.poItemAssets ?? [];
      const selectedAssetsQty = selectedAssets.reduce(
        (total, selectedAsset) => {
          const assetWeight =
            assets.find((a) => a.id === selectedAsset.assetId)?.assetWeight ??
            0;
          total += selectedAsset.assetQty * assetWeight;
          return total;
        },
        0
      );
      const diviser = thisItem.uom.toLowerCase() === "kg" ? 1000 : 1;
      const totalAssetQty = (selectedAssetsQty ?? 0) / diviser;
      const finalQty =
        Number(grossReceivedQty ?? 0) -
        Number(dockRtv ?? 0) -
        Number(sortingRtv ?? 0) -
        Number(rejectedQty ?? 0) -
        (thisItem.uom.toLowerCase() === "pieces" ? 0 : totalAssetQty);

      form.setFieldsValue({
        finalReceivedQty: finalQty >= 0 ? finalQty : 0,
      });
    } else {
      form.setFieldsValue({
        finalReceivedQty: 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assets, editingKey, form, localPos, viewedPO?.poItems]);

  const onAddAsset = useCallback(
    async (record: any) => {
      try {
        const assetRow = await assetForm.validateFields();
        const thisItemIdx =
          viewedPO?.poItems.findIndex(
            (po) => po.id.toString() === record.id.toString()
          ) ?? -1;
        if (thisItemIdx > -1) {
          const viewedPoIndex =
            localPos?.data.findIndex(
              (d) => d.id.toString() === viewedPO?.id.toString()
            ) ?? -1;

          if (viewedPoIndex > -1) {
            let newLocalPos = Object.assign({}, localPos);
            newLocalPos.data = (localPos?.data || []).slice();
            newLocalPos.data[viewedPoIndex] = {
              ...newLocalPos.data[viewedPoIndex],
              poItems: [...(localPos?.data[viewedPoIndex].poItems || [])],
            };

            newLocalPos.data[viewedPoIndex].poItems[thisItemIdx] = {
              ...newLocalPos.data[viewedPoIndex].poItems[thisItemIdx],
            };

            newLocalPos.data[viewedPoIndex].poItems = [
              ...newLocalPos.data[viewedPoIndex].poItems,
            ];

            const existingAssetIdx =
              newLocalPos.data[viewedPoIndex].poItems[
                thisItemIdx
              ].poItemAssets?.findIndex((a) => a.assetId === assetRow.id) ?? -1;

            if (existingAssetIdx > -1) {
              newLocalPos.data[viewedPoIndex].poItems[
                thisItemIdx
              ].poItemAssets = [
                  ...newLocalPos.data[viewedPoIndex].poItems[thisItemIdx]
                    .poItemAssets,
                ];
              newLocalPos.data[viewedPoIndex].poItems[thisItemIdx].poItemAssets[
                existingAssetIdx
              ] = {
                assetId: assetRow.id,
                assetQty: assetRow.assetQty,
              };
            } else {
              newLocalPos.data[viewedPoIndex].poItems[
                thisItemIdx
              ].poItemAssets = [
                  ...((newLocalPos.data[viewedPoIndex].poItems[thisItemIdx] ?? [])
                    .poItemAssets ?? []),
                  {
                    assetId: assetRow.id,
                    assetQty: assetRow.assetQty,
                  },
                ];
            }

            setLocalPos(newLocalPos as any);
            assetForm.resetFields();
            setHasUnsavedItems(record.id);
          }
        }
      } catch (e) { }
    },
    [assetForm, localPos, viewedPO?.id, viewedPO?.poItems]
  );

  useEffect(() => {
    calculateAndUpdateNetRcvQty();
  }, [calculateAndUpdateNetRcvQty, localPos]);

  useEffect(() => {
    if (poId) {
      dispatch(getPoById(poId));
    }
  }, [poId, dispatch]);

  let btnRef = useRef<any>(null);

  const onBtnClick = () => {
    if (btnRef.current) {
      btnRef.current.setAttribute("disabled", "disabled");
    }
  }

  useEffect(() => {
    if (status === "loading") {
      message.loading({ content: "loading purchase order", key: MESSAGE_KEY });
    } else if (status === "saving") {
      message.loading({ content: "saving purchase order", key: MESSAGE_KEY });
    } else if (status === "error") {
      message.error({ content: error, key: MESSAGE_KEY });
    }

    return () => {
      message.destroy();
    };
  }, [status, error]);

  useEffect(() => {
    const thisPO = pos?.data.find((p) => p.id.toString() === poId);
    if (thisPO) {
      setViewedPO(thisPO);
      setLocalPos(pos);
    } else {
      navigate("/po/all");
    }
  }, [navigate, poId, pos, pos?.data]);

  useEffect(() => {
    const thisPO = localPos?.data?.find((po) => po.id.toString() === poId);

    if (thisPO) {
      const columns = [
        ...Object.keys(headerKeys).map((key, idx) => ({
          key,
          title: headerKeys[key],
          dataIndex: key,
          editable: key !== "actions" && true,
          ...(key === "actions" &&
            isRowExpanded &&
            ({ fixed: "right" } as any)),
          render: (text: string, record: any) => {
            if (key === "skuId") {
              return (
                <>
                  <div>{record.skuCode}</div>
                  <div>{record.skuName}</div>
                </>
              );
            }

            if (key === "extPoIds") {
              return ((record.extPoIds as string[]) || []).map(
                (exPoId) => (
                  <div key={record.challanId + exPoId}>{exPoId}</div>
                )
              );
            }

            if (key === "rtvAllowed") {
              if (record.rtvAllowed === undefined) {
                return <div></div>;
              }
              else {
                return (
                  <div>
                    {record.rtvAllowed ? "Yes" : "No"}
                  </div>
                );
              }
            }

            if (key === "") {
              return ((record.request as string[]) || []).map(
                (exPoId) => (
                  <div key={record.challanId + exPoId}>{exPoId}</div>
                )
              );
            }

            if (key === "actions") {
              const editable = isEditing(record);
              return editable ? (
                <Row
                  align="middle"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-around"
                  }}
                >

                  <Button
                    type="primary"
                    size="small"
                    onClick={() => {
                      saveItem(record, true);
                    }}
                  >
                    {page === "sorting-rtv" ? "Save" : "Done"}
                  </Button>
                  {page === "sorting-rtv" && <Button
                    type="text"
                    className="action-strip__item"
                    size="small"
                    onClick={() => {
                      navigate(`/po/${viewedPO?.id}`);
                    }}
                  >
                    Cancel
                  </Button>}
                </Row>
              ) : (
                <></>
              );
            }
            return <>{text}</>;
          },
        })),
      ];

      const mergedColumns = columns.map((col, i) => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          ...(i === 0 && { width: "220px" }),
          onCell: (record: PartialIPoItemType) => ({
            record,
            inputType: ["skuId", "rtvAllowed"].includes(col.dataIndex) ? "select" : "text",
            dataIndex: col.dataIndex,
            title: col.title,
            editing: isEditing(record),
          }),
        };
      });

      const rows: any[] =
        thisPO?.poItems
          ?.map((poItem) => ({
            id: poItem.id,
            skuId: poItem.id,
            key: poItem.id,
            skuCode: poItem.skuCode,
            skuName: poItem.skuName,
            extPoIds: poItem.extPoIds,
            requestedQty: poItem.requestedQty,
            grossReceivedQty: poItem.grossReceivedQty,
            rtvAllowed: poItem.rtvAllowed,
            dockRtv: poItem.dockRtv,
            // netReceivedQty: poItem.netReceivedQty,
            sortingRtv: poItem.sortingRtv,
            rejectedQty: poItem.rejectedQty,
            uom: poItem.uom,
            poRate: poItem.poRate,
            billingRate: poItem.billingRate,
            totalAmount: poItem.totalAmount,
            finalReceivedQty: poItem.finalReceivedQty,
            poItemAssets: poItem.poItemAssets,
          }))
          .filter(
            (poI) => (isAddingItem && !(poI as any).temp) || !isAddingItem
          ) || [];

      setViewedPO(thisPO);
      setTableRows([...rows, ...(tempTableRows || [])]);
      setTableColumns(mergedColumns);
    }
  }, [
    edit,
    editingKey,
    form,
    isAddingItem,
    isEditing,
    isRowExpanded,
    localPos?.data,
    poId,
    saveItem,
    tempTableRows,
    viewedPO?.poItems,
    viewedPO?.status,
  ]);

  const getSkuOptions = useCallback(
    (includeSku?: string) => {
      if (masterSkus) {
        const selectedSkus = viewedPO?.poItems
          .filter((poi) => poi.skuCode !== includeSku)
          .map((poI) => poI.skuCode);
        return masterSkus.reduce((acc, sku) => {
          if (!selectedSkus?.includes(sku.skuCode)) {
            return (acc = {
              ...acc,
              [sku.id]: {
                value: sku.id,
                label: sku.skuCode,
                skuCode: sku.skuCode,
                skuName: sku.name,
              },
            });
          }

          return acc;
        }, {} as Record<string, { skuCode: string; skuName: string }>);
      }

      return {};
    },
    [masterSkus, viewedPO?.poItems]
  );

  const savePoDrafter = useCallback(async () => {
    try {
      const row = await form.validateFields();
      let thisPo = localPos?.data.find((po) => po.id === viewedPO?.id);
      if (thisPo) {
        thisPo = {
          ...thisPo,
          receivedDate: moment(row.receivedDate).toISOString(),
        };
        dispatch(savePoDraft(thisPo))
          .unwrap()
          .then(() => navigate(`/po/${viewedPO?.id}`));
      }
    } catch (e) {
      console.error(e);
    }
  }, [dispatch, form, localPos?.data, navigate, viewedPO?.id]);

  const onReceive = useCallback(async () => {
    try {
      const row = await form.validateFields();
      let thisPo = localPos?.data.find((po) => po.id === viewedPO?.id);
      if (thisPo) {
        thisPo = {
          ...thisPo,
          receivedDate: moment(row.receivedDate).toISOString(),
        };
        if (thisPo.poItems.every((poItem) => 
        poItem.grossReceivedQty !== null 
        && poItem.finalReceivedQty !== null
        )) {
          dispatch(receivePO(thisPo))
            .unwrap()
            .then(() => navigate(`/po/${viewedPO?.id}`));
        }
        else {
          message.error("The Gross Receive Qty is not present for all fields. Please update the same.", 3);
        }

      }
    } catch (e) {
      console.error(e);
    }
  }, [dispatch, form, localPos?.data, navigate, viewedPO?.id]);

  const saveDateInLocal = useCallback(
    (date: moment.Moment | null) => {
      const thisPoIndex =
        localPos?.data.findIndex((po) => po.id === viewedPO?.id) ?? -1;

      if (thisPoIndex > -1) {
        const newLocalPos = {
          ...localPos,
          data: [...(localPos?.data ?? [])],
        } as IPoApi;

        newLocalPos.data[thisPoIndex] = {
          ...newLocalPos.data[thisPoIndex],
          receivedDate: date
            ? moment(date).toISOString()
            : moment(new Date()).toISOString(),
        };

        setLocalPos(newLocalPos);
      }
    },
    [localPos, viewedPO?.id]
  );

  const EditableCell: React.FC<EditableCellProps> = useCallback(
    ({
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    }) => {
      const isFieldEditable =
        (["requestedQty", "dockRtv", "grossReceivedQty", "finalReceivedQty", "rejectedQty"].includes(dataIndex)) ||
        (dataIndex === "sortingRtv" && record.rtvAllowed);

      const isFieldNumberType =
        dataIndex === "requestedQty" ||
        dataIndex === "finalReceivedQty"
      const isFieldTypeAddition =
        dataIndex === "grossReceivedQty" ||
        dataIndex === "sortingRtv" ||
        dataIndex === "rejectedQty" ||
        dataIndex === "dockRtv";
      const isFieldBoolean = dataIndex === "rtvAllowed";
      let inputNode =
        inputType === "select" ? (
          <Select
            key={dataIndex}
            showSearch
            optionFilterProp=""
            filterOption={(input, option) => {
              const keyWords =
                option?.children?.map((child) =>
                  child.props.children.toLowerCase()
                ) || [];
              return keyWords.some((key) => key.includes(input.toLowerCase()));
            }}
            style={!isFieldBoolean ? { width: "200px" } : {}}
          >
            {!isFieldBoolean ? Object.entries(getSkuOptions(record.skuCode)).map(
              ([key, value]) => (
                <Option key={key} value={key}>
                  <div>{value.skuCode}</div>
                  <div>{value.skuName}</div>
                </Option>
              )
            ) : <>
              <Option key={`${dataIndex}_option_1`} value={true}>
                <div>Yes</div>
              </Option>
              <Option key={`${dataIndex}_option_1`} value={false}>
                <div>No</div>
              </Option>
            </>
            }
          </Select>
        ) : (
          <Input
            key={dataIndex}
            readOnly={dataIndex === 'requestedQty' || dataIndex === 'finalReceivedQty'}
            type={isFieldNumberType ? "number" : "text"}
            onChange={() => setHasUnsavedItems(record.id)}
            disabled={
              (dataIndex === "dockRtv" && !record.rtvAllowed) 
              || (dataIndex === "sortingRtv" && !record.rtvAllowed)
            }
          />
        );
      if (isFieldTypeAddition) {
        inputNode = <Input
          key={dataIndex}
          readOnly={true}
          onChange={() => setHasUnsavedItems(record.id)}
          type={"text"}
          disabled={
            (dataIndex === "dockRtv" && !record.rtvAllowed) 
            || (dataIndex === "sortingRtv" && !record.rtvAllowed)
            || (dataIndex === "rejectedQty" && record.rtvAllowed)
          }
          suffix={
            <Popconfirm
              title={
                <>
                  <p>Enter value to add</p>
                  <Form.Item
                    name={"tempAddField"}
                    rules={[
                      {
                        required: isRowExpanded,
                        message: `Please input value!`,
                      },
                    ]}
                  >
                    <InputNumber
                      onChange={async () => {
                        setHasUnsavedItems(record.id);
                      }}
                      min={0 - parseFloat((form.getFieldValue(dataIndex) ? form.getFieldValue(dataIndex) : 0)?.toString())}
                    />
                  </Form.Item>
                </>
              }
              placement="topRight"
              onConfirm={async () => {
                await (form.validateFields(["tempAddField"]));              
                form.setFieldsValue({
                  [dataIndex]: parseFloat(form.getFieldValue("tempAddField")) + parseFloat((form.getFieldValue(dataIndex) ? form.getFieldValue(dataIndex) : 0)?.toString()),
                  "tempAddField": "0",
                });
              }}
              onCancel={() => {
                form.setFieldsValue({
                  "tempAddField": "0",
                });
              }}
              onVisibleChange={(open) => {
                form.setFieldsValue({
                  "tempAddField": "0",
                });
              }}
            >
              <RiAddBoxLine size={20} />
            </Popconfirm>
          }
        />
      }
      if (dataIndex === 'requestedQty') {
        return <td className={"ant-table-cell"}>{record.requestedQty}</td>
      }

      return (
        <td {...restProps}>
          {editing && isFieldEditable ? (
            <Form.Item
              name={dataIndex}
              style={{ margin: 0 }}
              rules={[
                {
                  required: dataIndex === "grossReceivedQty",
                  message: `Please ${inputType === "select" ? "select" : "input"
                    } ${title}!`,
                },
              ]}

            >
              {inputNode}
            </Form.Item>
          ) : (
            children
          )}
        </td>
      );
    },
    [getSkuOptions, page]
  );

  const removeAsset = useCallback(
    (poItemId: number, assetId: number) => {
      const thisPoIndex =
        localPos?.data.findIndex((po) => po.id === viewedPO?.id) ?? -1;

      if (thisPoIndex > -1) {
        const newLocalPos = {
          ...localPos,
          data: [...(localPos?.data ?? [])],
        } as IPoApi;

        newLocalPos.data[thisPoIndex] = {
          ...newLocalPos.data[thisPoIndex],
        };

        newLocalPos.data[thisPoIndex].poItems = [
          ...newLocalPos.data[thisPoIndex].poItems,
        ];

        const thisPo = { ...localPos?.data[thisPoIndex] };
        const newPoItems = [...(thisPo.poItems ?? [])];
        const poItemIdx = newPoItems.findIndex((p) => p.id === poItemId);
        const newPoAssets = [
          ...newPoItems[poItemIdx].poItemAssets.filter(
            (poiA) => poiA.assetId !== assetId
          ),
        ];
        newPoItems[poItemIdx] = {
          ...newPoItems[poItemIdx],
          poItemAssets: newPoAssets,
        };

        thisPo.poItems = newPoItems;
        newLocalPos.data[thisPoIndex] = thisPo as IPo;

        setLocalPos(newLocalPos);
        setHasUnsavedItems(poItemId);
      }
    },
    [localPos, viewedPO?.id]
  );

  const getSelectedAssets = useCallback(
    (record: any) => {
      const { id } = record;
      const thisPo = localPos?.data.find((poId) => poId.id === viewedPO?.id);
      const previousPoItems = [
        ...(pos?.data.find((po) => po.id.toString() === poId)?.poItems ?? []),
      ];

      if (thisPo) {
        const thisPoItem = (
          (hasUnsavedItems && isRowExpanded) || isRowExpanded
            ? thisPo.poItems
            : previousPoItems
        ).find((poItem) => poItem.id === id);

        if (thisPoItem) {
          const selectedAssetIds = (thisPoItem.poItemAssets ?? []).map(
            (asset) => ({ id: asset.assetId, qty: asset.assetQty })
          );
          const selectedAssets = selectedAssetIds.map((selectedAsset) => ({
            asset: assets.find((asset) => asset.id === selectedAsset.id),
            qty: selectedAsset.qty,
          }));
          return (
            <>
              {selectedAssets.map((asset, i) => (
                <Tag
                  closable={page !== "sorting-rtv"}
                  key={asset?.asset?.id ?? i}
                  onClose={() => removeAsset(record.id, asset!.asset!.id)}
                >
                  {asset.asset?.assetName} x {asset.qty}
                </Tag>
              ))}
            </>
          );
        }
      }

      return <></>;
    },
    [
      assets,
      hasUnsavedItems,
      isRowExpanded,
      localPos?.data,
      poId,
      pos?.data,
      removeAsset,
      viewedPO?.id,
    ]
  );

  const onUpdateSortingRtv = useCallback(async () => {
    const { sortingRtv } = await form.validateFields();
    const id = Number(viewedPO!.id);
    const poItemId = expandedRowId;

    if (id && !isNaN(id) && poItemId && sortingRtv) {
      dispatch(
        updateSortingRTV({
          id: Number(id),
          poItemId: Number(poItemId),
          sortingRtv: Number(sortingRtv),
        })
      ).unwrap().then(() => navigate(`/po/${viewedPO?.id}`));

    }
  }, [dispatch, expandedRowId, form, navigate, viewedPO]);

  const poInfo = viewedPO && (
    <>
      <Descriptions
        size="small"
        layout="vertical"
        labelStyle={{ fontWeight: "bold" }}
        bordered
        column={{ xs: 2, md: 4 }}
      >
        <Descriptions.Item label="PO ID">
          {viewedPO.poId}
        </Descriptions.Item>
        <Descriptions.Item label="Status">{viewedPO.status}</Descriptions.Item>
        <Descriptions.Item label="Expected Delivery Date">
          {getDateFromISODate(viewedPO.expectedDeliveryDate)}
        </Descriptions.Item>
      </Descriptions>
      {viewedPO.vendorDetails && <Descriptions
        size="small"
        layout="vertical"
        labelStyle={{ fontWeight: "bold" }}
        bordered
        column={{ xs: 2, md: 3 }}
        style={{ marginTop: '20px' }}
      >
        <Descriptions.Item label="Vendor Detail"> 
        {viewedPO.vendorDetails?.vendorCode} || {viewedPO.vendorDetails?.name}
        </Descriptions.Item>
      </Descriptions>}
      {!!(tableRows && tableRows.length) && (
        <Row style={{ marginTop: "20px" }}>
          <Col xs={24}>
            <Form form={form} component={false}>
              <Row>
                <Form.Item
                  name="receivedDate"
                  rules={[
                    {
                      required: true,
                      message: "Please select Date and Time",
                    },
                  ]}
                  label="Received Date"
                  initialValue={
                    viewedPO.receivedDate && moment(viewedPO.receivedDate)
                  }
                >
                  <DatePicker
                    showTime
                    onChange={(value) => saveDateInLocal(value)}
                    disabled={page === "sorting-rtv"}
                  />
                </Form.Item>
              </Row>
              <Table
                className="po-item-table"
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                bordered
                dataSource={tableRows}
                columns={tableColumns}
                rowClassName={(record, index) =>
                  index === tableRows.length - 1
                    ? "editable-last-row editable-row"
                    : "editable-row"
                }
                pagination={false}
                scroll={{ x: "90%", y: "65vh" }}
                style={{ width: "100%" }}
                size="small"
                footer={() => {
                  if (page === "receiving") return (
                    <Row>
                      <Col style={{ marginRight: "20px" }}>
                        <Button
                          onClick={savePoDrafter}
                          disabled={status === "saving" || !!hasUnsavedItems}
                        >
                          Save as Draft
                        </Button>
                      </Col>
                      {/* <Col>
                        <Button
                          type="primary"
                          onClick={onReceive}
                          disabled={status === "saving" || !!hasUnsavedItems}
                        >
                          Save & Receieve
                        </Button>
                      </Col> */}
                    </Row>
                  )
                }
                }
                expandable={{
                  onExpand: (isExpanded, record) => {
                    setIsRowExpanded(isExpanded);
                    if (isExpanded) {
                      setExpandedRowId(record.id);
                      edit(record);
                    } else {
                      setExpandedRowId(undefined);
                      setEditingKey("");
                      setIsRowExpanded(false);
                      setTempTableRows([]);
                      setIsAddingItem(false);

                      form.resetFields([
                        "requestedQty",
                        "dockRtv",
                        "grossReceivedQty",
                        "finalReceivedQty",
                      ]);
                      assetForm.resetFields();
                    }
                  },
                  expandedRowKeys: [expandedRowId ? expandedRowId : ""],
                  rowExpandable: (record) =>
                    record.id === expandedRowId ||
                    (!expandedRowId && record.id === hasUnsavedItems) ||
                    (!expandedRowId && !hasUnsavedItems),
                  expandedRowRender: (record) => {
                    if (record?.uom?.toLowerCase() === "xyz") {
                      return (
                        <>
                          <div>{getSelectedAssets(record)}</div>
                          {page !== 'sorting-rtv' && <div>
                            {isRowExpanded && (
                              <div style={{ padding: "20px 0", width: "100%" }}>
                                <Form
                                  form={assetForm}
                                  layout="inline"
                                  style={{ width: "100%" }}
                                  component={false}
                                >
                                  <Row style={{ width: "100%" }}>
                                    <Col
                                      xs={24}
                                      md={12}
                                      xl={6}
                                      style={{ marginRight: "20px" }}
                                    >
                                      <Form.Item
                                        name="id"
                                        label="Asset"
                                        rules={[
                                          {
                                            required: true,
                                            message: "Please select Asset",
                                          },
                                        ]}
                                      >
                                        <Select>
                                          {assets.map((asset) => (
                                            <Option
                                              value={asset.id}
                                              key={asset.id}
                                            >{`${asset.assetName} (${asset.assetWeight})`}</Option>
                                          ))}
                                        </Select>
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12} xl={6}>
                                      <Form.Item
                                        name="assetQty"
                                        label="Asset Qty"
                                        rules={[
                                          {
                                            required: true,
                                            message: "Please enter Asset Qty",
                                          },
                                        ]}
                                      >
                                        <Input type="number" />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <Button
                                        onClick={() => onAddAsset(record)}
                                        type="primary"
                                        size="small"
                                      >
                                        Add Asset
                                      </Button>
                                    </Col>

                                  </Row>
                                </Form>
                              </div>
                            )}
                          </div>}
                        </>
                      );
                    }
                  },
                }}
              />
            </Form>
          </Col>
        </Row>
      )}
    </>
  );

  useDocumentTitle(
    page === "sorting-rtv" ? "Update Purchase Order GRN" : "Receive Purchase Order GRN"
  );

  return (
    <>
      <Row
        style={{
          margin: "1rem 0",
          position: "sticky",
          top: 0,
          zIndex: 1,
          background: variables.wmBackgroundColorLight1,
          padding: "5px",
        }}
      >
        <Col xs={24}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to="/po/all">Purchase Orders</NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <NavLink to={`/po/${viewedPO?.id}`}>
                {viewedPO?.vendorDetails?.name}
              </NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {page === "sorting-rtv" ? "Update" : "Receive"}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col>
          <AppPageHeader
            title={`${page === "sorting-rtv" ? "Update" : "Receive"
              } Purchase Order GRN`}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24}>{poInfo}</Col>
      </Row>
    </>
  );
};

export default POReceivePage;
