import { ComponentWithPropsChildren } from "common/interface";
import styled from "styled-components";
import { variables } from "common/variables";

const Group = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.2rem;
  margin-top: 0.5rem;
`;
const Identifier = styled.div`
  padding: 0.5rem 0;
  margin-right: 0.5rem;
`;
const Divider = styled.div`
  flex: 1;
  display: block;
  border-bottom: 0.01rem solid;
`;

export const FormGroupIdentifier = (props: ComponentWithPropsChildren) => (
  <Group>
    <Identifier style={{ color: variables.gray }}>{props.children}</Identifier>
    <Divider style={{ borderColor: variables.gray }} />
  </Group>
);
