export const routes = {
  LOGIN: "login",
  WAREHOUSE_SELECTION: "warehouse-selection",
  STORE_REQUISITION: {
    SELF: "requisition",
    ALL: "requisition/all",
    UPLOAD: "requisition/upload",
    SAVE: "requisition/save",
    ALL_DISPATCHED: "requisition/all-dispatched",
    ALL_SPLIT_SKU: "requisition/all-splitsku",
    CREATE_SPLIT_SKU: "requisition/new-splitsku",
    USER_MAPPING_UPLOAD: "user-mapping/upload",
    USER_MAPPING_SAVE: "user-mapping/save",
    SEC_PICKING_UPLOAD: "requisition/sec-picking/upload",
    SEC_PICKING_SAVE: "requisition/sec-picking/save",
    SUGGESTED_PICKING: "requisition/suggested-picking",
  },
  SR_USER_MAPPING: {
    NA_USER_MAPPING_UPLOAD: "napicking/user-mapping/upload",
    NA_USER_MAPPING_SAVE: "napicking/user-mapping/save",
    FA_USER_MAPPING_UPLOAD: "fapicking/user-mapping/upload",
    FA_USER_MAPPING_SAVE: "fapicking/user-mapping/save",
  },
  MIDDLE_MILE: {
    MIDDLE_MILE_UPLOAD: "middle-mile/upload",
    MIDDLE_MILE_SAVE: "middle-mile/save",
    ALL_MIDDLE_MILE: "middle-mile/all",
    TRACKING_INFO: "middle-mile/tracking-info/all",
    MERGE_ROUTES: "middle-mile/merge-routes",
  },
  STORE_REJECTION: {
    INVENTORY_REJECTION_UPLOAD: "store-rejection/rejection/upload",
    INVENTORY_REJECTION_SAVE: "store-rejection/rejection/save",
  },
  VENDOR: {
    ALL: 'vendors/',
    NEW: 'vendors/new',
    EDIT: 'vendors/edit/:id'
  },
  PO: {
    UPLOAD: "po/upload",
    GRN: "po/all",
    PO_REQUESTS: "po/requests",
    VIEW_PO_REQUEST: "po/requests/:poId",
    RECEIVED_PO_REQUEST: "po/requests/:poId/receive",
    SAVE: "po/save",
    PO: "po/:poId",
    EDIT: "po/:poId/edit",
    RECEIVE: "/po/:poId/receive",
    SORTING_RTV: "/po/:poId/sorting-rtv",
    UPLOAD_BILLING_RATE: "po/billing_rate/upload",
    SAVE_BILLING_RATE: "po/billing_rate/save",
  },
  PPD: {
    UPLOAD: "ppd/upload",
    SAVE: "ppd/save",
    DELIVERY_ORDERS: "ppd/delivery/orders",
    VIEW_DELIVERY_ORDERS: "ppd/delivery/orders/:id",
    ORDERS: "ppd/orders",
    VIEW: "ppd/orders/:id",
    CANCEL_ORDER: "ppd/order/cancel",
    CRON_REMAP_USERS: "ppd/cron/remap-users",
    VIEW_TABLE: "ppd/tables",
  },
  STORE_RETURN: {
    UPLOAD: "store-return/return/upload",
    SAVE: "store-return/return/save",
    ALL: "store-return/all",
    STORE_RETURN: "store-return/:srId",
    RECEIVE: "store-return/:srId/receive",
    CREATE_SR_REQUESTS: "store-return/create",
    CREATE_SR_REQUESTS_DETAILS: "store-return/create/:srId",
  },
  ADMIN_SERVICES: {
    ADD_NEW_USER: "admin-services/add-user",
    ADMIN_USERS: "admin-services/admin-users",
    UPDATE_ADMIN: "admin-services/admin-users/:id",
    UPDATE_SOCIETY: "admin-services/create-society/:id",
    CREATE_SOCIETY: "admin-services/create-society",
    SOCIETY: "admin-services/society",
    UPLOAD_SOCIETY_SEQUENCE: "admin-services/society/sequence/upload",
    SAVE_SOCIETY_SEQUENCE: "admin-services/society/sequence/upload/save",
  },
  FRANCHISE: {
    UPLOAD: "franchise-inventory/upload",
    SAVE: "franchise-inventory/save",
    UPLOAD_PRICING_OVERRIDE: "franchise-inventory/pricing-override/upload",
    SAVE_PRICING_OVERRIDE: "franchise-inventory/pricing-override/save",
    UPLOAD_CATEGORY_PRICING: "franchise-inventory/category-pricing/upload",
    SAVE_CATEGORY_PRICING: "franchise-inventory/category-pricing/save",
    UPLOAD_INVENTORY_ADJUSTMENT: "franchise-inventory/inventory-adj/upload",
    SAVE_INVENTORY_ADJUSTMENT: "franchise-inventory/inventory-adj/save",
    ALL: "franchise-inventory/all",
    ADD_NEW_WH_SKU: "franchise-inventory/all/wh-sku/add",
  },
  STOCK_EXCHANGE: {
    UPLOAD: "stock-exchange/upload",
    SAVE: "stock-exchange/save",
    UPLOAD_HOLD: "stock-exchange/hold/upload",
    SAVE_HOLD: "stock-exchange/hold/save",
    SENT: "stock-exchange/",
    RECEIVED: "stock-exchange-received",
    VIEW: "stock-exchange/:id",
    VIEW_RECEIVED: "stock-exchange-received/:id",
  },
  INTERNAL_STOCK_EXCHANGE: {
    UPLOAD: "internal-stock-exchange/upload",
    SAVE: "internal-stock-exchange/save",
  },
  PROCUREMENT: {
    UPLOAD_PROJECTED_SR: "procurement/projected-sr/upload",
    SAVE_PROJECTED_SR: "procurement/projected-sr/save",
    
    UPLOAD_VENDOR_WAREHOUSE_MAPPING: "procurement/vendor-warehouse-mapping/upload",
    SAVE_VENDOR_WAREHOUSE_MAPPING: "procurement/vendor-warehouse-mapping/save",
  },
  USER_SERVICES: {
    UPLOAD_USER_DAY_MAPPING: "user-services/upload-user-day-mapping",
    SAVE_USER_DAY_MAPPING: "user-services/save-user-day-mapping",
  },
  QUALITY_CHECK: {
    ALL: "quality-check/all",
  },
  CASH_COLLECTION: {
    ALL: "cash-collections/all"
  },
  CLUSTER_SERVICE: {
    UPLOAD_CLUSTER_STORE_MAPPING: 'cluster_services/upload_cluster_store_mapping',
    SAVE_CLUSTER_STORE_MAPPING: 'cluster_services/save_cluster_store_mapping',
    UPLOAD_CLUSTER_AM_MAPPING: 'cluster_services/upload_cluster_am_mapping',
    SAVE_CLUSTER_AM_MAPPING: 'cluster_services/save_cluster_am_mapping',
  },
  CONSUMER_SERVICE: {
    UPLOAD_CLUSTER_MAPPING: 'consumer_services/upload_cluster_store_mapping',
    SAVE_CLUSTER_MAPPING: 'consumer_services/save_cluster_store_mapping',
    UPLOAD_CLUSTER_SOCIETY_MAPPING: 'consumer_services/upload_cluster_society_store_mapping',
    SAVE_CLUSTER_SOCIETY_MAPPING: 'consumer_services/save_cluster_society_store_mapping',
  },
  CLUSTER_SOCIETY: {
    GET_DELAY_REQUEST: 'society/delay_requests',
  },
};
