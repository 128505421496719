import { Row, Col, message, Table, Pagination, Avatar, Image, Carousel, Select, Button, Form, DatePicker, Modal, TimePicker } from "antd";
import { ColumnsType } from "antd/lib/table";
import { AppPageHeader } from "components/AppPageHeader";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useDocumentTitle } from "hooks/usePageTitle";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { NavLink, useSearchParams } from "react-router-dom";
import { fallBackImage } from "../../common/styles";
import { ITrackingInfo } from "common/interface/ITrackingInfo";
import { fetchTrackingInfo, saveMiddleMileRemark as saveMiddleMileTrackingItem, updateMiddleMileItem, updateMiddleMileItemDateTime } from "store/middle-mile/middleMileSlice";
import { AppActionStrip } from "components/AppActionStrip/AppActionStrip";
import { AppFilter, AppFilterButton } from "components/AppFilter";
import { AppMiddleMileTrackingFilter } from "components/AppFilter/AppMiddleMileTrackingFilter";
import moment from "moment";
import { AiFillPlayCircle } from "react-icons/ai";
import { variables } from "common/variables";

type PartialTrackingInfo = Partial<ITrackingInfo> & { key: string, remark?: string, showModel?: boolean };
const MESSAGE_KEY = "middle_mile_tracking";

const headerKeys: { [key: string]: string } = {

    storeId: "Store Id",
    route: "Store Route",
    deliveryDate: "deliveryDate",
    slot: "Slot",
    storeOpeningTime: "Store Opening Time",
    dispatchTime: "Dispatch Time",
    status: "Status",
    userDetails: "User Details",
    arrivedAt: "Arrived At",
    deliveryAlert: "Delivery Alert",
    images: "Media",
    remarks: "Remarks",
    time: "Time",
    action: "Action",
};

const StyledPagination = styled(Pagination)`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

const MiddleMileTrackingPage = () => {
    useDocumentTitle("Middle Mile Tracking");
    const dispatch = useAppDispatch();
    const [columns, setColumns] = useState<ColumnsType<PartialTrackingInfo>>();
    const [rows, setRows] = useState<PartialTrackingInfo[]>();
    const { trackingInfo, status, error } = useAppSelector((state) => state.middleMile);
    const [searchParams] = useSearchParams();
    const [filterCount, setFilterCount] = useState(0);
    const [filterState, setFilterState] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeVideoUrl, setActiveVideoUrl] = useState("");

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setActiveVideoUrl("");
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setActiveVideoUrl("");
        setIsModalOpen(false);
    };
    const getTrackingInfo = useCallback(
        (pageNum = 1, pageSize = 25, filter = "") => {
            dispatch(fetchTrackingInfo({ pageNo: pageNum, pageSize, filter }));
        },
        [dispatch]
    );

    useEffect(() => {
        const filter = searchParams.toString();
        setFilterCount(
            Object.keys(Object.fromEntries(new URLSearchParams(filter))).length
        );
        getTrackingInfo(1, 25, filter);
    }, [dispatch, getTrackingInfo, searchParams]);

    useEffect(() => {
        if (status === "loading") {
            message.loading({ content: "Loading Tracking Details", key: MESSAGE_KEY });
        } else if (status === "success") {
            // message.success({
            //   content: "Admin Users loaded!",
            //   key: MESSAGE_KEY,
            // });
        } else if (status === "error") {
            message.error({ content: error, key: MESSAGE_KEY });
        }

        return () => {
            message.destroy();
        };
    }, [status, error]);

    useEffect(() => {
        const data: PartialTrackingInfo[] = (trackingInfo?.data ?? []).map((au, i) => {

            return { ...au, key: `trackingInfo-${i}`, ellipses: true, remark: au.metadata.remarks };
        });

        const tableHeaders: ColumnsType<PartialTrackingInfo> = [
            ...Object.keys(headerKeys).map((key) => ({
                key,
                title: headerKeys[key],
                dataIndex: key,
                render: (text: string, record: PartialTrackingInfo) => {
                    if (key === "deliveryDate") {
                        return (
                            <>
                                {record.deliveryDate?.split("T")[0]}
                            </>
                        );
                    }
                    if (key === "userDetails") {
                        return (
                            <Col>
                                <Row>{record.userDetails?.firstname ?? ""} {record.userDetails?.lastname ?? ""}</Row>
                                <Row>{record.userDetails?.phone != undefined ? `(${record.userDetails?.phone})` : ""}</Row>
                            </Col>
                        );
                    }
                    if (key === "arrivedAt") {
                        return (
                            <>
                                {record.metadata?.arrivedAt}
                            </>
                        );
                    }

                    if (key === "time") {
                        return <Row>
                            <TimePicker
                                value={record.metadata?.remarksCreatedAt == undefined ? undefined : moment(record.metadata?.remarksCreatedAt)}
                                style={{ width: "15rem" }}
                                use12Hours
                                format={'HH:mm'}
                                onChange={(nv) => {
                                    dispatch(updateMiddleMileItemDateTime({ id: record.id, nv: nv?.toISOString() }));
                                }} />
                        </Row>
                    }

                    if (key === "remarks") {
                        return <Row className="remarksRow">
                            <Select
                                showSearch
                                size="small"
                                value={record.remark}
                                filterOption={(input, option) => {
                                    return option?.children?.toString().toLowerCase().includes(input?.toLowerCase() ?? "") ?? false;
                                }}
                                style={{ width: "14rem", height: "auto", wordWrap: "break-word" }}
                                onChange={(nv) => {
                                    dispatch(updateMiddleMileItem({ id: record.id, nv: nv }));
                                }}

                            >
                                {[
                                    "Arrived",
                                    "Route not followed by driver",
                                    "Store location incorrect",
                                    "New Part Timer went for delivery",
                                    "Society entry issue",
                                    "Store location not found, navigation took time",
                                    "Delivery delayed due to issue in previous store(s)",
                                    "Dispatch delayed due to vehicle delay",
                                    "Dispatch delayed due to rain",
                                    "Vehicle breakdown",
                                    "Route design risk",
                                    "Network issue",
                                    "Delivery delayed due to heavy rain",
                                    "Incorrect loading of crates in vehicle",
                                ].map((status) => (
                                    <Select.Option value={status} key={status}>
                                        {(status)}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Row>
                    }

                    if (key === "action") {
                        return <Button
                            onClick={() => {
                                if (record.remark != undefined) {
                                    dispatch(saveMiddleMileTrackingItem({ remark: record.remark!, remarksCreatedAt: record.metadata?.remarksCreatedAt, mmId: record.id!.toString() },))
                                }

                            }}>
                            Save
                        </Button>
                    }

                    if (key === "images") {
                        return (
                            <Row align="middle" gutter={10}>
                                <Image.PreviewGroup>
                                    {(record.metadata?.urls ?? []).map((url: string, index) => {
                                        return url.substring(url.length - 4).includes("mp4")
                                            ? <Col>
                                                <AiFillPlayCircle
                                                    onClick={() => {
                                                        setActiveVideoUrl(url);
                                                        showModal();
                                                    }}
                                                    size={"2.35rem"}
                                                    color={variables?.primaryColor} />
                                                <Modal title="Video Preview"
                                                    bodyStyle={{
                                                        height: "70vh",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center"
                                                    }}
                                                    destroyOnClose={true}
                                                    visible={isModalOpen}
                                                    onOk={() => handleOk()}
                                                    onCancel={() => handleCancel()}
                                                    okButtonProps={{ hidden: true }}
                                                >
                                                    <video
                                                        key={activeVideoUrl}
                                                        controls={true}
                                                        src={activeVideoUrl}
                                                        muted={false}
                                                        autoPlay={false}
                                                        style={{ height: "100%", width: "100%", objectFit: "contain" }} //object-fit:cover
                                                    />
                                                </Modal>
                                            </Col>
                                            : <Col>
                                                <Avatar
                                                    src={<Image
                                                        src={`${url}`}
                                                        fallback={fallBackImage}
                                                        alt={text}
                                                        width="100%" />} />
                                            </Col>;
                                    })}
                                </Image.PreviewGroup>
                            </Row>
                        );
                    }
                    return <>{text}</>;
                },
            })),
        ];
        setColumns(tableHeaders.map((e) => {
            if (e.key === "remarks") {
                e.width = "16rem";
            }
            if (e.key === "time") {
                e.width = "13rem";
            }

            return e;
        }));
        setRows(data);
    }, [trackingInfo, isModalOpen]);

    const Paginator = () => {
        if (trackingInfo) {
            const { pageNo, pageSize, total } = trackingInfo;
            return (
                <StyledPagination
                    current={pageNo}
                    pageSize={pageSize}
                    total={total}
                    onChange={(page, pageSize) => {
                        const filter = searchParams.toString();
                        setFilterCount(
                            Object.keys(Object.fromEntries(new URLSearchParams(filter))).length
                        );
                        getTrackingInfo(page, pageSize, filter);
                    }}
                />
            );
        }

        return <></>;
    };

    return (
        <>
            <Row>
                <Col>
                    <AppPageHeader title="Middle Mile Tracking Info" />
                </Col>
            </Row>
            <Row>
                <AppActionStrip>
                    <div className="action-strip__item">
                        <AppFilterButton
                            active={filterState}
                            onClick={() => setFilterState(!filterState)}
                            filterCount={filterCount}
                        />
                    </div>
                </AppActionStrip>
            </Row>
            <Row style={{ marginBottom: "1rem" }}>
                <AppFilter expanded={filterState}>
                    <AppMiddleMileTrackingFilter />
                </AppFilter>
            </Row>
            {rows && rows.length ? (
                <Row>
                    <Col xs={24}>
                        <Table
                            rowClassName={(record, index) => isTimeAfterTwoThirty(record.dispatchTime) ? "" : 'table-row-dark' }
                            columns={columns}
                            dataSource={rows.filter(e => e.id !== -1)}
                            scroll={{ x: "100vh", y: "65vh" }}
                            size="small"
                            pagination={false}
                            loading={status === "loading"}
                            bordered
                        />
                    </Col>
                    <Col xs={24}>
                        <Paginator />
                    </Col>
                </Row>
            ) : (
                status !== "loading" && <>No Tracking Details to show.</>
            )}
        </>
    );
};

function isTimeAfterTwoThirty(time?: string): boolean {
    if (time === null) {
        return false;
    }

    const currentTime = new Date(`2000-01-01T${time}`);
    const twoThirtyAM = new Date(`2000-01-01T02:30:00`);

    return currentTime > twoThirtyAM;
}

export default MiddleMileTrackingPage;