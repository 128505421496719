import { Row, Col, message, Table, Pagination, Button, Form, Typography, Popconfirm, Input, Select, InputNumber, Switch } from "antd";
import { ColumnsType } from "antd/lib/table";
import { AppPageHeader } from "components/AppPageHeader";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useDocumentTitle } from "hooks/usePageTitle";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AppActionStrip } from "components/AppActionStrip/AppActionStrip";
import { AppFilterButton, AppFilter } from "components/AppFilter";
import { fetchLatestPricesStatus, getFranchiseCatalogue, updateFranchiseItems, updateLatestInventoryFlag } from "store/franchise/franchiseSlice";
import { FranchiseCatalogueItem } from "common/interface/IFranchiseServices";
import { FranchiseInventoryFilter } from "components/AppFilter/FranchiseInventoryFilter";
import { Rule } from "antd/lib/form";

type PartialFranchiseCatalogueType = Partial<FranchiseCatalogueItem> & { key: string };
const MESSAGE_KEY = "all_franchise_catalogue";

const headerKeys: { [key: string]: string } = {
  skuCode: "SKU Code",
  moq: "MOQ",
  salePrice: "Sale Price",
  sortedRetailPrice: "Retail Price",
  marginDiscount: "Margin Discount (%)",
  actualQty: "Total Quantity",
  quantity: "Remaining Quantity",
  maxOrderQty: "Max Order Qty",
  startOrderQty: "Start Order Qty",
  appActive: "Is App Active",
  actions: "Action",
};

const StyledPagination = styled(Pagination)`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

const FranchiseListing = () => {
  useDocumentTitle("Franchise Catalogue");
  const dispatch = useAppDispatch();
  const [columns, setColumns] = useState<ColumnsType<PartialFranchiseCatalogueType>>();
  const [rows, setRows] = useState<PartialFranchiseCatalogueType[]>();
  const { warehouseCatalogue, status, error, isLatestPrices } = useAppSelector((state) => state.franchiseServices);
  const { whId } = useAppSelector((state) => state.app);
  const [filterState, setFilterState] = useState(false);
  const [searchParams] = useSearchParams();
  const [filterCount, setFilterCount] = useState(0);
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState<string>("");
  const navigate = useNavigate();

  const getFranchiseData = useCallback(
    (pageNum = 1, pageSize = 25, filter = "") => {
      dispatch(getFranchiseCatalogue({ pageNo: pageNum, pageSize, whId: whId!, filter }));
    },
    [dispatch]
  );

  const edit = (record: PartialFranchiseCatalogueType & { key: React.Key }) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.key);
  };

  useEffect(() => {
    dispatch(fetchLatestPricesStatus());
  }, []);

  useEffect(() => { }, [isLatestPrices]);

  const cancel = () => {
    setEditingKey('');
  };

  const updateLatestPriceFlag = () => {
    dispatch(updateLatestInventoryFlag());
  };

  const isEditing = (record: PartialFranchiseCatalogueType) => record.key === editingKey;

  const save = async (key: React.Key) => {
    try {
      const row = (await form.validateFields()) as PartialFranchiseCatalogueType;
      if (Object.values(row).every(e => e !== undefined && e !== null)) {
        const newData = [...(rows ?? [])];
        const index = newData.findIndex((item) => key === item.key);
        await dispatch(updateFranchiseItems({ ...row, id: newData[index].id, whId: newData[index].whId }));
        setEditingKey('');
        form.resetFields();
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  useEffect(() => {
    const filter = searchParams.toString();
    setFilterCount(
      Object.keys(Object.fromEntries(new URLSearchParams(filter))).length
    );
    getFranchiseData(1, 25, filter);
  }, [dispatch, getFranchiseData, searchParams]);

  useEffect(() => {
    if (status === "loading") {
      message.loading({ content: "Loading Franchise Items", key: MESSAGE_KEY });
    } else if (status === "success") {
      message.success({
        content: "Item Updated",
        key: MESSAGE_KEY,
      });
    } else if (status === "error") {
      message.error({ content: error, key: MESSAGE_KEY });
    }

    return () => {
      message.destroy();
    };
  }, [status, error]);

  useEffect(() => {
    const FranchiseCataloguesData = warehouseCatalogue?.data;
    const data: PartialFranchiseCatalogueType[] = (FranchiseCataloguesData ?? []).map((au, i) => {
      const {
        id,
        whId,
        skuCode,
        skuName,
        moq,
        salePrice,
        sortedRetailPrice,
        marginDiscount,
        actualQty,
        quantity,
        maxOrderQty,
        startOrderQty,
        appActive,
      } = au;

      const newRow = {
        id,
        whId,
        skuCode,
        skuName,
        moq,
        salePrice,
        sortedRetailPrice,
        marginDiscount,
        actualQty,
        quantity,
        maxOrderQty,
        startOrderQty,
        appActive,
      };
      return { ...newRow, key: `FranchiseCatalogues-${i}`, ellipses: true };
    });

    const tableHeaders: ColumnsType<PartialFranchiseCatalogueType> = [
      ...Object.keys(headerKeys).map((key) => ({
        key,
        title: headerKeys[key],
        dataIndex: key,
        render: (text: string, record: PartialFranchiseCatalogueType) => {
          const editable = isEditing(record);

          if (key === 'skuCode') {
            return <Form.Item name={key} style={{ marginBottom: "0" }} rules={[{ "required": true, }]}>
              <Col>
                <Row>{record.skuCode} {record.skuName}</Row>
              </Col>
            </Form.Item>;

          }

          if (key === "actions") {

            return editable ? (
              <span>
                <Typography.Link
                  disabled={status === "loading"}
                  onClick={() => save(record.key)}
                  style={{ marginRight: 8 }}>
                  Save
                </Typography.Link>
                <Popconfirm title="Sure to cancel?"
                  disabled={status === "loading"}
                  onConfirm={cancel}>
                  <a>Cancel</a>
                </Popconfirm>
              </span>
            ) : (
              <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                Edit
              </Typography.Link>
            );
          }
          const cellRules: Rule[] = [{ "required": true, }];
          if (key === "actualQty") {
            cellRules.push({ pattern: new RegExp("^[0-9]*$"), message: "Only numbers allowed", });
          }
          return editable && key !== 'quantity' ?
            <Form.Item name={key} style={{ marginBottom: "0" }} rules={cellRules}>
              {getTableCell(record, key)}
            </Form.Item> : <>{key === "appActive" ? record.appActive === 1 ? "True" : "False" : text}</>;
        },
      })),
    ];

    setColumns(tableHeaders);
    setRows(data);
  }, [warehouseCatalogue, editingKey]);


  const getTableCell = (record: PartialFranchiseCatalogueType, key: string) => {
    switch (key) {
      case "skuCode":
        return <Input disabled={true} />;
      case "appActive":
        return <Select options={[
          {
            value: 0,
            label: 'False',
          }, {
            value: 1,
            label: 'True',
          },
        ]} />;
      default:
        return <InputNumber
          placeholder={headerKeys[key]}
          min={0}
        />;
    }
  }

  const Paginator = () => {
    if (warehouseCatalogue) {
      const { pageNo, pageSize, total } = warehouseCatalogue;
      return (
        <StyledPagination
          current={pageNo}
          pageSize={pageSize}
          total={total}
          onChange={(page, size) => {
            const filter = searchParams.toString();
            getFranchiseData(page, size, filter)
          }}
        />
      );
    }

    return <></>;
  };

  return (
    <>
      <Row style={{ justifyContent: "space-between", alignItems: "center", }}>
        <Col>
          <AppPageHeader title="Franchise Catalogue Listing" />
        </Col>
        {isLatestPrices !== undefined && <Col>
          <Row> Latest Prices Status</Row>
          <Popconfirm
            title={`This will update the price for all existing carts, \nare you sure?`}
            disabled={status === "updating"}
            onConfirm={() => updateLatestPriceFlag()}>
            <Switch disabled={isLatestPrices === undefined} loading={status === "updating"} checkedChildren={"Today"} checked={isLatestPrices === true} unCheckedChildren={isLatestPrices === undefined ? "Loading" : "Yesterday"} />
          </Popconfirm>
        </Col>}

      </Row>
      <Row>
        <AppActionStrip>
          <div className="action-strip__item">
            <AppFilterButton
              active={filterState}
              onClick={() => setFilterState(!filterState)}
              filterCount={filterCount}
            />
          </div>
          <Button
            type="primary"
            onClick={() => navigate('wh-sku/add')}
          >
            Add WH Sku
          </Button>
        </AppActionStrip>
      </Row>
      <Row style={{ marginBottom: "1rem" }}>
        <AppFilter expanded={filterState}>
          <FranchiseInventoryFilter />
        </AppFilter>
      </Row>
      {rows && rows.length ? (
        <Form form={form}>
          <Row>
            <Col xs={24}>
              <Table
                columns={columns}
                dataSource={rows.filter(e => e.id !== -1)}
                scroll={{ x: "100vh", y: "65vh" }}
                size="small"
                pagination={false}
                loading={status === "loading"}
                bordered
              />
            </Col>
            <Col xs={24}>
              <Paginator />
            </Col>
          </Row></Form>
      ) : (
        status !== "loading" && <>No SKUs to show.</>
      )}
    </>
  );
};

export default FranchiseListing;
