import { Button, Checkbox, Col, Form, Input, Row, Select } from "antd";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

const StyledFilterContainer = styled.div`
  &,
  & .filter {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    &__item {
      margin: 0.5rem;

      & .ant-form-item {
        margin: 0;
      }

      &--submit {
        align-self: flex-end;
      }
    }
  }
`;

export const AppRequisitionFilter = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [form] = Form.useForm();

    useEffect(() => {
        const params = Object.fromEntries(new URLSearchParams(searchParams));
        form.setFieldsValue(params);
    }, [form, searchParams]);

    const onSearch = (values: Record<string, string>) => {
        const params: Record<string, string> = {};
        Object.entries(values).forEach(([key, value]) => {
            if (value !== undefined && value != null && value != "") {
                params[key] = value;
            }
        });
        setSearchParams(params);
    };

    return (
        <StyledFilterContainer>
            <Form
                form={form}
                name="vendor-filter-form"
                onFinish={onSearch}
                layout="vertical"
                style={{ width: "100%" }}
            >
                <Row className="filter">
                    <Col xs={20} sm={4} md={5} lg={4} className="filter__item">
                        <Form.Item name="storeId" label="Store Id">
                            <Input
                                placeholder="Search by Store Id"
                                size="small"
                                allowClear
                                onChange={(e) => {
                                    if (e.target.value === '') {
                                        onSearch({});
                                      }
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={20} sm={4} md={5} lg={4} className="filter__item">
                        <Form.Item name="routeId" label="Route Id">
                            <Input
                                placeholder="Search by Route Id"
                                size="small"
                                allowClear
                                onChange={(e) => {
                                    if (e.target.value === '') {
                                        onSearch({});
                                      }
                                }}
                            />
                        </Form.Item>
                    </Col>


                    <Col
                        xs={20}
                        sm={4}
                        md={2}
                        lg={2}
                        className="filter__item filter__item--submit"
                    >
                        <Form.Item>
                            <Button type="primary" htmlType="submit" size="small">
                                Search
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </StyledFilterContainer>
    );
};
