import { Badge, Button } from "antd";
import { BsFilter } from "react-icons/bs";
import styled from "styled-components";
import { variables } from "common/variables";

interface FilterButtonProps {
  active?: boolean;
  onClick: () => void;
  filterCount?: number;
}

const StyledButton = styled(Button)`
  &.filterButton {
    &--active {
      border-color: ${variables.primaryColor} !important;
    }
  }
`;

export const AppFilterButton = (props: FilterButtonProps) => (
  <Badge
    count={props.filterCount || 0}
    style={{ backgroundColor: variables.primaryColor }}
  >
    <StyledButton
      className={`filterButton ${props.active && "filterButton--active"}`}
      icon={<BsFilter />}
      onClick={props.onClick}
    >
      Filters
    </StyledButton>
  </Badge>
);
