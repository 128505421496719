import { Layout } from "antd";
import { ComponentWithPropsChildren } from "common/interface";
import styled, { CSSProperties } from "styled-components";

const { Content } = Layout;

const StyledContent = styled(Content)`
  position: relative;
  overflow-y: auto;
  padding: 0 1.5rem;
  padding-bottom: 1.5rem;
`;

interface AppContentProps extends ComponentWithPropsChildren {
  style?: CSSProperties;
}

export const AppContent = (props: AppContentProps) => (
  <StyledContent style={{ ...props.style }}>{props.children}</StyledContent>
);
