import { IRequisitionUpload, IRequisitionUserMappingUpload } from "common/interface";

const STORAGE_KEY_REQ = "processingRequisition";
const STORAGE_KEY_USER_MAPPING = "processingRequisitionUserMapping";

export const persistProcessingRequisition = (data: IRequisitionUpload) => {
  localStorage.setItem(STORAGE_KEY_REQ, JSON.stringify(data));
}

export const getPersistedProcessingRequisition = (): IRequisitionUpload => {
  const processingRequisition = localStorage.getItem(STORAGE_KEY_REQ);
  return processingRequisition ? JSON.parse(processingRequisition) : {};
}

export const removePersistedProcessingRequisition = () => {
  localStorage.removeItem(STORAGE_KEY_REQ);
}

export const persistProcessingRequisitionUserMapping = (data: IRequisitionUserMappingUpload) => {
  localStorage.setItem(STORAGE_KEY_USER_MAPPING, JSON.stringify(data));
}


export const getPersistedProcessingRequisitionUserMapping = (): IRequisitionUserMappingUpload => {
  const processingUserMapping = localStorage.getItem(STORAGE_KEY_USER_MAPPING);
  return processingUserMapping ? JSON.parse(processingUserMapping) : {};
}

export const removePersistedProcessingRequisitionUserMapping = () => {
  localStorage.removeItem(STORAGE_KEY_USER_MAPPING);
}

export const persistStoreSecPicking = (data: IRequisitionUserMappingUpload) => {
  localStorage.setItem(STORAGE_KEY_USER_MAPPING, JSON.stringify(data));
}


export const getPersistedStoreSecPicking = (): IRequisitionUserMappingUpload => {
  const processingUserMapping = localStorage.getItem(STORAGE_KEY_USER_MAPPING);
  return processingUserMapping ? JSON.parse(processingUserMapping) : {};
}

export const removePersistedStoreSecPicking = () => {
  localStorage.removeItem(STORAGE_KEY_USER_MAPPING);
}