import {
  Row,
  Col,
  Alert,
  message,
  Table,
  Select,
  DatePicker,
  Form,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import { apis, AUTH_HEADER_NAME } from "common/apis";
import {
  INaPickingUserMappingUpload,
  INaPickingUserMappingUploadRow,
} from "common/interface";
import { routes } from "common/routes";
import { generateHeaders } from "common/utils";
import { AppPageHeader } from "components/AppPageHeader";
import { AppUpload } from "components/AppUpload";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useDocumentTitle } from "hooks/usePageTitle";
import { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { removeProcessingMiddleMileMapping, setProcessingMiddleMileMapping } from "store/middle-mile/middleMileSlice";

export const UploadMiddleMileMappingPage = () => {
  useDocumentTitle("Middle Mile: Upload Mapping");
  const { authToken } = useAppSelector((state) => state.app.user);
  const { processingMiddleMileMapping } = useAppSelector(
    (state) => state.middleMile
  );
  const { slots } = useAppSelector((state) => state.requisition);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [hasErrorRows, setHasErrorRows] = useState(false);
  const [date, setDate] = useState<string>();
  const [slot, setSlot] = useState<string>();
  const [deliveryNumber, setDeliveryNumber] = useState<number>();

  useEffect(() => {
    const { timeUploaded } = processingMiddleMileMapping;
    if (timeUploaded) {
      const uploadedTime = new Date(timeUploaded).getTime();
      const currentTime = new Date().getTime();
      const minsPassed = (currentTime - uploadedTime) / 1000 / 60;

      if (minsPassed >= 15) {
        dispatch(removeProcessingMiddleMileMapping());
      }
    }

    if (processingMiddleMileMapping && processingMiddleMileMapping.key) {
      navigate(`/${routes.MIDDLE_MILE.MIDDLE_MILE_SAVE}`, {});
    }

    return () => {
      message.destroy();
    };
  }, [processingMiddleMileMapping, navigate, dispatch]);

  // useEffect(() => {
  //   return (() => {
  //     dispatch(removeProcessingNaPickingUserMapping())
  //   });
  // }, []);

  useEffect(() => {
    const failed = processingMiddleMileMapping?.failedRows?.length;

    if (failed) {
      setHasErrorRows(true);
    }
  }, [processingMiddleMileMapping?.failedRows]);

  const headers: { [name: string]: string } = {
    ...(authToken && { [AUTH_HEADER_NAME]: authToken }),
  };

  const onUploaded = (data: INaPickingUserMappingUpload) => {
    dispatch(setProcessingMiddleMileMapping(data));
  };

  const onUploading = () => {
    dispatch(removeProcessingMiddleMileMapping());
  };

  const { Option } = Select;

  const { headerMapping } = processingMiddleMileMapping;
  const headerMappingWithError = headerMapping + ",errors:Errors";

  const generateMemoizedHeaders = useMemo(
    () => generateHeaders(headerMappingWithError),
    [headerMappingWithError]
  );

  const tableHeaders = generateMemoizedHeaders || {};
  const successRows = processingMiddleMileMapping?.successRows || [];
  const failedRows = processingMiddleMileMapping?.failedRows || [];
  const successData = successRows?.map((row, i) => ({
    ...row,
    key: `data-success-${i}`,
  }));
  const failedData = failedRows?.map((row, i) => ({
    ...row,
    key: `data-failed-${i}`,
  }));
  const data = [...failedData, ...successData];
  const columns: ColumnsType<INaPickingUserMappingUploadRow & { key: string }> =
    [
      ...Object.keys(tableHeaders).map((key) => ({
        key,
        title: tableHeaders[key],
        dataIndex: key,
        render: (
          text: string,
          record: INaPickingUserMappingUploadRow & { key: string }
        ) => {
          if (key === "errors") {
            const errorMessages = record.errors
              ?.map((error) => error.message)
              .join(", ");
            return <>{errorMessages}</>;
          }
          return <>{text}</>;
        },
      })),
    ];

  const addBorderToErrorRows = (
    record: INaPickingUserMappingUploadRow & { key: string }
  ) => {
    if (record.errors && record.errors.length) {
      return "error-row";
    }

    return "";
  };

  return (
    <>
      <Row>
        <Col>
          <AppPageHeader title="Upload Middle Mile Mapping" />
        </Col>
      </Row>
      <Row>
        <Form>
          <Form.Item label="Date" name="deliveryDate" required>
            <DatePicker
              onChange={(val) => setDate(val?.format("yyyy-MM-DD"))}
            />
          </Form.Item>
          {slots && (
            <Form.Item label="Slot" name="slot" required>
              <Select
                placeholder="Select slot"
                onChange={(val) => setSlot(val)}
              >
                {Object.entries(slots).map(([label, value]) => (
                  <Option value={value} key={value}>
                    {label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
          
            <Form.Item label="Delivery Type" name="deliveryNumber" required>
              <Select
                placeholder="Select Delivery Type"
                onChange={(val) => setDeliveryNumber(val)}
              >
                {Object.entries({1: "Morning Dispatch", 2: "Day Dispatch"}).map(([value, label]) => (
                  <Option value={value} key={value}>
                    {label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          
        </Form>
      </Row>
      {!!(date && slot && deliveryNumber) && (
        <Row>
          <Col lg={16} xs={24}>
            <AppUpload
              uploadUrl={apis.uploadMiddleMileMapping}
              headers={headers}
              onUploaded={onUploaded}
              onUploading={onUploading}
              hasErrorRows={hasErrorRows}
              data={{ deliveryDate: date, slot, deliveryNumber }}
            />
          </Col>
        </Row>
      )}
      {!!Object.keys(processingMiddleMileMapping).length &&
        !processingMiddleMileMapping.key && (
          <>
            <Row>
              <Col lg={16} xs={24}>
                <Alert
                  type="error"
                  message="Errors in sheet data."
                  description="Please correct errors and upload again."
                  showIcon
                  banner
                />
              </Col>
            </Row>

            <Row>
              <Table
                size="small"
                columns={columns}
                dataSource={data}
                rowClassName={(record) => addBorderToErrorRows(record)}
                scroll={{ y: 500 }}
                className="table-with-errors"
              ></Table>
            </Row>
          </>
        )}
    </>
  );
};
