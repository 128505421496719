import { Button, Col, Form, Input, Row, Select } from "antd";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { getDarkStores } from "store/admin-slice/adminServicesSlice";
import { fetchMasterSkus } from "store/requisition/requisitionSlice";
import { fetchWarehouses } from "store/stock-exchange/stockExchangeSlice";
import styled from "styled-components";

const StyledFilterContainer = styled.div`
  &,
  & .filter {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    &__item {
      margin: 0.5rem;

      & .ant-form-item {
        margin: 0;
      }

      &--submit {
        align-self: flex-end;
      }
    }
  }
`;

export const AppSplitSkuExchange = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [form] = Form.useForm();

    useEffect(() => {
        const params = Object.fromEntries(new URLSearchParams(searchParams));
        form.setFieldsValue(params);
    }, [form, searchParams]);

    const onSearch = (values: Record<string, string>) => {
        const params: Record<string, string> = {};
        Object.entries(values).forEach(([key, value]) => {
            if (value) {
                params[key] = value;
            }
        });
        setSearchParams(params);
    };

    const { warehouses } = useAppSelector(
        (state) => state.stockExchangeServices
    );
    const { masterSkus = [], } = useAppSelector(
        (state) => state.requisition
    );
    const { darkStores } = useAppSelector((state) => state.adminServices);

    const { whId: srcWhId } = useAppSelector(
        (state) => state.app
    );
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchWarehouses());
        dispatch(getDarkStores());
        dispatch(fetchMasterSkus());
    }, []);

    return (
        <StyledFilterContainer>
            <Form
                form={form}
                name="vendor-filter-form"
                onFinish={onSearch}
                layout="vertical"
                style={{ width: "100%" }}
            >
                <Row className="filter">

                    <Col className="filter__item" xs={20} sm={4} md={5} lg={4} >
                        <Form.Item name="skuCode" label="Sku Code" style={{ minWidth: "8rem" }}>
                            <Select
                            placeholder="Search by SKU Code"
                                allowClear
                                showSearch
                                onClear={() => {
                                    form.submit();
                                }}
                                options={masterSkus.map(masterSku => ({
                                    value: masterSku.skuCode,
                                    label: `${masterSku.skuCode} # ${masterSku.name} `,
                                }))}
                            />
                        </Form.Item>
                    </Col>
                    <div style={{minWidth: '2rem'}}></div>

                    {warehouses.length && <Col xs={20} sm={4} md={5} lg={4} className="filter__item">
                        <Form.Item name="whId" label="Warehouse">
                            <Select
                                placeholder="Search by Warehouse"
                                allowClear
                                showSearch
                                onClear={() => {
                                    form.submit();
                                }}
                                filterOption={(input, option) => {
                                    return option?.toString().toLowerCase().includes(input?.toLowerCase() ?? "") ?? false;
                                }}
                                options={warehouses.map(storeInfo => ({
                                    value: storeInfo.id?.toString(),
                                    label: `${storeInfo.name} # ${storeInfo.id}`,
                                }))}
                             />
                        </Form.Item>
                    </Col>}
                    <div style={{minWidth: '2rem'}}></div>
                    <Col className="filter__item" xs={20} sm={4} md={5} lg={4} >
                        {darkStores && <Form.Item name="storeId" label="Store ID" >
                            <Select placeholder="Search by Store ID" size="small" allowClear showSearch onClear={() => {
                                form.submit();
                            }}>
                                {(darkStores).map((e) => (
                                    <Select.Option value={e.id} key={e.id}>
                                        {e.name} || {e.id}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>}
                    </Col>
                    <Col
                        xs={20}
                        sm={4}
                        md={2}
                        lg={2}
                        className="filter__item filter__item--submit"
                    >
                        <Form.Item>
                            <Button type="primary" htmlType="submit" size="small">
                                Search
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </StyledFilterContainer>
    );
};
