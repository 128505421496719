import { Routes, Route, Navigate } from "react-router-dom";
import { routes } from "common/routes";
import {
  SaveUserMappingNaPickingPage,
  UploadUserMappingNaPickingPage,
} from "pages/NaPicking";
import {
  AllRequisitionsPage,
  RequisitionInfoPage,
  SaveStoreRequisitionPage,
  SaveUserMappingStoreRequisitionPage,
  UploadStoreRequisitionPage,
  UploadUserMappingStoreRequisitionPage,
} from "pages/StoreRequisition";
import { StoreRejectionUploadPage } from "pages/StoreRejection/StoreRejectionPage";
import { ProtectedRoute } from "./ProtectedRoute";
import { LoginPage } from "pages/LoginPage";
import { LoggedInLayout } from "components/AppLayout/LoggedInLayout";
import { LoggedOutLayout } from "components/AppLayout/LoggedOutLayout";
import { Suspense } from "react";
import { AppLoader } from "components/AppLoader";
import { StoreRejectionPage } from "pages/StoreRejection/StoreRejectionSavePage";
import { SavePickerRequisitionPage } from "pages/PPD/SavePickerMappingPage";
import { UploadPickerMappingStoreRequisitionPage } from "pages/PPD/UploadPickerMappingPage";
import { CronRemapUsersPage } from "pages/PPD/CronRemapUsersPage";
import { AddNewUserPage } from "pages/AdminServices/AddNewUser";
import AllStoreReturnsPage from "pages/StoreReturn/AllStoreReturn";
import StoreReturnUploadPage from "pages/StoreReturn/UploadStoreReturnMapping";
import FranchiseMappingUploadPage from "pages/FranchiseServices/FranchiseMappingUploadPage";
import { FranchiseMappingSavePage } from "pages/FranchiseServices/FranchiseMappingSavePage";
import StoreReturnViewPage from "pages/StoreReturn/StoreReturnViewPage";
import StoreReturnReceivePage from "pages/StoreReturn/StoreReturnRecievePage";
import SaveStoreReturnPage from "pages/StoreReturn/SaveStoreReturnMapping";
import OrdersPage from "../pages/PPD/OrdersPage";
import PPDViewPage from "pages/PPD/PPDViewPage";
import DeliveryOrdersPage from "../pages/PPD/DeliveryOrdersPage";
import AdminUsersPage from "../pages/AdminServices/AdminUsersPage";
import { UpdateAdminUserPage } from "pages/AdminServices/UpdateAdminUser";
import DeliveryItemViewPage from "pages/PPD/DeliveryOrderViewPage";
import VendorHomePage from "pages/VendorManagement/VendorHomePage";
import POEditPage from "pages/PO/POEditPage";
import POReceivePage from "pages/PO/POReceivePage";
import POSavePage from "pages/PO/POSavePage";
import POUploadPage from "pages/PO/POUploadPage";
import GRNViewPage from "pages/PO/GRNViewPage";
import EditVendorPage from "pages/VendorManagement/EditVendorPage";
import NewVendorPage from "pages/VendorManagement/NewVendorPage";
import GrnPage from "pages/PO/GrnPage";
import FranchiseListing from "pages/FranchiseServices/FranchiseCatalogueListing";
import { SaveProjectedSrPage } from "pages/AdminServices/SaveProjectedSr";
import { UploadPProcessingSrPage } from "pages/AdminServices/UploadProjectedSr";
import { WarehouseSelectionPage } from "pages/WarehouseSelectionPage";
import { UploadStockExchangePage } from "pages/StockExchange/UploadStockExchange";
import AllSentStockExchangesPage from "pages/StockExchange/AllSentStockExchanges";
import STViewPage from "pages/StockExchange/ViewStockExchange";
import { SaveStockExchangePage } from "pages/StockExchange/SaveStockExchange";
import AllReceivedStockExchangesPage from "pages/StockExchange/AllReceivedExchanges";
import { AllRequisitionsDispatchedPage } from "pages/StoreRequisition/AllDispatchedRequisitionsPage";
import { DispatchedRequisitionInfoPage } from "pages/StoreRequisition/DispatchedRequisitionInfoPage";
import { UploadInternalStockExchangePage } from "pages/StockInternelExchange/UploadInternelStockExchange";
import { SaveInternalStockExchangePage } from "pages/StockInternelExchange/SaveInternelStockExchange";
import { UploadUserDayMappingPage } from "pages/AdminServices/UploadUserDayMapping";
import { SaveUserDayMapping } from "pages/AdminServices/SaveUserDayMapping";
import { SuggestedPickingPage } from "pages/StoreRequisition/SuggestedPickingPage";
import QualityCheckPage from "pages/QualityCheck/AllQualityCheck";
import { SaveMiddleMileMappjngPage, UploadMiddleMileMappingPage } from "pages/MiddleMileServices";
import AllMiddleMilesPage from "pages/MiddleMileServices/AllMiddleMilesPage";
import AllCashCollectionsPage from "pages/CashCollectionServices/AllCashCollections";
import PricingOverrideUploadPage from "pages/FranchiseServices/PricingOverrideUploadPage";
import { PricingOverrideSavePage } from "pages/FranchiseServices/PricingOverrideSavePage";
import { AddNewWhSkuPage } from "pages/FranchiseServices/AddNewWhSku";
import CategoryPricingUploadPage from "pages/FranchiseServices/CategoryPricingUploadPage";
import { CatagoryPricingSavePage } from "pages/FranchiseServices/CategoryPricingSavePage";
import MiddleMileTrackingPage from "pages/MiddleMileServices/MiddleMileTrackingPage";
import { SaveStoreSecondaryPickingPage } from "pages/StoreRequisition/SaveStoreSecondaryPickingPage";
import { UploadStoreSecondaryPicking } from "pages/StoreRequisition/UploadStoreSecondaryPickingPage";
import { UploadHoldAdjustmentPage } from "pages/StockExchange/UploadHoldAdjustment";
import { SaveHoldExchangePage } from "pages/StockExchange/SaveHoldAdjustment";
import CreateStoreReturnPage from "pages/StoreReturn/AllCreateRequests";
import ViewCreateStoreReturnPage from "pages/StoreReturn/ViewCreateRequest";
import AllPOPage from "pages/PO/AllPOPage";
import PORequestViewPage from "pages/PO/POViewPage";
import InventoryAdjUploadPage from "pages/FranchiseServices/InventoryAdjustment/InventoryAdjustmentUpload";
import { InventoryAdjSavePage } from "pages/FranchiseServices/InventoryAdjustment/InventoryAdjustmentSavePage";
import POBillingRateUploadPage from "pages/PO/POBillingUpload";
import POSaveBillingRatePage from "pages/PO/POSaveBillingRatePage";
import { UploadUserMappingFaPickingPage } from "pages/NaPicking/UploadFAUserMappingPage";
import { SaveUserMappingFAPickingPage } from "pages/NaPicking/SaveFAUserMappingPage";
import MergeRoutesPage from "pages/MiddleMileServices/MergeRoutesPage";
import { UploadClusterStoreMappingPage } from "pages/ClusterServices/ClusterStoreMappingUploadPage";
import { SaveClusterStoreMappingPage } from "pages/ClusterServices/ClusterStoreMappingSaveUploadPage";
import { UploadClusterAMMappingPage } from "pages/ClusterServices/ClusterAMMappingUploadPage";
import { SaveClusterAmMappingPage } from "pages/ClusterServices/ClusterAMMappingSaveUploadPage";
import { ConsumerClusterSavePage } from "pages/ConsumerServices/ConsumerClusterUpload/ConsumerClusterSavePage";
import { UploadConsumerClusterPage } from "pages/ConsumerServices/ConsumerClusterUpload/ConsumerClusterUploadPage";
import { SaveConsumerSocietyClusterPage } from "pages/ConsumerServices/ConsumerClusterSociety/ConsumerClusterSocietySavePage";
import { UploadConsumerSocietyClusterPage } from "pages/ConsumerServices/ConsumerClusterSociety/ConsumerClusterSocietyUploadPage";
import { UploadVendorWarehousePage } from "pages/AdminServices/UploadVendorWarehouseMapping";
import { SaveVendorWarehousePage } from "pages/AdminServices/SaveVendorWarehouseMapping";
import EditTablePage from "pages/PPD/PPDTableConfigPage";
import { CreateNewSocietyPage } from "pages/SocietyServices/CreateSocietyPage";
import SocietyListingPage from "pages/SocietyServices/SocietyListingPage";
import DelayRequestListingPage from "pages/DelayRequetsPage/DelayRequestsListingPage";
import UploadSocietySequencePage from "pages/AdminServices/UploadSocietySequencePage";
import { SaveSocietySequenceMapping } from "pages/AdminServices/SaveSocietySequencePage";
import AllSplitSkusPage from "pages/SplitSkuServices/SplitSkuListingPage";
import SplitSKUDetailsPage from "pages/SplitSkuServices/SplitSkuDetailPage";

export const AppRoutes = () => (
  <Suspense fallback={<AppLoader />}>
    <Routes>
      <Route element={<LoggedInLayout />}>
        <Route
          path="/"
          element={<Navigate to={`${routes.STORE_REQUISITION.ALL}`} replace />}
        >
        </Route>
        <Route
          path={`${routes.STORE_REQUISITION.SELF}/:reqId`}
          element={<ProtectedRoute component={RequisitionInfoPage} />}
        />
        <Route
          path={`${routes.STORE_REQUISITION.ALL_DISPATCHED}/:reqId`}
          element={<ProtectedRoute component={DispatchedRequisitionInfoPage} />}
        />
        <Route
          path={`${routes.STORE_REQUISITION.ALL_SPLIT_SKU}`}
          element={<ProtectedRoute component={AllSplitSkusPage} />}
        />
        <Route
          path={`${routes.STORE_REQUISITION.ALL_SPLIT_SKU}/:id`}
          element={<ProtectedRoute component={SplitSKUDetailsPage} />}
        />
        <Route
          path={`${routes.STORE_REQUISITION.CREATE_SPLIT_SKU}`}
          element={<ProtectedRoute component={SplitSKUDetailsPage} />}
        />
        <Route
          path={routes.STORE_REQUISITION.ALL}
          element={<ProtectedRoute component={AllRequisitionsPage} />}
        />
        <Route
          path={routes.STORE_REQUISITION.UPLOAD}
          element={<ProtectedRoute component={UploadStoreRequisitionPage} />}
        />
        <Route
          path={routes.STORE_REQUISITION.SAVE}
          element={<ProtectedRoute component={SaveStoreRequisitionPage} />}
        />
        <Route
          path={routes.STORE_REQUISITION.ALL_DISPATCHED}
          element={<ProtectedRoute component={AllRequisitionsDispatchedPage} />}
        />
        <Route
          path={routes.STORE_REQUISITION.USER_MAPPING_UPLOAD}
          element={
            <ProtectedRoute component={UploadUserMappingStoreRequisitionPage} />
          }
        />
        <Route
          path={routes.STORE_REQUISITION.SUGGESTED_PICKING}
          element={
            <ProtectedRoute component={SuggestedPickingPage} />
          }
        />
        <Route
          path={routes.PPD.UPLOAD}
          element={
            <ProtectedRoute component={UploadPickerMappingStoreRequisitionPage} />
          }
        />
        <Route
          path={routes.STORE_REQUISITION.USER_MAPPING_SAVE}
          element={
            <ProtectedRoute component={SaveUserMappingStoreRequisitionPage} />
          }
        />
        <Route
          path={routes.STORE_REQUISITION.SEC_PICKING_SAVE}
          element={
            <ProtectedRoute component={SaveStoreSecondaryPickingPage} />
          }
        />
        <Route
          path={routes.STORE_REQUISITION.SEC_PICKING_UPLOAD}
          element={
            <ProtectedRoute component={UploadStoreSecondaryPicking} />
          }
        />
        <Route
          path={routes.PPD.SAVE}
          element={
            <ProtectedRoute component={SavePickerRequisitionPage} />
          }
        />
        <Route
          path={routes.PO.UPLOAD_BILLING_RATE}
          element={
            <ProtectedRoute component={POBillingRateUploadPage} />
          }
        />
        <Route
          path={routes.PO.SAVE_BILLING_RATE}
          element={
            <ProtectedRoute component={POSaveBillingRatePage} />
          }
        />
        <Route
          path={routes.PPD.CRON_REMAP_USERS}
          element={
            <ProtectedRoute component={CronRemapUsersPage} />
          }
        />
        <Route
          path={routes.PPD.ORDERS}
          element={
            <ProtectedRoute component={OrdersPage} />
          }
        />
        <Route
          path={routes.PPD.VIEW}
          element={
            <ProtectedRoute component={PPDViewPage} />
          }
        />
        <Route
          path={routes.PPD.DELIVERY_ORDERS}
          element={
            <ProtectedRoute component={DeliveryOrdersPage} />
          }
        />
        <Route
          path={routes.PPD.VIEW_DELIVERY_ORDERS}
          element={
            <ProtectedRoute component={DeliveryItemViewPage} />
          }
        />
        <Route
          path={routes.SR_USER_MAPPING.NA_USER_MAPPING_UPLOAD}
          element={
            <ProtectedRoute component={UploadUserMappingNaPickingPage} />
          }
        />
        <Route
          path={routes.SR_USER_MAPPING.NA_USER_MAPPING_SAVE}
          element={<ProtectedRoute component={SaveUserMappingNaPickingPage} />}
        />
        <Route
          path={routes.SR_USER_MAPPING.FA_USER_MAPPING_UPLOAD}
          element={
            <ProtectedRoute component={UploadUserMappingFaPickingPage} />
          }
        />
        <Route
          path={routes.SR_USER_MAPPING.FA_USER_MAPPING_SAVE}
          element={<ProtectedRoute component={SaveUserMappingFAPickingPage} />}
        />
        <Route
          path={routes.MIDDLE_MILE.MIDDLE_MILE_UPLOAD}
          element={
            <ProtectedRoute component={UploadMiddleMileMappingPage} />
          }
        />
        <Route
          path={routes.MIDDLE_MILE.MIDDLE_MILE_SAVE}
          element={<ProtectedRoute component={SaveMiddleMileMappjngPage} />}
        />
        <Route
          path={routes.MIDDLE_MILE.ALL_MIDDLE_MILE}
          element={<ProtectedRoute component={AllMiddleMilesPage} />}
        />
        <Route
          path={routes.MIDDLE_MILE.TRACKING_INFO}
          element={<ProtectedRoute component={MiddleMileTrackingPage} />}
        />        
        <Route
          path={routes.MIDDLE_MILE.MERGE_ROUTES}
          element={<ProtectedRoute component={MergeRoutesPage} />}
        />
        <Route
          path={routes.STORE_REJECTION.INVENTORY_REJECTION_UPLOAD}
          element={<ProtectedRoute component={StoreRejectionUploadPage} />}
        />
        <Route
          path={routes.STORE_REJECTION.INVENTORY_REJECTION_SAVE}
          element={<ProtectedRoute component={StoreRejectionPage} />}
        />

        <Route
          path={routes.STORE_RETURN.ALL}
          element={<ProtectedRoute component={AllStoreReturnsPage} />}
        />

        <Route
          path={routes.STORE_RETURN.STORE_RETURN}
          element={<ProtectedRoute component={StoreReturnViewPage} />}
        />

        <Route
          path={routes.STORE_RETURN.RECEIVE}
          element={<ProtectedRoute component={StoreReturnReceivePage} />}
        />

        <Route
          path={routes.STORE_RETURN.UPLOAD}
          element={<ProtectedRoute component={StoreReturnUploadPage} />}
        />

        <Route
          path={routes.STORE_RETURN.SAVE}
          element={<ProtectedRoute component={SaveStoreReturnPage} />}
        />

        <Route
          path={routes.STORE_RETURN.CREATE_SR_REQUESTS}
          element={<ProtectedRoute component={CreateStoreReturnPage} />}
        />
        
        <Route
          path={routes.STORE_RETURN.CREATE_SR_REQUESTS_DETAILS}
          element={<ProtectedRoute component={ViewCreateStoreReturnPage} />}
        />

        <Route
          path={routes.ADMIN_SERVICES.ADD_NEW_USER}
          element={<ProtectedRoute component={AddNewUserPage} />}
        />
      
        <Route
          path={routes.ADMIN_SERVICES.CREATE_SOCIETY}
          element={<ProtectedRoute component={CreateNewSocietyPage} />}
        />
        
        <Route
          path={routes.ADMIN_SERVICES.SOCIETY}
          element={<ProtectedRoute component={SocietyListingPage} />}
        />
        
        <Route
          path={routes.ADMIN_SERVICES.UPDATE_SOCIETY}
          element={<ProtectedRoute component={CreateNewSocietyPage} />}
        />
        
        <Route
          path={routes.ADMIN_SERVICES.UPLOAD_SOCIETY_SEQUENCE}
          element={<ProtectedRoute component={UploadSocietySequencePage} />}
        />
        
        <Route
          path={routes.ADMIN_SERVICES.SAVE_SOCIETY_SEQUENCE}
          element={<ProtectedRoute component={SaveSocietySequenceMapping} />}
        />
        
        <Route
          path={routes.CLUSTER_SOCIETY.GET_DELAY_REQUEST}
          element={<ProtectedRoute component={DelayRequestListingPage} />}
        />

        <Route
          path={routes.ADMIN_SERVICES.ADMIN_USERS}
          element={<ProtectedRoute component={AdminUsersPage} />}
        />

        <Route
          path={routes.ADMIN_SERVICES.UPDATE_ADMIN}
          element={<ProtectedRoute component={UpdateAdminUserPage} />}
        />
        <Route
          path={routes.PROCUREMENT.UPLOAD_PROJECTED_SR}
          element={<ProtectedRoute component={UploadPProcessingSrPage} />}
        />
        <Route
          path={routes.PROCUREMENT.SAVE_PROJECTED_SR}
          element={<ProtectedRoute component={SaveProjectedSrPage} />}
        />
        <Route
          path={routes.PROCUREMENT.UPLOAD_VENDOR_WAREHOUSE_MAPPING}
          element={<ProtectedRoute component={UploadVendorWarehousePage} />}
        />
        <Route
          path={routes.PROCUREMENT.SAVE_VENDOR_WAREHOUSE_MAPPING}
          element={<ProtectedRoute component={SaveVendorWarehousePage} />}
        />

        <Route
          path={routes.FRANCHISE.UPLOAD_PRICING_OVERRIDE}
          element={<ProtectedRoute component={PricingOverrideUploadPage} />}
        />
        <Route
          path={routes.FRANCHISE.SAVE_PRICING_OVERRIDE}
          element={<ProtectedRoute component={PricingOverrideSavePage} />}
        />
        <Route
          path={routes.FRANCHISE.UPLOAD_CATEGORY_PRICING}
          element={<ProtectedRoute component={CategoryPricingUploadPage} />}
        />  
        <Route
          path={routes.FRANCHISE.SAVE_CATEGORY_PRICING}
          element={<ProtectedRoute component={CatagoryPricingSavePage} />}
        />
        <Route
          path={routes.FRANCHISE.UPLOAD_INVENTORY_ADJUSTMENT}
          element={<ProtectedRoute component={InventoryAdjUploadPage} />}
        />  
        <Route
          path={routes.FRANCHISE.SAVE_INVENTORY_ADJUSTMENT}
          element={<ProtectedRoute component={InventoryAdjSavePage} />}
        />
        <Route
          path={routes.FRANCHISE.UPLOAD}
          element={<ProtectedRoute component={FranchiseMappingUploadPage} />}
        />
        <Route
          path={routes.FRANCHISE.SAVE}
          element={<ProtectedRoute component={FranchiseMappingSavePage} />}
        />
        <Route
          path={routes.FRANCHISE.ALL}
          element={<ProtectedRoute component={FranchiseListing} />}
        />
        <Route
          path={routes.INTERNAL_STOCK_EXCHANGE.UPLOAD}
          element={<ProtectedRoute component={UploadInternalStockExchangePage} />}
        />
        <Route
          path={routes.INTERNAL_STOCK_EXCHANGE.SAVE}
          element={<ProtectedRoute component={SaveInternalStockExchangePage} />}
        />
        <Route
          path={routes.STOCK_EXCHANGE.UPLOAD}
          element={<ProtectedRoute component={UploadStockExchangePage} />}
        />
        <Route
          path={routes.STOCK_EXCHANGE.SAVE}
          element={<ProtectedRoute component={SaveStockExchangePage} />}
        />
        <Route
          path={routes.STOCK_EXCHANGE.UPLOAD_HOLD}
          element={<ProtectedRoute component={UploadHoldAdjustmentPage} />}
        />
        <Route
          path={routes.STOCK_EXCHANGE.SAVE_HOLD}
          element={<ProtectedRoute component={SaveHoldExchangePage} />}
        />

        <Route
          path={routes.USER_SERVICES.UPLOAD_USER_DAY_MAPPING}
          element={<ProtectedRoute component={UploadUserDayMappingPage} />}
        />
        <Route
          path={routes.USER_SERVICES.SAVE_USER_DAY_MAPPING}
          element={<ProtectedRoute component={SaveUserDayMapping} />}
        />
        <Route
          path={routes.STOCK_EXCHANGE.SENT}
          element={<ProtectedRoute component={AllSentStockExchangesPage} />}
        />
        <Route
          path={routes.STOCK_EXCHANGE.RECEIVED}
          element={<ProtectedRoute component={AllReceivedStockExchangesPage} />}
        />
        <Route
          path={routes.STOCK_EXCHANGE.VIEW}
          element={<ProtectedRoute component={STViewPage} />}
        />
        <Route
          path={routes.STOCK_EXCHANGE.VIEW_RECEIVED}
          element={<ProtectedRoute component={STViewPage} />}
        />
        <Route
          path={routes.QUALITY_CHECK.ALL}
          element={<ProtectedRoute component={QualityCheckPage} />}
        />
        <Route
          path={routes.CASH_COLLECTION.ALL}
          element={<ProtectedRoute component={AllCashCollectionsPage} />}
        />
        {/**
         * Lazy Loaded Routes
         */}
        <Route
          path={routes.VENDOR.ALL}
          element={<ProtectedRoute component={VendorHomePage} />}
        />
        <Route
          path={routes.VENDOR.NEW}
          element={<ProtectedRoute component={NewVendorPage} />}
        />
        <Route
          path={routes.VENDOR.EDIT}
          element={<ProtectedRoute component={EditVendorPage} />}
        />
        <Route
          path={routes.PO.UPLOAD}
          element={<ProtectedRoute component={POUploadPage} />}
        />
        <Route
          path={routes.PO.GRN}
          element={<ProtectedRoute component={GrnPage} />}
        />
        <Route
          path={routes.PO.PO_REQUESTS}
          element={<ProtectedRoute component={AllPOPage} />}
        />
        <Route
          path={routes.PO.VIEW_PO_REQUEST}
          element={<ProtectedRoute component={PORequestViewPage} />}
        />
        <Route
          path={routes.PO.RECEIVED_PO_REQUEST}
          element={<ProtectedRoute component={PORequestViewPage} />}
        />
        <Route
          path={routes.PO.SAVE}
          element={<ProtectedRoute component={POSavePage} />}
        />
        <Route
          path={routes.PO.PO}
          element={<ProtectedRoute component={GRNViewPage} />}
        />
        <Route
          path={routes.PO.EDIT}
          element={<ProtectedRoute component={POEditPage} />}
        />
        <Route
          path={routes.PO.RECEIVE}
          element={<ProtectedRoute component={POReceivePage} />}
        />
        <Route
          path={routes.PO.SORTING_RTV}
          element={<ProtectedRoute component={POReceivePage} />}
        />
        <Route
          path={routes.FRANCHISE.ADD_NEW_WH_SKU}
          element={<ProtectedRoute component={AddNewWhSkuPage} />}
        />
        <Route
          path={routes.CLUSTER_SERVICE.UPLOAD_CLUSTER_STORE_MAPPING}
          element={
            <ProtectedRoute component={UploadClusterStoreMappingPage} />
          }
        />
        <Route
          path={routes.CLUSTER_SERVICE.SAVE_CLUSTER_STORE_MAPPING}
          element={<ProtectedRoute component={SaveClusterStoreMappingPage} />}
        />
        <Route
          path={routes.CLUSTER_SERVICE.UPLOAD_CLUSTER_AM_MAPPING}
          element={
            <ProtectedRoute component={UploadClusterAMMappingPage} />
          }
        />
        <Route
          path={routes.CLUSTER_SERVICE.SAVE_CLUSTER_AM_MAPPING}
          element={<ProtectedRoute component={SaveClusterAmMappingPage} />}
        />
        <Route
          path={routes.CONSUMER_SERVICE.UPLOAD_CLUSTER_MAPPING}
          element={
            <ProtectedRoute component={UploadConsumerClusterPage} />
          }
        />
        <Route
          path={routes.CONSUMER_SERVICE.SAVE_CLUSTER_MAPPING}
          element={<ProtectedRoute component={ConsumerClusterSavePage} />}
        />
        <Route
          path={routes.CONSUMER_SERVICE.UPLOAD_CLUSTER_SOCIETY_MAPPING}
          element={
            <ProtectedRoute component={UploadConsumerSocietyClusterPage} />
          }
        />
        <Route
          path={routes.CONSUMER_SERVICE.SAVE_CLUSTER_SOCIETY_MAPPING}
          element={<ProtectedRoute component={SaveConsumerSocietyClusterPage} />}
        />
        
        <Route
          path={routes.PPD.VIEW_TABLE}
          element={<ProtectedRoute component={EditTablePage} />}
        />
      </Route>
      {/**
       * Lazy Loaded Routes END
       */}

      <Route element={<LoggedOutLayout />}>
        <Route path={routes.LOGIN} element={<LoginPage />} />
        <Route path={routes.WAREHOUSE_SELECTION} element={<WarehouseSelectionPage />} />
      </Route>
    </Routes>
  </Suspense>
);
