import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApiServices, { apis as apiEndPoints } from "common/apis";
import { WhInfo } from "common/interface";
import { IApiError } from "common/interface/IApiError";
import { IPickerMappingUpload } from "common/interface/IPickerUploadScheme";
import { IStockerTranferApi, IStockTransfer, StockTransferItem } from "common/interface/IStockTransfer";
import { logoutIfNeeded } from "common/utils";
import { getProcessingInternalStoreExchange, persistProcessingInternalStoreExchange, removeProcessingInternalStoreExchange, } from "services/PersistService/PersistStockExchangeService";
import { RootState } from "store/store";

export interface State {
    status: "loading" | "success" | "error" | "idle" | "cancelling" | "saving";
    error?: string;
    inventoryTypes: string[];
    warehouses: WhInfo[];
    processingStockExchange: IPickerMappingUpload;
    stockTransferData?: IStockerTranferApi;
}

const initialState: State = {
    status: "idle",
    inventoryTypes: [],
    warehouses: [],
    processingStockExchange: getProcessingInternalStoreExchange(),
};

export const fetchInventoryTypes = createAsyncThunk<string[]>("internal-stock-exchange/inventory-types", async (_, thunkApi) => {
    const response = await ApiServices.get(apiEndPoints.inventoryTypes)
        .then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
});

export const fetchWarehouses = createAsyncThunk<WhInfo[]>("internal-stock-exchange/warehouses", async (_, thunkApi) => {
    const response = await ApiServices.get(apiEndPoints.warehouses)
        .then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
});

export const cancelStockTranfer = createAsyncThunk("internal-stock-exchange/upload/cancel", async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const { processingStockExchange } = state.internelStockExchangeServices;

    const response = await ApiServices.post(apiEndPoints.saveInternelStockTransfer, {}, { params: { key: processingStockExchange!.key, cancel: 1 } })
        .then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
});

export const saveStockTranfer = createAsyncThunk("internal-stock-exchange/upload/save", async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const { processingStockExchange } = state.internelStockExchangeServices;

    const response = await ApiServices.post(apiEndPoints.saveInternelStockTransfer, {}, { params: { key: processingStockExchange!.key, } })
        .then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
});

const internalStockExchangeSlice = createSlice({
    name: "internalStockExchange",
    initialState,
    reducers: {
        setProcessingST: (state, action) => {
            state.status = "idle";
            state.error = "";
            state.processingStockExchange = action.payload;
            state.processingStockExchange.timeUploaded = new Date().toString();
            persistProcessingInternalStoreExchange(state.processingStockExchange);
        },
        removeProcessingST: (state) => {
            state.processingStockExchange = {};
            state.status = "idle";
            state.error = "";
            removeProcessingInternalStoreExchange();
        },
    },
    extraReducers(builder) {
        builder
            /**
            * Save inventory Types
            **/
            .addCase(fetchInventoryTypes.pending, state => {
                state.status = "loading";
            })
            .addCase(fetchInventoryTypes.fulfilled, (state, action) => {
                state.status = "idle";
                state.error = "";
                state.inventoryTypes = action.payload;
            })
            .addCase(fetchInventoryTypes.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";
                if (error.message) {
                    state.error = error.message;
                }
            })
            /**
            * Save warehouses
            **/
            .addCase(fetchWarehouses.pending, state => {
                state.status = "loading";
            })
            .addCase(fetchWarehouses.fulfilled, (state, action) => {
                state.status = "idle";
                state.error = "";
                state.warehouses = action.payload;
            })
            .addCase(fetchWarehouses.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";
                if (error.message) {
                    state.error = error.message;
                }
            })
            /**
            * Cancel Upload
            **/
            .addCase(cancelStockTranfer.pending, state => {
                state.status = "loading";
            })
            .addCase(cancelStockTranfer.fulfilled, state => {
                state.status = "idle";
                state.error = "";
                state.processingStockExchange = {};
                removeProcessingInternalStoreExchange();
            })
            .addCase(cancelStockTranfer.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";
                if (error.message) {
                    state.error = error.message;
                }
            })
            /**
            * Save Upload
            **/
            .addCase(saveStockTranfer.pending, state => {
                state.status = "loading";
            })
            .addCase(saveStockTranfer.fulfilled, state => {
                state.status = "success";
                state.error = "";
                state.processingStockExchange = {};
                removeProcessingInternalStoreExchange();
            })
            .addCase(saveStockTranfer.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";
                if (error.message) {
                    state.error = error.message;
                }
            })
    }
});

export const {
    setProcessingST,
    removeProcessingST,
} = internalStockExchangeSlice.actions;
export default internalStockExchangeSlice.reducer;