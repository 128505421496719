import {
    Breadcrumb,
    Button,
    Col,
    DatePicker,
    Descriptions,
    Form,
    Input,
    message,
    Row,
    Select,
    Table,
    Tag,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import { IStoreReturnApi, StoreInventoryUpdateDataItem, StoreReturnDetails } from "common/interface/IStoreReturn";
import { getDateFromISODate } from "common/utils";
import { variables } from "common/variables";
import { AppPageHeader } from "components/AppPageHeader";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useDocumentTitle } from "hooks/usePageTitle";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";

const MESSAGE_KEY = "po_receieve";
type PartialISrItemType = Partial<StoreInventoryUpdateDataItem> & { key: string };

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    title: any;
    inputType: "select" | "text";
    record: PartialISrItemType;
    index: number;
    children: React.ReactNode;
}

const headerKeys: { [key: string]: string } = {
    skuId: "SKU ID/Name",
    quantity: "Quantity",
    actions: "Actions",
};

const { Option } = Select;

type Page = "received" | "receiving";

const StoreReturnReceivePage = () => {
    const dispatch = useAppDispatch();
    const { allStoreReturns, allStoreReturnDetails, error, status } = useAppSelector((state) => state.storeReturn);
    const { masterSkus = [], assets = [] } = useAppSelector(
      (state) => state.requisition
    );
    const { srId } = useParams();
    const [viewedPO, setViewedPO] = useState<StoreReturnDetails>();
    const [tableColumns, setTableColumns] =
      useState<ColumnsType<PartialISrItemType>>();
    const [tableRows, setTableRows] = useState<any[]>();
    const [tempTableRows, setTempTableRows] = useState<any[]>();
    const [form] = Form.useForm();
    const [assetForm] = Form.useForm();
    const [editingKey, setEditingKey] = useState("");
    const [isAddingItem, setIsAddingItem] = useState(false);
    const [isRowExpanded, setIsRowExpanded] = useState(false);
    const [expandedRowId, setExpandedRowId] = useState();
    const [localPos, setLocalPos] = useState<IStoreReturnApi>();
    const [hasUnsavedItems, setHasUnsavedItems] = useState<number | undefined>(
      undefined
    );
    const [page, setPage] = useState<Page>("receiving");
    const navigate = useNavigate();
    const location = useLocation();

    // useEffect(() => {
    //   const path = location.pathname.split("/").pop();
    //   if (path === "sorting-rtv") {
    //     setPage("sorting-rtv");
    //   } else {
    //     setPage("receiving");
    //   }
    // }, [location]);

    useEffect(() => {
        if (editingKey) {
          const selectedRowData = viewedPO?.storeReturnItemDataList.find(e =>( e.sku_code.toString() + e.quantity) === editingKey.toString());
          form.setFieldsValue({
            skuId: masterSkus.find(k => k.skuCode === selectedRowData?.sku_code)?.id.toString(),
          });
        }
      }, [editingKey, form]);
    
      const isEditing = useCallback(
        (record: PartialISrItemType) => record.key === editingKey,
        [editingKey]
      );

    useEffect(() => {
      if (hasUnsavedItems) {
        message.warning({
          content: (
            <div style={{ width: "100%" }}>
              <p>
                There are unsaved items, please click <b>Done</b>.
              </p>
            </div>
          ),
          key: MESSAGE_KEY,
          duration: 0,
        });
      } else {
        message.destroy();
      }
    }, [hasUnsavedItems]);

    const edit = useCallback(
      (record: PartialISrItemType) => {
        form.setFieldsValue({ ...record });
        setEditingKey(record.key);
      },
      [form]
    );

    const saveItem = useCallback(
        (poId: string, poItem: StoreInventoryUpdateDataItem) => {
        //   dispatch(savePoItem({ poId, poItem }));
        },
        [dispatch]
      );
    
    const save = useCallback(
        async (poId: string, poItemId: string) => {
          try {
            const row = await form.validateFields();
            const index = masterSkus.findIndex(
              (item: any) => row.skuId === item.id.toString()
            );
    
            if (viewedPO) {
              if (index > -1) {
                saveItem(poId, {
                  ...row,
                  skuCode: masterSkus[index].skuCode,
                  skuName: masterSkus[index].name,
                  id: poItemId,
                });
                setEditingKey("");
              } else {
                saveItem(poId, row);
                setEditingKey("");
              }
            }
    
            setTempTableRows([]);
            setIsAddingItem(false);
            form.resetFields();
          } catch (errInfo) {
            console.log("Validate Failed:", errInfo);
          }
        },
        [form, masterSkus, saveItem, viewedPO]
    );

    const poInfo = viewedPO && (
        <>
          <Descriptions
            size="small"
            layout="vertical"
            labelStyle={{ fontWeight: "bold" }}
            bordered
            column={{ xs: 2, md: 4 }}
          >
            <Descriptions.Item label="Challan ID">
              {viewedPO.id}
            </Descriptions.Item>
            <Descriptions.Item label="Status">{viewedPO.status}</Descriptions.Item>
            <Descriptions.Item label="Created Date">
              {getDateFromISODate(viewedPO.createdOn)}
            </Descriptions.Item>
          </Descriptions>
          {!!(tableRows && tableRows.length) && (
            <Row style={{ marginTop: "20px" }}>
              <Col xs={24}>
                <Form form={form} component={false}>
                  {/* <Row>
                    <Form.Item
                      name="receivedDate"
                      rules={[
                        {
                          required: true,
                          message: "Please select Date and Time",
                        },
                      ]}
                      label="Received Date"
                      initialValue={
                        viewedPO.receivedDate && moment(viewedPO.receivedDate)
                      }
                    >
                      <DatePicker
                        showTime
                        onChange={(value) => saveDateInLocal(value)}
                        disabled={page === "sorting-rtv"}
                      />
                    </Form.Item>
                  </Row> */}
                  <Table
                    className="po-item-table"
                    components={{
                    //   body: {
                    //     cell: EditableCell,
                    //   },
                    }}
                    bordered
                    dataSource={tableRows}
                    columns={tableColumns}
                    rowClassName={(record, index) =>
                      index === tableRows.length - 1
                        ? "editable-last-row editable-row"
                        : "editable-row"
                    }
                    pagination={false}
                    scroll={{ x: "90%", y: "65vh" }}
                    style={{ width: "100%" }}
                    size="small"
                    expandable={{
                      onExpand: (isExpanded, record) => {
                        setIsRowExpanded(isExpanded);
                        if (isExpanded) {
                          setExpandedRowId(record.id);
                          edit(record);
                        } else {
                          setExpandedRowId(undefined);
                          setEditingKey("");
                          setIsRowExpanded(false);
                          setTempTableRows([]);
                          setIsAddingItem(false);
    
                          form.resetFields([
                            "requestedQty",
                            "dockRtv",
                            "grossReceivedQty",
                            "netReceivedQty",
                          ]);
                          assetForm.resetFields();
                        }
                      },
                      expandedRowKeys: [expandedRowId ? expandedRowId: ""],
                      rowExpandable: (record) =>
                        record.id === expandedRowId ||
                        (!expandedRowId && record.id === hasUnsavedItems) ||
                        (!expandedRowId && !hasUnsavedItems),
                    }}
                  />
                </Form>
              </Col>
            </Row>
          )}
        </>
      );
    

    return (
        <>
            <Row
          style={{
            margin: "1rem 0",
            position: "sticky",
            top: 0,
            zIndex: 1,
            background: variables.wmBackgroundColorLight1,
            padding: "5px",
          }}
        >
          <Col xs={24}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <NavLink to="/store-return/all">All Store Returns</NavLink>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <NavLink to={`/store-return/${viewedPO?.id}`}>
                  {viewedPO?.id ?? "Store Return"}
                </NavLink>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                {"Receive"}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row>
          <Col>
            <AppPageHeader
              title={`${
               "Receive"} Store Return`}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={24}>{poInfo}</Col>
        </Row>
        </>
    );
};

export default StoreReturnReceivePage;
