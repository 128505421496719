import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API, { apis } from "common/apis";
import { INaPickingUserMappingUpload } from "common/interface"
import { IApiError } from "common/interface/IApiError";
import { IMiddleMile, IMiddleMileApi } from "common/interface/IMiddleMile";
import { ITrackingInfo, ITrackingInfoApi } from "common/interface/ITrackingInfo";
import { logoutIfNeeded } from "common/utils";
import { persistMiddleMileMapping, removePersistedMiddleMileMapping } from "services/PersistService/PersistMileMappingService";
import { getPersistedNaPickingUserMapping } from "services/PersistService/PersistNaPickingService";
import { RootState } from "store/store";
import { ClusterInfo } from "./interface/cluster-info";
import { ClusterRoute } from "./interface/cluster-route";

export interface State {
  middleMiles: IMiddleMile[],
  trackingInfo?: ITrackingInfoApi,
  clustersList?: ClusterInfo[],
  currentClusterRoutes?: ClusterRoute[],
  processingMiddleMileMapping: INaPickingUserMappingUpload,
  status: "loading" | "success" | "error" | "idle" | "cancelling",
  error?: string
}

const initialState: State = {
  processingMiddleMileMapping: getPersistedNaPickingUserMapping(),
  middleMiles: [],
  status: "idle"
};

export const saveMiddleMileMapping = createAsyncThunk<INaPickingUserMappingUpload>("middle-mile/user-mapping/save", async (_, thunkApi) => {
  const state = thunkApi.getState() as RootState;
  const { middleMile: { processingMiddleMileMapping: { key } } } = state;

  const response = await API.post(apis.saveMiddleMileMapping, {}, {
    params: { key }
  }).then(success => success.data)
    .catch(error => {
      const errorMessage = error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

export const cancelMiddleMileMapping = createAsyncThunk("middle-mile/user-mapping/cancel", async (_, thunkApi) => {
  const state = thunkApi.getState() as RootState;
  const { middleMile: { processingMiddleMileMapping: { key } } } = state;

  const response = await API.post(apis.saveMiddleMileMapping, {}, {
    params: { key, cancel: 1 }
  })
    .then(success => success.data)
    .catch(error => {
      const errorMessage = error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

export const saveMiddleMileRemark = createAsyncThunk<ITrackingInfo, { remark: string, remarksCreatedAt?: string, mmId: string }>("middle-mile/remark/save", async ({ remark, remarksCreatedAt, mmId }, thunkApi) => {

  const response = await API.put(apis.saveMiddleMileRemark + `/${mmId}/remarks`, {
    remarks: remark,
    remarksCreatedAt: remarksCreatedAt,
  },).then(success => success.data)
    .catch(error => {
      const errorMessage = error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage, id: mmId });
    });

  return response;
});


export const fetchTrackingInfo = createAsyncThunk<
  ITrackingInfoApi,
  { pageNo: number; pageSize: number; filter: string }
>("/middle-miles/tracking/all", async ({ pageNo = 1, pageSize = 25, filter }, thunkApi) => {
  const filterParams = filter
    ? Object.fromEntries(new URLSearchParams(filter))
    : "";
  const response = await API.get(apis.getMiddleMilesTrackingInfo, {
    params: {
      pageNo,
      pageSize,
      ...filterParams,
    },
  })
    .then((success) => success.data)
    .catch((error) => {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

export const getMiddleMileListing = createAsyncThunk<
  IMiddleMile[],
  { deliveryDate?: string, slot?: string, }
>("/middle-miles/all", async ({ deliveryDate, slot, }, thunkApi) => {

  const response = await API.get(apis.getMiddleMilesListing, {
    params: {
      deliveryDate,
      slot
    },
  })
    .then((success) => success.data)
    .catch((error) => {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

export const getClustersListing = createAsyncThunk<ClusterInfo[]>("/middle-miles/clusters", async (_, thunkApi) => {
  const response = await API.get(apis.getClustersList)
    .then((success) => success.data)
    .catch((error) => {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

export const getClusterRoutes = createAsyncThunk<ClusterRoute[], { clusterId?: string }>("/middle-miles/cluster/routes", async ({ clusterId }, thunkApi) => {
  const response = await API.get(apis.getClusterRoutes + `${clusterId}/routes`)
    .then((success) => success.data)
    .catch((error) => {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

export const mergeClusterRoutes = createAsyncThunk<ClusterRoute[], { clusterIds?: string[] }>("/middle-miles/cluster/routes/merge", async ({ clusterIds }, thunkApi) => {
  const response = await API.post(apis.mergeClusterRoutes, {"mmIds": clusterIds },)
    .then((success) => success.data)
    .catch((error) => {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});



const middleMileSlice = createSlice({
  name: "middle-mile",
  initialState,
  reducers: {
    setProcessingMiddleMileMapping: (state, action) => {
      state.status = "idle";
      state.error = "";
      state.processingMiddleMileMapping = action.payload;
      state.processingMiddleMileMapping.timeUploaded = new Date().toString();
      persistMiddleMileMapping(state.processingMiddleMileMapping);
    },
    removeProcessingMiddleMileMapping: (state) => {
      state.processingMiddleMileMapping = {};
      state.status = "idle";
      state.error = "";
      removePersistedMiddleMileMapping();
    },
    updateMiddleMileItem: (state, action) => {
      let idx = state.trackingInfo!.data.findIndex((e) => e.id == action.payload.id);
      let item = { ...state.trackingInfo!.data[idx] };
      item.metadata.remarks = action.payload.nv;
      state.trackingInfo?.data.splice(idx, 1, item);
      state.trackingInfo = { ...state.trackingInfo! };
    },
    updateMiddleMileItemDateTime: (state, action) => {
      let idx = state.trackingInfo!.data.findIndex((e) => e.id == action.payload.id);
      let item = { ...state.trackingInfo!.data[idx] };
      item.metadata.remarksCreatedAt = action.payload.nv;
      state.trackingInfo?.data.splice(idx, 1, item);
      state.trackingInfo = { ...state.trackingInfo! };
    },
    clearCurrentRoutes: (state) => {
      state.currentClusterRoutes = [];
    },
  },
  extraReducers(builder) {
    builder
      /**
       * SAVE MIDDLE MILE MAPPING
       */
      .addCase(saveMiddleMileMapping.pending, state => {
        state.status = "loading";
      })
      .addCase(saveMiddleMileMapping.fulfilled, state => {
        state.status = "success";
        state.error = "";
        state.processingMiddleMileMapping = {};
        removePersistedMiddleMileMapping();
      })
      .addCase(saveMiddleMileMapping.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })

      /**
       * CANCEL MIDDLE MILE MAPPING
       */
      .addCase(cancelMiddleMileMapping.pending, state => {
        state.status = "cancelling";
      })
      .addCase(cancelMiddleMileMapping.fulfilled, state => {
        state.status = "idle";
        state.error = "";
        state.processingMiddleMileMapping = {};
        removePersistedMiddleMileMapping();
      })
      .addCase(cancelMiddleMileMapping.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })
      /**
       * SAVE MIDDLE MILE REMARKS
       */
      .addCase(saveMiddleMileRemark.pending, state => {
        state.status = "cancelling";
      })
      .addCase(saveMiddleMileRemark.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = "";
        const idx = state.trackingInfo?.data.findIndex((item) => item.id == action.payload.id);
        state.trackingInfo!.data.splice(idx!, 1, action.payload);
        state.trackingInfo = { ...state.trackingInfo! };
      })
      .addCase(saveMiddleMileRemark.rejected, (state, action) => {
        const error = action.payload as { message: string, id: string };
        state.trackingInfo = { ...state.trackingInfo! };
        state.status = "error";
        if (error.message) {
          state.error = error.message;
        }
      })
      /**
      * GET ALL MIDDLE MILES
       **/
      .addCase(getMiddleMileListing.pending, state => {
        state.status = "loading";
      })
      .addCase(getMiddleMileListing.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = "";
        state.middleMiles = action.payload;
      })
      .addCase(getMiddleMileListing.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })

      /**
      * GET ALL CLUSTER LISTING
       **/
      .addCase(getClustersListing.pending, state => {
        state.status = "loading";
      })
      .addCase(getClustersListing.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = "";
        state.clustersList = action.payload;
      })
      .addCase(getClustersListing.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })

      /**
      * GET CLUSTER ROUTES
       **/
      .addCase(getClusterRoutes.pending, state => {
        state.status = "loading";
      })
      .addCase(getClusterRoutes.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = "";
        state.currentClusterRoutes = action.payload;
      })
      .addCase(getClusterRoutes.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })
      
      /**
      * MERGE CLUSTER ROUTES
       **/
      .addCase(mergeClusterRoutes.pending, state => {
        state.status = "loading";
      })
      .addCase(mergeClusterRoutes.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = "";
        state.currentClusterRoutes = action.payload;
      })
      .addCase(mergeClusterRoutes.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })

      /**
      * GET ALL MIDDLE MILES TRACKING DATA
       **/
      .addCase(fetchTrackingInfo.pending, state => {
        state.status = "loading";
      })
      .addCase(fetchTrackingInfo.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = "";
        state.trackingInfo = action.payload;
      })
      .addCase(fetchTrackingInfo.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";
        if (error.message) {
          state.error = error.message;
        }
      })


  }
});

export const {
  setProcessingMiddleMileMapping, 
  removeProcessingMiddleMileMapping, 
  updateMiddleMileItem, 
  updateMiddleMileItemDateTime, 
  clearCurrentRoutes
} = middleMileSlice.actions;
export default middleMileSlice.reducer;