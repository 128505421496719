import { ColumnsType } from "antd/lib/table";
import { AxiosError } from "axios";
import { logout } from "store/app/appSlice";

//"extStoreId:Store Id,skuCode:Sku,numOfCrates:Num of crates,date:Date,slot:Slot"
export const generateHeaders = (mappings?: string) => {
  const headers = mappings?.split(",").reduce((acc, pair) => {
    const [key, label] = pair.split(":");
    acc = { ...acc, [key]: label };
    return acc;
  }, {});

  return headers as { [label: string]: string };
};

export const logoutIfNeeded = (error: AxiosError, thunkAPI: any) => {
  if (error?.response?.status === 401) {
    thunkAPI.dispatch(logout());
  }
}

export const getSlotName = (
  slots: { [lable: string]: string },
  slot: string
): string => {
  if (slots) {
    const slotIndx = Object.values(slots).findIndex((sl) => sl === slot);
    if (slotIndx > -1) {
      return Object.keys(slots)[slotIndx];
    }
  }

  return "";
};

export const mobileFormValidator = (mobile: string): Promise<string> => {
  const isValid = /^\d{10}$/.test(mobile);

  if (mobile && !isValid) {
    return Promise.reject("Please enter valid mobile number");
  }
  return Promise.resolve(mobile);
}

export const getDateFromISODate = (date: string): string => {
  if (date === "" || date === undefined || date === null) {
    return "";
  }
  return date.split("T")[0];
}

export const getDateAndTimeFromDateFormatted = (inputString: string): string => {
  if (inputString === "" || inputString === undefined || inputString === null) {
    return "";
  }
  const [rawDate, rawTime] = inputString.split(" ");
  const [year, month, date] = rawDate.split("-");

  return `${date} ${monthNames[parseInt(month)]} ${year} | ${tConvert(rawTime)}`;
}

export const getDateFromDateFormatted = (inputString: string): string => {
  if (inputString === "" || inputString === undefined || inputString === null) {
    return "";
  }
  const [rawDate] = inputString.split(" ");
  const [year, month, date] = rawDate.split("-");
  
  return `${date} ${monthNames[parseInt(month) - 1]} ${year}`;
}

export const getDateAndTimeFromISODate = (date: string): string => {
  if (date === "" || !date) {
    return "";
  }
  const k = new Date(date);
  return `${getDateFromDateFormatted(date.split("T")[0])} | ${k.toLocaleString('default', { hour: 'numeric', minute: 'numeric', hour12: true })}`;
}

export const getTableColumns =
  <T>(
    headerKeys: Record<string, string>,
    render: (key: string, text: string, record: any) => JSX.Element | JSX.Element[]): ColumnsType<T> => ([
      ...Object.keys(headerKeys).map((key) => ({
        key,
        title: headerKeys[key],
        dataIndex: key,
        render: (text: string, record: any) => render(key, text, record),
      })),
    ])

var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

function tConvert(time: any) {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  if (time.length > 1) { // If time format correct
    time = time.slice(1);  // Remove full string match value
    time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }

  time.splice(3, 1, " ");
  return time.join(''); // return adjusted time or original string
}

export const openInNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}