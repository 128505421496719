import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API, { apis } from "common/apis";
import { INaPickingUserMappingUpload } from "common/interface"
import { IApiError } from "common/interface/IApiError";
import { logoutIfNeeded } from "common/utils";
import { getPersistedFaPickingUserMapping, getPersistedNaPickingUserMapping, persistFaPickingUserMapping, persistNaPickingUserMapping, removePersistedFaPickingUserMapping, removePersistedNaPickingUserMapping } from "services/PersistService/PersistNaPickingService";
import { RootState } from "store/store";

export interface State {
  processingNaPickingUserMapping: INaPickingUserMappingUpload,
  processingFaPickingUserMapping: INaPickingUserMappingUpload,
  status: "loading" | "success" | "error" | "idle" | "cancelling",
  error?: string
}

const initialState: State = {
  processingNaPickingUserMapping: getPersistedNaPickingUserMapping(),
  processingFaPickingUserMapping: getPersistedFaPickingUserMapping(),
  status: "idle"
};

export const saveNaPickingUserMapping = createAsyncThunk<INaPickingUserMappingUpload>("na-picking/user-mapping/save", async (_, thunkApi) => {
  const state = thunkApi.getState() as RootState;
  const { naPicking: { processingNaPickingUserMapping: { key } } } = state;

  const response = await API.post(apis.saveNaPickingUserMapping, {}, {
    params: { key }
  }).then(success => success.data)
    .catch(error => {
      const errorMessage = error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

export const cancelNaPickingUserMapping = createAsyncThunk("na-picking/user-mapping/cancel", async (_, thunkApi) => {
  const state = thunkApi.getState() as RootState;
  const { naPicking: { processingNaPickingUserMapping: { key } } } = state;

  const response = await API.post(apis.saveNaPickingUserMapping, {}, {
    params: { key, cancel: 1 }
  })
    .then(success => success.data)
    .catch(error => {
      const errorMessage = error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

export const saveFaPickingUserMapping = createAsyncThunk<INaPickingUserMappingUpload>("fa-picking/user-mapping/save", async (_, thunkApi) => {
  const state = thunkApi.getState() as RootState;
  const { naPicking: { processingFaPickingUserMapping: { key } } } = state;

  const response = await API.post(apis.saveFaPickingUserMapping, {}, {
    params: { key }
  }).then(success => success.data)
    .catch(error => {
      const errorMessage = error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

export const cancelFaPickingUserMapping = createAsyncThunk("fa-picking/user-mapping/cancel", async (_, thunkApi) => {
  const state = thunkApi.getState() as RootState;
  const { naPicking: { processingFaPickingUserMapping: { key } } } = state;

  const response = await API.post(apis.saveFaPickingUserMapping, {}, {
    params: { key, cancel: 1 }
  })
    .then(success => success.data)
    .catch(error => {
      const errorMessage = error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

const naPickingSlice = createSlice({
  name: "na-picking",
  initialState,
  reducers: {
    setProcessingNaPickingUserMapping: (state, action) => {
      state.status = "idle";
      state.error = "";
      state.processingNaPickingUserMapping = action.payload;
      state.processingNaPickingUserMapping.timeUploaded = new Date().toString();
      persistNaPickingUserMapping(state.processingNaPickingUserMapping);
    },
    removeProcessingNaPickingUserMapping: (state) => {
      state.processingNaPickingUserMapping = {};
      state.status = "idle";
      state.error = "";
      removePersistedNaPickingUserMapping();
    },
    setProcessingFaPickingUserMapping: (state, action) => {
      state.status = "idle";
      state.error = "";
      state.processingFaPickingUserMapping = action.payload;
      state.processingNaPickingUserMapping.timeUploaded = new Date().toString();
      persistFaPickingUserMapping(state.processingFaPickingUserMapping);
    },
    removeProcessingFaPickingUserMapping: (state) => {
      state.processingFaPickingUserMapping = {};
      state.status = "idle";
      state.error = "";
      removePersistedFaPickingUserMapping();
    }
  },
  extraReducers(builder) {
    builder
      /**
       * SAVE USER MAPPING
       */
      .addCase(saveNaPickingUserMapping.pending, state => {
        state.status = "loading";
      })
      .addCase(saveNaPickingUserMapping.fulfilled, state => {
        state.status = "success";
        state.error = "";
        state.processingNaPickingUserMapping = {};
        removePersistedNaPickingUserMapping();
      })
      .addCase(saveNaPickingUserMapping.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })

      /**
       * CANCEL PROCESSING USER MAPPING
       */
      .addCase(cancelNaPickingUserMapping.pending, state => {
        state.status = "cancelling";
      })
      .addCase(cancelNaPickingUserMapping.fulfilled, state => {
        state.status = "idle";
        state.error = "";
        state.processingNaPickingUserMapping = {};
        removePersistedNaPickingUserMapping();
      })
      .addCase(cancelNaPickingUserMapping.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })

      /**
       * SAVE FA USER MAPPING
       */
      .addCase(saveFaPickingUserMapping.pending, state => {
        state.status = "loading";
      })
      .addCase(saveFaPickingUserMapping.fulfilled, state => {
        state.status = "success";
        state.error = "";
        state.processingFaPickingUserMapping = {};
        removePersistedFaPickingUserMapping();
      })
      .addCase(saveFaPickingUserMapping.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })

      /**
       * CANCEL PROCESSING FA USER MAPPING
       */
      .addCase(cancelFaPickingUserMapping.pending, state => {
        state.status = "cancelling";
      })
      .addCase(cancelFaPickingUserMapping.fulfilled, state => {
        state.status = "idle";
        state.error = "";
        state.processingFaPickingUserMapping = {};
        removePersistedFaPickingUserMapping();
      })
      .addCase(cancelFaPickingUserMapping.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })
  }
});

export const { 
  setProcessingNaPickingUserMapping, 
  removeProcessingNaPickingUserMapping, 
  setProcessingFaPickingUserMapping, 
  removeProcessingFaPickingUserMapping,
 } = naPickingSlice.actions;
export default naPickingSlice.reducer;