import { Row, Col, Table, Tag, message, Button, Popconfirm, Switch } from "antd";
import { ColumnsType } from "antd/lib/table";
import { IRequisition } from "common/interface";
import { routes } from "common/routes";
import { getSlotName } from "common/utils";
import { AppActionStrip } from "components/AppActionStrip/AppActionStrip";
import { AppFilter, AppFilterButton } from "components/AppFilter";
import { AppRequisitionFilter } from "components/AppFilter/AppRequisitionFilter/AppRequisitionFilter";
import { AppPageHeader } from "components/AppPageHeader";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useDocumentTitle } from "hooks/usePageTitle";
import { useEffect, useState, useCallback } from "react";
import { Link, useSearchParams } from "react-router-dom";
import {
  getDispatchedRequisitions,
  toggleBulkStatus,
} from "store/requisition/requisitionSlice";

type PartialRequisitionType = Partial<IRequisition> & { key: string };

const headerKeys: { [key: string]: string } = {
  name: "Store Name",
  date: "Date",
  slot: "Slot",
  address: "Address",
  // createdOn: "Created On",
  status: "Status",
  actions: "Actions",
  srType: "SR Type",
};

const MESSAGE_KEY = "all_dispatched_requisitions";

export const AllRequisitionsDispatchedPage = () => {
  useDocumentTitle("All Non Dispatched Requisitions");
  const dispatch = useAppDispatch();
  const { dispatchedRequisitions, status, error, slots } = useAppSelector(
    (state) => state.requisition
  );
  const [columns, setColumns] = useState<ColumnsType<PartialRequisitionType>>();
  const [rows, setRows] = useState<PartialRequisitionType[]>();
  const [popConfirmReqId, setPopConfirmReqId] = useState<number>();
  const [filterState, setFilterState] = useState(false);
  const [searchParams] = useSearchParams();
  const [filterCount, setFilterCount] = useState(0);
  const [changingId, setChangingId] = useState<number>();

  const getUpdatedData = useCallback(
    (pageNo = 1, pageSize = 100, filter = "") => {
      dispatch(getDispatchedRequisitions({ pageNo, pageSize, filter }));
    }, [dispatch]
  );

  const toggleStatus = useCallback((reqId: number, status: string) => {
    setChangingId(reqId);
    dispatch(toggleBulkStatus({
      reqId: reqId,
      newStatus: status
    },),).then(() => {
      setChangingId(undefined);
    });
  }, [dispatch]);

  useEffect(() => {
    const filter = searchParams.toString();
    setFilterCount(
      Object.keys(Object.fromEntries(new URLSearchParams(filter))).length
    );
    getUpdatedData(1, 100, filter);
  }, [dispatch, getUpdatedData, searchParams]);

  useEffect(() => {
    if (status === "loading") {
      message.loading({ content: "Loading Requisitions", key: MESSAGE_KEY });
    } else if (status === "success") {
      message.success({
        content: "Requisitions loaded!",
        key: MESSAGE_KEY,
      });
    } else if (status === "saving") {
      message.loading({ content: "Creating Challan", key: MESSAGE_KEY });
    }
    else if (status === "error") {
      message.error({ content: error, key: MESSAGE_KEY });
    }

    return () => {
      message.destroy();
    };
  }, [status, error]);

  useEffect(() => {
    const data: PartialRequisitionType[] = (dispatchedRequisitions ?? []).map(
      (requisition, i) => {
        const {
          store: { address, name, id: storeId },
          createdOn,
          status,
          id,
          dispatching,
          date,
          slot,
          challanUrl,
          srType,
        } = requisition;

        const newRow = {
          address,
          name,
          status,
          createdOn,
          id,
          storeId,
          dispatching,
          date,
          slot: slots && slot ? getSlotName(slots, slot) : "",
          challanUrl,
          srType,
        };
        return { ...newRow, key: `req-${i}` };
      }
    );

    const tableHeaders: ColumnsType<PartialRequisitionType> = [
      ...Object.keys(headerKeys).map((key) => ({
        key,
        title: headerKeys[key],
        dataIndex: key,
        render: (text: string, record: PartialRequisitionType) => {
          if (key === "status") {
            return <Tag>{text}</Tag>;
          }
          if (key === 'srType') {
            return <Row align="middle"

            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
            >
              <Row style={{ marginRight: ".5rem", }}>{record.srType}</Row>
              <div style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center"
            }}>
              {record.status === "DEFAULT" && record.srType === "PARTIAL_BULK" && <Popconfirm
                title={`Are you sure you want to mark this to normal?`}
                disabled={status === "updating"}
                onConfirm={() => toggleStatus(record.id!, "normal",)}>
                <Button style={{margin: ".25rem"}} type="primary" loading={status === "updating" && changingId === record.id} disabled={status === "updating" && changingId === record.id}>Normal</Button>
              </Popconfirm>}
              {record.status === "DEFAULT" && record.srType === "PARTIAL_BULK" && <Popconfirm
                title={`Are you sure you want to mark this to bulk?`}
                disabled={status === "updating"}
                onConfirm={() => toggleStatus(record.id!, "bulk",)}>
                <Button style={{margin: ".25rem"}} type="primary" loading={status === "updating" && changingId === record.id} disabled={status === "updating" && changingId === record.id}>Bulk</Button>
              </Popconfirm>}
              {record.status === "DEFAULT" && record.srType === "NORMAL" && <Popconfirm
                title={`Are you sure you want to mark this to bulk?`}
                disabled={status === "updating"}
                onConfirm={() => toggleStatus(record.id!, "bulk",)}>
                <Button style={{margin: ".25rem"}} type="primary" loading={status === "updating" && changingId === record.id} disabled={status === "updating" && changingId === record.id}>Bulk</Button>
              </Popconfirm>}
              </div>
            </Row>
          }

          if (key === "actions") {
            return (
              <Col>
                <Row
                  align="middle"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  <Col style={{ marginRight: ".5rem" }}>
                    <Button size="small">
                      <Link to={`/${routes.STORE_REQUISITION.ALL_DISPATCHED}/${record.id}`}>
                        View
                      </Link>
                    </Button>
                  </Col>
                </Row>

              </Col>
            );
          }

          if (key == "name") {
            //@ts-ignore
            return <>{record["name"]} || {record["storeId"]}</>
          }

          return <>{text}</>;
        },
      })),
    ];

    setColumns(tableHeaders.map(e => {
      if (e.key === "srType") {
        return { ...e, width: "20rem", };
      }
      return e;
    }));
    setRows(data);
  }, [dispatch, popConfirmReqId, dispatchedRequisitions, slots, status]);

  return (
    <>
      <Row>
        <Col>
          <AppPageHeader title="All Non Dispatched Requisitions" />
        </Col>
      </Row>
      <Row>
        <AppActionStrip>
          <div className="action-strip__item">
            <AppFilterButton
              active={filterState}
              onClick={() => setFilterState(!filterState)}
              filterCount={filterCount}
            />
          </div>
        </AppActionStrip>
      </Row>
      <Row style={{ marginBottom: "1rem" }}>
        <AppFilter expanded={filterState}>
          <AppRequisitionFilter />
        </AppFilter>
      </Row>
      {rows && rows.length ? (
        <Row>
          <Col xs={24}>
            <Table
              columns={columns}
              dataSource={rows}
              pagination={false}
              scroll={{ x: "100vh" }}
            />
          </Col>
        </Row>
      ) : (
        status !== "loading" && <>No Requisitions to show.</>
      )}
    </>
  );
};
