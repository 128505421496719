import { IUser } from "common/interface"

const STORAGE_KEY = "user";

export const persistUser = (user: IUser) => {
  if (user) {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(user));
  }
}

export const getPersistedUser = (): IUser => {
  const userString = localStorage.getItem(STORAGE_KEY);

  if (userString) {
    return JSON.parse(userString) as IUser;
  }

  return {};
}

export const removePersistedUser = () => {
  localStorage.removeItem(STORAGE_KEY);
}