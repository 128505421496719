import { Row, Col, message, Table, Pagination, Button, Popconfirm, Select, Form } from "antd";
import { ColumnsType } from "antd/lib/table";
import { AppPageHeader } from "components/AppPageHeader";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useDocumentTitle } from "hooks/usePageTitle";
import { useCallback, useEffect, useState } from "react";
import { GrView } from "react-icons/gr";
import styled from "styled-components";
import { Link, NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { AppActionStrip } from "components/AppActionStrip/AppActionStrip";
import { AppFilterButton, AppFilter } from "components/AppFilter";
import { IPpdOrders } from "../../common/interface/IPpdOrders";
import { AppPpdOrderFilter } from "../../components/AppFilter/AppPpdOrderFilter/AppPpdOrderFilter";
import { routes } from "common/routes";
import { cancelPpdOrder, getPpdOrders } from "store/ppd/ppdSlice";
import { useForm } from "antd/lib/form/Form";
import { IStockTransfer } from "common/interface/IStockTransfer";
import { getStockTranfers } from "store/stock-exchange/stockExchangeSlice";
import { AppStockExchangeFilter } from "components/AppFilter/AppStockExchangeFilter/AppStockexchangeFilter";
import { getDateAndTimeFromDateFormatted } from "common/utils";

type PartialStockExchangeType = Partial<IStockTransfer> & { key: string };
const MESSAGE_KEY = "ALL_RECEIVED_STORE_EXCHANGE";

const headerKeys: { [key: string]: string } = {
    id: "Id",
    status: "Status",
    // stockTransferItems: "Items",
    whIdSrc: "Source Warehouse",
    whIdDest: "Destination Warehouse",
    // createdBy: "Created By",
    createdOn: "Created On",
    // modifiedBy: "Modified By",
    // modifiedOn: "Modified On",
    actions: "Actions"
};

const StyledPagination = styled(Pagination)`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

const StyledActionLink = styled(NavLink)`
  border: 1px solid;
  padding: 0.2rem;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AllReceivedStockExchangesPage = () => {
    useDocumentTitle("All Received Stock Transfer");
    const dispatch = useAppDispatch();
    const [columns, setColumns] = useState<ColumnsType<PartialStockExchangeType>>();
    const [rows, setRows] = useState<PartialStockExchangeType[]>();
    const { stockTransferData, status, error } = useAppSelector((state) => state.stockExchangeServices);
    const [filterState, setFilterState] = useState(false);
    const [searchParams] = useSearchParams();
    const [filterCount, setFilterCount] = useState(0);
    const navigate = useNavigate();
    const [form] = useForm();

    const getStockExchangeData = useCallback(
        (pageNum = 1, pageSize = 25, filter = "") => {
            dispatch(getStockTranfers({ pageNo: pageNum, pageSize, filter, isReceivePage: true }));
        },
        [dispatch]
    );

    useEffect(() => {
        const filter = searchParams.toString();
        setFilterCount(
            Object.keys(Object.fromEntries(new URLSearchParams(filter))).length
        );
        getStockExchangeData(1, 25, filter);
    }, [dispatch, getStockExchangeData, searchParams]);

    useEffect(() => {
        if (status === "loading") {
            message.loading({ content: "Loading Stock Exchanges", key: MESSAGE_KEY });
        } else if (status === "error") {
            message.error({ content: error, key: MESSAGE_KEY });
        }
        else if (status === "saving") {
            message.loading({ content: "Updating Stock Exchanges", key: MESSAGE_KEY })
        }

        return () => {
            message.destroy();
        };
    }, [status, error]);

    useEffect(() => {
        const tempStockData = stockTransferData?.data ? stockTransferData.data : [];
        const data: PartialStockExchangeType[] = tempStockData.map((au, i) => ({ ...au, key: `stockExchange-${i}`, ellipses: true }));

        const tableHeaders: ColumnsType<PartialStockExchangeType> = [
            ...Object.keys(headerKeys).map((key, idx) => ({
                key,
                title: headerKeys[key],
                dataIndex: key,
                render: (text: string, record: PartialStockExchangeType) => {
                    if (key === "stockTransferItems") {
                        return (
                            <Row>
                                {record.stockTransferItems?.map(e => {
                                    return <Row justify={"space-around"}>
                                        <Col>{e.skuCode}</Col>
                                        <Col>{": " + e.srcQty}</Col>
                                    </Row>
                                })}

                            </Row>
                        );
                    }

                    if (key === "createdOn") {
                        return <Row>{getDateAndTimeFromDateFormatted(record.createdOn ?? "")}</Row>
                    }

                    if (key === "actions") {
                        const fieldName = `cancellation-${idx}`;
                        return (
                            <Row
                                align="middle"
                                style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                }}
                            >
                                <Col style={{ marginRight: ".5rem" }}>
                                    <Button size="small">
                                        <Link to={`/${routes.STOCK_EXCHANGE.RECEIVED}/${record.id}`}>
                                            View
                                        </Link>
                                    </Button>
                                </Col>
                            </Row>
                        );
                    }
                    return <>{text}</>;
                },
            })),
        ];

        setColumns(tableHeaders);
        setRows(data);
    }, [stockTransferData]);

    const Paginator = () => {
        if (stockTransferData) {
            const { pageNo, pageSize, total } = stockTransferData;
            return (
                <StyledPagination
                    current={pageNo}
                    pageSize={pageSize}
                    total={total}
                    onChange={(page, size) => {
                        const filter = searchParams.toString();
                        setFilterCount(
                            Object.keys(Object.fromEntries(new URLSearchParams(filter))).length
                        );
                        getStockExchangeData(page, size, filter);
                    }}
                />
            );
        }

        return <></>;
    };

    return (
        <>
            <Row>
                <Col>
                    <AppPageHeader title="All Received Stock Transfer" />
                </Col>
            </Row>
            <Row>
                <AppActionStrip>
                    <div className="action-strip__item">
                        <AppFilterButton
                            active={filterState}
                            onClick={() => setFilterState(!filterState)}
                            filterCount={filterCount}
                        />
                    </div>
                </AppActionStrip>
            </Row>
            <Row style={{ marginBottom: "1rem" }}>
                <AppFilter expanded={filterState}>
                    <AppStockExchangeFilter />
                </AppFilter>
            </Row>
            <Form form={form}>
                {rows && rows.length ? (
                    <Row>
                        <Col xs={24}>
                            <Table
                                columns={columns}
                                dataSource={rows.filter(e => e.id !== -1)}
                                scroll={{ x: "100vh", y: "65vh" }}
                                size="small"
                                pagination={false}
                                loading={status === "loading"}
                                bordered
                            />
                        </Col>
                        <Col xs={24}>
                            <Paginator />
                        </Col>
                    </Row>
                ) : (
                    status !== "loading" && <>No Stock Transfer to show.</>
                )}
            </Form>
        </>
    );
};

export default AllReceivedStockExchangesPage;