import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Table,
  Alert,
  message,
  DatePicker,
  Select,
  Form,
} from "antd";
import { apis, AUTH_HEADER_NAME } from "common/apis";
import { IRequisitionUpload, IRequisitionUploadRow } from "common/interface";
import { routes } from "common/routes";
import { AppPageHeader } from "components/AppPageHeader";
import { AppUpload } from "components/AppUpload";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import {
  removeProcessingRequisition,
  setProcessingRequisition,
} from "store/requisition/requisitionSlice";
import { generateHeaders } from "common/utils";
import { ColumnsType } from "antd/lib/table";
import { useDocumentTitle } from "hooks/usePageTitle";

export const UploadStoreRequisitionPage = () => {
  useDocumentTitle("Upload Requisitions");
  const { authToken } = useAppSelector((state) => state.app.user);
  const { processingRequisition, slots } = useAppSelector(
    (state) => state.requisition
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [hasErrorRows, setHasErrorRows] = useState(false);
  const [date, setDate] = useState<string>();
  const [slot, setSlot] = useState<string>();

  useEffect(() => {
    const { timeUploaded } = processingRequisition;
    if (timeUploaded) {
      const uploadedTime = new Date(timeUploaded).getTime();
      const currentTime = new Date().getTime();
      const minsPassed = (currentTime - uploadedTime) / 1000 / 60;

      if (minsPassed >= 15) {
        dispatch(removeProcessingRequisition());
      }
    }

    if (processingRequisition && processingRequisition.key) {
      navigate(`/${routes.STORE_REQUISITION.SAVE}`, {});
    }

    return () => {
      message.destroy();
    };
  }, [processingRequisition, navigate, dispatch]);


  // useEffect(() => {
  //   return (() => {
  //     dispatch(removeProcessingRequisition())
  //   });
  // }, []);

  useEffect(() => {
    const failed = processingRequisition?.failedRows?.length;

    if (failed) {
      setHasErrorRows(true);
    }
  }, [processingRequisition?.failedRows]);

  const { Option } = Select;

  const headers: { [name: string]: string } = {
    ...(authToken && { [AUTH_HEADER_NAME]: authToken }),
  };

  const onUploaded = (data: IRequisitionUpload) => {
    dispatch(setProcessingRequisition(data));
  };

  const onUploading = () => {
    dispatch(removeProcessingRequisition());
  };

  const { headerMapping } = processingRequisition;
  const headerMappingWithError = headerMapping + ",errors:Errors";

  const generateMemoizedHeaders = useMemo(
    () => generateHeaders(headerMappingWithError),
    [headerMappingWithError]
  );

  const tableHeaders = generateMemoizedHeaders || {};
  const successRows = processingRequisition?.successRows || [];
  const failedRows = processingRequisition?.failedRows || [];
  const successData = successRows?.map((row, i) => ({
    ...row,
    key: `data-success-${i}`,
  }));
  const failedData = failedRows?.map((row, i) => ({
    ...row,
    key: `data-failed-${i}`,
  }));
  const data = [...failedData, ...successData];
  const columns: ColumnsType<IRequisitionUploadRow & { key: string }> = [
    ...Object.keys(tableHeaders).map((key) => ({
      key,
      title: tableHeaders[key],
      dataIndex: key,
      render: (
        text: string,
        record: IRequisitionUploadRow & { key: string }
      ) => {
        if (key === "errors") {
          const errorMessages = record.errors
            ?.map((error) => error.message)
            .join(", ");
          return <>{errorMessages}</>;
        }
        return <>{text}</>;
      },
    })),
  ];

  const addBorderToErrorRows = (
    record: IRequisitionUploadRow & { key: string }
  ) => {
    if (record.errors && record.errors.length) {
      return "error-row";
    }

    return "";
  };

  return (
    <>
      <Row>
        <Col>
          <AppPageHeader title="Upload Store Requisition" />
        </Col>
      </Row>
      <Row>
        <Form>
          <Form.Item label="Date" name="date" required>
            <DatePicker
              onChange={(val) => setDate(val?.format("yyyy-MM-DD"))}
            />
          </Form.Item>
          {slots && (
            <Form.Item label="Slot" name="slot" required>
              <Select
                placeholder="Select slot"
                onChange={(val) => setSlot(val)}
              >
                {Object.entries(slots).map(([label, value]) => (
                  <Option value={value} key={value}>
                    {label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </Form>
      </Row>
      {!!(date && slot) && (
        <Row>
          <Col lg={16} xs={24}>
            <AppUpload
              uploadUrl={apis.uploadRequisition}
              headers={headers}
              onUploaded={onUploaded}
              onUploading={onUploading}
              hasErrorRows={hasErrorRows}
              data={{ date, slot }}
            />
          </Col>
        </Row>
      )}
      {!!Object.keys(processingRequisition).length &&
        !processingRequisition.key && (
          <>
            <Row>
              <Col lg={16} xs={24}>
                <Alert
                  type="error"
                  message="Errors in sheet data."
                  description="Please correct errors and upload again."
                  showIcon
                  banner
                />
              </Col>
            </Row>

            <Row>
              <Table
                size="small"
                columns={columns}
                dataSource={data}
                rowClassName={(record) => addBorderToErrorRows(record)}
                scroll={{ y: 500, x: "100vh" }}
                className="table-with-errors"
              ></Table>
            </Row>
          </>
        )}
    </>
  );
};
