import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API, { apis } from "common/apis";
import { IApiError } from "common/interface/IApiError";
import { ICashCollection, ICashCollectionApi } from "common/interface/ICashCollection";
import { logoutIfNeeded } from "common/utils";
import { RootState } from "store/store";

export interface State {
  cashCollections?: ICashCollectionApi,
  status: "loading" | "success" | "error" | "idle" | "cancelling" | "saving",
  error?: string
}

const initialState: State = {
  status: "idle"
};

export const receiveCashCollection = createAsyncThunk<ICashCollection, { id?: string, receivedAmount?: string, }>("cash-collection/receive", async ({ id, receivedAmount }, thunkApi) => {
  const state = thunkApi.getState() as RootState;

  const response = await API.post(apis.receiveCashCollection, {
    id, status: "RECEIVED", receivedAmount,
  },).then(success => success.data)
    .catch(error => {
      const errorMessage = error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

export const cancelCashCollection = createAsyncThunk<ICashCollection, { id?: string }>("cash-collection/cancel", async ({ id }, thunkApi) => {
  const state = thunkApi.getState() as RootState;

  const response = await API.post(apis.receiveCashCollection, {
    id, status: "CANCELLED",
  },).then(success => success.data)
    .catch(error => {
      const errorMessage = error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

export const getCashCollections = createAsyncThunk<
  ICashCollectionApi,
  { pageNo: Number, pageSize: Number, filter: string, }
>("/cash-collection/all", async ({ pageNo, pageSize, filter }, thunkApi) => {
  const temp = Object.fromEntries(new URLSearchParams(filter));
  const filterParams = filter
    ? { ...temp }
    : "";
  const response = await API.get(apis.getCashCollections, {
    params: {
      pageNo,
      pageSize,
      ...filterParams,
    },
  })
    .then((success) => success.data)
    .catch((error) => {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

const cashCollectionSlice = createSlice({
  name: "cash-collection",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /**
       * Receive Cash Collection
       */
      .addCase(receiveCashCollection.pending, state => {
        state.status = "loading";
      })
      .addCase(receiveCashCollection.fulfilled, (state, action) => {
        state.status = "success";
        state.error = "";
        const newData = Array.from(state.cashCollections!.data);
        newData.splice(newData.findIndex(e => e.id === action.payload.id), 1, action.payload);
        state.cashCollections = { ...Object(state.cashCollections), data: newData };
      })
      .addCase(receiveCashCollection.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })

      /**
       * CANCEL PROCESSING USER MAPPING
       */
      .addCase(cancelCashCollection.pending, state => {
        state.status = "cancelling";
      })
      .addCase(cancelCashCollection.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = "";
        const newData = Array.from(state.cashCollections!.data);
        newData.splice(newData.findIndex(e => e.id === action.payload.id), 1, action.payload);
        state.cashCollections = { ...Object(state.cashCollections), data: newData };
      })
      .addCase(cancelCashCollection.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })
      /**
      * GET ALL CASH COLLECTIONS
       **/
      .addCase(getCashCollections.pending, state => {
        state.status = "loading";
      })
      .addCase(getCashCollections.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = "";
        state.cashCollections = action.payload;
      })
      .addCase(getCashCollections.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })


  }
});

export default cashCollectionSlice.reducer;