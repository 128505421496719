import { useEffect } from "react";
import styled from "styled-components";
import { Button, Col, Layout, Row } from "antd";
import { useAppDispatch } from "hooks/useAppDispatch";
import { setSider, updateWarehouseId } from "store/app/appSlice";
import { AppLogo } from "components/AppLogo";
import { useAppSelector } from "hooks/useAppSelector";
import { AppUserInfoMenu } from "components/AppHeader/AppUserInfoMenu";
import { variables } from "common/variables";
import { useNavigate } from "react-router-dom";

const { Header } = Layout;

const StyledHeader = styled(Header)`
  background-color: white;
  border-bottom: 1px solid #ebebeb;
  padding: 0 1.5rem;
  z-index: 9;
`;

const StyledButton = styled(Button)`
  &.filterButton {
    &--active {
      border-color: ${variables.primaryColor} !important;
    }
  }
`;

export const AppHeaderDesktop = () => {
  const dispatch = useAppDispatch();
  const { user, whId } = useAppSelector((state) => state.app);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setSider(true));
  }, [dispatch]);

  return (
    <StyledHeader>
      <Row justify="space-between" align={"middle"}>
        <Col>
          <AppLogo />
        </Col>
        {user?.isAuthenticated && (
          <>
            <Col style={{ "justifyContent": "center", "alignItems": "center" }}>
              <StyledButton 
              style={{ marginRight: ".5rem" }}
              onClick={() => {
                // dispatch(updateWarehouseId(undefined));
                navigate("/warehouse-selection");
              }}
              >Warehouse: {user.user?.mappedWh?.find(e => e.id === whId)?.name} # {whId}</StyledButton>
            </Col>
            <Col style={{ marginLeft: ".5rem", }}>
              <AppUserInfoMenu />
            </Col>
          </>
        )}
      </Row>
    </StyledHeader>
  );
};
