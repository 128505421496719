import { Form, Row, Col, Input, Button, Grid, Select } from "antd";
import { mobileFormValidator } from "common/utils";
import { AppActionStrip } from "components/AppActionStrip/AppActionStrip";
import { FormGroupIdentifier } from "components/FormGroupIdentifier/FormGroupIdentifier";
import CustomUploadField from "components/FormUploadField/FormUploadField";
import { useEffect, useState } from "react";
import { getSupplyLocations } from "store/vendor/vendorSlice";
import { useAppDispatch } from "hooks/useAppDispatch";

interface VendorFormProps {
  formInstance: any;
  onFinish: (values: Record<string, any>) => void;
  onCancel: () => void;
  onSubmit: () => void;
  values?: any;
  loading?: boolean;
}

const { Option } = Select;

let supplyLocations: string[] = [];

const { useBreakpoint } = Grid;

export const VendorForm = (props: VendorFormProps) => {
  const [formWidth, setFormWidth] = useState("100%");
  const screens = useBreakpoint();
  
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSupplyLocations())
      .then((action: any) => {
        if (action.type === getSupplyLocations.fulfilled.type) {
          supplyLocations = action.payload;
        } else {
          console.error(
            action.payload?.message || "Failed to fetch supply locations"
          );
        }
      })
      .catch((error) => {
        console.error("An unexpected error occurred:", error);
      });
  }, [dispatch]);

  useEffect(() => {
    if (screens.lg) {
      setFormWidth("70%");
    } else {
      setFormWidth("100%");
    }
  }, [screens]);

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  useEffect(() => {
    props?.formInstance.setFieldsValue(props.values);
  }, [props.formInstance, props.values]);

  return (
    <Form
      form={props.formInstance}
      name="new-vendor"
      layout="vertical"
      style={{ width: formWidth }}
      onFinish={props.onFinish}
    >
      <Row>
        <Col xs={24}>
          <FormGroupIdentifier>Vendor Details</FormGroupIdentifier>
        </Col>

        <Col xs={24}>
          <Row>
            <Col xs={24} md={8}>
              <Form.Item
                name="name"
                label="Vendor Name"
                rules={[
                  {
                    required: true,
                    message: "Please input Vendor Name!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={3}></Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="pocName"
                label="Point of Contact Name"
                rules={[
                  // {
                  //   required: true,
                  //   message: "Please input Point of Contact Name!",
                  // },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        
        <Col xs={24} md={6}>
          <Form.Item
            name="extVendorId"
            label="Ext Vendor ID"
            rules={[
              // {
              //   required: true,
              //   message: "Please provide your PAN Number!",
              // },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} md={3} />
        <Col xs={24} md={6}>
              <Form.Item
                name="panNumber"
                label="PAN Number"
                rules={[
                  // {
                  //   required: true,
                  //   message: "Please provide PAN Number!",
                  // },
                ]}
              >
                <Input
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={3}/>
        <Col xs={24} md={6}>
          <Form.Item
            name="panFileUrl"
            label="Upload PAN Proof"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[
              // {
              //   required: true,
              //   message: "Please provide your PAN Number!",
              // },
            ]}
          >
            <CustomUploadField form={props.formInstance} fieldName={"panFileUrl"} />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col xs={24}>
          <FormGroupIdentifier>
            <>Address Details</>
          </FormGroupIdentifier>
        </Col>

        <Col xs={24}>
          <Row>
            <Col xs={24}>
              <Form.Item
                name="address1"
                label="Address Line 1"
                rules={[
                  {
                    required: true,
                    message: "Please input Address!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name="address2"
                label="Address Line 2"
                rules={[
                  {
                    message: "Please input Address!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={6}>
              <Form.Item
                name="city"
                label="City"
                rules={[
                  {
                    required: true,
                    message: "Please input City!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={3}></Col>
            <Col xs={24} md={6}>
              <Form.Item
                name="state"
                label="State"
                rules={[
                  {
                    required: true,
                    message: "Please input State!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={3}></Col>
            <Col xs={24} md={6}>
              <Form.Item
                name="pincode"
                label="Pincode"
                rules={[
                  {
                    required: true,
                    message: "Please input Pincode!",
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col xs={24} md={6}>
            <Form.Item name="location" label="Supply Location">
              <Select placeholder="Select a location">
                {supplyLocations.map((item) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={3}></Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="addressFileUrl"
                label="Upload Address Proof"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                rules={[
                  // {
                  //   required: true,
                  //   message: "Please provide a file!",
                  // },
                ]}
              >
                <CustomUploadField form={props.formInstance} fieldName={"addressFileUrl"} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col xs={24}>
          <FormGroupIdentifier>Contact Details</FormGroupIdentifier>
        </Col>

        <Col xs={24}>
          <Row>
            <Col xs={24} md={6}>
              <Form.Item
                name="primaryPhone"
                label="Primary Phone"
                rules={[
                  {
                    required: true,
                    message: "Please input your Mobile!",
                  },
                  {
                    validator: function (rule, value) {
                      return mobileFormValidator(value);
                    },
                    validateTrigger: "onFinish",
                  },
                ]}
              >
                <Input
                  // prefix={<>+91</>}
                  maxLength={10}
                  formNoValidate={false}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={3}></Col>
            <Col xs={24} md={6}>
              <Form.Item
                name="otherPhone"
                label="Other Phone"
                rules={[
                  {
                    message: "Please input your Secondary Mobile!",
                  },
                  {
                    validator: function (rule, value) {
                      return mobileFormValidator(value);
                    },
                    validateTrigger: "onFinish",
                  },
                ]}
              >
                <Input
                  // prefix={<>+91</>}
                  maxLength={10}
                  formNoValidate={false}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={3}></Col>
            <Col xs={24} md={6}>
              <Form.Item
                name="whatsappPhone"
                label="Whatsapp Phone"
                rules={[
                  {
                    validator: function (rule, value) {
                      return mobileFormValidator(value);
                    },
                    validateTrigger: "onFinish",
                  },
                ]}
              >
                <Input
                  // prefix={<>+91</>}
                  maxLength={10}
                  formNoValidate={false}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={6}>
              <Form.Item
                name="email"
                label="Email Address"
                rules={[
                  // {
                  //   required: true,
                  //   message: "Please input your Email!",
                  // },
                ]}
              >
                <Input type="email" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <FormGroupIdentifier>Bank Details</FormGroupIdentifier>
        </Col>

        <Col xs={24}>
          <Row>
            <Col xs={24} md={8}>
              <Form.Item
                name="accountNumber"
                label="Account Number"
                rules={[
                  {
                    required: true,
                    message: "Please provide your Account No!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={3} />
            <Col xs={24} md={8}>
              <Form.Item
                name="accountName"
                label="Account Name"
                rules={[
                  {
                    required: true,
                    message: "Please input Address!",
                  },
                ]}
              >
                <Input
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={8}>
              <Form.Item
                name="bankName"
                label="Bank Name"
                rules={[
                  {
                    required: true,
                    message: "Please provide your bank name!",
                  },
                ]}
              >
                <Input
                  formNoValidate={false}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={3} />
            <Col xs={24} md={8}>
              <Form.Item
                name="ifscCode"
                label="IFSC Code"
                rules={[
                  {
                    required: true,
                    message: "Please provide IFSC Code!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={8}>
              <Form.Item
                name="branchName"
                label="Branch Name"
                rules={[
                  {
                    required: true,
                    message: "Please provide your Branch name!",
                  },
                ]}
              >
                <Input
                  formNoValidate={false}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={3}></Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="gstin"
                label="GST Number"
                rules={[
                  // {
                  //   required: true,
                  //   message: "Please provide your bank name!",
                  // },
                ]}
              >
                <Input
                // formNoValidate={false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Col xs={24} md={8}>
            <Form.Item
              name="cancelledChequeFileUrl"
              label="Upload Cancelled Cheque"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              rules={[
                {
                  required: true,
                  message: "Please provide a file!",
                },
              ]}
            >
              <CustomUploadField form={props.formInstance} fieldName={"cancelledChequeFileUrl"} />
            </Form.Item>
          </Col>
        </Col>
      </Row>

      <Row>
        <AppActionStrip>
          <Button
            type="text"
            className="action-strip__item"
            htmlType="submit"
            disabled={props.loading}
            onClick={props.onCancel}
          >
            cancel
          </Button>
          <Button
            type="primary"
            className="action-strip__item"
            onClick={props.onSubmit}
            loading={props.loading}
            disabled={props.loading}
          >
            Save
          </Button>
        </AppActionStrip>
      </Row>
    </Form>
  );
};
