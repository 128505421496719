import { IPickerMappingUpload } from "common/interface/IPickerUploadScheme";

const STORAGE_KEY_FRANCHISE_MAPPING = "processingFranchiseMapping";
const STORAGE_KEY_PRICE_OVERRIDE = "processingPricingOverride";
const STORAGE_KEY_CATEGORY_PRICING = "processingCategoryPricing";
const STORAGE_KEY_INVENTORY_ADJ = "processingInventoryAdj";

export const persistProcessingFranchiseMapping = (data: IPickerMappingUpload) => {
  localStorage.setItem(STORAGE_KEY_FRANCHISE_MAPPING, JSON.stringify(data));
}

export const getPersistProcessingFranchiseMapping = (): IPickerMappingUpload => {
  const processingWhReturnMapping = localStorage.getItem(STORAGE_KEY_FRANCHISE_MAPPING);
  return processingWhReturnMapping ? JSON.parse(processingWhReturnMapping) : {};
}

export const removePersistProcessingFranchiseMapping = () => {
  localStorage.removeItem(STORAGE_KEY_FRANCHISE_MAPPING);
}

export const persistProcessingPricingOverride = (data: IPickerMappingUpload) => {
  localStorage.setItem(STORAGE_KEY_PRICE_OVERRIDE, JSON.stringify(data));
}

export const getPersistProcessingPricingOverride  = (): IPickerMappingUpload => {
  const processingWhReturnMapping = localStorage.getItem(STORAGE_KEY_PRICE_OVERRIDE);
  return processingWhReturnMapping ? JSON.parse(processingWhReturnMapping) : {};
}

export const removePersistProcessingPricingOverride = () => {
  localStorage.removeItem(STORAGE_KEY_PRICE_OVERRIDE);
}

export const persistProcessingCategoryPricing = (data: IPickerMappingUpload) => {
  localStorage.setItem(STORAGE_KEY_CATEGORY_PRICING, JSON.stringify(data));
}

export const getPersistProcessingCategoryPricing  = (): IPickerMappingUpload => {
  const processingWhReturnMapping = localStorage.getItem(STORAGE_KEY_CATEGORY_PRICING);
  return processingWhReturnMapping ? JSON.parse(processingWhReturnMapping) : {};
}

export const removePersistProcessingCategoryPricing= () => {
  localStorage.removeItem(STORAGE_KEY_CATEGORY_PRICING);
}

export const persistProcessingInventoryAdj = (data: IPickerMappingUpload) => {
  localStorage.setItem(STORAGE_KEY_INVENTORY_ADJ, JSON.stringify(data));
}

export const getPersistProcessingInventoryAdj  = (): IPickerMappingUpload => {
  const processingWhReturnMapping = localStorage.getItem(STORAGE_KEY_INVENTORY_ADJ);
  return processingWhReturnMapping ? JSON.parse(processingWhReturnMapping) : {};
}

export const removePersistProcessingInventoryAdj = () => {
  localStorage.removeItem(STORAGE_KEY_INVENTORY_ADJ);
}