import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API, { apis } from "common/apis";
import { ILoginForm, IUser } from "common/interface";
import { IApiError } from "common/interface/IApiError";
import { getPersistedUser, persistUser, removePersistedUser } from "services/PersistService/PersistUserService";
import { getWarehouseId, removeWarehouseId, setWarehouseId } from "services/PersistService/PersistWareHouseReturn";

const APP_VERSION = process.env.REACT_APP_VERSION;
// const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;
interface State {
  version?: string;
  apiDomain?: string;
  siderCollapsed?: boolean,
  whId?: number,
  user: IUser,
  status: "idle" | "loading" | "succeeded" | "failed",
  errors?: string[],
}

const initialState: State = {
  version: APP_VERSION,
  siderCollapsed: undefined,
  user: getPersistedUser() || {},
  status: "idle",
  whId: getWarehouseId(),
}

export const login = createAsyncThunk<IUser & IApiError, ILoginForm, {}>("app/login", async (user, thunkApi) => {
  const response = await API.post(apis.login, {
    username: `${user.mobile}`,
    password: user.password
  }, {
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(success => success.data)
    .catch(error => {
      const errorMessage = error?.response?.data?.message || "Something went wrong!";
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    toggleSider: (state) => {
      state.siderCollapsed = !state.siderCollapsed;
    },
    setSider: (state, action) => {
      state.siderCollapsed = !action.payload
    },
    logout: (state) => {
      state.user = {};
      state.whId = undefined;
      removeWarehouseId();
      removePersistedUser();
    },
    updateWarehouseId: (state, action) => {
      state.whId = action.payload;
      if (action.payload === undefined) {
        removeWarehouseId();
      }
      else {
        setWarehouseId(action.payload);
      }
      
    }
  },
  extraReducers(builder) {
    builder
      .addCase(login.pending, (state) => {
        state.status = "loading";
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = "succeeded";
        const { errors, error, message, ...userData } = action.payload;

        if (userData && userData.authToken) {
          state.user = { ...userData, isAuthenticated: true };
          state.errors = [];
          persistUser(state.user);
        } else {
          state.status = "failed";
          state.errors = ['Something went wrong!']
        }
      })
      .addCase(login.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "failed";

        if (error.message) {
          state.errors = [error.message];
        }
      })
  },
});

export const { toggleSider, setSider, logout, updateWarehouseId } = appSlice.actions;
export default appSlice.reducer;