import {
    Alert,
    Col,
    DatePicker,
    Form,
    Input,
    message,
    Row,
    Select,
    Table,
  } from "antd";
  import { ColumnsType } from "antd/lib/table";
  import { apis, AUTH_HEADER_NAME } from "common/apis";
  import { IPickerMappingUpload, IPickerMappingUploadRow } from "common/interface/IPickerUploadScheme";
  import { routes } from "common/routes";
  import { generateHeaders } from "common/utils";
  import { AppPageHeader } from "components/AppPageHeader";
  import { AppUpload } from "components/AppUpload";
  import { useAppDispatch } from "hooks/useAppDispatch";
  import { useAppSelector } from "hooks/useAppSelector";
  import { useDocumentTitle } from "hooks/usePageTitle";
import moment from "moment";
  import { useState, useEffect, useMemo } from "react";
  import { useNavigate } from "react-router-dom";
import { removeProcessingProjectedSr, setProcessingProjectedSr } from "store/admin-slice/adminServicesSlice";
  
  export const UploadPProcessingSrPage = () => {
    useDocumentTitle("Upload Projected SR");
    const { authToken } = useAppSelector((state) => state.app.user);
    const { slots, storesList } = useAppSelector(
      (state) => state.requisition
    );  
    
    const { processingProjectedSrMapping } = useAppSelector(
      (state) => state.adminServices,
    );
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [hasErrorRows, setHasErrorRows] = useState(false);
    const [deliveryDate, setDate] = useState<string>();
    const [whId, setWhId] = useState<string>();
  
    useEffect(() => {
      if (
        processingProjectedSrMapping &&
        processingProjectedSrMapping.key
      ) {
        navigate(`/${routes.PROCUREMENT.SAVE_PROJECTED_SR}`, {});
      }
  
      return () => {
        message.destroy();
      };
    }, [processingProjectedSrMapping, navigate]);
  
    useEffect(() => {
      const failed = processingProjectedSrMapping?.failedRows?.length;
  
      if (failed) {
        setHasErrorRows(true);
      }
    }, [processingProjectedSrMapping?.failedRows]);
  
    const headers: { [name: string]: string } = {
      ...(authToken && { [AUTH_HEADER_NAME]: authToken }),
    };
  
    const onUploaded = (data: IPickerMappingUpload) => {
      dispatch(setProcessingProjectedSr(data));
    };
  
    const onUploading = () => {
      dispatch(removeProcessingProjectedSr());
    };
  
    const { headerMapping } = processingProjectedSrMapping;
    const headerMappingWithError = headerMapping + ",errors:Errors";
  
    const generateMemoizedHeaders = useMemo(
      () => generateHeaders(headerMappingWithError),
      [headerMappingWithError]
    );
  
    const tableHeaders = generateMemoizedHeaders || {};
    const successRows = processingProjectedSrMapping?.successRows || [];
    const failedRows = processingProjectedSrMapping?.failedRows || [];
    const successData = successRows?.map((row, i) => ({
      ...row,
      key: `data-success-${i}`,
    }));
    const failedData = failedRows?.map((row, i) => ({
      ...row,
      key: `data-failed-${i}`,
    }));
    const data = [...failedData, ...successData];
    const columns: ColumnsType<
      IPickerMappingUploadRow & { key: string }
    > = [
        ...Object.keys(tableHeaders).map((key) => ({
          key,
          title: tableHeaders[key],
          dataIndex: key,
          render: (
            text: string,
            record: IPickerMappingUploadRow & { key: string }
          ) => {
            if (key === "errors") {
              const errorMessages = record.errors
                ?.map((error) => error.message)
                .join(", ");
              return <>{errorMessages}</>;
            }
            return <>{text}</>;
          },
        })),
      ];
  
    const addBorderToErrorRows = (
      record: IPickerMappingUploadRow & { key: string }
    ) => {
      if (record.errors && record.errors.length) {
        return "error-row";
      }
  
      return "";
    };
  
    return (
      <>
        <Row>
          <Col>
            <AppPageHeader title="Upload Projected SR" />
          </Col>
        </Row>
        <Row>
          <Form>
            <Form.Item label="Select date" name="date" required>
              <DatePicker
                onChange={(val) => setDate(val?.format("yyyy-MM-DD"))}
              />
            </Form.Item>
            {/* <Form.Item label="Warehouse ID" name="whId" required>
                <Input onChange={(val) => setWhId(val.target.value)}/>
              </Form.Item> */}
          </Form>
        </Row>
        {(deliveryDate) && (
          <Row>
            <Col lg={16} xs={24}>
              <AppUpload
                uploadUrl={apis.uploadProjectedSr}
                headers={headers}
                onUploaded={onUploaded}
                onUploading={onUploading}
                hasErrorRows={hasErrorRows}
                data={{ date: deliveryDate }}
              />
            </Col>
          </Row>
        )}
        {Object.keys(processingProjectedSrMapping).length != 0 &&
          !processingProjectedSrMapping.key && (
            <>
              <Row>
                <Col lg={16} xs={24}>
                  <Alert
                    type="error"
                    message="Errors in sheet data."
                    description="Please correct errors and upload again."
                    showIcon
                    banner
                  />
                </Col>
              </Row>
  
              <Row>
                <Table
                    size="small"
                    columns={columns}
                    dataSource={data}
                    rowClassName={(record) => addBorderToErrorRows(record)}
                    scroll={{ y: 500 }}
                    className="table-with-errors"
                  ></Table>
              </Row>
            </>
          )}
      </>
    );
  };
  