import {
  Alert,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  Select,
  Table,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import { apis, AUTH_HEADER_NAME } from "common/apis";
import { IPickerMappingUpload, IPickerMappingUploadRow } from "common/interface/IPickerUploadScheme";
import { routes } from "common/routes";
import { generateHeaders } from "common/utils";

import { AppPageHeader } from "components/AppPageHeader";
import { AppUpload } from "components/AppUpload";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useDocumentTitle } from "hooks/usePageTitle";
import { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { removeProcessingPickerMapping, setProcessingPickerMapping } from "store/ppd/ppdSlice";
import {
  fetchStoresList
} from "store/requisition/requisitionSlice";

export const UploadPickerMappingStoreRequisitionPage = () => {
  useDocumentTitle("Requisition: Upload Picker Mapping");
  const { authToken } = useAppSelector((state) => state.app.user);
  const { slots, storesList } = useAppSelector(
    (state) => state.requisition
  );  
  
  const { processingPickerMapping } = useAppSelector(
    (state) => state.ppd
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [hasErrorRows, setHasErrorRows] = useState(false);
  const [deliveryDate, setDate] = useState<string>();
  const [storeId, setStoreId] = useState<string>();

  useEffect(() => {
    if (
      processingPickerMapping &&
      processingPickerMapping.key
    ) {
      navigate(`/${routes.PPD.SAVE}`, {});
    }

    return () => {
      message.destroy();
    };
  }, [processingPickerMapping, navigate]);

  useEffect(() => {
    const failed = processingPickerMapping?.failedRows?.length;

    if (failed) {
      setHasErrorRows(true);
    }
  }, [processingPickerMapping?.failedRows]);

  const headers: { [name: string]: string } = {
    ...(authToken && { [AUTH_HEADER_NAME]: authToken }),
  };

  useEffect(() => {
    dispatch(fetchStoresList(""));
  }, []);

  const onUploaded = (data: IPickerMappingUpload) => {
    dispatch(setProcessingPickerMapping(data));
  };

  const onUploading = () => {
    dispatch(removeProcessingPickerMapping());
  };

  // useEffect(() => {
  //   return (() => {
  //     dispatch(removeProcessingPickerMapping())
  //   });
  // }, []);


  const { headerMapping } = processingPickerMapping;
  const headerMappingWithError = headerMapping + ",errors:Errors";

  const generateMemoizedHeaders = useMemo(
    () => generateHeaders(headerMappingWithError),
    [headerMappingWithError]
  );

  const tableHeaders = generateMemoizedHeaders || {};
  const successRows = processingPickerMapping?.successRows || [];
  const failedRows = processingPickerMapping?.failedRows || [];
  const successData = successRows?.map((row, i) => ({
    ...row,
    key: `data-success-${i}`,
  }));
  const failedData = failedRows?.map((row, i) => ({
    ...row,
    key: `data-failed-${i}`,
  }));
  const data = [...failedData, ...successData];
  const columns: ColumnsType<
    IPickerMappingUploadRow & { key: string }
  > = [
      ...Object.keys(tableHeaders).map((key) => ({
        key,
        title: tableHeaders[key],
        dataIndex: key,
        render: (
          text: string,
          record: IPickerMappingUploadRow & { key: string }
        ) => {
          if (key === "errors") {
            const errorMessages = record.errors
              ?.map((error) => error.message)
              .join(", ");
            return <>{errorMessages}</>;
          }
          return <>{text}</>;
        },
      })),
    ];

  const addBorderToErrorRows = (
    record: IPickerMappingUploadRow & { key: string }
  ) => {
    if (record.errors && record.errors.length) {
      return "error-row";
    }

    return "";
  };

  return (
    <>
      <Row>
        <Col>
          <AppPageHeader title="Upload Picker Packer Mapping" />
        </Col>
      </Row>
      <Row>
        <Form>
          <Form.Item label="Delivery date" name="date" required>
            <DatePicker
              onChange={(val) => setDate(val?.format("yyyy-MM-DD"))}
            />
          </Form.Item>
          {slots && (
            <Form.Item label="Store ID" name="storeId" required>
              <Select
              showSearch
              filterOption={(input, option) => {
                return option?.children?.toString().toLowerCase().includes(input?.toLowerCase() ?? "") ?? false;
              }}
              style={{ width: "200px" }}
              onChange={(e) => {
                setStoreId(e);
              }}
            >
              {(storesList ?? []).map((storeInfo) => (
                <Select.Option value={storeInfo.id} key={storeInfo.id}>
                  {storeInfo.name}
                </Select.Option>
              ))}
            </Select>
            </Form.Item>
          )}
        </Form>
      </Row>
      {(deliveryDate && storeId) && (
        <Row>
          <Col lg={16} xs={24}>
            <AppUpload
              uploadUrl={apis.uploadPickerMapping}
              headers={headers}
              onUploaded={onUploaded}
              onUploading={onUploading}
              hasErrorRows={hasErrorRows}
              data={{ deliveryDate: deliveryDate, storeId }}
            />
          </Col>
        </Row>
      )}
      {Object.keys(processingPickerMapping).length != 0 &&
        !processingPickerMapping.key && (
          <>
            <Row>
              <Col lg={16} xs={24}>
                <Alert
                  type="error"
                  message="Errors in sheet data."
                  description="Please correct errors and upload again."
                  showIcon
                  banner
                />
              </Col>
            </Row>

            <Row>
              <Table
                  size="small"
                  columns={columns}
                  dataSource={data}
                  rowClassName={(record) => addBorderToErrorRows(record)}
                  scroll={{ y: 500 }}
                  className="table-with-errors"
                ></Table>
            </Row>
          </>
        )}
    </>
  );
};
