import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API, { apis } from "common/apis";
import { IApiError } from "common/interface/IApiError";
import { logoutIfNeeded } from "common/utils";
import { IAdminGroups } from "../../common/interface/IAdminGroups";
import { IAdminUsersAPI } from "../../common/interface/IAdminUsers";
import { IPickerMappingUpload } from "common/interface/IPickerUploadScheme";
import { getPersistProcessingProjectedSr, getPersistProcessingSocietySequenceMapping, getPersistProcessingUserDayMapping, persistProcessingProjectedSr, persistProcessingSocietySequenceMapping, persistProcessingUserDayMapping, removePersistProcessingProjectedSr, removePersistProcessingSocietySequenceMapping, removePersistProcessingUserDayMapping } from "services/PersistService/PersistAdminServices";
import { IRequisitionUserMappingUpload, IStore } from "common/interface";
import { RootState } from "store/store";
import { config } from "process";
import { ClusterInfo, Society } from "common/interface/Society";
import { DelayRequest } from "pages/DelayRequetsPage/model/DelayRequest";
import { PpdSlots } from "pages/SocietyServices/PpdSlots";

export interface State {
    formData: Object,
    status: "loading" | "success" | "error" | "idle" | "cancelling" | "saving" | "updating";
    error?: string;
    processingProjectedSrMapping: IPickerMappingUpload;
    processingUserDayMapping: IPickerMappingUpload;
    processingSocietySequenceMapping: IPickerMappingUpload;
    adminUserGroups: IAdminGroups[];
    darkStores: IStore[];
    societies: Society[];
    ppdSlots: PpdSlots[];
    delayRequests: DelayRequest[];
    clusters: ClusterInfo[];
    adminUsers: IAdminUsersAPI;
    isSocietyActive?: boolean;
}

const initialState: State = {
    adminUserGroups: [],
    darkStores: [],
    clusters: [],
    societies: [],
    ppdSlots: [],
    delayRequests: [],
    adminUsers: {
        data: [],
        pageNo: 0,
        pageSize: 0,
        total: 0,
        pages: 0,
    },
    formData: {},
    status: "idle",
    processingProjectedSrMapping: getPersistProcessingProjectedSr(),
    processingUserDayMapping: getPersistProcessingUserDayMapping(),
    processingSocietySequenceMapping: getPersistProcessingSocietySequenceMapping(),
};

export const saveProcessingProjectedSr = createAsyncThunk<IRequisitionUserMappingUpload>("admin/projected-sr/upload/save", async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const { adminServices: { processingProjectedSrMapping: { key } } } = state;

    const response = await API.post(apis.saveProjectedSr, {}, {
        params: { key }
    })
        .then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
});

export const cancelProcessingProjectedSr = createAsyncThunk("admin/projected-sr/upload/cancel", async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const { adminServices: { processingProjectedSrMapping: { key } } } = state;

    const response = await API.post(apis.saveProjectedSr, {}, { //FixMe: Get Cancel API
        params: { key, cancel: 1 }
    })
        .then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
});

export const saveProcessingUserDayMapping = createAsyncThunk<IRequisitionUserMappingUpload>("admin/user-day/upload/save", async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const { adminServices: { processingUserDayMapping: { key } } } = state;

    const response = await API.post(apis.saveUserDayMapping, {}, {
        params: { key }
    })
        .then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
});

export const cancelProcessingUserDayMapping = createAsyncThunk("admin/user-day/upload/cancel", async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const { adminServices: { processingUserDayMapping: { key } } } = state;

    const response = await API.post(apis.saveProjectedSr, {}, { //FixMe: Get Cancel API
        params: { key, cancel: 1 }
    })
        .then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
});

export const saveProcessingSocietySequenceMapping = createAsyncThunk<IRequisitionUserMappingUpload>("admin/society/sequence/upload/save", async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const { adminServices: { processingSocietySequenceMapping: { key } } } = state;

    const response = await API.post(apis.saveSocietySequenceMapping, {}, {
        params: { key }
    })
        .then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
});

export const cancelProcessingSocietySequenceMapping = createAsyncThunk("admin/society/sequence/upload/cancel", async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const { adminServices: { processingSocietySequenceMapping: { key } } } = state;

    const response = await API.post(apis.saveSocietySequenceMapping, {}, {
        params: { key, cancel: 1 }
    })
        .then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
});

export const addNewUser = createAsyncThunk("/admin-services/add-admin", async (data: any, thunkApi) => {
    data["phone"] = data["phone"];
    data["groups"] = (data.groups ?? []).map((group: number) => {
        return {
            id: group
        }
    });
    data["mappedWh"] = (data.mappedWh ?? []).map((e: number) => ({ id: e }));

    const response = await API.post(apis.addNewAdmin, data)
        .then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
});

export const addNewSociety = createAsyncThunk("/admin-services/add-society", async (data: any, thunkApi) => {
    const response = await API.post(apis.addNewSociety, data)
        .then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
});

export const updateSociety = createAsyncThunk("/admin-services/update-society", async (data: any, thunkApi) => {
    let id = data["id"];
    
    const response = await API.put(apis.addNewSociety + '/' + id, data)
        .then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
});

export const toggleSocietyStatus = createAsyncThunk("admin-service/society-status", async ({id, status}: any, thunkApi) => {
    const response = await API.patch(`${apis.addNewSociety}/${id}/toggle-active?toggle=${status}`)
    .then(success => success.data)
    .catch(error => {
        const errorMessage = error?.response?.data?.message || "Something went wrong!";
        logoutIfNeeded(error, thunkApi);
        return thunkApi.rejectWithValue({ message: errorMessage });
    });
    return response;
});

export const updateUser = createAsyncThunk("/admin-services/update-admin", async (data: any, thunkApi) => {
    const id = data["id"];
    data["phone"] = "+91" + data["phone"];
    data["id"] = undefined;
    data["groups"] = (data.groups ?? []).map((group: number) => {
        return {
            id: group
        }
    });
    data["mappedWh"] = (data.mappedWh ?? []).map((e: number) => ({ id: e }));

    const response = await API.put(apis.getAdminUser + "/" + id, data)
        .then(success => success.data)
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
});

export const getAdminGroups = createAsyncThunk("/admin-services/get-admin-groups", async (_, thunkApi) => {
    const response = await API.get(apis.getAdminGroups)
        .then(success => success.data as IAdminGroups[])
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
})

export const getDarkStores = createAsyncThunk("/admin-services/get-dark-stores", async (_, thunkApi) => {
    const response = await API.get(apis.getDarkStore)
        .then(success => success.data as IStore[])
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
})

export const getClusterListing = createAsyncThunk("/admin-services/get-clusters", async (_, thunkApi) => {
    const response = await API.get(apis.getClusters)
        .then(success => success.data as ClusterInfo[])
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
})

export const getSocieties = createAsyncThunk<Society[], {filter: string}>("/admin-services/get-society", async ({filter}, thunkApi) => {
    
    const temp = Object.fromEntries(new URLSearchParams(filter));

    const filterParams = filter
        ? { ...temp }
        : "";
        const response = await API.get(apis.addNewSociety, {
            params: {
                ...filterParams,
                isOnlyActive: -1,
            },
        })
        .then(success => success.data as Society[])
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
})

export const getPpdSlots = createAsyncThunk<PpdSlots[]>("/admin-services/get-ppd-slots", async (_, thunkApi) => {

        const response = await API.get(apis.getPpdSlots, { 
        })
        .then(success => success.data as PpdSlots[])
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
})

export const getDelayRequests = createAsyncThunk<DelayRequest[]>("/societies/get-delay-requests", async (_, thunkApi) => {
    
    // const temp = Object.fromEntries(new URLSearchParams(filter));

    // const filterParams = filter
    //     ? { ...temp }
    //     : "";
        const response = await API.get(apis.getDelayRequests, {})
        .then(success => success.data as DelayRequest[])
        .catch(error => {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
})

export const updateDelayStatus = createAsyncThunk("/societies/update-delay-requests", async ({id, status}: {
    id: number;
    status: string;
  }, thunkApi) => {
    const response = await API.put(`${apis.updateDelayRequests}`, {
        "id":id,
        "status":status,
    },)
    .then(success => success.data)
    .catch(error => {
        const errorMessage = error?.response?.data?.message || "Something went wrong!";
        logoutIfNeeded(error, thunkApi);
        return thunkApi.rejectWithValue({ message: errorMessage });
    });
    return response;
});

export const getAdminUsers = createAsyncThunk<
    IAdminUsersAPI,
    { pageNo: number; pageSize: number, filter: string }
>("/admin-services/get-admin-users", async ({ pageNo = 1, pageSize = 25, filter }, thunkApi) => {

    const temp = Object.fromEntries(new URLSearchParams(filter));
    if (temp["phone"]) {
        temp["phone"] = temp['phone'];
    }
    const filterParams = filter
        ? { ...temp }
        : "";
    const response = await API.get(apis.getAdminUsers, {
        params: {
            pageNo,
            pageSize,
            ...filterParams,
        },
    })
        .then((success) => success.data)
        .catch((error) => {
            const errorMessage =
                error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
});

export const getAdminUserDetail = createAsyncThunk("/admin-services/get-admin-user-detail", async (id: string, thunkApi) => {
    const response = await API.get(apis.getAdminUser + "/" + id)
        .then((success) => success.data)
        .catch((error) => {
            const errorMessage =
                error?.response?.data?.message || "Something went wrong!";
            logoutIfNeeded(error, thunkApi);
            return thunkApi.rejectWithValue({ message: errorMessage });
        });

    return response;
});

const adminServicesSlice = createSlice({
    name: "admin-services",
    initialState,
    reducers: {
        setFormData: (state, action) => {
            state.status = "idle";
            state.error = "";
            state.formData = action.payload;
        },
        resetForm: (state) => {
            state.formData = {};
            state.status = "idle";
            state.error = "";
        },
        setProcessingProjectedSr: (state, action) => {
            state.status = "idle";
            state.error = "";
            state.processingProjectedSrMapping = action.payload;
            state.processingProjectedSrMapping.timeUploaded = new Date().toString();
            persistProcessingProjectedSr(state.processingProjectedSrMapping);
        },
        removeProcessingProjectedSr: (state) => {
            state.processingProjectedSrMapping = {};
            state.status = "idle";
            state.error = "";
            removePersistProcessingProjectedSr();
        },
        setProcessingUserDay: (state, action) => {
            state.status = "idle";
            state.error = "";
            state.processingUserDayMapping = action.payload;
            state.processingUserDayMapping.timeUploaded = new Date().toString();
            persistProcessingUserDayMapping(state.processingProjectedSrMapping);
        },
        removeProcessingUserDay: (state) => {
            state.processingUserDayMapping = {};
            state.status = "idle";
            state.error = "";
            removePersistProcessingUserDayMapping();
        },
        setProcessingSocietySequenceDay: (state, action) => {
            state.status = "idle";
            state.error = "";
            state.processingSocietySequenceMapping = action.payload;
            state.processingUserDayMapping.timeUploaded = new Date().toString();
            persistProcessingSocietySequenceMapping(state.processingProjectedSrMapping);
        },
        removeProcessingSocietySequenceDay: (state) => {
            state.processingSocietySequenceMapping = {};
            state.status = "idle";
            state.error = "";
            removePersistProcessingSocietySequenceMapping();
        },
    },
    extraReducers(builder) {
        builder

            /**
            * CREATE NEW ADMIN
            **/
            .addCase(addNewUser.pending, state => {
                state.status = "saving";
            })
            .addCase(addNewUser.fulfilled, state => {
                state.status = "success";
                state.error = "";
                state.formData = {};
            })
            .addCase(addNewUser.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";

                if (error.message) {
                    state.error = error.message;
                }
            })
            
            /**
            * CREATE NEW SOCIETY
            **/
            .addCase(addNewSociety.pending, state => {
                state.status = "saving";
            })
            .addCase(addNewSociety.fulfilled, state => {
                state.status = "success";
                state.error = "";
                state.formData = {};
            })
            .addCase(addNewSociety.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";
                if (error.message) {
                    state.error = error.message;
                }
            })
            
            
            /**
            * UPDATE SOCIETY
            **/
            .addCase(updateSociety.pending, state => {
                state.status = "saving";
            })
            .addCase(updateSociety.fulfilled, state => {
                state.status = "success";
                state.error = "";
                state.formData = {};
            })
            .addCase(updateSociety.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";
                if (error.message) {
                    state.error = error.message;
                }
            })

            /*
            * TOGGLE SOCIETY STATUS
            */
            .addCase(toggleSocietyStatus.pending, state => {
                state.status = "saving";
            })
            .addCase(toggleSocietyStatus.fulfilled, (state, action) => {
                state.status = "idle";
                state.error = "";
                let targetIndex = state.societies.findIndex(society => society.id === action.payload.id);
                if (targetIndex !== -1) {
                    state.societies[targetIndex].active = action.payload["active"];
                }
            })
            .addCase(toggleSocietyStatus.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";

                if (error.message) {
                    state.error = error.message;
                }
            })
            
            /*
            * TOGGLE DELAY REQUEST
            */
            .addCase(updateDelayStatus.pending, state => {                
                state.status = "updating";
            })
            .addCase(updateDelayStatus.fulfilled, (state, action) => {
                state.status = "success";
                state.error = "";
                let targetIndex = state.delayRequests.findIndex(req => req.id === action.meta.arg.id);
                if (targetIndex !== -1) {
                    state.delayRequests[targetIndex].status = action.meta.arg.status;
                }
            })
            .addCase(updateDelayStatus.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";

                if (error.message) {
                    state.error = error.message;
                }
            })

            /**
             * GET SOCIETIES
             **/
            .addCase(getSocieties.pending, state => {
                state.status = "loading";
            })
            .addCase(getSocieties.fulfilled, (state, action) => {
                state.status = "idle";
                state.error = "";
                state.societies = action.payload;
            })
            .addCase(getSocieties.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";

                if (error.message) {
                    state.error = error.message;
                }
            })
            
            /**
             * GET PPD SLOTS
             **/
            .addCase(getPpdSlots.pending, state => {
                state.status = "loading";
            })
            .addCase(getPpdSlots.fulfilled, (state, action) => {
                state.status = "idle";
                state.error = "";
                state.ppdSlots = action.payload;
            })
            .addCase(getPpdSlots.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";

                if (error.message) {
                    state.error = error.message;
                }
            })
            
            /**
             * GET DELAY REQUESTS
             **/
            .addCase(getDelayRequests.pending, state => {
                state.status = "loading";
            })
            .addCase(getDelayRequests.fulfilled, (state, action) => {
                state.status = "idle";
                state.error = "";
                state.delayRequests = action.payload;
            })
            .addCase(getDelayRequests.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";

                if (error.message) {
                    state.error = error.message;
                }
            })
            
            /**
             * GET CLUSTERS
             **/
            .addCase(getClusterListing.pending, state => {
                state.status = "loading";
            })
            .addCase(getClusterListing.fulfilled, (state, action) => {
                state.status = "idle";
                state.error = "";
                state.clusters = action.payload;
            })
            .addCase(getClusterListing.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";

                if (error.message) {
                    state.error = error.message;
                }
            })

            /**
             * GET ADMIN USERS
             **/
            .addCase(getAdminUsers.pending, state => {
                state.status = "loading";
            })
            .addCase(getAdminUsers.fulfilled, (state, action) => {
                state.status = "idle";
                state.error = "";
                state.adminUsers = action.payload;
            })
            .addCase(getAdminUsers.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";

                if (error.message) {
                    state.error = error.message;
                }
            })
            /**
             * GET ADMIN USER DETAIL
             **/
            .addCase(getAdminUserDetail.pending, state => {
                state.status = "loading";
            })
            .addCase(getAdminUserDetail.fulfilled, (state, action) => {
                state.status = "idle";
                state.error = "";
            })
            .addCase(getAdminUserDetail.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";

                if (error.message) {
                    state.error = error.message;
                }
            })

            /**
             * UPDATE ADMIN USER DETAIL
             **/
            .addCase(updateUser.pending, state => {
                state.status = "saving";
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                state.status = "success";
                state.error = "";
            })
            .addCase(updateUser.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";

                if (error.message) {
                    state.error = error.message;
                }
            })

            /**
             * GET ADMIN GROUPS
             **/
            .addCase(getAdminGroups.pending, state => {
                state.status = "loading";
            })
            .addCase(getAdminGroups.fulfilled, (state, action) => {
                state.status = "idle";
                state.error = "";
                state.adminUserGroups = action.payload;
            })
            .addCase(getAdminGroups.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";

                if (error.message) {
                    state.error = error.message;
                }
            })
            /**
             * GET DARK STORES
            **/
            .addCase(getDarkStores.pending, state => {
                state.status = "loading";
            })
            .addCase(getDarkStores.fulfilled, (state, action) => {
                state.status = "idle";
                state.error = "";
                state.darkStores = action.payload;
            })
            .addCase(getDarkStores.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";
                if (error.message) {
                    state.error = error.message;
                }
            })
            /**
            * SAVE PROJECTED SR
            **/
            .addCase(saveProcessingProjectedSr.pending, state => {
                state.status = "loading";
            })
            .addCase(saveProcessingProjectedSr.fulfilled, state => {
                state.status = "success";
                state.error = "";
                state.processingProjectedSrMapping = {};
                removeProcessingProjectedSr();
            })
            .addCase(saveProcessingProjectedSr.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";

                if (error.message) {
                    state.error = error.message;
                }
            })

            /**
             * CANCEL PROJECTED SR
            **/
            .addCase(cancelProcessingProjectedSr.pending, state => {
                state.status = "cancelling";
            })
            .addCase(cancelProcessingProjectedSr.fulfilled, state => {
                state.status = "idle";
                state.error = "";
                state.processingProjectedSrMapping = {};
                removeProcessingProjectedSr();
            })
            .addCase(cancelProcessingProjectedSr.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";
                if (error.message) {
                    state.error = error.message;
                }
            })

            /**
            * SAVE USER DAY MAPPING
            **/
            .addCase(saveProcessingUserDayMapping.pending, state => {
                state.status = "loading";
            })
            .addCase(saveProcessingUserDayMapping.fulfilled, state => {
                state.status = "success";
                state.error = "";
                state.processingUserDayMapping = {};
                removePersistProcessingUserDayMapping();
            })
            .addCase(saveProcessingUserDayMapping.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";

                if (error.message) {
                    state.error = error.message;
                }
            })

            /**
             * CANCEL USER DAY MAPPING
            **/
            .addCase(cancelProcessingUserDayMapping.pending, state => {
                state.status = "cancelling";
            })
            .addCase(cancelProcessingUserDayMapping.fulfilled, state => {
                state.status = "idle";
                state.error = "";
                state.processingUserDayMapping = {};
                removePersistProcessingUserDayMapping();
            })
            .addCase(cancelProcessingUserDayMapping.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";
                if (error.message) {
                    state.error = error.message;
                }
            })


            /**
            * SAVE SOCIETY SEQUENCE MAPPING
            **/
            .addCase(saveProcessingSocietySequenceMapping.pending, state => {
                state.status = "loading";
            })
            .addCase(saveProcessingSocietySequenceMapping.fulfilled, state => {
                state.status = "success";
                state.error = "";
                state.processingSocietySequenceMapping = {};
                removeProcessingSocietySequenceDay();
            })
            .addCase(saveProcessingSocietySequenceMapping.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";

                if (error.message) {
                    state.error = error.message;
                }
            })

            /**
             * CANCEL SOCIETY SEQUENCE MAPPING
            **/
            .addCase(cancelProcessingSocietySequenceMapping.pending, state => {
                state.status = "cancelling";
            })
            .addCase(cancelProcessingSocietySequenceMapping.fulfilled, state => {
                state.status = "idle";
                state.error = "";
                state.processingSocietySequenceMapping = {};
                removeProcessingSocietySequenceDay();
            })
            .addCase(cancelProcessingSocietySequenceMapping.rejected, (state, action) => {
                const error = action.payload as IApiError;
                state.status = "error";
                if (error.message) {
                    state.error = error.message;
                }
            })


    }
});

export const {
    setFormData,
    setProcessingProjectedSr,
    removeProcessingProjectedSr,
    setProcessingUserDay,
    removeProcessingUserDay,
    setProcessingSocietySequenceDay,
    removeProcessingSocietySequenceDay,
    resetForm
} = adminServicesSlice.actions;
export default adminServicesSlice.reducer; 