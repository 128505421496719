import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Dropdown, Menu, Space, Typography } from "antd";
import { variables } from "common/variables";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { logout } from "store/app/appSlice";

const { Text } = Typography;

export const AppUserInfoMenu = () => {
  const { user } = useAppSelector((state) => state.app.user);
  const dispatch = useAppDispatch();

  const onLogout = () => {
    dispatch(logout());
  };

  const menu = (
    <Menu
      items={[
        {
          label: (
            <Space direction="vertical">
              <Text>
                {user?.firstname} {user?.lastname}
              </Text>
              <Text type="secondary" italic style={{}}>
                {user?.email}
              </Text>
            </Space>
          ),
          key: "0",
        },
        { type: "divider" },
        {
          label: (
            <Button
              style={{ width: "100%" }}
              icon={<LogoutOutlined />}
              onClick={onLogout}
            >
              Logout
            </Button>
          ),
          key: "2",
        },
      ]}
    />
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <Avatar
        style={{
          backgroundColor: variables?.secondaryColor,
          cursor: "pointer",
        }}
      >
        <UserOutlined style={{ color: variables?.primaryColor }} />
      </Avatar>
    </Dropdown>
  );
};
