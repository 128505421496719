import { IPickerMappingUpload } from "common/interface/IPickerUploadScheme";

const STORAGE_KEY_STORE_RETURN = "processingProjectedSr";
const STORAGE_KEY_USER_DAY_MAPPING = "processingUserDayMapping";
const STORAGE_KEY_SOCIETY_SEQUENCE_MAPPING = "processingSocietySequenceMapping";

export const persistProcessingProjectedSr = (data: IPickerMappingUpload) => {
    localStorage.setItem(STORAGE_KEY_STORE_RETURN, JSON.stringify(data));
}

export const getPersistProcessingProjectedSr = (): IPickerMappingUpload => {
    const processingWhReturnMapping = localStorage.getItem(STORAGE_KEY_STORE_RETURN);
    return processingWhReturnMapping ? JSON.parse(processingWhReturnMapping) : {};
}

export const removePersistProcessingProjectedSr = () => {
    localStorage.removeItem(STORAGE_KEY_STORE_RETURN);
}

export const persistProcessingUserDayMapping = (data: IPickerMappingUpload) => {
    localStorage.setItem(STORAGE_KEY_USER_DAY_MAPPING, JSON.stringify(data));
}

export const getPersistProcessingUserDayMapping = (): IPickerMappingUpload => {
    const processingWhReturnMapping = localStorage.getItem(STORAGE_KEY_USER_DAY_MAPPING);
    return processingWhReturnMapping ? JSON.parse(processingWhReturnMapping) : {};
}

export const removePersistProcessingUserDayMapping = () => {
    localStorage.removeItem(STORAGE_KEY_USER_DAY_MAPPING);
}

export const persistProcessingSocietySequenceMapping = (data: IPickerMappingUpload) => {
    localStorage.setItem(STORAGE_KEY_SOCIETY_SEQUENCE_MAPPING, JSON.stringify(data));
}

export const getPersistProcessingSocietySequenceMapping = (): IPickerMappingUpload => {
    const processingWhReturnMapping = localStorage.getItem(STORAGE_KEY_SOCIETY_SEQUENCE_MAPPING);
    return processingWhReturnMapping ? JSON.parse(processingWhReturnMapping) : {};
}

export const removePersistProcessingSocietySequenceMapping = () => {
    localStorage.removeItem(STORAGE_KEY_SOCIETY_SEQUENCE_MAPPING);
}