import { Button, Col, DatePicker, Form, Input, Row } from "antd";
import moment from "moment";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

const StyledFilterContainer = styled.div`
  &,
  & .filter {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    &__item {
      margin: 0.5rem;

      & .ant-form-item {
        margin: 0;
      }

      &--submit {
        align-self: flex-end;
      }
    }
  }
`;

export const AppQualityCheck = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [form] = Form.useForm();

  useEffect(() => {
    const params = Object.fromEntries(new URLSearchParams(searchParams));
    form.setFieldsValue(params);
  }, [form, searchParams]);

  const onSearch = (values: Record<string, string>) => {
    const params: Record<string, string> = {};
    Object.entries(values).forEach(([key, value]) => {
      if (value) {
        params[key] =
          key === "date"
            ? (value as unknown as moment.Moment).format("YYYY-MM-DD")
            : value;
      }
    });
    setSearchParams(params);
  };

  return (
    <StyledFilterContainer>
      <Form
        form={form}
        name="vendor-filter-form"
        onFinish={onSearch}
        layout="vertical"
        style={{ width: "100%" }}
      >
        <Row className="filter">
          <Col xs={20} sm={4} md={5} lg={4} className="filter__item">
            <Form.Item name="date" label="Date" valuePropName={'date'}>
              <DatePicker
                format={"YYYY-MM-DD"}
                onChange={(val) => {
                  form.setFieldsValue({ "date": val });
                }}
                disabledDate={(current) => current && current > moment(moment(), "YYYY-MM-DD")}
                placeholder="Search by delivery date" size="small" allowClear
              />
            </Form.Item>
          </Col>

          {/* <Col xs={20} sm={4} md={5} lg={4} className="filter__item">
            <Form.Item name="status" label="Status">
              <Input placeholder="Search by Status" size="small" allowClear />
            </Form.Item>
          </Col> */}

          <Col
            xs={20}
            sm={4}
            md={2}
            lg={2}
            className="filter__item filter__item--submit"
          >
            <Form.Item>
              <Button type="primary" htmlType="submit" size="small">
                Search
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </StyledFilterContainer>
  );
};
