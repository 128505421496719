import {
    Button,
    Col, message,
    Row,
} from "antd";
import {apis, AUTH_HEADER_NAME} from "common/apis";

import {AppPageHeader} from "components/AppPageHeader";
import {useAppSelector} from "hooks/useAppSelector";
import {useDocumentTitle} from "hooks/usePageTitle";
import React, {useState, useEffect, useMemo} from "react";
import { cronRemapUsers } from "store/ppd/ppdSlice";
import API from "../../common/apis";
import {useAppDispatch} from "../../hooks/useAppDispatch";

const MESSAGE_KEY = "CRON_REMAP_USERS";
export const CronRemapUsersPage = () => {
    useDocumentTitle("Cron : Remap-Users");
    const {authToken} = useAppSelector((state) => state.app.user);
    const headers: { [name: string]: string } = {
        ...(authToken && {[AUTH_HEADER_NAME]: authToken}),
    };
    const {status, error} = useAppSelector(
        (state) => state.requisition
    );
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (status === "loading") {
            message.loading({content: "Running Cron Remap-Users", key: MESSAGE_KEY});
        } else if (status === "success") {
            message.success({
                content: "Remap Users Cron completed!",
                duration: 5,
                key: MESSAGE_KEY,
            });
        } else if (status === "error") {
            message.error({content: error, key: MESSAGE_KEY});
        }
    }, [status, error]);

    const handleButtonClick = async () => {
        dispatch(cronRemapUsers(headers));
    }

    return (
        <>
            <Row>
                <Col>
                    <AppPageHeader title="Cron User Remapping"/>
                </Col>
            </Row>
            <Row>
                <Button
                    type="primary"
                    onClick={handleButtonClick}
                    disabled={status === "loading"}
                    loading={status === "loading"}
                    >
                    Click here to Remap Users
                </Button>
            </Row>
        </>
    );
};
