import {
    Row,
    Col,
    Typography,
    Table,
    Button,
    message,
  } from "antd";
  import { routes } from "common/routes";
  import { generateHeaders, getSlotName } from "common/utils";
  import { AppPageHeader } from "components/AppPageHeader";
  import { useAppDispatch } from "hooks/useAppDispatch";
  import { useAppSelector } from "hooks/useAppSelector";
  import { useDocumentTitle } from "hooks/usePageTitle";
  import { useEffect, useMemo } from "react";
  import { useNavigate } from "react-router-dom";
import { cancelCategoryPricing, cancelFranchiseMapping, removeProcessingCategoryPricing, removeProcessingFranchiseMapping, saveCategoryPricing, saveFranchiseMapping } from "store/franchise/franchiseSlice";
  
  const MESSAGE_KEY = "SAVE_CATAGORY_PRICING_UPLOAD";
  
  export const CatagoryPricingSavePage = () => {
    useDocumentTitle("Save Category Pricing");
    const { processingCategoryPricing, status, error } = useAppSelector(
      (state) => state.franchiseServices
    );
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
  
    useEffect(() => {
      const { timeUploaded } = processingCategoryPricing;
      if (timeUploaded) {
        const uploadedTime = new Date(timeUploaded).getTime();
        const currentTime = new Date().getTime();
        const minsPassed = (currentTime - uploadedTime) / 1000 / 60;
  
        if (minsPassed >= 15) {
          dispatch(removeProcessingCategoryPricing());
        }
      }
  
      if (!Object.keys(processingCategoryPricing).length) { 
        navigate(`/${routes.FRANCHISE.UPLOAD_CATEGORY_PRICING}`);
      }
    }, [dispatch, processingCategoryPricing, navigate]);

    useEffect(() => {
      if (status === "loading") {
        message.loading({ content: "saving upload", key: MESSAGE_KEY });
      } else if (status === "success") {
        message.success({
          content: "Category Pricing saved!",
          duration: 5,
          key: MESSAGE_KEY,
        });
      } else if (status === "cancelling") {
        message.loading({ content: "cancelling upload", key: MESSAGE_KEY });
      } else if (status === "error") {
        message.error({ content: error, key: MESSAGE_KEY });
      }
  
      return () => {
        message.destroy();
      };
    }, [status, error]);
  
    let content;
    const { headerMapping } = processingCategoryPricing;
    const { Text } = Typography;
  
    const generateMemoizedHeaders = useMemo(
      () => generateHeaders(headerMapping),
      [headerMapping]
    );
  
    const onSave = () => {
      dispatch(saveCategoryPricing());
    };
  
    const onCancelSave = () => {
      dispatch(cancelCategoryPricing());
    };
  
    if ((processingCategoryPricing.success) === 0) {
      content = <Text>Nothing to show here!</Text>;
    } else {
      const headers = generateMemoizedHeaders || {};
      const successRows = processingCategoryPricing.successRows;
      const data = successRows?.map((row, i) => ({ ...row, key: `data-${i}` }));
      const columns = [
        ...Object.keys(headers).map(
          (key) =>
            ({
              key,
              title: headers[key],
              dataIndex: key,
            } as any)
        ),
      ];
      content = (
        <Table
          size="small"
          columns={columns}
          dataSource={data}
          scroll={{ y: 500, x: "100vh" }}
          summary={() => (
            <Table.Summary fixed="bottom">
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={24}>
                  <Row>
                    <Col>
                      <Button
                        type="primary"
                        onClick={onSave}
                        loading={status === "loading"}
                        disabled={status === "loading"}
                      >
                        Save
                      </Button>
                    </Col>
                    <Col style={{ justifyContent: "left", marginLeft: "20px" }}>
                      <Button
                        onClick={onCancelSave}
                        disabled={status === "loading"}
                      >
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
        ></Table>
      );
    }
  
    return (
      <>
        <Row>
          <Col>
            <AppPageHeader title="Save Picker Packer Mapping" />
          </Col>
        </Row>
        <Row>
          <Col xs={24}>{content}</Col>
        </Row>
      </>
    );
  };
  