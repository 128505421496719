import {
    Alert,
    Col,
    DatePicker,
    Form,
    Input,
    message,
    Row,
    Select,
    Table,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import { apis, AUTH_HEADER_NAME } from "common/apis";
import { IPickerMappingUpload, IPickerMappingUploadRow } from "common/interface/IPickerUploadScheme";
import { routes } from "common/routes";
import { generateHeaders } from "common/utils";
import { AppPageHeader } from "components/AppPageHeader";
import { AppUpload } from "components/AppUpload";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useDocumentTitle } from "hooks/usePageTitle";
import { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { getDarkStores } from "store/admin-slice/adminServicesSlice";
import { removeProcessingConsumerUpload, setProcessingConsumerUpload } from "store/consumer-services/cosumerServicesSlice";

export const UploadConsumerClusterPage = () => {
    useDocumentTitle("Upload Consumer Cluster");
    const { authToken } = useAppSelector((state) => state.app.user);
    const { darkStores } = useAppSelector(
        (state) => state.adminServices
    );

    const { processingConsumerClusterUpload: processingConsumerUpload } = useAppSelector(
        (state) => state.consumerServices,
    );
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [hasErrorRows, setHasErrorRows] = useState(false);
    const [storeId, setStoreId] = useState<string>();

    useEffect(() => { 
        dispatch(getDarkStores());
    }, []);

    useEffect(() => {
        if (
            processingConsumerUpload &&
            processingConsumerUpload.key
        ) {
            navigate(`/${routes.CONSUMER_SERVICE.SAVE_CLUSTER_MAPPING}`, {});
        }

        return () => {
            message.destroy();
        };
    }, [processingConsumerUpload, navigate]);

    useEffect(() => {
        const failed = processingConsumerUpload?.failedRows?.length;

        if (failed) {
            setHasErrorRows(true);
        }
    }, [processingConsumerUpload?.failedRows]);

    const headers: { [name: string]: string } = {
        ...(authToken && { [AUTH_HEADER_NAME]: authToken }),
    };

    const onUploaded = (data: IPickerMappingUpload) => {
        dispatch(setProcessingConsumerUpload(data));
    };

    const onUploading = () => {
        dispatch(removeProcessingConsumerUpload());
    };

    const { headerMapping } = processingConsumerUpload;
    const headerMappingWithError = headerMapping + ",errors:Errors";

    const generateMemoizedHeaders = useMemo(
        () => generateHeaders(headerMappingWithError),
        [headerMappingWithError]
    );

    const tableHeaders = generateMemoizedHeaders || {};
    const successRows = processingConsumerUpload?.successRows || [];
    const failedRows = processingConsumerUpload?.failedRows || [];
    const successData = successRows?.map((row, i) => ({
        ...row,
        key: `data-success-${i}`,
    }));
    const failedData = failedRows?.map((row, i) => ({
        ...row,
        key: `data-failed-${i}`,
    }));
    const data = [...failedData, ...successData];
    const columns: ColumnsType<
        IPickerMappingUploadRow & { key: string }
    > = [
            ...Object.keys(tableHeaders).map((key) => ({
                key,
                title: tableHeaders[key],
                dataIndex: key,
                render: (
                    text: string,
                    record: IPickerMappingUploadRow & { key: string }
                ) => {
                    if (key === "errors") {
                        const errorMessages = record.errors
                            ?.map((error) => error.message)
                            .join(", ");
                        return <>{errorMessages}</>;
                    }
                    return <>{text}</>;
                },
            })),
        ];

    const addBorderToErrorRows = (
        record: IPickerMappingUploadRow & { key: string }
    ) => {
        if (record.errors && record.errors.length) {
            return "error-row";
        }

        return "";
    };

    return (
        <>
            <Row>
                <Col>
                    <AppPageHeader title="Upload Consumer Cluster" />
                </Col>
            </Row>
            <Row>
                <>
                    <Form>
                        <Form.Item label="Store ID" name="storeId" required>

                            <Select
                                showSearch
                                filterOption={(input, option) => {
                                    return option?.children?.toString().toLowerCase().includes(input?.toLowerCase() ?? "") ?? false;
                                }}
                                style={{ width: "200px" }}
                                onChange={(e) => {
                                    setStoreId(e);
                                }}

                            >
                                {(darkStores ?? []).map((storeInfo) => (
                                    <Select.Option value={storeInfo.id} key={storeInfo.id}>
                                        {storeInfo.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Form>
                </>
            </Row>
            {(storeId) && (
                <Row>
                    <Col lg={16} xs={24}>
                        <AppUpload
                            uploadUrl={apis.uploadConsumerClusterSheet}
                            headers={headers}
                            onUploaded={onUploaded}
                            onUploading={onUploading}
                            hasErrorRows={hasErrorRows}
                            data={{ storeId: storeId }}
                        />
                    </Col>
                </Row>
            )}
            {Object.keys(processingConsumerUpload).length != 0 &&
                !processingConsumerUpload.key && (
                    <>
                        <Row>
                            <Col lg={16} xs={24}>
                                <Alert
                                    type="error"
                                    message="Errors in sheet data."
                                    description="Please correct errors and upload again."
                                    showIcon
                                    banner
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Table
                                size="small"
                                columns={columns}
                                dataSource={data}
                                rowClassName={(record) => addBorderToErrorRows(record)}
                                scroll={{ y: 500 }}
                                className="table-with-errors"
                            ></Table>
                        </Row>
                    </>
                )}
        </>
    );
};
