import { PropsWithChildren } from "react";
import styled from "styled-components";
import { variables } from "common/variables";

interface FilterProps {
  expanded: boolean;
}

const StyledFilter = styled.div`
  border: 1px solid ${variables.gray};
  display: flex;
  padding: 0.5rem;
  width: 100%;
  border-radius: ${variables.borderRadius};
`;

const StyledFilterLabel = styled.div`
  color: ${variables.darkGray};
  font-size: 12px;
  font-weight: 500;
`;

export const AppFilter = (props: PropsWithChildren<any> & FilterProps) =>
  props.expanded ? (
    <>
      <StyledFilterLabel>Filter Results</StyledFilterLabel>
      <StyledFilter>{props.children}</StyledFilter>
    </>
  ) : (
    <></>
  );
