import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API, { apis } from "common/apis";
import { INaPickingUserMappingUpload } from "common/interface"
import { IApiError } from "common/interface/IApiError";
import { logoutIfNeeded } from "common/utils";
import { getPersistedClusterAmMapping, getPersistedClusterStoreMapping, persistClusterAmMapping, persistClusterStoreMapping, removePersistedClusterAmMapping, removePersistedClusterStoreMapping } from "services/PersistService/PersistClusterServices";
import { RootState } from "store/store";

export interface State {
  processingClusterStoreMapping: INaPickingUserMappingUpload,
  processingClusterAmMapping: INaPickingUserMappingUpload,
  status: "loading" | "success" | "error" | "idle" | "cancelling",
  error?: string
}

const initialState: State = {
  processingClusterStoreMapping: getPersistedClusterStoreMapping(),
  processingClusterAmMapping: getPersistedClusterAmMapping(),
  status: "idle"
};

export const saveClusterStoreMapping = createAsyncThunk<INaPickingUserMappingUpload>("cluster-services/cluster-store-mapping/save", async (_, thunkApi) => {
  const state = thunkApi.getState() as RootState;
  const { clusterServices: { processingClusterStoreMapping: { key } } } = state;

  const response = await API.post(apis.saveClusterStoreMapping, {}, {
    params: { key }
  }).then(success => success.data)
    .catch(error => {
      const errorMessage = error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

export const cancelClusterStoreMapping = createAsyncThunk("cluster-services/cluster-store-mapping/cancel", async (_, thunkApi) => {
  const state = thunkApi.getState() as RootState;
  const { clusterServices: { processingClusterStoreMapping: { key } } } = state;

  const response = await API.post(apis.saveClusterStoreMapping, {}, {
    params: { key, cancel: 1 }
  })
    .then(success => success.data)
    .catch(error => {
      const errorMessage = error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

export const saveClusterAmMapping = createAsyncThunk<INaPickingUserMappingUpload>("cluster-services/cluster-am-mapping/save", async (_, thunkApi) => {
  const state = thunkApi.getState() as RootState;
  const { clusterServices: { processingClusterAmMapping: { key } } } = state;

  const response = await API.post(apis.saveClusterAmMapping, {}, {
    params: { key }
  }).then(success => success.data)
    .catch(error => {
      const errorMessage = error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

export const cancelClusterAmMapping = createAsyncThunk("cluster-services/cluster-am-mapping/cancel", async (_, thunkApi) => {
  const state = thunkApi.getState() as RootState;
  const { clusterServices: { processingClusterAmMapping: { key } } } = state;

  const response = await API.post(apis.saveClusterAmMapping, {}, {
    params: { key, cancel: 1 }
  })
    .then(success => success.data)
    .catch(error => {
      const errorMessage = error?.response?.data?.message || "Something went wrong!";
      logoutIfNeeded(error, thunkApi);
      return thunkApi.rejectWithValue({ message: errorMessage });
    });

  return response;
});

const clusterServicesSlice = createSlice({
  name: "cluster-services",
  initialState,
  reducers: {
    setProcessingClusterStoreMapping: (state, action) => {
      state.status = "idle";
      state.error = "";
      state.processingClusterStoreMapping = action.payload;
      state.processingClusterStoreMapping.timeUploaded = new Date().toString();
      persistClusterStoreMapping(state.processingClusterStoreMapping);
    },
    removeProcessingClusterStoreMapping: (state) => {
      state.processingClusterStoreMapping = {};
      state.status = "idle";
      state.error = "";
      removePersistedClusterStoreMapping();
    },
    setProcessingClusterAmMapping: (state, action) => {
      state.status = "idle";
      state.error = "";
      state.processingClusterAmMapping = action.payload;
      state.processingClusterStoreMapping.timeUploaded = new Date().toString();
      persistClusterAmMapping(state.processingClusterAmMapping);
    },
    removeProcessingClusterAmMapping: (state) => {
      state.processingClusterAmMapping = {};
      state.status = "idle";
      state.error = "";
      removePersistedClusterAmMapping();
    },
  },
  extraReducers(builder) {
    builder
      /**
       * SAVE CLUSTER STORE MAPPING
       */
      .addCase(saveClusterStoreMapping.pending, state => {
        state.status = "loading";
      })
      .addCase(saveClusterStoreMapping.fulfilled, state => {
        state.status = "success";
        state.error = "";
        state.processingClusterStoreMapping = {};
        removePersistedClusterStoreMapping();
      })
      .addCase(saveClusterStoreMapping.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })

      /**
       * CANCEL CLUSTER STORE MAPPING
       */
      .addCase(cancelClusterStoreMapping.pending, state => {
        state.status = "cancelling";
      })
      .addCase(cancelClusterStoreMapping.fulfilled, state => {
        state.status = "idle";
        state.error = "";
        state.processingClusterStoreMapping = {};
        removePersistedClusterStoreMapping();
      })
      .addCase(cancelClusterStoreMapping.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })
      
      /**
       * SAVE CLUSTER STORE MAPPING
       */
      .addCase(saveClusterAmMapping.pending, state => {
        state.status = "loading";
      })
      .addCase(saveClusterAmMapping.fulfilled, state => {
        state.status = "success";
        state.error = "";
        state.processingClusterAmMapping = {};
        removePersistedClusterAmMapping();
      })
      .addCase(saveClusterAmMapping.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })

      /**
       * CANCEL CLUSTER STORE MAPPING
       */
      .addCase(cancelClusterAmMapping.pending, state => {
        state.status = "cancelling";
      })
      .addCase(cancelClusterAmMapping.fulfilled, state => {
        state.status = "idle";
        state.error = "";
        state.processingClusterAmMapping = {};
        removePersistedClusterAmMapping();
      })
      .addCase(cancelClusterAmMapping.rejected, (state, action) => {
        const error = action.payload as IApiError;
        state.status = "error";

        if (error.message) {
          state.error = error.message;
        }
      })


  }
});

export const {setProcessingClusterStoreMapping, removeProcessingClusterStoreMapping, setProcessingClusterAmMapping, removeProcessingClusterAmMapping,} = clusterServicesSlice.actions;
export default clusterServicesSlice.reducer;