import { Row, Col, message, Button, Table, Pagination, Switch } from "antd";
import { ColumnsType } from "antd/lib/table";
import { IVendor } from "common/interface/IVendor";
import { AppPageHeader } from "components/AppPageHeader";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useDocumentTitle } from "hooks/usePageTitle";
import { useCallback, useEffect, useState } from "react";
import { BsPlus, BsTelephone, BsWhatsapp } from "react-icons/bs";
import { MdOutlineEmail } from "react-icons/md";
import { FiEdit2 } from "react-icons/fi";
import { getAllVendors, updateVendorStatus } from "store/vendor/vendorSlice";
import styled from "styled-components";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { AppActionStrip } from "components/AppActionStrip/AppActionStrip";
import {
  AppFilter,
  AppFilterButton,
  AppVendorFilter,
} from "components/AppFilter";

type PartialVendorType = Partial<IVendor> & { key: string };
const MESSAGE_KEY = "all_vendors";

const headerKeys: { [key: string]: string } = {
  id: "Vendor Code",
  extVendorId: "Ext Vendor ID",
  name: "Name",
  pocName: "POC Name",
  address: "Address",
  contactNumber: "Contact Number",
  supplyLocation: "Supply Location",
  status: "Status",
  actions: "Actions",
};

const StyledContactItem = styled.div`
  display: flex;
  align-items: center;

  & > a {
    margin-left: 1rem;
  }
`;

const StyledPagination = styled(Pagination)`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

const StyledActionLink = styled(NavLink)`
  border: 1px solid;
  padding: 0.2rem;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const VendorHomePage = () => {
  useDocumentTitle("All Vendors");
  const dispatch = useAppDispatch();
  const [columns, setColumns] = useState<ColumnsType<PartialVendorType>>();
  const [rows, setRows] = useState<PartialVendorType[]>();
  const { vendors, status, error } = useAppSelector((state) => state.vendors);
  const [filterState, setFilterState] = useState(false);
  const [searchParams] = useSearchParams();
  const [filterCount, setFilterCount] = useState(0);
  const navigate = useNavigate();

  const getVendorData = useCallback(
    (pageNum = 1, pageSize = 25, filter = "") => {
      dispatch(getAllVendors({ pageNo: pageNum, pageSize, filter }));
    },
    [dispatch]
  );

  useEffect(() => {
    const filter = searchParams.toString();
    setFilterCount(
      Object.keys(Object.fromEntries(new URLSearchParams(filter))).length
    );
    getVendorData(1, 25, filter);
  }, [dispatch, getVendorData, searchParams]);

  useEffect(() => {
    if (status === "loading") {
      message.loading({ content: "Loading Vendors", key: MESSAGE_KEY });
    } else if (status === "success") {
      message.success({
        content: "Vendors loaded!",
        key: MESSAGE_KEY,
      });
    } else if (status === "error") {
      message.error({ content: error, key: MESSAGE_KEY });
    }

    return () => {
      message.destroy();
    };
  }, [status, error]);

  useEffect(() => {
    const vendorData = vendors && vendors.data ? vendors.data : [];
    const data: PartialVendorType[] = vendorData.map((vendor, i) => {
      const {
        id,
        vendorCode,
        extVendorId,
        address,
        name,
        pocName,
        email,
        primaryPhone,
        secondaryPhone,
        whatsappPhone,
        location,
        status,
      } = vendor;

      const newRow = {
        id,
        vendorCode,
        address,
        name,
        pocName,
        email,
        primaryPhone,
        secondaryPhone,
        whatsappPhone,
        extVendorId,
        location,
        status,
      };
      return { ...newRow, key: `req-${i}`, ellipses: true };
    });

    const tableHeaders: ColumnsType<PartialVendorType> = [
      ...Object.keys(headerKeys).map((key) => ({
        key,
        title: headerKeys[key],
        dataIndex: key,
        render: (text: string, record: PartialVendorType) => {
          if (key === "id") {
            return <Button type="link" onClick={() => navigate(`/vendors/edit/${record.id}`)}>{record.vendorCode ?? record.id}</Button>;
          }
          if (key === "address") {
            return (
              <>
                <div>{record.address?.address1}</div>
                <div>{record.address?.address2}</div>
                <div>
                  {record.address?.city}, {record.address?.state}
                </div>
                <div>{record.address?.pincode}</div>
              </>
            );
          }

          if (key === "supplyLocation") {
            return (
              <>
                <div>{record.location}</div>
              </>
            );
          }

          if (key === "contactNumber") {
            return (
              <>
                <StyledContactItem>
                  <BsTelephone />
                  <a href={`tel:${record.primaryPhone}`}>
                    {record.primaryPhone}
                  </a>
                </StyledContactItem>
                {record.secondaryPhone && (
                  <StyledContactItem>
                    <BsTelephone />
                    <a href={`tel:${record.secondaryPhone}`}>
                      {record.secondaryPhone}
                    </a>
                  </StyledContactItem>
                )}
                {record.whatsappPhone && (
                  <StyledContactItem>
                    <BsWhatsapp />
                    <a
                      href={`https://wa.me/${record.whatsappPhone}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {record.whatsappPhone}
                    </a>
                  </StyledContactItem>
                )}
              </>
            );
          }

          // if (key === "email") {
          //   return (
          //     <StyledContactItem>
          //       <MdOutlineEmail />
          //       <a href={`mailto: ${record.email}`}>{record.email}</a>
          //     </StyledContactItem>
          //   );
          // }

          if (key === "status") {
            return <Switch checked={record.status === "ACTIVE"} onChange={(e) => onStatusToggle(e, record.id!)} />
          }

          if (key === "actions") {
            return (
              <Row
                align="middle"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <Col>
                  <StyledActionLink to={`/vendors/edit/${record.id}`}>
                    <FiEdit2 />
                  </StyledActionLink>
                </Col>
              </Row>
            );
          }

          return <>{text}</>;
        },
      })),
    ];

    setColumns(tableHeaders);
    setRows(data);
  }, [vendors]);

  const redirectToNewVendorPage = () => {
    navigate("new");
  };

  const onStatusToggle = useCallback((isActive: boolean, vendorId: number) => {
    dispatch(updateVendorStatus({ vendorId, isActive }))
      .unwrap()
      .then(() => {

      });
  }, [dispatch, navigate, vendors]);

  const Paginator = () => {
    if (vendors) {
      const { pageNo, pageSize, total } = vendors;
      return (
        <StyledPagination
          current={pageNo}
          pageSize={pageSize}
          total={total}
          onChange={(page, size) => {
            const filter = searchParams.toString();
            getVendorData(page, size, filter);
          }}
        />
      );
    }

    return <></>;
  };

  return (
    <>
      <Row>
        <Col>
          <AppPageHeader title="All Vendors" />
        </Col>
      </Row>
      <Row>
        <AppActionStrip>
          <div className="action-strip__item">
            <AppFilterButton
              active={filterState}
              onClick={() => setFilterState(!filterState)}
              filterCount={filterCount}
            />
          </div>
          <Button
            onClick={redirectToNewVendorPage}
            type="primary"
            className="action-strip__item"
            icon={<BsPlus />}
          >
            New Vendor
          </Button>
        </AppActionStrip>
      </Row>
      <Row style={{ marginBottom: "1rem" }}>
        <AppFilter expanded={filterState}>
          <AppVendorFilter />
        </AppFilter>
      </Row>
      {rows && rows.length ? (
        <Row>
          <Col xs={24}>
            <Table
              columns={columns}
              dataSource={rows}
              scroll={{ x: "100vh", y: "65vh" }}
              size="small"
              pagination={false}
              loading={status === "loading"}
            />
          </Col>
          <Col xs={24}>
            <Paginator />
          </Col>
        </Row>
      ) : (
        status !== "loading" && <>No Vendors to show.</>
      )}
    </>
  );
};

export default VendorHomePage;
