import {
  Breadcrumb,
  Button,
  Col,
  Descriptions,
  Form,
  Input,
  message,
  Row,
  Select,
  Table,
  Typography,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import { IPo, IPoItem } from "common/interface";
import { getDateAndTimeFromISODate } from "common/utils";
import { variables } from "common/variables";
import { AppPageHeader } from "components/AppPageHeader";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useDocumentTitle } from "hooks/usePageTitle";
import React, { useCallback, useEffect, useState } from "react";
import { BsPlus } from "react-icons/bs";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { closePO, getPoById, savePoItem } from "store/po/poSlice";

const MESSAGE_KEY = "po_info";
type PartialIPoItemType = Partial<IPoItem> & { key: string };

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "select" | "text";
  record: PartialIPoItemType;
  index: number;
  children: React.ReactNode;
}

const { Option } = Select;

const GRNViewPage = () => {
  useDocumentTitle("Purchase Order GRN");
  const dispatch = useAppDispatch();
  const { pos, error, status } = useAppSelector((state) => state.po);
  const { masterSkus = [] } = useAppSelector((state) => state.requisition);
  const { poId } = useParams();
  const [viewedPO, setViewedPO] = useState<IPo>();
  const [tableColumns, setTableColumns] =
    useState<ColumnsType<PartialIPoItemType>>();
  const [tableRows, setTableRows] = useState<any[]>();
  const [tempTableRows, setTempTableRows] = useState<any[]>();
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const [isAddingItem, setIsAddingItem] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (editingKey) {
      const selectedRowData = viewedPO?.poItems.find(e => e.id.toString() === editingKey.toString().split("-")[0]);
      form.setFieldsValue({
        skuId: masterSkus.find(k => k.skuCode === selectedRowData?.skuCode)?.id.toString(),
      });
    }
  }, [editingKey, form]);

  const isEditing = useCallback(
    (record: PartialIPoItemType) => record.key === editingKey,
    [editingKey]
  );

  const edit = useCallback(
    (record: PartialIPoItemType) => {
      form.setFieldsValue({ ...record });
      setEditingKey(record.key);
    },
    [form]
  );

  const saveItem = useCallback(
    (poId: string, poItem: IPoItem) => {
      // poItem.totalAmount = poItem.requestedQty * poItem.poRate;
      dispatch(savePoItem({ poId, poItem }));
    },
    [dispatch]
  );

  const save = useCallback(
    async (poId: string, poItemId: string) => {
      try {
        const row = await form.validateFields();
        const index = masterSkus.findIndex(
          (item: any) => row.skuId === item.id.toString()
        );

        if (viewedPO) {
          if (index > -1) {
            saveItem(poId, {
              ...row,
              skuCode: masterSkus[index].skuCode,
              skuName: masterSkus[index].name,
              id: poItemId,
            });
            setEditingKey("");
          } else {
            saveItem(poId, row);
            setEditingKey("");
          }
        }

        setTempTableRows([]);
        setIsAddingItem(false);
        form.resetFields();
      } catch (errInfo) {
        console.log("Validate Failed:", errInfo);
      }
    },
    [form, masterSkus, saveItem, viewedPO]
  );

  useEffect(() => {
    if (poId) {
      dispatch(getPoById(poId));
    }
  }, [poId, dispatch]);

  useEffect(() => {
    if (status === "loading") {
      message.loading({ content: "loading purchase order", key: MESSAGE_KEY });
    } else if (status === "saving") {
      message.loading({ content: "saving purchase order", key: MESSAGE_KEY });
    } else if (status === "error") {
      message.error({ content: error, key: MESSAGE_KEY });
    }

    return () => {
      message.destroy();
    };
  }, [status, error]);

  useEffect(() => {
    const thisPO = pos?.data.find((p) => p.id.toString() === poId);
    if (thisPO) {
      setViewedPO(thisPO);
    } else {
      navigate("/po/all");
    }
  }, [navigate, poId, pos?.data]);

  const cancel = useCallback(() => {
    setEditingKey("");
    setIsAddingItem(false);
    setTempTableRows([]);
    form.resetFields();
  }, [form]);

  const onClosePo = useCallback(() => {
    const id = Number(viewedPO!.id);

    if (!isNaN(id)) {
      if (viewedPO?.poItems.every(item => (item.grossReceivedQty != undefined))) {
        dispatch(closePO({ id }))
        .unwrap()
        .then(() => navigate("/po/all"));
      }
      else {
        message.error("The Gross Receive Qty is not present for all fields. Please update the same.", 3);
      }
    }
  }, [dispatch, navigate, viewedPO]);

  useEffect(() => {
    const thisPO = pos?.data?.find((po) => po.id.toString() === poId);

    const headerKeys: { [key: string]: string } = {
      skuId: "SKU ID/Name",
      uom: "UOM",
      // extPoIds: "Ext PO ID",
      rtvAllowed: "RTV Allowed",
      requestedQty: "Requested Quantity",
      ...(viewedPO?.status !== "ISSUED" && {
        grossReceivedQty: "Gross Rcv. Qty.",
      }),
      ...(viewedPO?.status !== "ISSUED" && { dockRtv: "Dock RTV" }),
      // ...(viewedPO?.status !== "ISSUED" && { netReceivedQty: "Net Rcv. Qty." }),
      ...(viewedPO?.status !== "ISSUED" && { sortingRtv: "Sorting RTV" }),
      ...(viewedPO?.status !== "ISSUED" && { rejectedQty: "Rejected Qty" }),
      ...(viewedPO?.status !== "ISSUED" && { finalReceivedQty: "Final Receive Qty." }),
      // poRate: "PO Rate",
      // billingRate: "Billing Rate",
      // totalAmount: "Total Amount",
      // actions: "Actions",
    };

    if (thisPO) {
      const columns = [
        ...Object.keys(headerKeys).map((key) => ({
          key,
          title: headerKeys[key],
          dataIndex: key,
          editable: key !== "actions" && true,
          render: (text: string, record: any) => {
            if (key === "skuId") {
              return (
                <>
                  <div>{record.skuCode}</div>
                  <div>{record.skuName}</div>
                </>
              );
            }

            if (key === "extPoIds") {
              return ((record.extPoIds as string[]) || []).map(
                (exPoId, idx) => (
                  <div key={record.challanId + exPoId + `-${idx}`}>
                    {exPoId}
                  </div>
                )
              );
            }

            if (key === "rtvAllowed") {
              if (record.rtvAllowed === undefined) {
                return <div></div>;
              }
              else {
                return (
                  <div>
                    {record.rtvAllowed ? "Yes" : "No"}
                  </div>
                );
              }
            }

            if (key === "actions") {
              const editable = isEditing(record);
              return editable ? (
                <Row
                  align="middle"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  <Col style={{ marginRight: ".5rem" }}>
                    <Typography.Link
                      onClick={() => save(thisPO.id, record.id)}
                      disabled={status === "loading"}
                      style={{ marginRight: 8 }}
                    >
                      Save
                    </Typography.Link>
                  </Col>
                  <Col>
                    <Typography.Link
                      onClick={cancel}
                      style={{ marginRight: 8 }}
                      type="secondary"
                      disabled={status === "loading"}
                    >
                      Cancel
                    </Typography.Link>
                  </Col>
                </Row>
              ) : (
                <Typography.Link
                  disabled={editingKey !== "" || viewedPO?.status !== "ISSUED"}
                  onClick={() => edit(record)}
                >
                  Edit
                </Typography.Link>
              );
            }
            return <>{text}</>;
          },
        })),
      ];

      const mergedColumns = columns.map((col, i) => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          ...(i === 0 && { width: "220px" }),
          onCell: (record: PartialIPoItemType) => ({
            record,
            inputType: ["skuId", "rtvAllowed"].includes(col.dataIndex) ? "select" : "text",
            dataIndex: col.dataIndex,
            title: col.title,
            editing: isEditing(record),
          }),
        };
      });

      const rows: any[] =
        thisPO?.poItems
          ?.map((poItem, idx) => ({
            id: poItem.id,
            skuId: poItem.id,
            key: poItem.id + `-${idx}`,
            skuCode: poItem.skuCode,
            skuName: poItem.skuName,
            extPoIds: poItem.extPoIds,
            requestedQty: poItem.requestedQty,
            rtvAllowed: poItem.rtvAllowed,
            grossReceivedQty: poItem.grossReceivedQty,
            dockRtv: poItem.dockRtv,
            // netReceivedQty: poItem.netReceivedQty,
            sortingRtv: poItem.sortingRtv,
            rejectedQty: poItem.rejectedQty,
            uom: poItem.uom,
            poRate: poItem.poRate,
            billingRate: poItem.billingRate,
            totalAmount: poItem.totalAmount,
            finalReceivedQty: poItem.finalReceivedQty,
          }))
          .filter(
            (poI) => (isAddingItem && !(poI as any).temp) || !isAddingItem
          ) || [];

      setViewedPO(thisPO);
      setTableRows([...rows, ...(tempTableRows || [])]);
      setTableColumns(mergedColumns);
    }
  }, [
    cancel,
    edit,
    editingKey,
    form,
    isAddingItem,
    isEditing,
    poId,
    pos?.data,
    save,
    tempTableRows,
    viewedPO?.poItems,
    viewedPO?.status,
  ]);

  // const onReceive = useCallback(async () => {
  //   try {
  //     const row = await form.validateFields();
  //     let thisPo: Partial = viewedPO?.poItems.find((po) => po.id.toString() === viewedPO?.id);
  //     if (thisPo) {
  //       thisPo = {
  //         ...thisPo,
  //         receivedDate: moment(row.receivedDate).toISOString(),
  //       };
  //       if (viewedPO?.poItems.every((poItem) => 
  //       poItem.grossReceivedQty !== null 
  //       && poItem.netReceivedQty !== null
  //       )) {
  //         dispatch(receivePO(thisPo))
  //           .unwrap()
  //           .then(() => navigate(`/po/${viewedPO?.id}`));
  //       }
  //       else {
  //         message.error("The Gross Receive Qty is not present for all fields. Please update the same.", 3);
  //       }

  //     }
  //   } catch (e) {
  //     console.error(e);
  //   }
  // }, [dispatch, form, navigate, viewedPO?.id]);

  const getSkuOptions = (includeSku?: string) => {
    if (masterSkus) {
      const selectedSkus = viewedPO?.poItems
        .filter((poi) => poi.skuCode !== includeSku)
        .map((poI) => poI.skuCode);
      return masterSkus.reduce((acc, sku) => {
        if (!selectedSkus?.includes(sku.skuCode)) {
          return (acc = {
            ...acc,
            [sku.id]: {
              value: sku.id,
              label: sku.skuCode,
              skuCode: sku.skuCode,
              skuName: sku.name,
            },
          });
        }

        return acc;
      }, {} as Record<string, { skuCode: string; skuName: string }>);
    }

    return {};
  };

  const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    let inputNode =
      inputType === "select" ? (
        <>
          <div>{record.skuCode}</div>
          <div>{record.skuName}</div>
        </>
      ) : (
        <Input readOnly={dataIndex === 'totalAmount'} />
      );
    const isFieldBoolean = dataIndex === "rtvAllowed";
    if (dataIndex === "rtvAllowed") {
    }
    if ((isAddingItem || isEditing) && inputType === "select") {
      inputNode = <Select
        showSearch
        optionFilterProp=""
        filterOption={(input, option) => {
          const keyWords =
            option?.children?.map((child) =>
              child.props.children.toLowerCase()
            ) || [];
          return keyWords.some((key) => key.includes(input.toLowerCase()));
        }}
        style={!isFieldBoolean ? { width: "200px" } : {}}
      >
        {!isFieldBoolean ? Object.entries(getSkuOptions(record.skuCode)).map(
          ([key, value]) => (
            <Option key={key} value={key}>
              <div>{value.skuCode}</div>
              <div>{value.skuName}</div>
            </Option>
          )
        ) : <>
          <Option key={`${dataIndex}_option_1`} value={true}>
            <div>Yes</div>
          </Option>
          <Option key={`${dataIndex}_option_1`} value={false}>
            <div>No</div>
          </Option>
        </>
        }
      </Select>
    }
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required:
                  !(dataIndex === "totalAmount"),
                message: `Please ${inputType === "select" ? "select" : "input"
                  } ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const onAddItem = () => {
    let idx = tableRows?.length || 0;
    const key = (++idx).toString();
    setTempTableRows([...(tempTableRows || []), { key, temp: true, }]);
    setEditingKey(key);
    setIsAddingItem(true);

    setTimeout(() => {
      document
        .querySelector(".editable-last-row")
        ?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  const poInfo = viewedPO && (
    <>
      <Descriptions
        size="small"
        layout="vertical"
        labelStyle={{ fontWeight: "bold" }}
        bordered
        column={{ xs: 2, md: 3 }}
      >
        {viewedPO.poId && <Descriptions.Item label="PO ID">
          {viewedPO.poId}
        </Descriptions.Item>}
        <Descriptions.Item label="Status">{viewedPO.status}</Descriptions.Item>
        {(["ISSUED", "ISSUE_DRAFT", "RECEIVED_DRAFT"].includes(viewedPO.status)) && (
          <Descriptions.Item label="Actions">
            <Row>
              <Col style={{ marginRight: "20px" }}>
                <Button onClick={onClosePo} disabled={status === "saving"} loading={status === "saving"}>
                  Close GRN
                </Button>
              </Col>
              <Col style={{ marginRight: "20px" }}>
                <Button
                  type="primary"
                  onClick={() => navigate(`/po/${viewedPO.id}/receive`)}
                >
                  Receive
                </Button>
              </Col>
            </Row>
          </Descriptions.Item>
        )}
        {/* <Col>
          <Button
            type="primary"
            onClick={onReceive}
            disabled={status === "saving"}
          >
            Save & Receieve
          </Button>
        </Col> */}
      </Descriptions>

      {viewedPO.vendorDetails && <Descriptions
        size="small"
        layout="vertical"
        labelStyle={{ fontWeight: "bold" }}
        bordered
        column={{ xs: 2, md: 3 }}
        style={{ marginTop: '20px' }}
      >
        <Descriptions.Item label="Vendor Detail"> 
        {viewedPO.vendorDetails?.vendorCode} || {viewedPO.vendorDetails?.name}
        </Descriptions.Item>
      </Descriptions>}

      {(viewedPO.expectedDeliveryDate || viewedPO.receivedDate) && <Descriptions
        size="small"
        layout="vertical"
        labelStyle={{ fontWeight: "bold" }}
        bordered
        column={2}
        style={{ marginTop: "20px" }}
      >
        {viewedPO.expectedDeliveryDate && <Descriptions.Item label="Expected Delivery Date">
          {getDateAndTimeFromISODate(viewedPO.expectedDeliveryDate)}
        </Descriptions.Item>}
        {viewedPO.receivedDate && <Descriptions.Item label="Receiving Date">
          {getDateAndTimeFromISODate(viewedPO.receivedDate)}
        </Descriptions.Item>}
      </Descriptions>}

      {!!(tableRows && tableRows.length) && (
        <Row style={{ marginTop: "20px" }}>
          <Col xs={24}>
            <Form form={form} component={false}>
              <Table
                className="po-item-table"
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                bordered
                dataSource={tableRows}
                columns={tableColumns}
                rowClassName={(record, index) =>
                  index === tableRows.length - 1
                    ? "editable-last-row editable-row"
                    : "editable-row"
                }
                pagination={false}
                scroll={{ x: "100vh", y: "65vh" }}
                style={{ width: "100%" }}
                size="small"
                // footer={() => (
                //   <Button
                //     type="primary"
                //     icon={<BsPlus />}
                //     onClick={onAddItem}
                //     disabled={isAddingItem || viewedPO.status !== "ISSUED"}
                //   >
                //     Add Item
                //   </Button>
                // )}
              />
            </Form>
          </Col>
        </Row>
      )}
    </>
  );

  return (
    <>
      <Row
        style={{
          margin: "1rem 0",
          position: "sticky",
          top: 0,
          zIndex: 1,
          background: variables.wmBackgroundColorLight1,
          padding: "5px",
        }}
      >
        <Col xs={24}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to="/po/all">Purchase Orders GRN</NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {viewedPO?.poId ?? "Purchase Order GRN"}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col>
          <AppPageHeader title="Purchase Order GRN" />
        </Col>
      </Row>
      <Row>
        <Col xs={24}>{poInfo}</Col>
      </Row>
    </>
  );
};

export default GRNViewPage;
