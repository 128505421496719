import { Button, Col, Form, Input, Row, Select } from "antd";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { fetchWarehouses } from "store/stock-exchange/stockExchangeSlice";
import styled from "styled-components";

const StyledFilterContainer = styled.div`
  &,
  & .filter {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    &__item {
      margin: 0.5rem;

      & .ant-form-item {
        margin: 0;
      }

      &--submit {
        align-self: flex-end;
      }
    }
  }
`;

export const AppStockExchangeFilter = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [form] = Form.useForm();

    useEffect(() => {
        const params = Object.fromEntries(new URLSearchParams(searchParams));
        form.setFieldsValue(params);
    }, [form, searchParams]);

    const onSearch = (values: Record<string, string>) => {
        const params: Record<string, string> = {};
        Object.entries(values).forEach(([key, value]) => {
            if (value) {
                params[key] = value;
            }
        });
        setSearchParams(params);
    };

    const { warehouses } = useAppSelector(
        (state) => state.stockExchangeServices
    );

    const { whId: srcWhId } = useAppSelector(
        (state) => state.app
    );
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchWarehouses());
      }, []);

    return (
        <StyledFilterContainer>
            <Form
                form={form}
                name="vendor-filter-form"
                onFinish={onSearch}
                layout="vertical"
                style={{ width: "100%" }}
            >
                <Row className="filter">

                    {warehouses.length && <Col xs={20} sm={4} md={5} lg={4} className="filter__item">
                        <Form.Item name="whIdDest" label="Destination Warehouse">
                            <Select
                            allowClear
                                showSearch
                                filterOption={(input, option) => {
                                    return option?.children?.toString().toLowerCase().includes(input?.toLowerCase() ?? "") ?? false;
                                }}
                            >
                                {warehouses.filter(e => e.id !== srcWhId).map((storeInfo) => (
                                    <Select.Option value={storeInfo.id?.toString()} key={storeInfo.id}>
                                        {storeInfo.name} # {storeInfo.id}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>}
                    {/* <Col xs={20} sm={4} md={5} lg={4} className="filter__item">
            <Form.Item name="whIdDest" label="Destination WH">
              <Input placeholder="Search by " size="small" allowClear />
            </Form.Item>
          </Col> */}

                    <Col
                        xs={20}
                        sm={4}
                        md={2}
                        lg={2}
                        className="filter__item filter__item--submit"
                    >
                        <Form.Item>
                            <Button type="primary" htmlType="submit" size="small">
                                Search
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </StyledFilterContainer>
    );
};
