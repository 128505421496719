import {
    Row,
    Col,
    Button,
    message,
    Form,
    Select, InputNumber,
} from "antd";
import { AppPageHeader } from "components/AppPageHeader";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useDocumentTitle } from "hooks/usePageTitle";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getWarehouseId } from "services/PersistService/PersistWareHouseReturn";
import { addWhSku } from "store/franchise/franchiseSlice";
import { fetchMasterSkus } from "store/requisition/requisitionSlice";
import { fetchWarehouses } from "store/stock-exchange/stockExchangeSlice";

const MESSAGE_KEY = "CREATE_WH_SKU";
export const AddNewWhSkuPage = () => {
    useDocumentTitle("Add New WH SKU");
    const { status, error, } = useAppSelector(
        (state) => state.franchiseServices
    );

    const { masterSkus = [], } = useAppSelector(
        (state) => state.requisition
    );
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(fetchWarehouses());
    }, []);

    useEffect(() => {
        if (status === "loading") {
            message.loading({ content: "Loading", key: MESSAGE_KEY });
        } else if (status === "success") {
            message.success({
                content: "WH SKU Added!",
                key: MESSAGE_KEY,
            });
            form.resetFields();
            navigate(-1);
        } else if (status === "saving") {
            message.loading({
                content: "Saving WH SKU",
                key: MESSAGE_KEY,
            });
        } else if (status === "error") {
            message.error({ content: error, key: MESSAGE_KEY });
        }

        return () => {
            message.destroy();
        };
    }, [status, error]);

    useEffect(() => {
        dispatch(fetchMasterSkus());
        return () => {
            message.destroy();
        };
    }, []);

    const [form] = Form.useForm();
    const onSave = async () => {
        try {
            await form.validateFields();
            await dispatch(addWhSku({ ...form.getFieldsValue(), "whId": getWarehouseId(), }));
        }
        catch (e) {
            //Place logger here
        }
    };

    const onCancelSave = () => {
        navigate(-1);
    };

    return (
        <>
            <Row>
                <Col>
                    <AppPageHeader title="Add New Warehouse SKU" />
                </Col>
            </Row>
            <Form
                form={form}
                name="vendor-filter-form"
                layout="vertical"
                style={{ width: "50%" }}
                initialValues={{"startOrderQty": "1"}}
            >
                <Form.Item name="skuCode" label="Sku Code" rules={[
                    {
                        required: true,
                    },
                ]}>
                    <Select
                        options={masterSkus.map(masterSku => ({
                            value: masterSku.skuCode,
                            label: `${masterSku.skuCode} # ${masterSku.name} `,
                        }))}
                    />
                </Form.Item>
                <Form.Item name="moq" label="MOQ" rules={[
                    {
                        required: true,
                    },
                ]}>
                    <InputNumber placeholder="MOQ" size="small" required style={{ width: "100%" }} min={0} />
                </Form.Item>
                <Form.Item name="salePrice" label="Sale Price" rules={[
                    {
                        required: true,
                    },
                ]}>
                    <InputNumber placeholder="Sale Price" size="small" required style={{ width: "100%" }} min={0} />
                </Form.Item>
                <Form.Item name="sortedRetailPrice" label="Sorted Retail Price" rules={[
                    {
                        required: true,
                    },
                ]}>
                    <InputNumber placeholder="Sorted Retail Price" size="small" required style={{ width: "100%" }} min={0} />
                </Form.Item>
                <Form.Item name="marginDiscount" label="Margin Discount" rules={[
                    {
                        required: true,
                    },
                ]}>
                    <InputNumber placeholder="Margin Discount" size="small" required style={{ width: "100%" }} min={0} max={100} prefix={"%"} />
                </Form.Item>
                <Form.Item name="actualQty" label="Quantity" rules={[
                    {
                        required: true,
                    },
                ]}>
                    <InputNumber placeholder="Quantity" size="small" required style={{ width: "100%" }} min={0} />
                </Form.Item>
                <Form.Item name="startOrderQty" label="Start Order Quantity" rules={[
                    {
                        required: true,
                    },
                ]}>
                    <InputNumber placeholder="Start Order Quantity" size="small" required style={{ width: "100%" }} min={1}/>
                </Form.Item>
                <Form.Item name="maxOrderQty" label="Max Order Qty" rules={[
                ]} >
                    <InputNumber placeholder="Max Order Qty" size="small" required style={{ width: "100%" }} min={0} />
                </Form.Item>
                <Form.Item
                    label="App Active"
                    name="appActive"
                    rules={[
                        {
                            required: true,
                        },
                    ]}>
                    <Select
                        options={[
                            {
                                value: 1,
                                label: 'True',
                            }, {
                                value: 0,
                                label: 'False',
                            },
                        ]}
                        style={{ width: "100%" }}
                    />
                </Form.Item>
            </Form>
            <Row>
                <Col>
                    <Button
                        type="primary"
                        onClick={onSave}
                        loading={status === "loading"}
                        disabled={status === "loading"}
                    >
                        Add Sku
                    </Button>
                </Col>
                <Col style={{ justifyContent: "left", marginLeft: "20px" }}>
                    <Button
                        onClick={onCancelSave}
                        disabled={status === "loading"}
                    >
                        Cancel
                    </Button>
                </Col>
            </Row>
        </>
    );
};
