import { IPickerMappingUpload } from "common/interface/IPickerUploadScheme";

const STORAGE_KEY_WH_RETURN = "processingWarehouseReturnMapping";
const WAREHOUSE_ID = "warehouseId";

export const persistProcessingWhReturnMapping = (data: IPickerMappingUpload) => {
  localStorage.setItem(STORAGE_KEY_WH_RETURN, JSON.stringify(data));
}

export const getPersistProcessingWhReturnMapping = (): IPickerMappingUpload => {
  const processingWhReturnMapping = localStorage.getItem(STORAGE_KEY_WH_RETURN);
  return processingWhReturnMapping ? JSON.parse(processingWhReturnMapping) : {};
}

export const removePersistProcessingWhReturnMapping = () => {
    localStorage.removeItem(STORAGE_KEY_WH_RETURN);
}

export const setWarehouseId = (whId: number) => {
  localStorage.setItem(WAREHOUSE_ID, whId.toString());
}

export const getWarehouseId = (): number | undefined => {
  const processingWhReturnMapping = localStorage.getItem(WAREHOUSE_ID);
  return processingWhReturnMapping ? parseInt(processingWhReturnMapping) : undefined;
}

export const removeWarehouseId = () => {
    localStorage.removeItem(WAREHOUSE_ID);
}

